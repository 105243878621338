export const getDirtyValues = ({ values, initialValues }) => {
  const data = { ...values }
  const keyValues = Object.keys(data)

  const dirtyValues = keyValues.filter(
    (keyValue) => data[keyValue] !== initialValues[keyValue]
  )

  keyValues.forEach((key) => {
    if (!dirtyValues.includes(key)) delete data[key]
  })

  return data
}
