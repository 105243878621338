import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { CalendarDaysIcon } from '@heroicons/react/24/solid'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker, { registerLocale } from 'react-datepicker'
import { de, enUS as en } from 'date-fns/locale'
import { format } from 'date-fns'

import { useLanguageContext } from '../../context'

registerLocale('de', de)
registerLocale('en', en)

const CalendarButton = forwardRef(({ date, onClick }, ref) => (
  <button
    className='flex items-center rounded-full bg-white py-1.5 px-4 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
    onClick={onClick}
    ref={ref}
  >
    <CalendarDaysIcon className='mr-1.5 w-6 h-6 text-gray-700' />
    {format(date, 'dd.MM.yyyy')}
  </button>
))

CalendarButton.propTypes = {
  date: PropTypes.object.isRequired,
  onClick: PropTypes.func
}

CalendarButton.defaultProps = {
  onClick: () => undefined
}

const Datepicker = ({ selected, onChange, minDate, maxDate, customInput }) => {
  const { language } = useLanguageContext()

  return (
    <DatePicker
      locale={language}
      selected={selected}
      onChange={onChange}
      minDate={minDate}
      maxDate={maxDate}
      customInput={customInput || <CalendarButton date={selected} />}
    />
  )
}

Datepicker.propTypes = {
  selected: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  minDate: PropTypes.object.isRequired,
  maxDate: PropTypes.object,
  customInput: PropTypes.node
}

Datepicker.defaultProps = {
  customInput: null,
  maxDate: undefined
}

export default Datepicker
