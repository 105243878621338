import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import SelectMenu from '../select-menu'

const Careers = ({ careers, onChange }) => {
  const { t } = useTranslation()

  return (
    <div className='max-w-lg'>
      <div className='mb-3'>
        <span className='block text-sm font-medium  text-gray-900 mb-1'>
          {t('team_analyse.careers.title')}
        </span>

        <p className='max-w-4xl text-sm text-gray-500'>
          {t('team_analyse.careers.description')}
        </p>
      </div>
      <SelectMenu
        id='career'
        options={[
          [-1, t('team_analyse.careers.select_menu_empty')],
          ...careers
            .filter(({ archived }) => !archived)
            .filter(({ id }) => !id.includes('sample_'))
            .map(({ id, title, external_custom_id }) => [
              id,
              title,
              external_custom_id && `| ${external_custom_id}`
            ])
        ]}
        onChange={onChange}
        defaultValue={-1}
        translate={false}
      />
    </div>
  )
}

Careers.propTypes = {
  careers: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
}

export default Careers
