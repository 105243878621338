import { useState } from 'react'
import { TrashIcon as TrashIconOutline } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { Buffer } from 'buffer'
import { useQuery } from '@tanstack/react-query'

import SettingsContainer from './settings-container'
import ApiKey from './api-key'
import { uuidv4 } from '../../helpers'
import SEO from '../seo'
import { mutation, query } from '../../graphql'
import { ActivityIndicator } from '..'
import {
  useNotificationContext,
  useUserContext,
  usePartnerContext
} from '../../context'

const TRANSLATION_LOCATION = 'components.settings.api_keys.'

const ApiKeys = () => {
  const { t } = useTranslation()
  const { success, alert } = useNotificationContext()
  const { active_space } = useUserContext()
  const { partner } = usePartnerContext()

  const [isLoading, setIsLoading] = useState(false)

  const {
    fetchStatus,
    data: apiKey,
    refetch
  } = useQuery({
    queryKey: ['api-keys-by-partner', partner.id],
    queryFn: () =>
      query({
        query: 'apiKeysByPartner',
        variables: { partner_id: partner.id }
      }),
    select: (items) => items[0] || null,
    refetchOnWindowFocus: false
  })

  const createApiKey = async () => {
    setIsLoading(true)

    const auth_token = 'Bearer ' + Buffer.from(uuidv4()).toString('base64')

    mutation({
      mutation: 'createApiKey',
      input: {
        auth_token,
        partner_id: partner.id,
        authorized_create: ['__create_' + partner.id],
        authorized_read: ['__read_' + partner.id],
        authorized_update: ['__update_' + partner.id],
        authorized_delete: ['__delete_' + partner.id],
        owner: active_space.owner
      }
    })
      .then(() => {
        refetch()
        success(t(TRANSLATION_LOCATION + 'create_api_key_success'))
        setIsLoading(false)
      })
      .catch(() => setIsLoading(false))
  }

  const deleteApiKey = async ({ id }) => {
    alert(
      t(TRANSLATION_LOCATION + 'modal_delete_api_key_title'),
      t(TRANSLATION_LOCATION + 'modal_delete_api_key_text'),
      [
        {
          onPress: async () => {
            setIsLoading(true)

            try {
              await mutation({ mutation: 'deleteApiKey', input: { id } })
            } catch (err) {
              setIsLoading(false)
              return
            }

            refetch()
            success(
              t(TRANSLATION_LOCATION + 'delete_api_key_success_description')
            )
            setIsLoading(false)
          },
          text: t(TRANSLATION_LOCATION + 'modal_delete_api_key_action')
        },
        {
          text: t(TRANSLATION_LOCATION + 'modal_cancel_action'),
          style: 'cancel'
        }
      ],
      <TrashIconOutline className='h-6 w-6' />
    )
  }

  const getApiKey = ({ apiKey }) => {
    const { auth_token } = apiKey

    return auth_token.replace('Bearer ', '')
  }

  const is_loading = fetchStatus === 'fetching' || isLoading

  return (
    <SettingsContainer
      title={t(TRANSLATION_LOCATION + 'settings_container_title')}
      description={t(TRANSLATION_LOCATION + 'settings_container_description')}
    >
      <SEO title={t('seo.titles.settings_ats')} />
      <div className='relative'>
        <legend className='text-base font-medium text-gray-900'>API-Key</legend>
        {apiKey && !is_loading && (
          <div className='mt-2'>
            <ApiKey
              apiKey={getApiKey({ apiKey })}
              deleteApiKey={() => deleteApiKey(apiKey)}
            />
          </div>
        )}
        {!apiKey && !is_loading && (
          <button
            type='button'
            className='mt-2 relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-4 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
            onClick={createApiKey}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='mx-auto h-8 w-8 text-gray-400'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4'
              />
            </svg>
            <span className='mt-2 block text-sm italic font-medium text-gray-600'>
              {t(TRANSLATION_LOCATION + 'create_api_key_description')}
            </span>
          </button>
        )}
        {is_loading && (
          <div className='h-16 rounded-md flex justify-center items-center'>
            <ActivityIndicator className='' />
          </div>
        )}
      </div>
    </SettingsContainer>
  )
}

export default ApiKeys
