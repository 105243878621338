import { useMutation, useQueryClient } from '@tanstack/react-query'

import { mutation as mutate } from '../graphql'

export const useMutationAndUpdateQuery = ({ mutation, queryKey, updater }) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ input }) => mutate({ mutation, input }),
    onSuccess: (response) => {
      queryClient.setQueryData(
        queryKey,
        updater ? (prevData) => updater({ prevData, response }) : response
      )
    }
  })
}

// [] add error handling
