import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import initials from 'initials'
import { useNavigate } from 'react-router'
import { Tooltip } from 'react-tooltip'

import { trackEvent } from '../../helpers/analytics'
import trackEvents from '../../constants/track-events'

import { useChartData } from '../../hooks/use-chart-data'

const Spaces = ({ spaces }) => {
  const { t } = useTranslation()
  const { data } = useChartData({ spaces })

  const navigate = useNavigate()

  return (
    <div className='flex flex-col lg:flex-row justify-between items-start gap-6'>
      <div className='w-full max-w-lg'>
        <div className=''>
          <h3 className='text-xl font-semibold leading-6 text-gray-900'>
            {t('team_analyse.spaces.title')}
          </h3>
          <p className='mt-2 max-w-4xl text-sm text-gray-500'>
            {t('team_analyse.spaces.description')}
          </p>
        </div>

        <ul role='list' className='flex flex-row mt-4 ml-4 relative'>
          {data.spaces.map((space, index) => {
            return (
              <span
                data-tooltip-id='teamanalyse-talent'
                data-tooltip-content={space.initials || space.identification}
                data-tooltip-place='bottom'
                onClick={() => {
                  trackEvent(trackEvents.VIEW_TALENT_TEAMANALYSIS)
                  navigate(`/talent/${space.id}`)
                }}
                key={index}
                style={{
                  position: 'absolute',
                  left: index * 33,
                  backgroundColor: space.borderColor
                }}
                className='inline-flex h-10 w-10 items-center justify-center rounded-full -ml-2 border border-white cursor-pointer hover:opacity-80'
              >
                <span className='font-medium leading-none text-white'>
                  {initials(
                    space.initials || space.identification.substring(0, 3)
                  )}
                </span>
                <Tooltip
                  id='teamanalyse-talent'
                  className='text-sm font-semibold z-10'
                />
              </span>
            )
          })}
        </ul>
      </div>
      <div className='mt-6 lg:mt-0 rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5 p-6'>
        <h1 className='text-xl font-semibold text-gray-900'>
          {t('team_analyse.spaces.interpretation_guide.title')}
        </h1>
        <p className='mt-1 max-w-lg text-sm text-gray-700'>
          {t('team_analyse.spaces.interpretation_guide.description')}
        </p>
        <div className='mt-6'>
          <a
            target='_blank'
            rel='noreferrer'
            onClick={() =>
              trackEvent(trackEvents.DOWNLOAD_INTERPRETATIONGUIDE_TEAMANALYSIS)
            }
            href={`https://aivy-assets.s3.eu-central-1.amazonaws.com/pdfs/teamanalysis_guide_de.pdf?random_number=${new Date().getTime()}`}
            className='text-sm font-semibold leading-6 text-gray-900'
          >
            {t('team_analyse.spaces.interpretation_guide.cta')}{' '}
            <span aria-hidden='true'>&rarr;</span>
          </a>
        </div>
      </div>
    </div>
  )
}

Spaces.propTypes = {
  spaces: PropTypes.array.isRequired
}

export default Spaces
