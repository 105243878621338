import { useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'

import { trackEvent } from '../../helpers/analytics'
import SpaceCommentListItem from './space-comment-listitem'
import initials from 'initials'
import trackEvents from '../../constants/track-events'
import Button from '../tailwind/Button'
import { mutation } from '../../graphql'
import { useNotificationContext, useUserContext } from '../../context'

const SpaceComment = ({ data }) => {
  const { t } = useTranslation()
  const { error } = useNotificationContext()
  const { active_space } = useUserContext()

  const { space, refetchSpace } = data

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [newComment, setNewComment] = useState('')

  const addNewComment = async (event) => {
    setIsSubmitting(true)
    event.preventDefault()

    const c = {
      person_id: active_space.person?.id,
      owner: active_space.owner,
      message: newComment,
      createdAt: new Date().toISOString()
    }

    const comment = space.comment || []
    comment.push(c)

    mutation({
      mutation: 'updateSpace',
      input: {
        id: space.id,
        comment
      }
    })
      .then(() => {
        trackEvent(trackEvents.COMMENT_TALENT)
        refetchSpace().then(() => {
          setIsSubmitting(false)
          setNewComment('')
        })
      })
      .catch(() => {
        error()
        setIsSubmitting(false)
      })
  }

  return (
    <section aria-labelledby='notes-title'>
      <div className='sm:overflow-hidden'>
        <div className='divide-y divide-gray-300'>
          <div className='py-4'>
            <h2 id='notes-title' className='text-lg font-medium text-gray-900'>
              {t('pages.talent.notes')}
            </h2>
          </div>
          {!isEmpty(space.comment) && (
            <div className='py-6'>
              <ul role='list' className='space-y-8 max-w-3xl'>
                {space.comment.map((comment, index) => (
                  <SpaceCommentListItem key={index} comment={comment} />
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className='py-6 border-t border-t-gray-300'>
          <div className='flex space-x-3'>
            <div className='flex-shrink-0'>
              <span className='inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500'>
                <span className='font-medium leading-none text-white'>
                  {initials(
                    `${active_space.person.firstname} ${active_space.person.lastname}`
                  )}
                </span>
              </span>
            </div>
            <div className='min-w-0 flex-1'>
              <form action='#'>
                <div>
                  <label htmlFor='comment' className='sr-only'>
                    Comments
                  </label>
                  <textarea
                    id='comment'
                    name='comment'
                    rows={3}
                    className='shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md'
                    placeholder={t('pages.talent.comment_placeholder')}
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                  />
                </div>
                <div className='mt-3 flex items-center justify-end'>
                  <Button.PrimaryXL
                    text={t('pages.talent.comment')}
                    onClick={addNewComment}
                    disabled={!newComment}
                    isLoading={isSubmitting}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

SpaceComment.propTypes = {
  data: PropTypes.object.isRequired
}

export default SpaceComment
