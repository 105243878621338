export const ROLES = {
  ADMIN: {
    id: 'ADMIN',
    roles: [
      '__read_low',
      '__read',
      '__create',
      '__update',
      '__delete',
      '__admin'
    ],
    isAdmin: ({ groups, partner_id }) => {
      if (groups.includes('__admin')) return true

      return ROLES.ADMIN.roles
        .map((role) => `${role}_${partner_id}`)
        .every((role) => groups.includes(role))
    },
    title: 'authorization_role.admin.title',
    description: 'authorization_role.admin.description',
    crud: { create: true, read: true, update: true, delete: true }
  },
  MANAGER: {
    id: 'MANAGER',
    roles: ['__read_low', '__read', '__create', '__update', '__delete'],
    isManager: ({ groups, partner_id }) => {
      return ROLES.MANAGER.roles
        .map((role) => `${role}_${partner_id}`)
        .every((role) => groups.includes(role))
    },
    title: 'authorization_role.manager.title',
    description: 'authorization_role.manager.description',
    crud: { create: true, read: true, update: true, delete: true }
  },
  WORKER: {
    id: 'WORKER',
    roles: ['__read_low', '__read', '__create', '__update'],
    isWorker: ({ groups, partner_id }) => {
      return ROLES.WORKER.roles
        .map((role) => `${role}_${partner_id}`)
        .every((role) => groups.includes(role))
    },
    title: 'authorization_role.worker.title',
    description: 'authorization_role.worker.description',
    crud: { create: true, read: true, update: true, delete: false }
  },
  VIEWER: {
    id: 'VIEWER',
    roles: ['__read_low'],
    isViewer: ({ groups, partner_id }) => {
      return ROLES.VIEWER.roles
        .map((role) => `${role}_${partner_id}`)
        .every((role) => groups.includes(role))
    },
    title: 'authorization_role.viewer.title',
    description: 'authorization_role.viewer.description',
    crud: { create: false, read: true, update: false, delete: false }
  }
}

export const getAuthorizationRole = ({ user }) => {
  const { ADMIN, MANAGER, WORKER, VIEWER } = ROLES

  if (ADMIN.isAdmin(user)) return ADMIN
  else if (MANAGER.isManager(user)) return MANAGER
  else if (WORKER.isWorker(user)) return WORKER
  else if (VIEWER.isViewer(user)) return VIEWER

  throw new Error(
    [
      'getAuthorizationRole: the authorization role could not be validated!',
      `username: ${user.username}`
    ].join(' / ')
  )
}
