import PropTypes from 'prop-types'

const Header = ({ className }) => {
  return (
    <div className={className}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='currentColor'
        viewBox='0 0 24 24'
      >
        <path d='M17 11V4h2v17h-2v-8H7v8H5V4h2v7h10z'></path>
      </svg>
    </div>
  )
}

Header.propTypes = {
  className: PropTypes.string
}

Header.defaultProps = {
  className: undefined
}

export default Header
