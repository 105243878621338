export default {
  talents: [
    {
      de: 'Wie kann ich mehrere Talente auf verschiedene Stellenprofile vergleichen?',
      link: 'https://aivyapp.notion.site/Wie-kann-ich-mehrere-Talente-auf-verschiedene-Stellenprofile-vergleichen-6fbbf7489c5e488282cdf7a4c41652ed?pvs=4'
    },
    {
      de: 'Wie aktiviere ich das automatisierte Löschen von Talenten?',
      link: 'https://aivyapp.notion.site/Wie-aktiviere-ich-das-automatisierte-L-schen-von-Talenten-212f4f8a56504aac9f029102531c498a?pvs=4'
    },
    {
      de: 'Wie lade ich ein Talent zum Assessment ein?',
      link: 'https://aivyapp.notion.site/Wie-lade-ich-ein-Talent-zum-Assessment-ein-4734cdc39d0c4861be2fb92b8ba5cf84?pvs=4'
    },
    {
      de: 'Wie funktioniert die Filterung der Talente?',
      link: 'https://aivyapp.notion.site/Wie-funktioniert-die-Filterung-der-Talente-ccceb5dcc84e4c50bcd2281a992a2a46?pvs=4'
    },
    {
      de: 'Wie erinnere ich meine Talente bzw. wie passe ich die Bearbeitungszeit an?',
      link: 'https://aivyapp.notion.site/Wie-erinnere-ich-meine-Talente-bzw-wie-passe-ich-die-Bearbeitungszeit-an-d7f97938855343e1804c5114b985ce7e?pvs=4'
    },
    {
      de: 'Wie erneuere ich das Ablaufdatum zum Assessment?',
      link: 'https://aivyapp.notion.site/Wie-erneuere-ich-das-Ablaufdatum-zum-Assessment-3b78bd85420f498493e582d786774231?pvs=4'
    }
  ],
  talent: [
    {
      de: 'Wie ist das Stärkenprofil zu interpretieren?',
      link: 'https://aivyapp.notion.site/Wie-ist-das-St-rkenprofil-zu-interpretieren-d7169fbeca73468e85ada1fd9aa7542e?pvs=4'
    },
    {
      de: 'Erhalten Talente ebenfalls eine Auswertung? ',
      link: 'https://aivyapp.notion.site/Erhalten-Talente-ebenfalls-eine-Auswertung-a76fe5f04cfd4cd3af428121fef03bf6?pvs=4'
    },
    {
      de: 'Wie ist der Passungsscore zu interpretieren? ',
      link: 'https://aivyapp.notion.site/Wie-ist-der-Passungsscore-zu-interpretieren-f94ae4ba931a485ca1541bc6dcfc4401?pvs=4'
    },
    {
      de: 'Wie erinnere ich ein Talent bzw. wie passe ich die Bearbeitungszeit an?',
      link: 'https://aivyapp.notion.site/Wie-erinnere-ich-ein-Talent-bzw-wie-passe-ich-die-Bearbeitungszeit-an-af555a7d5e064eedbfb40ff934f52d2e?pvs=4'
    }
  ],
  careers: [
    {
      de: 'Wie kann ich ein Anforderungsprofil erstellen, Talente einladen & die Passung analysieren?',
      link: 'https://aivyapp.notion.site/Wie-kann-ich-ein-Anforderungsprofil-erstellen-Talente-einladen-die-Passung-analysieren-f7efb708eaa641a1914cf04bfe73cc20?pvs=4'
    },
    {
      de: 'Wieso wird bei der Erstellung des Anforderungsprofils empfohlen, Expert:innen zu befragen anstatt eine Vorlage zu nutzen? ',
      link: 'https://aivyapp.notion.site/Wieso-wird-bei-der-Erstellung-des-Anforderungsprofils-empfohlen-Expert-innen-zu-befragen-anstatt-ei-2c458e97a1ee456fb86582f880cb0411?pvs=4'
    },
    {
      de: 'Wieso müssen mindestens 3 Expert:innen befragt werden, um ein Anforderungsprofil zu erstellen?',
      link: 'https://aivyapp.notion.site/Wieso-m-ssen-mindestens-3-Expert-innen-befragt-werden-um-ein-Anforderungsprofil-zu-erstellen-8b1004dbbe754af39059b2fead50b27d?pvs=4'
    },
    {
      de: 'Was kann ich tun, wenn das Anforderungsprofil aufgrund geringer Übereinstimmung der Expert:innen nicht erstellt werden konnte?',
      link: 'https://aivyapp.notion.site/Was-kann-ich-tun-wenn-das-Anforderungsprofil-aufgrund-geringer-bereinstimmung-der-Expert-innen-nic-c5ffb964f37f433e93d7f7b75d6d0fec?pvs=4'
    },
    {
      de: 'Welche Modelle stehen hinter den Assessments?',
      link: 'https://aivyapp.notion.site/Welche-Modelle-stehen-hinter-den-Assessments-c5c807fabfea464ea85cf615e04116d3?pvs=4'
    },
    {
      de: 'Wie kann ich eine alte Stelle archivieren?',
      link: 'https://aivyapp.notion.site/Wie-kann-ich-eine-alte-Stelle-archivieren-28cd51f3a1384d49a739d101beac32e3?pvs=4'
    }
  ],
  career: [
    {
      de: 'Kann ich im später einzelne Challenges zur Testbatterie hinzufügen oder entfernen?',
      link: 'https://aivyapp.notion.site/Kann-ich-im-sp-ter-einzelne-Challenges-zur-Testbatterie-hinzuf-gen-oder-entfernen-2f6e9fc2ebca47729a5ecfbe15add44b?pvs=4'
    },
    {
      de: 'Wie erstelle ich benutzerdefinierte Felder für den öffentlichen Einladungslink?',
      link: 'https://aivyapp.notion.site/Wie-erstelle-ich-benutzerdefinierte-Felder-f-r-den-ffentlichen-Einladungslink-71d7656c3ccb42038d571bb5bd132648?pvs=4'
    },
    {
      de: 'Wieviele verschiedene Challenges gibt es?',
      link: 'https://aivyapp.notion.site/Wieviele-verschiedene-Challenges-gibt-es-496408081da241c89399a20fc47e19b2?pvs=4'
    }
  ],
  'career#attract': [
    {
      de: 'Was ist Aivy Attract und wie nutze ich es?',
      link: 'https://aivyapp.notion.site/Was-ist-Aivy-Attract-und-wie-nutze-ich-es-2e8d8784a56b44ea95213152544df2f4?pvs=4'
    }
  ],
  'career#flex': [],
  'career#assessment-settings': [
    {
      de: 'Wie bearbeite ich die E-Mail-Vorlage für Einladungen, Erinnerungen oder Absagen?',
      link: 'https://aivyapp.notion.site/Wie-bearbeite-ich-die-E-Mail-Vorlage-f-r-Einladungen-Erinnerungen-oder-Absagen-a978f0a1ec784ddb975f28eddb305380?pvs=4'
    }
  ],
  'team-analyses': [
    {
      de: 'Was sind Teamanalysen & wie nutze ich sie?',
      link: 'https://aivyapp.notion.site/Was-sind-Teamanalysen-wie-nutze-ich-sie-55472bce45d94e6c893496ee54137a22?pvs=4'
    }
  ],
  'settings#personal': [],
  'settings#notifications': [
    {
      de: 'Kann sich jede:r im Team per E-Mail informieren lassen, dass ein Talent die Challenges abgeschlossen hat?',
      link: 'https://aivyapp.notion.site/Kann-sich-jede-r-im-Team-per-E-Mail-informieren-lassen-dass-ein-Talent-die-Challenges-abgeschlossen-d71c4b0c88c14d2f9b20153bf8090080?pvs=4'
    }
  ],
  'settings#company': [],
  'settings#user': [
    {
      de: 'Wie füge ich neue Teammitglieder hinzu?',
      link: 'https://aivyapp.notion.site/Wie-f-ge-ich-neue-Teammitglieder-hinzu-23404eecb1ff4c17bf5076b61fdeff25?pvs=4'
    }
  ],
  'settings#templates': [
    {
      de: 'Wie bearbeite ich die E-Mail-Vorlage für Einladungen, Erinnerungen oder Absagen?',
      link: 'https://aivyapp.notion.site/Wie-bearbeite-ich-die-E-Mail-Vorlage-f-r-Einladungen-Erinnerungen-oder-Absagen-6f0bf1f4673c449f9e5766c46ab53779?pvs=4'
    }
  ],
  'settings#assessment': [],
  'settings#resultreport': [],
  'settings#plan': [],
  'settings#integrations': [
    {
      de: 'Kann sich jede:r im Team per E-Mail informieren lassen, dass ein Talent die Challenges abgeschlossen hat?',
      link: 'https://aivyapp.notion.site/Kann-sich-jede-r-im-Team-per-E-Mail-informieren-lassen-dass-ein-Talent-die-Challenges-abgeschlossen-d71c4b0c88c14d2f9b20153bf8090080?pvs=4'
    }
  ],
  'settings#ats': [],
  'settings#automations': []
}
