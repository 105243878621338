import { useQuery } from '@tanstack/react-query'

import { query } from '../graphql'
import { usePartnerContext } from '../context'

export const usePartnerIntegrations = ({ select }) => {
  const { partner } = usePartnerContext()

  return useQuery({
    queryKey: ['partner-integrations', partner.id],
    queryFn: () =>
      query({
        query: 'listIntegrationsByPartner',
        variables: {
          partner_id: partner.id
        }
      }),
    select
  })
}
