export const DE = 'de'
export const EN = 'en'
const languages = [DE, EN]

const TRANSLATION_LOCATION = 'constants.languages.'

export const getAllLanguages = () => {
  // [ key, value ]
  return languages.map((id) => [id, TRANSLATION_LOCATION + id])
}

export const isLanguageAvailable = (language) => {
  return languages.includes(language)
}

export const getLanguage = (id) => {
  return TRANSLATION_LOCATION + id
}
