import { useQuery } from '@tanstack/react-query'

import { query } from '../graphql'
import { getSpacesTableData } from '../helpers/spaces-table-data'
import {
  careerActiveFilterInput,
  talentContextFilterInput
} from '../graphql/filter-inputs'
import { usePartnerContext } from '../context'

export const useRecoverTalentsQuery = () => {
  const { partner } = usePartnerContext()

  const talentsQuery = useQuery({
    queryKey: ['recover/talents', partner.id],
    queryFn: () =>
      Promise.all([
        new Promise((resolve, reject) => {
          query({
            query: 'spacesByPartnerIdByUpdatedAt',
            variables: {
              partner_id: partner.id,
              filter: talentContextFilterInput
            }
          })
            .then((response) => {
              resolve(response.filter(({ soft_deleted }) => !!soft_deleted))
            })
            .catch((err) => reject(err))
        }),
        query({
          query: 'careerByPartnerByDate',
          variables: {
            partner_id: partner.id,
            filter: careerActiveFilterInput
          }
        })
      ]),
    select: (response) => {
      return {
        spaces: getSpacesTableData({
          spaces: response[0].filter(({ context, mail_stored }) =>
            context === 'ATTRACT' ? mail_stored : true
          )
        }),
        careers: response[1].filter(({ id }) => !id.includes('PRODUCT_TOUR'))
      }
    }
  })

  return talentsQuery
}
