import { notifyBugsnag } from '.'

const setItem = (key, value) => {
  try {
    typeof window !== 'undefined' && window.localStorage.setItem(key, value)
  } catch (err) {
    // if (err.name === 'QuotaExceededError') {
    //  return
    // }

    notifyBugsnag(err)
  }
}

const getItem = (key) => {
  try {
    return typeof window !== 'undefined' && window.localStorage.getItem(key)
  } catch (err) {
    notifyBugsnag(err)
  }
}

const removeItem = (key) => {
  try {
    typeof window !== 'undefined' && window.localStorage.removeItem(key)
  } catch (err) {
    notifyBugsnag(err)
  }
}

const clear = () => {
  const releaseNotesRead = getItem('release-notes-read')

  try {
    typeof window !== 'undefined' && window.localStorage.clear()
    setItem('release-notes-read', releaseNotesRead) // persist release-notes-read
  } catch (err) {
    notifyBugsnag(err)
  }
}

export const localStorage = {
  setItem,
  getItem,
  removeItem,
  clear
}

export const STORAGE_KEYS = {
  ACTIVATE_REPORT_SETTINGS: 'ACTIVATE_REPORT_SETTINGS'
}
