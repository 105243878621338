import { useTranslation } from 'react-i18next'
import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@tanstack/react-query'
import { isObject } from 'lodash'

import {
  useLanguageContext,
  useNotificationContext,
  usePartnerContext,
  useUserContext
} from '../../../context'
import SelectPreset from './select-preset'
import { ActivityIndicator } from '../..'
import { getAppSettings } from '../../../constants/app-settings'
import {
  UPDATE_PRESET,
  updateCareerHistory
} from '../../../helpers/career-history'
import { query } from '../../../graphql'
import Button from '../../tailwind/Button'
import { useCreateCareerNavigation } from '../../../hooks/use-create-career-navigation'
import { trackEvent } from '../../../helpers/analytics'
import trackEvents from '../../../constants/track-events'
import { sendSlackMessage } from '../../../helpers'
import Modal from '../../tailwind/Modal'
import Input from '../../input'

const i18n = 'career.requirement_profile.presets.'

const UsePresets = ({ career, careerMutation, generateRequirementProfile }) => {
  const { t } = useTranslation()
  const { partner } = usePartnerContext()
  const { language } = useLanguageContext()
  const { success } = useNotificationContext()
  const { active_space, cognitoUser } = useUserContext()

  const career_analyse = JSON.parse(career.career_analyse || '{}')
  const { career_preset_id } = career_analyse

  const [isRequestingPreset, setIsRequestingPreset] = useState(false)
  const [showRequestPresetModal, setShowRequestPresetModal] = useState(false)
  const [requestPresetInput, setRequestPresetInput] = useState('')

  const {
    isLoadingBack,
    isLoadingNext,
    handleBackNavigation,
    handleNextNavigation
  } = useCreateCareerNavigation({ career, careerMutation })

  const { data: selectedPreset, status } = useQuery({
    enabled: !!career_preset_id,
    queryKey: ['career-preset', career_preset_id],
    queryFn: () =>
      query({
        query: 'publicSqlQuery',
        variables: {
          action: 'query-career-preset',
          input: JSON.stringify({ career_preset_id })
        }
      }),
    select: (response) => {
      const preset = JSON.parse(response.Items)[0]
      preset.name = JSON.parse(preset.name)
      preset.displayValue = preset.name[language]

      return preset
    }
  })

  const storePresetInsideCareer = useCallback(
    async (preset) => {
      // callback function is initially called by search input
      if (!preset) return

      preset.career_analyse = isObject(preset.career_analyse)
        ? preset.career_analyse
        : JSON.parse(preset.career_analyse)

      const { id, career_analyse } = preset

      const dimensions = Object.keys(career_analyse.scores)
      const { career_preset_id: before_career_preset_id } = JSON.parse(
        career.career_analyse || '{}'
      )

      const input = {
        id: career.id,
        app_settings: getAppSettings(career.app_settings, []),
        career_analyse: JSON.stringify({
          career_preset_id: id,
          ...career_analyse,
          dimensions
        }),
        history: updateCareerHistory(
          career.history,
          UPDATE_PRESET,
          before_career_preset_id,
          id,
          cognitoUser.username
        )
      }

      careerMutation.mutate({ input })
    },
    [career, cognitoUser, careerMutation]
  )

  const requestPreset = async () => {
    setIsRequestingPreset(true)

    await sendSlackMessage(
      'bugsnag_customer-dashboard',
      `Profilanfrage: *${requestPresetInput}* (partner_id: ${partner.id} - active_space_id: ${active_space.id})`
    )

    setTimeout(() => {
      setIsRequestingPreset(false)
      success(t(i18n + 'request_preset_success'))
    }, 1000 * 0.8)
  }

  return (
    <>
      <div className='mt-5'>
        <span
          id='use-presets'
          className='text-3xl font-extrabold tracking-tight text-gray-900'
        >
          {t(i18n + 'title')}
        </span>
      </div>
      <div className='mt-4 max-w-lg'>
        <p className='text-sm text-gray-900'>{t(i18n + 'description_1')}</p>
      </div>
      <div className='mt-4 max-w-2xl'>
        <SelectPreset
          callback={storePresetInsideCareer}
          defaultValue={selectedPreset?.displayValue || ''}
          onChange={(event) => setRequestPresetInput(event.target.value)}
        />
      </div>
      <p className='mt-4 max-w-xl text-xs text-gray-900 italic'>
        {t(i18n + 'description_2')}
      </p>
      <div className='mt-8 flex items-center gap-x-1.5'>
        <span className='block text-sm text-gray-900'>
          {t(i18n + 'request_preset_title')}
        </span>
        <Button.Text
          className='inline-block'
          text={t(i18n + 'request_preset_action')}
          onClick={() => setShowRequestPresetModal(true)}
        />
      </div>
      {career_preset_id && status === 'pending' && (
        <div className='mt-4 pt-4'>
          <ActivityIndicator className='' />
        </div>
      )}
      {career_preset_id && status === 'success' && (
        <div className='mt-8 max-w-lg'>
          <span className='block text-md font-bold text-gray-900'>
            {t(i18n + 'selected_preset_title')}
          </span>
          <span className='block mt-1 text-sm font-normal text-gray-900'>
            {selectedPreset.displayValue}
          </span>
        </div>
      )}
      <div className='mt-8 sm:mt-12 flex flex-row justify-between items-center'>
        <Button.SecondaryLG
          onClick={handleBackNavigation}
          isLoading={isLoadingBack}
          text={t('create_career.back_action')}
        />
        <div className='flex gap-x-2'>
          <Button.SecondaryLG
            className='mr-auto'
            text={t(
              'components.career.expert_analysis.requirement_profile_action'
            )}
            onClick={generateRequirementProfile}
            disabled={status === 'pending' || !selectedPreset}
          />
          <Button.PrimaryLG
            text={t('create_career.next_action')}
            onClick={() =>
              handleNextNavigation({
                // career_analyse is calculated by summarize || select preset
                onSuccess: () =>
                  trackEvent(trackEvents.FINISH_CAREER_ANALYSE_BY_PRESETS)
              })
            }
            isLoading={isLoadingNext}
            disabled={!selectedPreset}
          />
        </div>
      </div>
      <div className='pb-4' />
      <Modal
        open={showRequestPresetModal}
        setOpen={setShowRequestPresetModal}
        size='lg'
      >
        <span className='block text-md font-bold text-gray-900'>
          {t(i18n + 'request_preset_title')}
        </span>
        <span className='block mt-2 text-sm text-gray-900'>
          {t(i18n + 'request_preset_description')}
        </span>
        <div className='mt-4' />
        <Input
          id='request_preset'
          type='text'
          label={t(i18n + 'request_preset_input_title')}
          placeholder={t(i18n + 'request_preset_input_placeholder')}
          onChange={(event) => setRequestPresetInput(event.target.value)}
          value={requestPresetInput}
        />
        <div className='mt-8 flex justify-end'>
          <Button.PrimaryBase
            text={t(i18n + 'request_preset_submit_action')}
            onClick={requestPreset}
            isLoading={isRequestingPreset}
          />
        </div>
      </Modal>
    </>
  )
}

UsePresets.propTypes = {
  career: PropTypes.object.isRequired,
  careerMutation: PropTypes.object.isRequired,
  generateRequirementProfile: PropTypes.func.isRequired
}

export default UsePresets
