import { Fragment } from 'react'
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild
} from '@headlessui/react'
import PropTypes from 'prop-types'

import { classNames } from '../../helpers'

const Slideover = ({ open, setOpen, className, children }) => {
  return (
    <Transition show={open} as={Fragment}>
      <Dialog as='div' className='relative z-20' onClose={setOpen}>
        <div className='fixed inset-0' />
        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16'>
              <TransitionChild
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <DialogPanel
                  className={classNames(
                    'relative pointer-events-auto',
                    className
                  )}
                >
                  <div className='h-full overflow-y-scroll bg-white shadow-xl'>
                    {children}
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

Slideover.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

Slideover.defaultProps = {
  className: 'w-screen max-w-5xl'
}

export default Slideover
