import { useState, useEffect, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ArrowSmallRightIcon } from '@heroicons/react/24/outline'
import { format, formatDistance, subSeconds } from 'date-fns'
import { de, enUS } from 'date-fns/locale'
import { JSONTree } from 'react-json-tree'
import { rankItem } from '@tanstack/match-sorter-utils'
import {
  EllipsisHorizontalIcon,
  Square3Stack3DIcon
} from '@heroicons/react/24/solid'

import { Seo } from '../components'
import Button from '../components/tailwind/Button'
import { trackEvent } from '../helpers/analytics'
import { toBase64 } from '../helpers'
import Table from '../components/react-table/table'
import State from '../components/state'
import PageHeading from '../components/tailwind/PageHeading'
import { filter } from '../helpers/filters'
import { localStorage } from '../helpers/local-storage'
import ActivityIndicator from '../components/activity-indicator'
import {
  useLanguageContext,
  useAivyContext,
  usePartnerContext,
  usePaymentContext
} from '../context'
import { useSampleData } from '../hooks/use-sample-data'
import Dropdown from '../components/tailwind/dropdown'
import { useCareersQuery } from '../hooks/use-careers-query'

const TRANSLATION_LOCATION = 'pages.careers.'
const locale = { de, en: enUS }

const prepareStatus = ({ status }) => {
  // if (archived) return 'ARCHIVED'

  switch (status) {
    case 'ACTIVE':
      return 'ACTIVE'
    case null:
    case 'WAIT_EXPERTS':
    case 'WAIT_CONFIG':
    case 'WAIT_ACTIVE':
      return 'IN_PROGRESS'
    default:
      return 'IN_PROGRESS'
  }
}

function Careers() {
  const { t } = useTranslation()
  const { partner } = usePartnerContext()
  const { adminInsights } = useAivyContext()
  const { is_trial } = usePaymentContext()
  const { language } = useLanguageContext()

  const navigate = useNavigate()

  const {
    status,
    data: careers,
    dataUpdatedAt,
    fetchStatus
  } = useCareersQuery({
    select: (items) => {
      return items
        .filter(({ id }) => !id.includes('PRODUCT_TOUR'))
        .filter(({ archived }) => !archived)
        .map((item) => ({
          ...item,
          status: prepareStatus(item)
        }))
    }
  })

  useEffect(() => {
    if (is_trial) return
    if (!careers) return
    if (!careers.find(({ status }) => status === 'ACTIVE')) return

    const storageSearchParams = localStorage.getItem('/careers')

    navigate(
      {
        search:
          storageSearchParams ||
          `filter=${toBase64([{ status: { in: ['ACTIVE'] } }])}`
      },
      { replace: true }
    )
  }, [is_trial, careers, navigate])

  const [time, setTime] = useState(null)

  const {
    isLoading: isLoadingSampleData,
    createSampleData,
    deleteSampleData
  } = useSampleData()

  const fetchingSubtitle = useMemo(() => {
    if (status === 'error') return null

    // data updated at defaults to 0 for an empty cache
    if (!dataUpdatedAt) {
      return t('pages.careers.fetching_subtitle.is_loading')
    }

    if (fetchStatus === 'fetching') {
      return t('pages.careers.fetching_subtitle.is_updating')
    }

    return t('pages.careers.fetching_subtitle.last_updated_at', {
      time, // only used here for the use memo dependencies
      distance_sub_seconds: formatDistance(
        subSeconds(new Date(dataUpdatedAt), 3),
        new Date(),
        {
          locale: locale[language]
        }
      )
    })
  }, [status, dataUpdatedAt, fetchStatus, time, language, t])

  useEffect(() => {
    // update fetching subtitle every minute
    const interval = setInterval(() => setTime(Date.now()), 1000 * 60)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const mapStatus = useCallback(
    ({ id, status }) => {
      if (id.includes('sample')) {
        return (
          <State
            color='grey'
            text={t(TRANSLATION_LOCATION + 'state_sample_data')}
          />
        )
      }

      switch (status) {
        case 'ACTIVE':
          return (
            <State
              color='green'
              text={t(TRANSLATION_LOCATION + 'state_active')}
            />
          )
        case 'IN_PROGRESS':
          return (
            <State
              color='orange'
              text={t(TRANSLATION_LOCATION + 'state_in_progress')}
            />
          )
        default:
          throw new Error(`map status failed - ${status} not found`)
      }
    },
    [t]
  )

  const handleNavigate = useCallback(
    (cell) => {
      const { id: career_id, status } = cell.row.original

      if (status === 'ACTIVE') {
        navigate(`/career/${career_id}`)
        return
      }

      navigate(`/career/new/${career_id}`)
    },
    [navigate]
  )

  const columns = useMemo(
    () =>
      [
        adminInsights && {
          id: 'admin-insights',
          accessorKey: 'id',
          enableSorting: false,
          disableOnClick: true,
          header: (
            <span className='text-sm font-semibold text-gray-900'>ID</span>
          ),
          cell: ({ getValue, row }) => (
            <>
              <span className='block text-sm font-medium text-gray-900'>
                {getValue()}
              </span>
              <JSONTree
                data={row.original}
                shouldExpandNodeInitially={() => false}
              />
            </>
          )
        },
        {
          accessorKey: 'title',
          sortingFn: 'alphanumeric',
          header: (
            <span className='text-sm font-semibold text-gray-900'>
              {t(
                TRANSLATION_LOCATION + 'table_header_career_name'
              ).toUpperCase()}
            </span>
          ),
          cell: ({ getValue }) => (
            <span className='text-sm font-medium text-gray-900'>
              {getValue()}
            </span>
          )
        },
        !!careers?.find(({ external_custom_id }) => !!external_custom_id) && {
          accessorKey: 'external_custom_id',
          sortDescFirst: false,
          header: (
            <span className='text-sm font-semibold text-gray-900'>
              {t(
                TRANSLATION_LOCATION + 'table_header_career_external_id'
              ).toUpperCase()}
            </span>
          ),
          cell: ({ getValue }) => (
            <span className='text-sm text-gray-500'>{getValue() || 'n/a'}</span>
          )
        },
        {
          accessorKey: 'number_of_spaces',
          sortDescFirst: true,
          header: (
            <span className='text-sm font-semibold text-gray-900'>
              {t(
                TRANSLATION_LOCATION + 'table_header_career_number_of_spaces'
              ).toUpperCase()}
            </span>
          ),
          cell: ({ getValue }) => (
            <span className='text-sm text-gray-500'>{getValue()}</span>
          )
        },
        {
          accessorKey: 'updatedAt',
          sortDescFirst: true,
          header: (
            <span className='text-sm font-semibold text-gray-900'>
              {t(
                TRANSLATION_LOCATION + 'table_header_career_updated_at'
              ).toUpperCase()}
            </span>
          ),
          cell: ({ getValue }) => (
            <span className='text-sm text-gray-500'>
              {t(TRANSLATION_LOCATION + 'datetime', {
                datetime: format(new Date(getValue()), 'dd.MM.yyyy | HH:mm')
              })}
            </span>
          )
        },
        {
          accessorKey: 'createdAt',
          sortDescFirst: true,
          header: (
            <span className='text-sm font-semibold text-gray-900'>
              {t(
                TRANSLATION_LOCATION + 'table_header_career_created_at'
              ).toUpperCase()}
            </span>
          ),
          cell: ({ getValue }) => (
            <span className='text-sm text-gray-500'>
              {format(new Date(getValue()), 'dd.MM.yyyy')}
            </span>
          )
        },
        // {
        //   accessorKey: 'createdBy',
        //   sortDescFirst: true,
        //   header: (
        //     <span className='text-sm font-semibold text-gray-900'>
        //       {t(
        //         TRANSLATION_LOCATION + 'table_header_career_created_by'
        //       ).toUpperCase()}
        //     </span>
        //   ),
        //   cell: ({ getValue }) => (
        //     <div className='flex flex-row items-center'>
        //       <span className='inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500'>
        //         <span className='text-sm font-medium leading-none text-white'>
        //           AR
        //         </span>
        //       </span>
        //       <span className='ml-2 text-sm text-gray-600'>Arbnor Raci</span>
        //     </div>
        //   )
        // },
        {
          accessorKey: 'status',
          enableSorting: false,
          header: (
            <span className='text-sm font-semibold text-gray-900'>
              {t(
                TRANSLATION_LOCATION + 'table_header_career_status'
              ).toUpperCase()}
            </span>
          ),
          cell: ({ cell }) => <span>{mapStatus(cell.row.original)}</span>,
          filter: {
            id: 'status',
            filterKey: 'filter',
            operator: 'in',
            fn: filter,
            name: t(TRANSLATION_LOCATION + 'filter_title'),
            column: 'status',
            options: [
              {
                value: 'ACTIVE',
                label: t(TRANSLATION_LOCATION + 'filter_option_active_title')
              },
              {
                value: 'IN_PROGRESS',
                label: t(
                  TRANSLATION_LOCATION + 'filter_option_in_progress_title'
                )
              }
            ].filter((item) => item)
          }
        },
        {
          accessorKey: 'id',
          header: null,
          cell: () => <div />,
          invisible: true
        },
        {
          accessorKey: 'view',
          enableSorting: false,
          header: '',
          cell: () => (
            <ArrowSmallRightIcon className='ml-auto w-5 h-5 text-gray-500 group-hover:text-gray-900' />
          )
        }
      ]
        .filter((item) => item)
        .map((item) => ({
          ...item,
          onClick: item.disableOnClick
            ? undefined
            : (cell) => handleNavigate(cell)
        })),
    [careers, mapStatus, handleNavigate, adminInsights, t]
  )

  const globalFilterFn = useCallback((row, columnId, value, addMeta) => {
    const columnValue = row.getValue(columnId)
    const itemRank = rankItem(columnValue, value)

    addMeta({ itemRank })

    return itemRank.passed
  }, [])

  if (status === 'pending') {
    return <ActivityIndicator />
  }

  const pageHeadingParams = {
    title: t('seo.titles.careers'),
    subtitle: fetchingSubtitle,
    withActions: (
      <div className='flex flex-wrap items-center '>
        <div className='mr-2'>
          {careers.find(({ id }) => id.includes('sample_')) ? (
            <Button.SecondaryXL
              onClick={() => deleteSampleData({})}
              isLoading={isLoadingSampleData}
              text={t(TRANSLATION_LOCATION + 'delete_sample_data_action')}
            />
          ) : (
            <Button.SecondaryXL
              onClick={() =>
                createSampleData({
                  onSuccess: () => navigate(`/career/new/sample_${partner.id}`)
                })
              }
              isLoading={isLoadingSampleData}
              text={t(TRANSLATION_LOCATION + 'create_sample_data_action')}
            />
          )}
        </div>

        <Button.PrimaryXL
          id='btn-new-career'
          text={t(TRANSLATION_LOCATION + 'create_career_action')}
          onClick={() => navigate('/career/new')}
        />
      </div>
    )
  }

  if (!careers.length) {
    return (
      <>
        <Seo title={t('seo.titles.careers')} />
        <PageHeading {...pageHeadingParams} />
        <div className='mt-16 flex flex-col items-center'>
          <div className='text-center'>
            <span className='mb-1 block text-3xl font-extrabold tracking-tight text-gray-900'>
              {t(TRANSLATION_LOCATION + 'empty_title_1')}
            </span>
            <span className='px-2 text-lg font-bold text-gray-900 border-b-4 border-yellow-200'>
              {t(TRANSLATION_LOCATION + 'empty_title_2')}
            </span>
          </div>
          <div className='max-w-3xl'>
            <video
              className='w-full aspect-video mt-8 p-4'
              controls
              onPlaying={() => trackEvent('welcome_video_playing')}
              src='https://fra1.do.dubbcdn.com/videos/2022-07-20/fab3ba342fda7387ea9c619ccadaf6e0/720p_Dbxh.mp4'
            ></video>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Seo title={t('seo.titles.careers')} />
      <PageHeading {...pageHeadingParams} />

      <Table
        data={careers}
        columns={columns}
        initialState={{ sorting: [{ id: 'updatedAt', desc: true }] }}
        globalFilterFn={globalFilterFn}
        disableVirtual={is_trial}
        displayNumberOfEntries
        withOptions={
          <Dropdown
            MenuButtonX={() => (
              <div className='h-7 w-7 bg-gray-100 rounded-full flex justify-center items-center group hover:bg-gray-200 cursor-pointer'>
                <EllipsisHorizontalIcon className='h-5 w-5 text-gray-700 group-hover:text-gray-900' />
              </div>
            )}
            actions={[
              {
                key: 'recover-careers',
                label: t('careers.options.recover_careers_title'),
                available: true,
                Icon: Square3Stack3DIcon,
                onClick: () => navigate('/recover/careers')
              }
            ]}
          />
        }
      />
    </>
  )
}

export default Careers
