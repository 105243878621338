import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DialogTitle } from '@headlessui/react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { useQueryClient } from '@tanstack/react-query'

import Input from '../../input'
import Modal from '../../tailwind/Modal'
import Button from '../../tailwind/Button'
import { ROLES } from '../../../helpers/authorization-roles'
import SelectMenuWithDescription from '../../tailwind/select-menu-with-description'
import {
  useLanguageContext,
  useNotificationContext,
  usePartnerContext,
  usePaymentContext
} from '../../../context'
import { query } from '../../../graphql'
import { trackEvent } from '../../../helpers/analytics'
import trackEvents from '../../../constants/track-events'

const isUserAlreadyInGroup = (err) => {
  return (
    err.errors &&
    Array.isArray(err.errors) &&
    err.errors[0] === Object(err.errors[0]) && // is object
    err.errors[0].message === 'User is already in a Group'
  )
}

const ModalCreateUser = ({ open, setOpen, data, onSuccess }) => {
  const { t } = useTranslation()
  const { language } = useLanguageContext()
  const { success, error } = useNotificationContext()
  const { is_trial } = usePaymentContext()
  const { partner } = usePartnerContext()

  const queryClient = useQueryClient()

  const { email } = data

  const roles = Object.values(ROLES).map(({ id, title, description }) => ({
    id,
    title: t(title),
    description: t(description)
  }))

  const [selectedRole, setSelectedRole] = useState(roles[1]) // MANAGER

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: ''
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required(
        t('settings.user_management.input_firstname_validation')
      ),
      lastname: Yup.string().required(
        t('settings.user_management.input_lastname_validation')
      )
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      trackEvent(trackEvents.INVITE_USER)
      setSubmitting(true)

      const { firstname, lastname } = values

      query({
        query: 'userControl',
        variables: {
          groupname: partner.id,
          action: 'createMemberToPartner',
          email: email.toLowerCase(),
          firstname,
          lastname,
          language
        }
      })
        .then((response) => {
          const parsed = JSON.parse(response)
          const space_id = parsed.body.createUserSpace.res.Item.id

          query({
            query: 'spaceControl',
            variables: {
              action: 'setAuthorizations',
              space_id,
              authorization_role: selectedRole.id
            }
          }).then(() => {
            success(t('settings.user_management.invite_user_success'))

            setOpen(false)
            setSubmitting(false)

            onSuccess()

            setTimeout(() => {
              setSelectedRole(roles[1])
              resetForm()
            }, 1000 * 0.4)

            if (is_trial) {
              queryClient.refetchQueries({
                queryKey: ['trial-data', partner.id],
                type: 'active'
              })
            }
          })
        })
        .catch((err) => {
          if (isUserAlreadyInGroup(err)) {
            error(
              t(
                'settings.user_management.already_member_in_another_company_error',
                { email: email.toLowerCase() }
              )
            )
          }
        })
    }
  })

  const handleCancel = () => {
    trackEvent(trackEvents.CANCEL_INVITE_USER)
    setOpen(false)
    setTimeout(() => {
      setSelectedRole(roles[1])
      formik.resetForm()
    }, 1000 * 0.4)
  }

  return (
    <Modal open={open} setOpen={handleCancel} size='lg'>
      <div className='mt-4 text-center'>
        <DialogTitle
          as='h3'
          className='text-lg leading-6 font-medium text-gray-900'
        >
          {t('settings.user_management.dialog_title_create')}
        </DialogTitle>
      </div>
      <div className='mt-6'>
        {[
          [
            'firstname',
            t('settings.user_management.input_given_name_label'),
            t('settings.user_management.input_given_name_placeholder')
          ],
          [
            'lastname',
            t('settings.user_management.input_family_name_label'),
            t('settings.user_management.input_family_name_placeholder')
          ]
        ].map((input, index) => (
          <div key={index} className='mt-4'>
            <Input
              id={input[0]}
              label={input[1]}
              placeholder={input[2]}
              type='text'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched[input[0]]}
              value={formik.values[input[0]]}
              error={formik.errors[input[0]]}
            />
          </div>
        ))}
        <div className='mt-4'>
          <Input
            id='email'
            label={t('settings.user_management.disabled_input_email_label')}
            type='text'
            value={email}
            disabled
          />
        </div>
        <div className='mt-4'>
          <SelectMenuWithDescription
            id='role'
            label={t('settings.user_management.manage_rights_label')}
            selected={selectedRole}
            setSelected={setSelectedRole}
            options={roles}
          />
        </div>
      </div>
      <div className='mt-12 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense'>
        <Button.SecondaryLG
          text={t('settings.user_management.dialog_cancel_action')}
          onClick={handleCancel}
          disabled={formik.isSubmitting}
        />
        <Button.PrimaryLG
          text={t('settings.user_management.dialog_submit_action')}
          onClick={formik.handleSubmit}
          isLoading={formik.isSubmitting}
        />
      </div>
    </Modal>
  )
}

ModalCreateUser.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired
}

export default ModalCreateUser
