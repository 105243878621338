import { JSONTree } from 'react-json-tree'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'

import IndeterminateCheckbox from '../components/react-table/indeterminate-checkbox'
import { IdentificationIcon, UserIcon } from '@heroicons/react/24/solid'
import {
  filter,
  filterCreatedStatus,
  filterScore,
  filterStatus
} from './filters'
import {
  ASSESSMENT_SPACE_STATES,
  SMART_PREDICT_SPACE_STATES
} from '../constants/space-states'
import { query } from '../graphql'
import {
  classNames,
  colorByString,
  getBackgroundColorByMatchingScore,
  notifyBugsnag
} from '.'
import { format } from 'date-fns'
import { ArrowSmallRightIcon } from '@heroicons/react/24/outline'

const COLUMNS = ({ t, careers_map, data, language, locale }) => ({
  SELECT: {
    id: 'select',
    disableOnClick: true,
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          id: 'react-table-select-all',
          checked: table.getIsAllRowsSelected(),
          // indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler()
        }}
      />
    ),
    cell: ({ row }) => (
      <IndeterminateCheckbox
        checked={row.getIsSelected()}
        // disabled={!row.getCanSelect()}
        // indeterminate={row.getIsSomeSelected()}
        onChange={row.getToggleSelectedHandler()}
      />
    )
  },
  ADMIN_INSIGHTS: {
    id: 'admin-insights',
    accessorKey: 'id',
    enableSorting: false,
    disableOnClick: true,
    header: <span className='text-sm font-semibold text-gray-900'>ID</span>,
    cell: ({ getValue, row }) => (
      <>
        <span className='block text-sm font-medium text-gray-900'>
          {getValue()}
        </span>
        <JSONTree data={row.original} shouldExpandNodeInitially={() => false} />
      </>
    )
  },
  IDENTIFICATION: {
    accessorKey: 'identification',
    accessorFn: (row) => row.identification || row.email,
    enableSorting: false,
    header: (
      <span className='text-sm font-semibold text-gray-900'>
        {t('talents.column_header_identification')}
      </span>
    ),
    cell: ({ getValue, cell }) => {
      const identification = getValue()
      const via = cell.row.original.via
      const hasName =
        cell.row.original.person?.invitation_firstname &&
        cell.row.original.person?.invitation_lastname
      const partner_user_id = cell.row.original.partner_user_id

      return (
        <div>
          <p className='truncate text-sm font-medium text-blue-700'>
            {via === 'EMAIL' ? identification : t('invitation_via_link')}
          </p>
          {(hasName || partner_user_id) && (
            <div className='mt-2 flex items-center gap-x-2'>
              {hasName && (
                <p
                  className='flex items-center text-sm text-gray-500'
                  title='Talent Name'
                >
                  <UserIcon
                    className='mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400'
                    aria-hidden='true'
                  />
                  <span className='truncate'>{`${cell.row.original.person?.invitation_firstname} ${cell.row.original.person?.invitation_lastname}`}</span>
                </p>
              )}
              {partner_user_id && (
                <p
                  className='flex items-center text-sm text-gray-500'
                  title='Talent ID'
                >
                  <IdentificationIcon
                    className='mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400'
                    aria-hidden='true'
                  />
                  <span className='truncate'>{partner_user_id}</span>
                </p>
              )}
            </div>
          )}
        </div>
      )
    }
  },
  PARTNER_USER_ID: {
    invisible: true,
    accessorKey: 'partner_user_id',
    accessorFn: (row) => row.partner_user_id || '-',
    header: null,
    cell: () => <div />
  },
  NAME: {
    invisible: true,
    accessorKey: 'name',
    accessorFn: (row) => row.name || '-',
    header: null,
    cell: () => <div />
  },
  SOURCE: {
    accessorKey: 'assessment_id',
    enableSorting: false,
    header: (
      <span className='text-sm font-semibold text-gray-900'>
        {t('talents.column_header_career')}
      </span>
    ),
    cell: ({ getValue }) => {
      const { title, external_custom_id, archived } =
        careers_map.get(getValue()) || {}

      const career_title_with_archived = archived
        ? t('talents.career_archived_title', { career_title: title })
        : title

      const career_title = external_custom_id
        ? `${career_title_with_archived} | ${external_custom_id}`
        : career_title_with_archived

      return (
        <span
          className={`inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium ${
            colorByString(career_title)?.backgroundColor
          } ${colorByString(career_title)?.text}`}
        >
          {career_title}
        </span>
      )
    },
    filter: {
      id: 'assessment_id',
      filterKey: 'computed',
      operator: 'in',
      fn: filter,
      name: t('talents.filter_title_sources'),
      column: 'assessment_id',
      options: [
        { id: 'ATTRACT', title: 'Attract' },
        { id: 'TALENTPOOL', title: t('talents.talentpool_title') },
        ...(data ? data.careers : [])
      ]
        .filter(({ archived }) => !archived)
        .map(({ id, title, external_custom_id }) => ({
          value: id,
          label: external_custom_id ? `${title} | ${external_custom_id}` : title
        }))
    }
  },
  UPDATED_AT: {
    accessorKey: 'updatedAt',
    sortDescFirst: true,
    header: (
      <span className='text-sm font-semibold text-gray-900'>
        {t('talents.column_header_last_activity')}
      </span>
    ),
    cell: ({ getValue }) => (
      <span className='text-sm text-gray-500'>
        {t('talents.updated_at', {
          datetime: format(new Date(getValue()), 'dd.MM.yyyy | HH:mm', {
            locale: locale[language]
          })
        })}
      </span>
    )
  },
  CREATED_STATUS: {
    accessorKey: 'created_status',
    sortDescFirst: true,
    header: (
      <span className='text-sm font-semibold text-gray-900'>
        {t('talents.column_header_created_at')}
      </span>
    ),
    cell: ({ getValue }) => (
      <span className='text-sm text-gray-500'>
        {format(new Date(getValue().createdAt), 'dd.MM.yyyy', {
          locale: locale[language]
        })}
      </span>
    ),
    filter: {
      id: 'created_status',
      filterKey: 'computed',
      operator: 'in',
      fn: filterCreatedStatus,
      name: t('talents.filter_title_created_before'),
      column: 'created_status',
      options: [
        {
          label: t('talents.filter_value_6_month'),
          value: 'BEFORE_6_MONTH'
        }
      ]
    }
  },
  ID: {
    accessorKey: 'id',
    header: null,
    cell: () => <div />,
    invisible: true
  },
  OWNER: {
    accessorKey: 'owner',
    header: null,
    cell: () => <div />,
    invisible: true
  }
})

export const createTalentTableColumns = ({
  adminInsights,
  t,
  careers_map,
  data,
  language,
  partner,
  locale,
  handleNavigate,
  actions
}) => {
  const {
    SELECT,
    ADMIN_INSIGHTS,
    IDENTIFICATION,
    PARTNER_USER_ID,
    NAME,
    SOURCE,
    UPDATED_AT,
    CREATED_STATUS,
    ID,
    OWNER
  } = COLUMNS({ t, careers_map, data, language, locale })

  return [
    SELECT,
    adminInsights && ADMIN_INSIGHTS,
    IDENTIFICATION,
    PARTNER_USER_ID,
    NAME,
    SOURCE,
    {
      accessorKey: 'progress',
      sortDescFirst: true,
      header: (
        <span className='text-sm font-semibold text-gray-900'>
          {t('talents.column_header_progress')}
        </span>
      ),
      cell: ({ getValue }) => {
        const progress = getValue() || 0

        return (
          <div style={{ height: 48, width: 48 }}>
            <CircularProgressbar
              strokeWidth={4}
              value={progress}
              text={`${progress}% `}
              className='font-semibold'
              styles={buildStyles({
                pathColor: '#29CCC7',
                textColor: '#29CCC7',
                trailColor: '#F5F7FA',
                textSize: '24px'
              })}
            />
          </div>
        )
      },
      sortingFn: (rowA, rowB) => {
        const progressA = rowA.original.progress || 0
        const progressB = rowB.original.progress || 0

        return progressA < progressB ? 1 : -1
      }
    },
    {
      accessorKey: 'status',
      sortDescFirst: true,
      disableOnClick: true,
      header: (
        <span className='text-sm font-semibold text-gray-900'>
          {t('talents.column_header_status')}
        </span>
      ),
      cell: ({ cell }) => {
        const { assessment_status, smart_predict_status, progress } =
          cell.row.original

        const { status, action } =
          progress === 100 ? smart_predict_status : assessment_status

        const onClick = action
          ? () => actions[action](cell.row.original)
          : () => handleNavigate(cell)

        return (
          <span
            onClick={onClick}
            className={classNames(
              'text-sm',
              action
                ? 'font-medium text-blue-700 hover:text-blue-600 cursor-pointer'
                : 'text-gray-500'
            )}
          >
            {action
              ? t(`talents.actions.${action}`)
              : t(status.label, { progress })}
          </span>
        )
      },
      sortingFn: (rowA, rowB, columnId) => {
        // '291' < '3'
        const a_progress = rowA.original.progress
        const b_progress = rowB.original.progress

        const { sortWeight: a_sortWeight } =
          a_progress === 100
            ? rowA.getValue(columnId).smart_predict_status.status
            : rowA.getValue(columnId).assessment_status.status

        const { sortWeight: b_sortWeight } =
          b_progress === 100
            ? rowB.getValue(columnId).smart_predict_status.status
            : rowB.getValue(columnId).assessment_status.status

        return a_sortWeight < b_sortWeight ? 1 : -1
      }
    },
    {
      invisible: true,
      accessorKey: 'assessment_status',
      header: null,
      cell: () => <div />,
      filter: {
        id: 'assessment_status',
        filterKey: 'computed',
        operator: 'in',
        fn: filterStatus,
        name: t('talents.filter_title_assessment_status'),
        column: 'assessment_status',
        options: Object.keys(ASSESSMENT_SPACE_STATES)
          .map((key) => ({
            value: ASSESSMENT_SPACE_STATES[key].filterValue,
            label: t(ASSESSMENT_SPACE_STATES[key].filterValue),
            sortWeight: ASSESSMENT_SPACE_STATES[key].sortWeight
          }))
          .sort((a, b) => (a.sortWeight < b.sortWeight ? -1 : 1))
      }
    },
    {
      invisible: true,
      accessorKey: 'smart_predict_status',
      header: null,
      cell: () => <div />,
      filter: {
        id: 'smart_predict_status',
        filterKey: 'computed',
        operator: 'in',
        fn: filterStatus,
        name: t('talents.filter_title_smart_predict_status'),
        column: 'smart_predict_status',
        options: Object.keys(SMART_PREDICT_SPACE_STATES)
          .map((key) => ({
            value: SMART_PREDICT_SPACE_STATES[key].filterValue,
            label: t(SMART_PREDICT_SPACE_STATES[key].filterValue),
            sortWeight: SMART_PREDICT_SPACE_STATES[key].sortWeight
          }))
          .sort((a, b) => (a.sortWeight < b.sortWeight ? -1 : 1))
      }
    },
    {
      accessorKey: 'score',
      sortDescFirst: true,
      disableOnClick: true,
      header: (
        <span className='text-sm font-semibold text-gray-900'>
          {t('talents.column_header_score')}
        </span>
      ),
      onClick: async (o) => {
        const {
          matching_score_to_space_career,
          career_id,
          partner_user_id,
          owner
        } = o.row.original

        if (typeof matching_score_to_space_career !== 'number') return

        const variables = {
          career_id,
          language,
          partner_user_id: partner_user_id || undefined,
          user_id: partner_user_id ? undefined : owner
        }

        const pdfDocument = window.open('', '_blank')
        pdfDocument.document.write(t('pages.talent.pdf_preparation'))

        try {
          const pdf = await query({ query: 'generatePdf', variables })

          pdfDocument.location.href = JSON.parse(pdf).headers.Location
        } catch (err) {
          notifyBugsnag(err)
          pdfDocument.close()
        }
      },
      cell: ({ getValue }) => {
        const score = getValue()
        const scoreAvailable = typeof score === 'number'

        return (
          <div
            title={scoreAvailable ? 'Download' : undefined}
            style={{
              height: 32,
              width: 60,
              backgroundColor: getBackgroundColorByMatchingScore(score)
            }}
            className={`rounded text-white font-bold flex items-center justify-center z-50 ${
              scoreAvailable && 'hover:opacity-50'
            }`}
          >
            {partner.isScoreVisible === true ? score : ''}
            {scoreAvailable && (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
                fill='currentColor'
                className='w-5 h-5 ml-1'
              >
                <path d='M10.75 2.75a.75.75 0 00-1.5 0v8.614L6.295 8.235a.75.75 0 10-1.09 1.03l4.25 4.5a.75.75 0 001.09 0l4.25-4.5a.75.75 0 00-1.09-1.03l-2.955 3.129V2.75z' />
                <path d='M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z' />
              </svg>
            )}
          </div>
        )
      },
      filter: {
        id: 'score',
        filterKey: 'computed',
        operator: 'in',
        fn: filterScore,
        name: t('talents.filter_title_fit'),
        column: 'score',
        options: [
          {
            label: t('talents.filter_high_fit'),
            value: 'HIGH_FIT'
          },
          {
            label: t('talents.filter_middle_fit'),
            value: 'MIDDLE_FIT'
          },
          {
            label: t('talents.filter_low_fit'),
            value: 'LOW_FIT'
          }
        ]
      }
    },
    UPDATED_AT,
    CREATED_STATUS,
    ID,
    OWNER,
    {
      accessorKey: 'view',
      enableSorting: false,
      header: '',
      cell: () => (
        <ArrowSmallRightIcon className='ml-auto w-5 h-5 text-gray-500 group-hover:text-gray-900' />
      )
    }
  ]
    .filter((item) => item)
    .map((item) => {
      if (item.disableOnClick) return item

      return {
        ...item,
        onClick: handleNavigate
      }
    })
}

export const createRecoverTalentsTableColumns = ({
  adminInsights,
  t,
  careers_map,
  data,
  language,
  locale
}) => {
  const {
    SELECT,
    ADMIN_INSIGHTS,
    IDENTIFICATION,
    PARTNER_USER_ID,
    NAME,
    SOURCE,
    CREATED_STATUS,
    ID,
    OWNER
  } = COLUMNS({ t, careers_map, data, language, locale })

  return [
    SELECT,
    adminInsights && ADMIN_INSIGHTS,
    {
      ...IDENTIFICATION,
      cell: ({ getValue }) => (
        <span className='text-sm font-medium text-gray-900'>{getValue()}</span>
      )
    },
    PARTNER_USER_ID,
    NAME,
    SOURCE,
    {
      accessorKey: 'soft_deleted',
      sortDescFirst: true,
      header: (
        <span className='text-sm font-semibold text-gray-900'>
          {t('talents.column_header_soft_deleted')}
        </span>
      ),
      cell: ({ getValue }) => (
        <span className='text-sm text-gray-500'>
          {t('talents.soft_deleted', {
            datetime: format(new Date(getValue()), 'dd.MM.yyyy | HH:mm', {
              locale: locale[language]
            })
          })}
        </span>
      )
    },
    { ...CREATED_STATUS, filter: undefined },
    ID,
    OWNER
  ].filter((item) => item)
}
