/** https://www.arbeitsagentur.de/bildung/ausbildung/erkunde-die-berufsfelder */
const jobCategories = [
  -1, 11, 12, 21, 22, 23, 24, 25, 26, 27, 28, 29, 31, 32, 33, 34, 41, 42, 43,
  51, 52, 53, 54, 61, 62, 63, 71, 72, 73, 81, 82, 83, 84, 91, 92, 93, 94, 1
]

const TRANSLATION_LOCATION = 'constants.position_categories.'

export const getAllJobCategories = () => {
  // [ key, value ]
  return jobCategories.map((id) => [
    String(id),
    TRANSLATION_LOCATION + String(id)
  ])
}

export const getJobCategory = (id) => {
  return id ? TRANSLATION_LOCATION + id : undefined
}
