/* eslint react/prop-types: 0 */
import { AivyContextProvider } from './aivy-context'
import { LanguageContextProvider } from './language-context'
import { NotificationContextProvider } from './notification-context'
import { PartnerContextProvider } from './partner-context'
import { PaymentContextProvider } from './payment-context'
import { ProductTourContextProvider } from './product-tour-context'
import { UserContextProvider } from './user-context'

export { useAivyContext } from './aivy-context'
export { useLanguageContext } from './language-context'
export { useNotificationContext } from './notification-context'
export { usePartnerContext } from './partner-context'
export { usePaymentContext } from './payment-context'
export { useProductTourContext } from './product-tour-context'
export { useUserContext } from './user-context'

export const GeneralContext = ({ language, children }) => {
  return (
    <NotificationContextProvider>
      <LanguageContextProvider language={language}>
        {children}
      </LanguageContextProvider>
    </NotificationContextProvider>
  )
}

export const DataContext = ({
  system,
  restartDashboard,
  cognitoUser,
  active_space,
  refetch_active_space,
  isAdmin,
  partner,
  refetchPartner,
  payments,
  emailEndpoint,
  refetchEmailEndpoint,
  spacesByOwner,
  refetchSpacesByOwner,
  user,
  children
}) => {
  return (
    <ProductTourContextProvider>
      <AivyContextProvider system={system} restartDashboard={restartDashboard}>
        <UserContextProvider
          cognitoUser={cognitoUser}
          active_space={active_space}
          refetch_active_space={refetch_active_space}
          isAdmin={isAdmin}
          emailEndpoint={emailEndpoint}
          refetchEmailEndpoint={refetchEmailEndpoint}
          spacesByOwner={spacesByOwner}
          refetchSpacesByOwner={refetchSpacesByOwner}
          user={user}
        >
          <PartnerContextProvider
            partner={partner}
            refetchPartner={refetchPartner}
          >
            <PaymentContextProvider payments={payments}>
              {children}
            </PaymentContextProvider>
          </PartnerContextProvider>
        </UserContextProvider>
      </AivyContextProvider>
    </ProductTourContextProvider>
  )
}
