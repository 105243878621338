import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { classNames } from '../../helpers'

const Header = ({ className, children }) => {
  const { t } = useTranslation()

  return (
    <h2
      className={classNames(
        'text-3xl font-extrabold tracking-tight text-gray-900',
        className
      )}
    >
      {t(children)}
    </h2>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
}

Header.defaultProps = {
  className: undefined
}

export default Header
