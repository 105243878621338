import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { useLocation, useNavigate } from 'react-router'

import Introduction from './introduction'
import Announcement from '../announcement'
import Guide from './guide'
import Tutorials from './tutorials'
import { usePersist } from '../../hooks/use-persist'
import { query } from '../../graphql'
import {
  employeeContextFilterInput,
  talentContextFilterInput
} from '../../graphql/filter-inputs'
import InviteYourselfModal from './invite-yourself-modal'
import { usePartnerContext } from '../../context/partner-context'
import { usePaymentContext } from '../../context/payment-context'
import { useProductTourContext, useUserContext } from '../../context'
import Button from '../tailwind/Button'
import clsx from 'clsx'
import { useWindowDimensions } from '../../hooks/use-window-dimensions'

const Trial = () => {
  const { t } = useTranslation()
  const { partner } = usePartnerContext()
  const { expires_at, is_trial, showPaywall } = usePaymentContext()
  const { active_space, isAdmin } = useUserContext()

  const { is_sidebar_collapsed } = active_space.ui_settings_general

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { width } = useWindowDimensions()

  const [displayIntroduction, setDisplayIntroduction] = usePersist({
    key: 'TRIAL:DISPLAY_INTRODUCTION',
    initialState: true
  })

  const [displayCareersTutorial, setDisplayCareersTutorial] = usePersist({
    key: 'TRIAL:DISPLAY_CAREERS_TUTORIAL',
    initialState: false
  })

  const [displayTalentsTutorial, setDisplayTalentsTutorial] = usePersist({
    key: 'TRIAL:DISPLAY_TALENTS_TUTORIAL',
    initialState: false
  })

  const { status, data } = useQuery({
    queryKey: ['trial-data', partner.id],
    queryFn: () =>
      Promise.all([
        query({
          query: 'spacesByPartnerIdByUpdatedAt',
          variables: {
            partner_id: partner.id,
            filter: talentContextFilterInput
          }
        }),
        query({
          query: 'careerByPartnerByDate',
          variables: {
            partner_id: partner.id
          }
        }),
        query({
          query: 'spacesByPartnerIdByUpdatedAt',
          variables: {
            partner_id: partner.id,
            filter: employeeContextFilterInput
          }
        })
      ]),
    select: ([spaces, careers, employees]) => ({
      spaces,
      careers,
      employees
    }),
    enabled: !!is_trial
  })

  const {
    productTourState: { tourActive }
  } = useProductTourContext()

  if (!is_trial || tourActive) return null
  if (status === 'pending') return null

  // 'xl': '1280px',
  // => @media (min-width: 1280px) { ... }
  if (width < 1280) return null

  const date1 = new Date(expires_at)
  const date2 = new Date()
  const difference = date1.getTime() - date2.getTime()
  const trial_days = Math.ceil(difference / (1000 * 3600 * 24))

  if (isAdmin) {
    return (
      <Announcement
        type='INFO'
        text={t('trial.trial_ends_in.message', { days: trial_days })}
      />
    )
  }

  return (
    <>
      {trial_days <= 10 && (
        <Announcement
          text={t('trial.trial_ends_in.message', { days: trial_days })}
          type='INFO'
          onClick={() => showPaywall('general')}
          // onClick={() => navigate('/upgrade')}
        />
      )}
      <Introduction
        open={displayIntroduction}
        next={() => {
          setDisplayIntroduction(false)
          setTimeout(() => {
            setDisplayCareersTutorial(true)
            setDisplayTalentsTutorial(true)
          }, 1000 * 0.4)
        }}
        trial_days={trial_days}
      />
      <Tutorials
        displayCareersTutorial={displayCareersTutorial}
        onCloseCareersTutorial={() => setDisplayCareersTutorial(false)}
        displayTalentsTutorial={displayTalentsTutorial}
        onCloseTalentsTutorial={() => {
          setDisplayTalentsTutorial(false)
          setTimeout(() => navigate('/talents'), 1000 * 0.4)
        }}
      />
      <div
        className={clsx(
          'z-10 fixed  bottom-14',
          // sidebar-width: is_sidebar_collapsed ? 'w-20' : 'w-72'
          is_sidebar_collapsed ? 'left-24' : 'left-80'
        )}
      >
        <Guide
          data={data}
          insideTutorial={displayCareersTutorial || displayTalentsTutorial}
          toggleTutorials={() => {
            return (
              ['/careers', '/talents'].includes(pathname) && (
                <div
                  id={
                    (pathname === '/careers' && 'display-help-careers') ||
                    (pathname === '/talents' && 'display-help-talents')
                  }
                >
                  <Button.SecondaryBase
                    text={t('trial.guide.display_help_action')}
                    onClick={() => {
                      if (pathname === '/careers') {
                        setDisplayCareersTutorial(!displayCareersTutorial)
                      }

                      if (pathname === '/talents') {
                        setDisplayTalentsTutorial(!displayTalentsTutorial)
                      }
                    }}
                    disabled={!['/careers', '/talents'].includes(pathname)}
                  />
                </div>
              )
            )
          }}
        />
      </div>
      <InviteYourselfModal data={data} />
    </>
  )
}

export default Trial
