export const SPIDER_CHART_COLORS = [
  {
    backgroundColor: 'rgba(26, 188, 156,0.2)',
    borderColor: 'rgba(26, 188, 156,1.0)'
  },
  {
    backgroundColor: 'rgba(46, 204, 113,0.2)',
    borderColor: 'rgba(46, 204, 113,1.0)'
  },
  {
    backgroundColor: 'rgba(52, 152, 219,0.2)',
    borderColor: 'rgba(52, 152, 219,1.0)'
  },
  {
    backgroundColor: 'rgba(155, 89, 182,0.2)',
    borderColor: 'rgba(155, 89, 182,1.0)'
  },
  {
    backgroundColor: 'rgba(52, 73, 94,0.2)',
    borderColor: 'rgba(52, 73, 94,1.0)'
  },
  {
    backgroundColor: 'rgba(26, 188, 156,0.2)',
    borderColor: 'rgba(26, 188, 156,1.0)'
  },
  {
    backgroundColor: 'rgba(241, 196, 15,0.2)',
    borderColor: 'rgba(241, 196, 15,1.0)'
  },
  {
    backgroundColor: 'rgba(230, 126, 34,0.2)',
    borderColor: 'rgba(230, 126, 34,1.0)'
  },
  {
    backgroundColor: 'rgba(231, 76, 60,0.2)',
    borderColor: 'rgba(231, 76, 60,1.0)'
  },
  {
    backgroundColor: 'rgba(190, 46, 221,0.2)',
    borderColor: 'rgba(190, 46, 221,1.0)'
  },
  {
    backgroundColor: 'rgba(104, 109, 224,0.2)',
    borderColor: 'rgba(104, 109, 224,1.0)'
  },
  {
    backgroundColor: 'rgba(186, 220, 88,0.2)',
    borderColor: 'rgba(186, 220, 88,1.0)'
  },
  {
    backgroundColor: 'rgba(246, 229, 141,0.2)',
    borderColor: 'rgba(246, 229, 141,1.0)'
  },
  {
    backgroundColor: 'rgba(255, 121, 121,0.2)',
    borderColor: 'rgba(255, 121, 121,1.0)'
  },
  {
    backgroundColor: 'rgba(19, 15, 64,0.2)',
    borderColor: 'rgba(19, 15, 64,1.0)'
  }
]
