import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/react'
import { useTranslation } from 'react-i18next'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import PropTypes from 'prop-types'

import { useAivyContext, useLanguageContext } from '../../context'
import SlideoverWithBackgroundOverlay from '../tailwind/slideover-with-background-overlay'

const SlideoverDimensions = ({ open, setOpen }) => {
  const { t } = useTranslation()
  const { system } = useAivyContext()
  const { language } = useLanguageContext()

  const { PERSONALITY, SKILLS, INTERESTS } = {
    PERSONALITY: {
      ...system.categories['1'],
      dimensions: Object.keys(system.dimensions)
        .filter((key) => system.dimensions[key].category === 1)
        .map((key) => ({ ...system.dimensions[key] }))
    },
    SKILLS: {
      ...system.categories['2'],
      dimensions: Object.keys(system.dimensions)
        .filter((key) => system.dimensions[key].category === 2)
        .map((key) => ({ ...system.dimensions[key] }))
    },
    INTERESTS: {
      ...system.categories['3'],
      dimensions: Object.keys(system.dimensions)
        .filter((key) => system.dimensions[key].category === 3)
        .map((key) => ({ ...system.dimensions[key] }))
    }
  }

  return (
    <SlideoverWithBackgroundOverlay
      className='w-screen max-w-lg'
      title={t('team_analyse.slideover_dimensions.title')}
      open={open}
      setOpen={setOpen}
    >
      <div className='mx-auto pb-16'>
        {[PERSONALITY, SKILLS, INTERESTS].map((category, index) => (
          <div
            key={index}
            className='mx-auto pb-16 max-w-4xl divide-y divide-gray-900/10'
          >
            <h2 className='text-xl font-bold leading-10 tracking-tight text-gray-900'>
              {category.name[language]}
            </h2>
            <dl className='mt-4 space-y-4 divide-y divide-gray-900/10'>
              {category.dimensions.map((dimension, index) => (
                <Disclosure as='div' key={index} className='pt-4'>
                  {({ open }) => (
                    <>
                      <dt>
                        <DisclosureButton className='flex w-full items-start justify-between text-left text-gray-900'>
                          <span className='text-md font-medium leading-7'>
                            {dimension.property[language]}
                          </span>
                          <span className='ml-6 flex h-7 items-center'>
                            {open ? (
                              <MinusSmallIcon
                                className='h-6 w-6'
                                aria-hidden='true'
                              />
                            ) : (
                              <PlusSmallIcon
                                className='h-6 w-6'
                                aria-hidden='true'
                              />
                            )}
                          </span>
                        </DisclosureButton>
                      </dt>
                      <DisclosurePanel as='dd' className='mt-2 pr-12'>
                        <p className='text-sm leading-7 text-gray-900'>
                          {dimension.intro[language]}
                        </p>
                      </DisclosurePanel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        ))}
      </div>
    </SlideoverWithBackgroundOverlay>
  )
}

SlideoverDimensions.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
}

export default SlideoverDimensions
