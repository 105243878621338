// &nbsp;
const notes_20230110 = `
# 🗣️ Aivy Update

### Nachrichtenvorlagen

Die **Nachrichtenvorlagen** können **ab sofort pro Stelle** vollständig angepasst und gespeichert werden. Dazu gibt es eine neue Einstellung unter der Stelle und innerhalb der Erstellen-Ansicht.

### Englische Sprache

Du kannst das Dashboard inkl. den Profilen (Passungsprofil & Anforderungsprofil) auch auf **Englisch** verwenden.  Um die Sprache zu wechseln, nutze dazu den Schalter (DE) neben dem Profil-Icon oben rechts. 

### Öffentlicher Einladungslink

Mit dem öffentlichen Einladungslink kannst du Talente schon in deiner Stellenanzeige, in Social Media Kampagnen oder an anderer Stelle vor der eigentlichen eingegangenen Bewerbung zu Aivy für diese Stelle einladen. Der öffentliche Einladungslink lässt sich innerhalb einer Stelle konfigurieren.

### Talente weiterleiten

Ab sofort lassen sich Talente, nachdem sie das Assessment abschließen, zu einer URL deiner Wahl weiter leiten.
`

const notes_20230201 = `
# 🗣️ Aivy Update

### Überarbeitete Anforderungsanalyse ✨

Wir haben das **Design** der Anforderungsanalyse **umfassend überarbeitet**. Neben einer **verbesserten Benutzeroberfläche** und **Nutzerführung**, bieten wir die Anforderungsanalyse ab sofort auch auf **Englisch** an.

### Stellen duplizieren

Stellen lassen sich nun in der Übersicht **duplizieren**, nachdem sie aktiv geschaltet wurden.

### Neue Einstellungsoptionen für Stellen

Unter den **Einstellungen einer Stelle** lässt sich nun die **Sprache ändern**. So erhalten Talente das Assessment auf Wunsch z.B. auf Englisch. Außerdem **kann ein Bild hinterlegt** werden, welches in dem **Assessment des Talents genutzt wird** - als Standard wird das Logo des Unternehmens genutzt.
`

const notes_20231017 = `
# 🗣️ Aivy Update
###
Hi zusammen, wir möchten euch nun wieder regelmäßig darüber informieren, an welchen Stellschrauben wir gedreht haben, damit euer Erlebnis im Dashboard einzigartig wird! 🚀

### 🔬 Neue Funktion bei den Kreuzvergleichen

Talente können nun erneut zum Assessment eingeladen werden, wenn die Informationen für die Kreuzvergleiche zuvor nicht ausreichend waren. Im Assessment werden den Talenten dann nur die restlichen Challenges angezeigt. Bei Abschluss können Talente dann wie gewohnt weiter mit anderen Stellen und Talenten verglichen werden. 

### ✨ Verbesserter Flow bei "Stelle erstellen"
Wir haben das Erstellen der Stelle noch einmal vereinfacht. Im zweiten Schritt gibt es nun eine Auswahl an Möglichkeiten, wie die Anforderungen für die Stelle zu definieren sind. Neben unserer empfohlenen Expert*innen-Einladung und den Vorlagen kann jetzt auch ein bestehendes Anforderungsprofil einer bereits aktiven Stelle dafür verwendet werden.

### ⚙️ Diverse Fehlerbehebungen
Im Hintergrund haben wir einige Dinge optimiert und vebessert, damit du Aivy noch besser nutzen kannst.
###
###
So, das war es erst einmal für heute. Wir melden uns sicherlich bald wieder 🥳
###
Eure Aivy Engineers
`

const notes_20231102 = `
# 🗣️ Aivy Update
###
Hi zusammen, wir haben im heutigen Update zwei Neuigkeiten anzukündigen. 
###
### 📱 Anforderungsanalyse mobil optimiert
![requirement-analysis](https://react-native-app-content.s3.eu-central-1.amazonaws.com/assets/requirement-analysis-mobile.png)
Eingeladenen Expert:innen können ab sofort die Anforderungen zur Stelle über das Smartphone definieren und müssen nicht zwangsläufig den Link auf dem Desktop-Rechner öffnen. Darüber hinaus haben wir die Benutzeroberfläche verbessert und bieten am Ende eine Übersicht über die definierten Anforderungen.
###
### ✨ Design der Talent-Tabelle überarbeitet
Wir haben das Design der Talent-Tabelle etwas angepasst. Unter der Kennung befindet sich nun Name, E-Mail und die Externe-ID. Die Stellen haben wir farblich markiert, sodass Stellen jetzt bei langen Listen einfacher zuzuordnen sind. Unter dem Assessment-Status zeigen wir nun den Fortschritt anhand eines Kreisdiagramms an.
###
Eure Aivy Engineers
`

const notes_20240111 = `
# 🗣️ Aivy Update
###
Hi alle zusammen,

wir starten ins neue Jahr mit tollen Neuigkeiten im Produkt.
###
### 🔬 Neu bei uns: Teamanalysen
In dieser neuen Funktion besteht die Möglichkeit, einzelne Talente zu einer Teamanalyse einzuladen. Die Dimensionen der Talente werden in einem Spinnendiagramm gegenübergestellt. Mit unserem Interpretationsleitfaden lassen sich die Ergebnisse zielführend interpretieren. Du findest die Teamanalyse direkt links im Menü.
###
[Jetzt ausprobieren](https://www.dashboard.aivy.app/team-analyses)
###
---------------
### 📨 E-Mail-Vorlage für Absagen
Wir haben zusätzlich zur Einladung und Erinnerungs-E-Mail-Vorlage nun auch eine E-Mail-Vorlage für Absagen implementiert. Talente können ab sofort automatisch benachrichtigt werden, sobald der Status auf abgelehnt gesetzt wird. Außerdem haben wir die Benutzeroberfläche des Eingabefeldes optimiert. Die neue Option findest z.B. in der Talentdetail-Seite unter Aktionen → Status ändern.
###
---------------
### 🎮 Optimierte Spielmechanik zur “Hochstapler” Challenge
Vereinzelt gab es bei User*innen Feedback, dass unter Microsoft Edge mit Windows, die Steine sich nicht verschieben lassen. Das Problem haben wir mit einer neuen Spielmechanik gelöst. So stellen wir sicher, dass die Challenge weiterhin auf allen gängigen Plattformen (Web, IOS & Android) spielbar ist.
###
[Jetzt ausprobieren](https://webapp.aivy.app/?challenge=TOWER_OF_LONDON)
###
---------------
### ⚙️ Diverse Fehlerbehebungen & Verbesserungen
Wir sind sehr dankbar für euer Feedback, das wir erhalten haben. Im Hintergrund haben wir Fehler behoben, die ihr uns mitgeteilt habt.
###
Eure Aivy Engineers
`

const notes_20240404 = `
# 🗣️ Aivy Update
###
Hi alle zusammen,

in den letzten Monaten haben wir an tollen neuen Features gearbeitet und bestehende verbessert. Darüber möchten wir euch heute einmal informieren.
###
### 📧 Neuer Editor für deine E-Mail-Vorlagen
![E-Mail-Vorlage](https://assets.unlayer.com/projects/169277/1712238146106-dashboard-release-note-april.gif)
Wir haben den Editor für die E-Mail-Vorlagen umfassend überarbeitet und zeigen ab sofort auch eine Live-Vorschau während des Bearbeitens an. Der Editor selbst bietet eine neue Toolbar, mit der sich der Inhalt einfach formatieren lässt. Auch unsere Makros haben dort Platz gefunden. Ab sofort kann auch jederzeit eine Test-E-Mail an sich selbst verschickt werden, um sicherzugehen, dass die E-Mail optimal aussieht, bevor Talente eingeladen werden!
###
[Jetzt ausprobieren](https://dashboard.aivy.app/settings#templates)
###
---------------
### 📂  Talente als CSV-Datei exportieren
Du kannst ab sofort alle oder ausgewählte Talente per CSV-Datei exportieren. Die Datenspalten wählst du selbst aus, wie es für dich am besten passt. Die neue Funktion findest du direkt auf der Talentseite neben der Suchleiste. Dort befindet sich ein Kreis mit drei Punkten.
###
---------------
### ▶️ Automatisierungen
Mit unseren neuen Automatisierungen kannst du Talente z.B. automatisch erinnern das Assessment abzuschließen. Zusätzlich haben wir eine Unterstützung eingebaut, um die Einhaltung von Datenschutzrichtlinien zu vereinfachen. Zum Beispiel können Talente von uns nach 6 Monaten automatisiert gelöscht werden.
###
[Jetzt ausprobieren](https://dashboard.aivy.app/settings#automations)
###
---------------
---------------
### 🗑️ Talente wiederherstellen
Die Funktion ermöglicht es dir, bereits freigegebene Talente wiederherzustellen. Freigegebene Talente können innerhalb von 14 Tagen wiederhergestellt werden. Die neue Funktion findest du direkt auf der Talentseite neben der Suchleiste.
###
[Jetzt ausprobieren](https://dashboard.aivy.app/recover/talents)
###
---------------
---------------
### ℹ️ Unser neuer Support-Hub
Du findest unseren neuen Support-Hub links im Menü. Öffne ihn, um intelligent vorgeschlagene Hilfe-Artikel von uns zu erhalten. Du bist z.B. aktuell mit Talenten beschäftigt, dann hilft dir unser Support-Hub bei Fragestellungen speziell mit Talenten. Bist du in den Stellen/Assessments unterwegs, erhältst du Tipps speziell zu dem Thema.
###
---------------
### ⚙️ Diverse Fehlerbehebungen & Verbesserungen
Wir sind sehr dankbar für euer Feedback, das wir erhalten haben. Im Hintergrund haben wir Fehler behoben, die ihr uns mitgeteilt habt. Zusätzlich haben wir das Einladen der Talente vereinfacht, einen neuen Talent-Status-Filter eingeführt und das Menü links überarbeitet. Das lässt sich ab sofort auch einklappen.
###
Eure Aivy Engineers
`

const notes_20240617 = `
# 🗣️ Neu im Aivy-Dashboard
###
Hallo zusammen,

wir freuen uns, euch das neueste Release vorzustellen, das einige spannende Updates enthält. Wir haben für euch wie immer einmal zusammengefasst, welche Änderungen durchgeführt wurden.
###
---------------
### 📊 Teamanalysen überarbeitet
Unsere Teamanalysen-Funktion hat ein umfassendes Update erhalten. Ab sofort gibt es zusätzlich zum Spinnennetzdiagramm **eine neue Listenansicht, die intuitiver ist und euch detaillierter eure Teamanalysen durchführen lässt**. Neu dazugekommen ist auch die Möglichkeit jederzeit die **Anforderungen eurer Stelle zur Teamanalyse einzublenden**.
###
[Jetzt ausprobieren](https://dashboard.aivy.app/team-analyses)
###
---------------
### 🆕 Neue Benutzerrollen unter Teammitglieder
Wir haben das Rollensystem für Teammitglieder erweitert und bieten jetzt **vier neue Benutzerrollen an: Administrator, HR-Verantwortlicher, Recruiter und Gast**. Jede Rolle hat spezifische Berechtigungen und Verantwortlichkeiten, um den Workflow und die Zusammenarbeit im Team zu optimieren. Ihr könnt die neuen Rollen direkt in den Benutzereinstellungen vergeben.
###
[Jetzt ausprobieren](https://dashboard.aivy.app/settings#user)
###
---------------
### ⚙️ Diverse Fehlerbehebungen & Verbesserungen

Dank eures wertvollen Feedbacks konnten wir verschiedene Fehler beheben und Verbesserungen vornehmen.
###
Eure Aivy Engineers
`

export const release_notes = [
  {
    key: 'notes_20230201',
    notes: notes_20230201,
    created_at: '2023-02-01T00:00:00.173Z'
  },
  {
    key: 'notes_20230110',
    notes: notes_20230110,
    created_at: '2023-01-10T00:00:00.173Z'
  },
  {
    key: 'notes_20231017',
    notes: notes_20231017,
    created_at: '2023-10-17T00:00:00.173Z'
  },
  {
    key: 'notes_20231102',
    notes: notes_20231102,
    created_at: '2023-11-02T00:00:00.173Z'
  },
  {
    key: 'notes_20240111',
    notes: notes_20240111,
    created_at: '2024-01-11T00:00:00.173Z'
  },
  {
    key: 'notes_20240404',
    notes: notes_20240404,
    created_at: '2024-04-04T00:00:00.173Z'
  },
  {
    key: 'notes_20240617',
    notes: notes_20240617,
    created_at: '2024-06-17T00:00:00.173Z'
  }
]
