import PropTypes from 'prop-types'
import { classNames } from '../helpers'

const LayoutPadding = ({ padding, background, border, children }) => {
  return (
    <div
      style={{
        background,
        borderTopWidth: border ? 1 : undefined,
        borderBottomWidth: border ? 1 : undefined,
        borderColor: border ? '#e7ebe5' : undefined
      }}
    >
      <div
        className={classNames(
          'max-w-7xl mx-auto px-4 sm:px-6 lg:px-8',
          padding
        )}
      >
        {children}
      </div>
    </div>
  )
}

LayoutPadding.propTypes = {
  padding: PropTypes.string,
  background: PropTypes.string,
  border: PropTypes.bool,
  children: PropTypes.node.isRequired
}

LayoutPadding.defaultProps = {
  padding: 'py-12', // default from tailwind ui
  background: undefined,
  border: false
}

export default LayoutPadding
