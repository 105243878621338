import { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { de, enUS as en } from 'date-fns/locale'
import { Formik } from 'formik'

import { classNames, uuidv4 } from '../../../helpers'
import InputFormik from '../../input-formik'
import Toggle from '../../tailwind/toggle'
import Button from '../../tailwind/Button'
import { useLanguageContext } from '../../../context'
import { isEqual } from 'lodash'

const EMPTY_FIELD = {
  type: 'text',
  label: '',
  placeholder: '',
  required: true
}

const AdditionalFields = ({
  career,
  updateHandler,
  backHandler,
  create,
  update,
  isLoading
}) => {
  const { t } = useTranslation()
  const { language } = useLanguageContext()

  const initial = useRef(
    JSON.parse(career.app_settings?.application_input_additional_fields || '[]')
  )

  const [edit, setEdit] = useState(null)
  const [fields, setFields] = useState(initial.current)

  const handleDropdownEvent = ({ id }) => {
    if (edit && edit === id) setEdit(null)
    else setEdit(id)
  }

  const createField = () => {
    const field = {
      id: uuidv4(),
      updatedAt: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      ...EMPTY_FIELD
    }

    setEdit(field.id)
    setFields((previous) => [...previous, field])
  }

  const updateField = ({ field }) => {
    field.updatedAt = new Date().toISOString()
    setFields((previous) => [
      ...previous.filter(({ id }) => id !== field.id),
      field
    ])
  }

  const removeField = ({ field }) => {
    setEdit(null)
    setFields((previous) => previous.filter(({ id }) => id !== field.id))
  }

  const handleSubmit = () => {
    updateHandler({ application_input_additional_fields: fields })
  }

  return (
    <div>
      {!fields.length && (
        <div className='px-4 py-5 space-y-6 sm:p-6 sm:rounded-md bg-gray-50'>
          <button
            type='button'
            onClick={createField}
            className='relative flex justify-center items-center group w-full rounded-lg border-2 border-dashed border-gray-300 p-4 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
          >
            <PlusCircleIcon className='h-8 w-8 text-gray-500 group-hover:text-gray-700' />
            <span className='ml-2 block text-sm font-semibold text-gray-500 group-hover:text-gray-700'>
              {t('career.flex.create_field_action')}
            </span>
          </button>
        </div>
      )}
      <ul role='list'>
        {fields.map((item) => (
          <li
            key={item.id}
            className={classNames(
              edit === item.id ? '' : 'border-t border-t-gray-100'
            )}
          >
            <div
              onClick={() => handleDropdownEvent({ id: item.id })}
              className={classNames(
                'px-4 py-2.5 flex items-center gap-x-3 hover:cursor-pointer',
                edit === item.id
                  ? 'bg-orange-50 border-l-4 border-orange-200'
                  : 'hover:bg-orange-50'
              )}
            >
              {edit === item.id ? (
                <ChevronUpIcon
                  // -ml-1 because of the border
                  className='-ml-1 w-5 h-5 text-gray-700'
                />
              ) : (
                <ChevronDownIcon className='w-5 h-5 text-gray-700' />
              )}
              <span
                className={classNames(
                  'text-sm leading-6',
                  item.label
                    ? 'text-gray-900 font-semibold'
                    : 'text-gray-500 italic'
                )}
              >
                {item.label || t('career.flex.no_label')}
              </span>
              <div className='ml-auto'>
                <span className='text-xs text-gray-500 italic'>
                  {t('career.flex.field_last_updated_at')}
                </span>
                <span className='ml-1 text-sm text-gray-900'>
                  {format(new Date(item.updatedAt), 'd. MMMM yyyy', {
                    locale: { de, en }[language]
                  })}
                </span>
              </div>
              {edit === item.id && (
                <div
                  onClick={() => removeField({ field: item })}
                  className='h-8 w-8 p-1.5 bg-orange-300 rounded-full hover:cursor-pointer hover:bg-orange-400'
                >
                  <TrashIcon className='h-5 w-5 text-orange-50' />
                </div>
              )}
            </div>

            <div
              className={classNames(
                'px-4 py-2.5 border-l-4 border-orange-200',
                edit === item.id ? 'block' : 'hidden'
              )}
            >
              <Formik
                enableReinitialize
                initialValues={{
                  label: item.label,
                  placeholder: item.placeholder,
                  required: item.required
                }}
                validate={(values) => {
                  const errors = {}

                  if (!values.label) {
                    errors.label = t('career.flex.validate_required')
                  }

                  updateField({ field: { ...item, ...values } })

                  return errors
                }}
              >
                {(formik) => (
                  <form
                    className='p-4 flex flex-col gap-y-4 max-w-lg'
                    onSubmit={formik.handleSubmit}
                  >
                    {[
                      {
                        id: 'label',
                        label: t('career.flex.label_label'),
                        placeholder: t('career.flex.label_placeholder'),
                        description: t('career.flex.label_description')
                      },
                      {
                        id: 'placeholder',
                        label: t('career.flex.placeholder_label'),
                        placeholder: t('career.flex.placeholder_placeholder'),
                        description: t('career.flex.placeholder_description')
                      }
                    ].map((input, index) => (
                      <div key={index}>
                        <InputFormik {...input} formik={formik} />
                      </div>
                    ))}
                    <Toggle
                      appearance='with-left-label-and-description'
                      label={t('career.flex.required_label')}
                      description={t('career.flex.required_description')}
                      enabled={formik.values.required}
                      setEnabled={() => {
                        formik.setFieldValue(
                          'required',
                          !formik.values.required
                        )
                      }}
                    />
                  </form>
                )}
              </Formik>
            </div>
          </li>
        ))}
      </ul>
      {!!fields.length && (
        <div className='px-4 py-3 text-right sm:rounded-b-md sm:px-6 bg-gray-50'>
          <Button.SecondaryBase
            appearance='plus'
            text={t('career.flex.create_field_action')}
            onClick={createField}
          />
        </div>
      )}
      <div className='mt-8 flex gap-x-2 justify-end'>
        {create && (
          <Button.PrimaryLG
            text={t('career.flex.create_flex.next_action')}
            onClick={handleSubmit}
            disabled={fields.some(({ label }) => !label)}
          />
        )}
        {update && (
          <>
            <Button.SecondaryLG
              text={t('career.flex.create_flex.back_action')}
              onClick={backHandler}
            />
            <Button.PrimaryLG
              text={t('career.flex.create_flex.submit_action')}
              onClick={handleSubmit}
              disabled={
                fields.some(({ label }) => !label) ||
                isEqual(initial.current, fields)
              }
              isLoading={isLoading}
            />
          </>
        )}
      </div>
    </div>
  )
}

AdditionalFields.propTypes = {
  career: PropTypes.object.isRequired,
  updateHandler: PropTypes.func.isRequired,
  backHandler: PropTypes.func.isRequired,
  create: PropTypes.bool.isRequired,
  update: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool
}

AdditionalFields.defaultProps = {
  isLoading: false
}

export default AdditionalFields
