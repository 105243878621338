import PropTypes from 'prop-types'

const Divider = ({ marginY }) => (
  <div
    style={{ marginTop: marginY, marginBottom: marginY }}
    className='w-full border-t border-gray-200'
  />
)

Divider.propTypes = {
  marginY: PropTypes.number
}

Divider.defaultProps = {
  marginY: 32
}

export default Divider
