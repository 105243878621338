import { createContext, useContext, useMemo } from 'react'
import { useSetState } from '../hooks/use-set-state'

const appState = {
  run: false,
  stepIndex: 0,
  steps: [],
  tourActive: false
}

const ProductTourContext = createContext({
  productTourState: appState,
  setProductTourState: () => undefined
})

ProductTourContext.displayName = 'ProductTourContext'

export function ProductTourContextProvider(props) {
  const [productTourState, setProductTourState] = useSetState(appState)

  const value = useMemo(
    () => ({
      productTourState,
      setProductTourState
    }),
    [setProductTourState, productTourState]
  )

  return <ProductTourContext.Provider value={value} {...props} />
}

export function useProductTourContext() {
  const context = useContext(ProductTourContext)

  if (!context) {
    throw new Error('useProductTourContext must be used within a AppProvider')
  }

  return context
}
