import PropTypes from 'prop-types'
import { LockClosedIcon } from '@heroicons/react/24/solid'
import { Tooltip as ReactTooltip } from 'react-tooltip'

const LockedIcon = ({ id, text }) => {
  return (
    <div>
      <div
        data-tooltip-id={id}
        className='border-2 border-aivyOrange rounded-full h-7 w-7 ml-2 items-center justify-center flex'
      >
        <LockClosedIcon className='h-4 w-5 text-aivyOrange' />
      </div>
      <ReactTooltip
        id={id}
        content={text}
        style={{ backgroundColor: '#45586B' }}
        place='right'
        variant='info'
        className='max-w-xs sm:max-w-sm font-medium rounded-full z-20'
      />
    </div>
  )
}

LockedIcon.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string
}

LockedIcon.defaultProps = {
  text: 'Hier Tipp / Info übergeben'
}

export default LockedIcon
