import { useState } from 'react'
import PropTypes from 'prop-types'
import {
  ClipboardDocumentListIcon,
  CheckIcon
} from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { BUILD_ENV } from '../../../helpers'
import { useNotificationContext } from '../../../context'

const DEVELOP_URL = new URL('https://develop.pbc.aivy.app/invitation/')
const STAGING_URL = new URL('https://staging.pbc.aivy.app/invitation')
const PRODUCTION_URL = new URL('https://pbc.aivy.app/invitation/')

const InviteLink = ({ career_id }) => {
  const { t } = useTranslation()
  const [copied, setCopied] = useState(false)
  const { success } = useNotificationContext()

  const createInviteLink = () => {
    const param = {
      name: 'career',
      value: career_id
    }

    DEVELOP_URL.searchParams.set(param.name, param.value)
    STAGING_URL.searchParams.set(param.name, param.value)
    PRODUCTION_URL.searchParams.set(param.name, param.value)

    switch (BUILD_ENV) {
      case 'develop':
        return DEVELOP_URL.toString()
      case 'staging':
        return STAGING_URL.toString()
      default:
        return PRODUCTION_URL.toString()
    }
  }

  const inviteLink = createInviteLink()

  const copyInviteLinkToClipboard = () => {
    navigator.clipboard.writeText(inviteLink)
    setCopied(true)
    success(t('career.public_invitation_link.copy_success'))
    setTimeout(() => setCopied(false), 1000 * 3)
  }

  return (
    <div className='rounded bg-gray-50 px-6 py-5'>
      <div className='flex items-center'>
        <div>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='currentColor'
            className='w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244'
            />
          </svg>
        </div>
        <code className='ml-2 mr-4 text-xs text-gray-900 truncate'>
          <a
            className='text-blue-700 visited:text-blue-800 hover:text-blue-500 underline'
            href={inviteLink}
            target='_blank'
            rel='noreferrer'
          >
            {inviteLink}
          </a>
        </code>
        <div
          title={t('career.public_invitation_link.copy')}
          className='ml-auto flex justify-center items-center cursor-pointer h-9 w-9 rounded-full bg-primaryBlue hover:bg-gray-200 shrink-0'
          onClick={copyInviteLinkToClipboard}
        >
          {copied ? (
            <CheckIcon className='h-5 w-5 text-white' />
          ) : (
            <ClipboardDocumentListIcon className='h-5 w-5 text-white' />
          )}
        </div>
      </div>
    </div>
  )
}

InviteLink.propTypes = {
  career_id: PropTypes.string.isRequired
}

export default InviteLink
