import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { useQuery } from '@tanstack/react-query'

import Alex from '../assets/images/landingpage/alex.png'
import Shapes from '../assets/images/illustrations/shapes.png'
import { trackEvent } from '../helpers/analytics'
import { ActivityIndicator } from '../components'
import Outro from '../components/tryout/outro'
import Intro from '../components/tryout/intro'
import Questionaire from '../components/tryout/questionaire'
import Footer from '../components/footer'
import { query } from '../graphql'

const TryoutFunnel = () => {
  const { id } = useParams()
  const { t } = useTranslation()

  const navigate = useNavigate()

  const t0 = useRef(new Date()) // prevent reinit
  const t1 = useRef(null) // prevent reinit

  const { data } = useQuery({
    queryKey: ['lead-qualification-funnel', id],
    queryFn: () =>
      query({
        query: 'getForm',
        authMode: 'AWS_IAM',
        variables: { id }
      }),
    select: (response) => JSON.parse(response.data),
    enabled: id !== 'new'
  })

  const [state, setState] = useState(id === 'new' ? 'INTRO' : 'QUESTIONAIRE')

  const handleStartAction = () => {
    t0.current = new Date()
    t1.current = null

    trackEvent('startLeadQualificationFunnel')
    setState('QUESTIONAIRE')
  }

  const handleNavigateBackAction = ({ question }) => {
    trackEvent('abortLeadQualificationFunnel', { question })
    navigate('/ausprobieren')
  }

  if (id !== 'new' && !data) {
    return <ActivityIndicator />
  }

  const completed = !!data?.completed

  const intro = !completed && state === 'INTRO'
  const questionaire = !completed && state === 'QUESTIONAIRE'
  const outro = completed

  return (
    <div className='relative w-full h-screen bg-white'>
      <div className='relative h-screen flex flex-col mx-auto p-16 max-w-4xl bg-white'>
        <img
          src={Shapes}
          className='hidden xl:block absolute top-0 -right-24 h-80 opacity-80'
          style={{ transform: 'rotate(-20deg)' }}
        />
        <img
          src={Shapes}
          className='hidden xl:block absolute top-80 -left-16 h-64 opacity-40'
          style={{ transform: 'rotate(160deg)' }}
        />
        <div className='mt-4 flex items-center gap-x-4'>
          <img src={Alex} className='h-28 w-28 rounded-full' />
          <div className='pb-4 flex flex-col'>
            <span className='block text-xl font-bold tracking-tight text-gray-900'>
              {t('leadfunnel.alex')}
            </span>

            <span className='block text-sm text-gray-700'>
              {t('leadfunnel.your_diagnostic_expert')}
            </span>
          </div>
        </div>

        {intro && (
          <Intro
            handleStartAction={handleStartAction}
            handleNavigateBackAction={handleNavigateBackAction}
          />
        )}
        {questionaire && (
          <Questionaire
            data={data}
            showFunnelIntro={() => setState('INTRO')}
            handleNavigateBackAction={handleNavigateBackAction}
          />
        )}
        {outro && <Outro handleNavigateBackAction={handleNavigateBackAction} />}
        <div className='mt-auto pt-8 flex justify-between items-center'>
          <Footer className='w-full flex justify-between' />
        </div>
      </div>
    </div>
  )
}

export default TryoutFunnel
