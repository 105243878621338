import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useLocation } from 'react-router'

import { query } from '../graphql'
import { getSpacesTableData } from '../helpers/spaces-table-data'
import {
  careerActiveFilterInput,
  talentContextFilterInput
} from '../graphql/filter-inputs'
import { usePartnerContext } from '../context'

export const useTalentsQuery = () => {
  const { partner } = usePartnerContext()

  const queryClient = useQueryClient()
  const { state: talentsQueryState } =
    queryClient.getQueryCache().find({ queryKey: ['talents', partner.id] }) ||
    {}

  const { state: locationState } = useLocation()

  const talentsQuery = useQuery({
    queryKey: ['talents', partner.id],
    queryFn: () =>
      Promise.all([
        new Promise((resolve, reject) => {
          query({
            query: 'spacesByPartnerIdByUpdatedAt',
            variables: {
              partner_id: partner.id,
              updatedAt:
                talentsQueryState?.status === 'success'
                  ? {
                      gt: new Date(
                        talentsQueryState.dataUpdatedAt
                      ).toISOString()
                    }
                  : undefined,
              filter: talentContextFilterInput
            }
          })
            .then((response) => {
              if (talentsQueryState?.status === 'success') {
                const responseIds = response.map(({ id }) => id)

                resolve(
                  // select: [0] = spaces / [1] = careers
                  talentsQueryState.data[0]
                    .filter(({ id }) => !responseIds.includes(id))
                    .concat(response)
                    .filter(({ soft_deleted }) => !soft_deleted)
                )

                return
              }

              resolve(response.filter(({ soft_deleted }) => !soft_deleted))
            })
            .catch((err) => reject(err))
        }),
        query({
          query: 'careerByPartnerByDate',
          variables: {
            partner_id: partner.id,
            filter: careerActiveFilterInput
          }
        })
      ]),
    select: (response) => {
      return {
        spaces: getSpacesTableData({
          spaces: response[0].filter(({ context, mail_stored }) =>
            context === 'ATTRACT' ? mail_stored : true
          )
        }),
        careers: response[1].filter(({ id }) => !id.includes('PRODUCT_TOUR'))
      }
    }
  })

  if (locationState?.productTourData) {
    return {
      ...talentsQuery,
      data: locationState.productTourData,
      status: 'success'
    }
  }

  return talentsQuery
}
