import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import SettingsContainer from './settings-container'
import SEO from '../seo'
import { usePartnerIntegrations } from '../../hooks/use-partner-integrations'
import { ActivityIndicator } from '..'
import Checkbox from '../checkbox'
import { usePartnerIntegrationCreate } from '../../hooks/use-partner-integration-create'
import { usePartnerIntegrationDelete } from '../../hooks/use-partner-integration-delete'
import {
  useProductTourContext,
  useUserContext,
  usePartnerContext,
  useNotificationContext
} from '../../context'
import { useMount } from '../../hooks/use-mount'
import { query } from '../../graphql'
import { notifyBugsnag } from '../../helpers'
import { getDirtyValues } from '../../helpers/formik'
import { has, isEmpty } from 'lodash'

const TRANSLATION_LOCATION = 'settings.notifications.'

const TOPIC_TYPE = {
  RELEASENOTES: 'RELEASENOTES'
}

const Notifications = () => {
  const { t } = useTranslation()
  const { partner } = usePartnerContext()
  const { error } = useNotificationContext()
  const { active_space, emailEndpoint, refetchEmailEndpoint, cognitoUser } =
    useUserContext()

  const [initialValues, setInitialValues] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const {
    setProductTourState,
    productTourState: { tourActive }
  } = useProductTourContext()

  useMount(() => {
    if (tourActive) {
      setTimeout(() => {
        setProductTourState({ run: true })
      }, 0.4 * 1000)
    }
  })

  const { person } = active_space
  const email = person.invitation_email || person.email

  const { status: emailIntegrationStatus, data: emailIntegration } =
    usePartnerIntegrations({
      select: (response) => {
        return response.find(
          ({ remote_service, integration_settings }) =>
            remote_service === 'EMAIL' && integration_settings.email === email
        )
      }
    })

  useEffect(() => {
    if (isSubmitting) return
    if (emailIntegrationStatus === 'pending') return

    setInitialValues({
      spaceCompletedNotification: !!emailIntegration,
      subscribeToReleasenotes:
        !emailEndpoint?.Attributes?.unsubscribed_topics?.includes(
          TOPIC_TYPE.RELEASENOTES
        )
    })
  }, [emailIntegration, emailIntegrationStatus, emailEndpoint, isSubmitting])

  const createIntegration = usePartnerIntegrationCreate()
  const deleteIntegration = usePartnerIntegrationDelete()

  const updateIntegration = ({ selected }) => {
    const promise = new Promise((resolve, reject) => {
      if (selected) {
        const input = {
          authorized_create: ['__create_' + partner.id],
          authorized_read: ['__read_' + partner.id],
          authorized_update: ['__update_' + partner.id],
          authorized_delete: ['__delete_' + partner.id],
          partner_id: partner.id,
          integration_settings: { email },
          remote_service: 'EMAIL'
        }

        createIntegration.mutate(
          { input },
          { onSuccess: resolve, onError: reject }
        )
      } else {
        const { id } = emailIntegration

        deleteIntegration.mutate(
          { input: { id } },
          { onSuccess: resolve, onError: reject }
        )
      }
    })

    return promise
  }

  const updatePinpointEndpoint = async ({ selected }) => {
    const input = {
      user_id: cognitoUser.username,
      topic: TOPIC_TYPE.RELEASENOTES,
      channel_type: 'EMAIL'
    }

    input.action = selected
      ? 'reSubscribeEndpointToTopic'
      : 'unsubscribeEndpointFromTopic'

    await query({ query: 'pinpointControl', variables: input })
    await refetchEmailEndpoint()
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      spaceCompletedNotification: Yup.bool(),
      subscribeToReleasenotes: Yup.bool()
    }),
    onSubmit: async (values, { resetForm }) => {
      setSubmitting(true)

      const dirtyValues = getDirtyValues({
        values,
        initialValues: formik.initialValues
      })

      try {
        if (has(dirtyValues, 'spaceCompletedNotification')) {
          await updateIntegration({
            selected: dirtyValues.spaceCompletedNotification
          })
        }

        if (has(dirtyValues, 'subscribeToReleasenotes')) {
          await updatePinpointEndpoint({
            selected: dirtyValues.subscribeToReleasenotes
          })
        }
      } catch (err) {
        error()
        resetForm()
        notifyBugsnag(err)
      } finally {
        setSubmitting(false)
      }
    }
  })

  if (emailIntegrationStatus === 'pending' || isEmpty(formik.values)) {
    return <ActivityIndicator className='flex justify-center py-16' />
  }

  return (
    <>
      <SEO title={t(TRANSLATION_LOCATION + 'settings_container_title')} />
      <SettingsContainer
        title={t(TRANSLATION_LOCATION + 'settings_container_title')}
        description={t(TRANSLATION_LOCATION + 'settings_container_description')}
        onSubmit={formik.handleSubmit}
        dirty={formik.dirty}
        isLoading={isSubmitting}
      >
        <div className='flex'>
          <Checkbox
            id='spaceCompletedNotification'
            onChange={formik.handleChange}
            checked={formik.values.spaceCompletedNotification}
            label={t(
              TRANSLATION_LOCATION + 'space_completed_notification_label'
            )}
            description={t(
              TRANSLATION_LOCATION + 'space_completed_notification_description'
            )}
          />
        </div>
        <div className='flex'>
          <Checkbox
            id='subscribeToReleasenotes'
            onChange={formik.handleChange}
            checked={formik.values.subscribeToReleasenotes}
            label={t(TRANSLATION_LOCATION + 'releasenotes_label')}
            description={t(TRANSLATION_LOCATION + 'releasenotes_description')}
          />
        </div>
      </SettingsContainer>
    </>
  )
}

export default Notifications
