import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import AccessExpiredPNG from '../assets/images/illustrations/access-expired.png'
import { usePartnerContext } from '../context'
const { version } = require('../../package.json')

const AccessExpired = () => {
  const { t } = useTranslation()
  const { partner } = usePartnerContext()

  return (
    <div className='p-16 flex flex-col items-center'>
      <img className='h-80 w-80 object-contain' src={AccessExpiredPNG} alt='' />
      <div className='py-4 px-6 sm:px-6 sm:py-8 lg:px-8'>
        <div className='mx-auto max-w-2xl text-center'>
          <h2 className='text-lg font-medium text-gray-900'>
            {t('access_expired.title')}
          </h2>
          <p className='mx-auto mt-2 max-w-xl text-sm text-gray-700'>
            {t('access_expired.description')}
          </p>
          <div className='mt-6 flex items-center justify-center gap-x-6'>
            <Link
              to='/support'
              className='text-sm font-semibold leading-6 text-gray-900'
            >
              {t('access_expired.action')}
              <span aria-hidden='true'> →</span>
            </Link>
          </div>
        </div>
      </div>
      <div className='mt-4 text-center'>
        <span className='text-xs italic text-gray-700 text-center'>{`v${version}`}</span>
        <span className='block text-xs italic text-gray-700 text-center'>
          {t('settings.partner_id', { partner_id: partner.id })}
        </span>
      </div>
    </div>
  )
}

export default AccessExpired
