import { useState } from 'react'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Storage } from 'aws-amplify'
import * as Yup from 'yup'
import { useMutation, useQuery } from '@tanstack/react-query'

import Divider from '../divider'
import CareerSettingsContainer from './career-settings-container'
import SEO from '../seo'
import { trackEvent } from '../../helpers/analytics'
import trackEvents from '../../constants/track-events'
import Toggle from '../tailwind/toggle'
import Input from '../input'
import UploadPicture from '../upload-picture'
import AttractIllustration from '../../assets/images/illustrations/attract.svg'
import { classNames, resizeFile } from '../../helpers'
import { mutation, query } from '../../graphql'
import { ActivityIndicator } from '..'
import {
  useNotificationContext,
  usePartnerContext,
  usePaymentContext
} from '../../context'

const TRANSLATION_LOCATION = 'pages.career.attract.'

const Attract = ({ career, updateHandler }) => {
  const { t } = useTranslation()
  const { partner } = usePartnerContext()
  const { attract, showPaywall } = usePaymentContext()
  const { success } = useNotificationContext()

  const [photoUrl, setPhotoUrl] = useState(career.photo_url)
  const [random_number, set_random_number] = useState(new Date().getTime())
  const [
    is_available_in_attract_for_validation_schema,
    set_is_available_in_attract_for_validation_schema
  ] = useState(career.is_available_in_attract || false)

  const { mutate: updatePartner } = useMutation({
    mutationFn: (input) => mutation({ mutation: 'updatePartner', input })
  })

  const { data: careers, isLoading } = useQuery({
    queryKey: ['careers', partner.id],
    queryFn: () =>
      query({
        query: 'careerByPartnerByDate',
        variables: { partner_id: partner.id }
      })
  })

  const shouldDisableAttractForPartner = () => {
    const filteredCareers = careers.filter(
      (c) => c.is_available_in_attract === true
    )

    if (filteredCareers.length > 1) return false
    if (career.id !== filteredCareers[0].id) return false

    return true
  }

  const formik = useFormik({
    validationSchema: Yup.object({
      is_available_in_attract: Yup.bool(),
      external_link: is_available_in_attract_for_validation_schema
        ? Yup.string()
            .url(t(TRANSLATION_LOCATION + 'validate_url'))
            .required(t(TRANSLATION_LOCATION + 'validate_required'))
        : Yup.string().url(t(TRANSLATION_LOCATION + 'validate_url'))
    }),
    enableReinitialize: true,
    initialValues: {
      is_available_in_attract: career.is_available_in_attract || false,
      external_link: career.external_link || ''
    },
    onSubmit: ({ is_available_in_attract, external_link }) => {
      updatePartner(
        {
          id: partner.id,
          app_settings: {
            ...(partner.app_settings || {}),
            usedAlgorithm: 'matchMeIfYouCan',
            components: JSON.stringify({
              ...JSON.parse(partner.app_settings?.components || '{}'),
              successPerspective:
                is_available_in_attract === true
                  ? true
                  : !shouldDisableAttractForPartner(),
              randomChallenges: false
            })
          }
        },
        { onError: () => formik.setSubmitting(false) }
      )

      updateHandler({
        input: {
          id: career.id,
          external_link: external_link || null,
          is_available_in_attract: is_available_in_attract || null
        },
        onSuccess: () => {
          success(t('career.flex.update_career_success'))
          formik.setSubmitting(false)
          trackEvent(trackEvents.UPDATE_CAREER_ATTRACT_SETTINGS)
        },
        onError: () => formik.setSubmitting(false)
      })
    }
  })

  const uploadPhoto = async (event) => {
    const file = event.target.files[0]
    if (!file) return

    formik.setSubmitting(true)

    const fileExtension = event.target.files[0].name.split('.').pop()
    const image = await resizeFile(file, 300, 168)

    const result = await Storage.put(
      `career-photos/${career.id}-attract.${fileExtension}`,
      image
    )
    const updatedPhotoUrl = (await Storage.get(result.key)).split('?')[0]

    updateHandler({
      input: {
        id: career.id,
        photo_url: updatedPhotoUrl
      },
      onSuccess: () => {
        set_random_number(new Date().getTime())
        trackEvent(trackEvents.UPDATE_CAREER_ASSESSMENT_SETTINGS_PHOTO_URL)
        setPhotoUrl(updatedPhotoUrl)
        success(t('career.flex.update_career_success'))
        formik.setSubmitting(false)
      }
    })
  }

  if (isLoading) {
    return <ActivityIndicator />
  }

  return (
    <div>
      <SEO title={t('seo.titles.career_attract_settings')} />
      <div className='my-6 md:flex md:items-center'>
        <div className='md:flex-auto'>
          <h1 className='text-xl font-semibold text-gray-900'>
            {t(TRANSLATION_LOCATION + 'title')}
          </h1>
          <p className='mt-2 max-w-lg lg:max-w-2xl text-sm text-gray-700'>
            {t(TRANSLATION_LOCATION + 'description')}
          </p>
        </div>
      </div>
      <Divider />
      <CareerSettingsContainer
        title={t(TRANSLATION_LOCATION + 'settings_container.title')}
        description={t(
          TRANSLATION_LOCATION + 'settings_container.description',
          { career_title: career.title }
        )}
        dirty={formik.dirty}
        onSubmit={formik.handleSubmit}
        isLoading={formik.isSubmitting}
        withLockedIcon={
          attract ? null : { onClick: () => showPaywall('general') }
        }
      >
        <div
          className={classNames(!attract && 'cursor-pointer')}
          onClick={() => {
            if (!attract) {
              showPaywall('general')
            }
          }}
        >
          <div
            className={`grid gap-y-8 ${
              !attract && 'blur-sm pointer-events-none'
            }`}
          >
            <div>
              <Toggle
                label={t(TRANSLATION_LOCATION + 'activate_toggle.title')}
                description={t(
                  TRANSLATION_LOCATION + 'activate_toggle.description',
                  { career_title: career.title }
                )}
                appearance={'with-left-label-and-description'}
                enabled={formik.values.is_available_in_attract}
                setEnabled={() => {
                  set_is_available_in_attract_for_validation_schema(
                    !is_available_in_attract_for_validation_schema
                  )
                  formik
                    .setFieldValue(
                      'is_available_in_attract',
                      !formik.values.is_available_in_attract
                    )
                    .then(() => formik.setFieldError('external_link'))
                }}
              />
            </div>
            <div>
              <Input
                id={'external_link'}
                label={t(TRANSLATION_LOCATION + 'external_link_input.title')}
                type='text'
                description={t(
                  TRANSLATION_LOCATION + 'external_link_input.description'
                )}
                placeholder={'https://www.google.com/'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.external_link}
                touched={formik.touched.external_link}
                error={formik.errors.external_link}
                disabled={formik.isSubmitting}
              />
            </div>
            <div>
              <UploadPicture
                title={t(TRANSLATION_LOCATION + 'upload_picture_input.title')}
                imageSrc={photoUrl}
                description={t(
                  TRANSLATION_LOCATION + 'upload_picture_input.description'
                )}
                onSubmit={uploadPhoto}
              />
            </div>

            <div>
              <Divider />
              <label className='block text-sm font-medium text-gray-700'>
                {t(TRANSLATION_LOCATION + 'preview_app.title')}
              </label>
              <span className='text-sm text-gray-700'>
                {t(TRANSLATION_LOCATION + 'preview_app.description')}
              </span>
              <div
                className='mt-4 shadow-md'
                style={{
                  width: 327,
                  backgroundColor: '#fff',
                  borderRadius: 18,
                  overflow: 'hidden'
                }}
              >
                <div className='p-5 flex flex-row items-center'>
                  <img src={AttractIllustration} />
                  <div className='ml-6'>
                    <span
                      style={{ fontSize: 14, fontFamily: 'Fira Sans' }}
                      className='block'
                    >
                      {t(TRANSLATION_LOCATION + 'preview_app.matching_level')}
                    </span>
                    <span
                      className='font-bold uppercase'
                      style={{ fontSize: 16 }}
                    >
                      {t(TRANSLATION_LOCATION + 'preview_app.matching')}
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: '#D1DBE6',
                    background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),url("${photoUrl}?random_number=${random_number}")`
                  }}
                  className='p-5 background bg-cover bg-center'
                >
                  <span
                    style={{ fontSize: 20 }}
                    className='block font-bold text-white mb-14'
                  >
                    {career.title}
                  </span>
                  <div className='flex w-full flex-col items-end'>
                    <a
                      href={formik.values.external_link}
                      target='_blank'
                      className='py-3 px-6 block cursor-pointer'
                      style={{
                        backgroundColor: '#4461D4',
                        borderRadius: 24,
                        fontSize: 16,
                        color: '#fff'
                      }}
                      rel='noreferrer'
                    >
                      {t(TRANSLATION_LOCATION + 'preview_app.more_button')}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CareerSettingsContainer>
    </div>
  )
}

Attract.propTypes = {
  career: PropTypes.object.isRequired,
  updateHandler: PropTypes.func.isRequired
}

export default Attract
