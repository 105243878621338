import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import SettingsContainer from '../settings-container'
import {
  useNotificationContext,
  usePartnerContext,
  usePaymentContext
} from '../../../context'
import { useMutationAndUpdateQuery } from '../../../hooks/use-mutation-and-update-query'
import Toggle from '../../tailwind/toggle'
import SelectMenu from '../../select-menu'
import { classNames } from '../../../helpers'
import Alert from '../../alert'

const i18n_remind_spaces = 'settings.automations.remind_spaces.'

const RemindSpaces = () => {
  const { t } = useTranslation()
  const { partner } = usePartnerContext()
  const { success } = useNotificationContext()
  const { automations: automationsProductAvailable, showPaywall } =
    usePaymentContext()

  const [conflict, setConflict] = useState(null)

  const automation_assessment_reminder_active = useMemo(() => {
    const { automation_assessment_reminder_active } = JSON.parse(
      partner.app_settings?.automations || '{}'
    )

    return !!automation_assessment_reminder_active
  }, [partner])

  const automation_assessment_reminder_days = useMemo(() => {
    const { automation_assessment_reminder_days } = JSON.parse(
      partner.app_settings?.automations || '{}'
    )

    return automation_assessment_reminder_days || 7
  }, [partner])

  const partnerMutation = useMutationAndUpdateQuery({
    queryKey: ['partner', partner.id],
    mutation: 'updatePartner'
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      active: automation_assessment_reminder_active,
      days: automation_assessment_reminder_days
    },
    validationSchema: Yup.object({
      remind_spaces: Yup.bool(),
      days: Yup.number()
    }),
    validate: ({ active: aar_active, days: aar_days }) => {
      const app_settings = partner.app_settings || {}
      const aea_days = app_settings.assessment_expires_after_days || 14

      if (aar_active && automationsProductAvailable && aar_days >= aea_days) {
        setConflict({
          title: t(i18n_remind_spaces + 'conflict_title'),
          description: t(i18n_remind_spaces + 'conflict_description')
        })
      } else setConflict(null)

      return {}
    },
    onSubmit: ({ active: aar_active, days: aar_days }, { setSubmitting }) => {
      setSubmitting(true)

      const app_settings = partner.app_settings || {}
      const automations = JSON.parse(app_settings.automations || '{}')

      automations.automation_assessment_reminder_active = aar_active
      automations.automation_assessment_reminder_days = aar_days

      const input = { id: partner.id }
      input.app_settings = {
        ...app_settings,
        automations: JSON.stringify(automations)
      }

      const onSuccess = () => {
        setSubmitting(false)
        success(t('settings.automations.success_message'))
      }

      partnerMutation.mutate(
        { input },
        { onSuccess, onError: () => setSubmitting(false) }
      )
    }
  })

  const set_days_available = automationsProductAvailable && formik.values.active

  return (
    <SettingsContainer
      title={t(i18n_remind_spaces + 'settings_container_title')}
      description={t(i18n_remind_spaces + 'settings_container_description')}
      onSubmit={formik.handleSubmit}
      dirty={conflict ? false : formik.dirty}
      isLoading={formik.isSubmitting}
    >
      <div className='space-y-5'>
        <Toggle
          label={t(i18n_remind_spaces + 'toggle_label')}
          description={t(i18n_remind_spaces + 'toggle_description')}
          locked={!automationsProductAvailable}
          enabled={formik.values.active}
          setEnabled={() => {
            if (!automationsProductAvailable) showPaywall('general')
            else formik.setFieldValue('active', !formik.values.active)
          }}
          appearance='with-left-label-and-description'
        />
        <div className={classNames(set_days_available ? '' : 'opacity-40')}>
          <SelectMenu
            id='days'
            // label={t(i18n_remind_spaces + 'days_title')}
            label={t(i18n_remind_spaces + 'days_description')}
            options={[
              ...Array.from({ length: 30 }, (_, index) => index + 1).map(
                (days) => [
                  days,
                  t(
                    `${i18n_remind_spaces}days_${days === 1 ? 'day' : 'days'}`,
                    { number: days }
                  )
                ]
              )
            ]}
            defaultValue={formik.values.days}
            onChange={(days) => formik.setFieldValue('days', days)}
            className='w-full sm:w-2/3'
            translate={false}
            disabled={!set_days_available}
          />
        </div>
        {!conflict && formik.dirty && (
          <div className='max-w-lg'>
            <Alert
              type='info'
              title={t('settings.automations.info_title')}
              description={t('settings.automations.info_description')}
            />
          </div>
        )}
        {conflict && (
          <div className='max-w-lg'>
            <Alert type='error' {...conflict} />
          </div>
        )}
      </div>
    </SettingsContainer>
  )
}

export default RemindSpaces
