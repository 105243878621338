import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BellAlertIcon as BellAlertIconOutline,
  TrashIcon as TrashIconOutline,
  LinkIcon as LinkIconOutline
} from '@heroicons/react/24/outline'
import {
  TrashIcon as TrashIconSolid,
  BellAlertIcon as BellAlertIconSolid
} from '@heroicons/react/24/solid'
import { formatDistanceToNow } from 'date-fns'
import { BeatLoader } from 'react-spinners'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { de, enUS as en } from 'date-fns/locale'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { useLocation, useNavigate } from 'react-router'

import ScoresContainer from './scores-container'
import State from '../../state'
import { colors } from '../../../constants/colors'
import {
  useLanguageContext,
  useNotificationContext,
  usePartnerContext,
  useUserContext
} from '../../../context'
import { trackEvent } from '../../../helpers/analytics'
import trackEvents from '../../../constants/track-events'
import { BUILD_ENV, classNames, uuidv4 } from '../../../helpers'
import { mutation, query } from '../../../graphql'
import Button from '../../tailwind/Button'
import InviteCareerAnalyseDialog from './invite-career-analyse-dialog'
import { useCreateCareerNavigation } from '../../../hooks/use-create-career-navigation'
import Modal from '../../tailwind/Modal'
import { employeeContextFilterInput } from '../../../graphql/filter-inputs'

const TRANSLATION_LOCATION = 'components.career.expert_analysis.'

const templates = {
  de: 'INVITE_EXPERT_DE',
  en: 'INVITE_EXPERT_EN'
}

const getRequirementAnalyseLink = (careerAnalyseId) => {
  const DEVELOP_URL = new URL('https://develop.anforderungsanalyse.aivy.app')
  const STAGING_URL = new URL('https://staging.anforderungsanalyse.aivy.app')
  const PRODUCTION_URL = new URL('https://www.anforderungsanalyse.aivy.app')

  DEVELOP_URL.searchParams.set('invitation', careerAnalyseId)
  STAGING_URL.searchParams.set('invitation', careerAnalyseId)
  PRODUCTION_URL.searchParams.set('invitation', careerAnalyseId)

  switch (BUILD_ENV) {
    case 'develop':
      return DEVELOP_URL.toString()
    case 'staging':
      return STAGING_URL.toString()
    default:
      return PRODUCTION_URL.toString()
  }
}

const InviteExperts = ({
  career,
  careerMutation,
  generateRequirementProfile,
  careerAnalyses,
  refetchCareerAnalyses
}) => {
  const { t } = useTranslation()
  const { partner } = usePartnerContext()
  const { cognitoUser } = useUserContext()
  const { getLanguage } = useLanguageContext()
  const { success, error, alert } = useNotificationContext()

  const headerAnalysesList = useRef()

  const [careerAnalyseDialogOpen, setCareerAnalyseDialogOpen] = useState(false)
  const [invitingExperts, setInvitingExperts] = useState(false)
  const [uuid, setUuid] = useState(uuidv4())
  const [remindExpert, setRemindExpert] = useState(null)
  const [analyseIsValid, setAnalyseIsValid] = useState(null)
  const [highlightNotCompletedAnalyses, setHighlightNotCompletedAnalyses] =
    useState(false)

  const navigate = useNavigate()
  const { state: navigationState } = useLocation()
  const [displayCopyAnalysesSuccess, setDisplayCopyAnalysesSuccess] = useState(
    navigationState ? !!navigationState.displayCopyAnalysesSuccess : false
  )

  const {
    isLoadingBack,
    isLoadingNext,
    handleBackNavigation,
    handleNextNavigation
  } = useCreateCareerNavigation({ career, careerMutation })

  const careerAnalysesVerifiedCount = careerAnalyses.filter(
    ({ status }) => status === 'COMPLETED'
  ).length

  async function deleteCareerAnalyse({ person, id }) {
    alert(
      t(TRANSLATION_LOCATION + 'modal_delete_analyse_title'),
      t(TRANSLATION_LOCATION + 'modal_delete_analyse_text', {
        email: person.email
      }),
      [
        {
          onPress: async () => {
            try {
              await mutation({ mutation: 'deleteCareerAnalyse', input: { id } })
            } catch (err) {
              return
            }

            refetchCareerAnalyses()

            trackEvent(trackEvents.DELETE_CAREER_ANALYSE)
            success(t(TRANSLATION_LOCATION + 'delete_analyse_success'))
          },
          text: t(TRANSLATION_LOCATION + 'modal_delete_analyse_action')
        },
        {
          text: t(TRANSLATION_LOCATION + 'modal_delete_analyse_cancel'),
          style: 'cancel'
        }
      ],
      <TrashIconSolid className='h-8 w-8 text-gray-800' />
    )
  }

  const copyInvitationLinkToClipboard = ({ id }) => {
    const invitationLink = getRequirementAnalyseLink(id)
    navigator.clipboard.writeText(invitationLink)

    success(t(TRANSLATION_LOCATION + 'copy_success_message'))
  }

  const remindExpertHandler = ({ id, person }) => {
    alert(
      t(TRANSLATION_LOCATION + 'modal_remind_expert_title'),
      t(TRANSLATION_LOCATION + 'modal_remind_expert_text', {
        email: person.email
      }),
      [
        {
          onPress: () => {
            setRemindExpert(id)
            sendEmailToExpert({ email: person.email, id }).then(() => {
              trackEvent(trackEvents.REMIND_EXPERT_CAREER_ANALYSE)
              success(
                t(TRANSLATION_LOCATION + 'remind_expert_success', {
                  email: person.email
                })
              )
              setRemindExpert(null)
            })
          },
          text: t(TRANSLATION_LOCATION + 'modal_remind_expert_action')
        },
        {
          text: t(TRANSLATION_LOCATION + 'modal_remind_expert_cancel'),
          style: 'cancel'
        }
      ],
      <BellAlertIconSolid className='h-8 w-8 text-gray-800' />
    )
  }

  const inviteExperts = async (experts) => {
    setInvitingExperts(true)

    try {
      for (const email of experts) {
        await onInviteCareerAnalyse(email)
      }

      success(t(TRANSLATION_LOCATION + 'invitation_success'))
    } catch (err) {
      error(t(TRANSLATION_LOCATION + 'invitation_error'))
    } finally {
      // reset key of invite experts modal to render it again
      setUuid(uuidv4())

      setCareerAnalyseDialogOpen(false)
      setInvitingExperts(false)
    }
  }

  async function onInviteCareerAnalyse(email) {
    if (!email) return

    const employeeByPartnerItems = await query({
      query: 'spacesByPartnerIdByUpdatedAt',
      variables: {
        partner_id: partner.id,
        filter: employeeContextFilterInput
      }
    })

    const foundPerson = employeeByPartnerItems.find(
      (element) => element.person && element.person.email === email
    )

    let career_analyse_id
    try {
      let personToInvite = foundPerson ? foundPerson.person.id : null
      if (!personToInvite) {
        const { id } = await mutation({
          mutation: 'createPerson',
          input: {
            owner: partner.id,
            email,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            authorized_create: ['__create_' + partner.id],
            authorized_read: ['__read_low_' + partner.id],
            authorized_update: ['__update_' + partner.id],
            authorized_delete: ['__delete_' + partner.id]
          }
        })

        personToInvite = id
      }

      const career_analyse = await mutation({
        mutation: 'createCareerAnalyse',
        input: {
          career_id: career.id,
          status: 'INVITED',
          partner_id: partner.id,
          authorized_create: ['__create_' + partner.id],
          authorized_read: ['__read_low_' + partner.id],
          authorized_update: ['__update_' + partner.id],
          authorized_delete: ['__delete_' + partner.id],
          person_id: personToInvite,
          invited_from_user_id: cognitoUser.username,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        }
      })

      career_analyse_id = career_analyse.id
    } catch (err) {
      return
    }

    await sendEmailToExpert({ email, id: career_analyse_id })
    refetchCareerAnalyses()

    trackEvent(trackEvents.INVITE_EXPERT_TO_CAREER_ANALYSE)
  }

  const sendEmailToExpert = ({ email, id }) => {
    const input = {
      to_addresses: [email],
      template_id: templates[getLanguage()],
      input: JSON.stringify({
        partner_name: partner.display_name || partner.name,
        career_name: career.title,
        invitation_link: getRequirementAnalyseLink(id)
      })
    }

    return query({ query: 'sendTemplateMail', variables: input })
  }

  const handleCloseCopyAnalysesSuccessModal = () => {
    // remove navigation state
    navigate('#invite-experts', { replace: true })
    setDisplayCopyAnalysesSuccess(false)
  }

  const showNotAllAnalysesCompletedHint = () => {
    headerAnalysesList.current.scrollIntoView({ behavior: 'smooth' })
    setHighlightNotCompletedAnalyses(true)

    alert(
      t(TRANSLATION_LOCATION + 'modal_not_all_analyses_completed_title'),
      t(TRANSLATION_LOCATION + 'modal_not_all_analyses_completed_text'),
      [
        {
          onPress: () => {
            setTimeout(() => setHighlightNotCompletedAnalyses(false), 3 * 1000)
          },
          text: t(
            TRANSLATION_LOCATION + 'modal_not_all_analyses_completed_action'
          )
        }
      ]
    )
  }

  return (
    <>
      <div className='max-w-lg'>
        <div className='mt-5'>
          <span
            id='invite-experts'
            className='text-3xl font-extrabold tracking-tight text-gray-900'
          >
            {t('career.requirement_profile.experts.title')}
          </span>
        </div>
        <ul className='mt-4 ml-6 list-disc'>
          {[
            'career.requirement_profile.experts.list_entry_0',
            'career.requirement_profile.experts.list_entry_1',
            'career.requirement_profile.experts.list_entry_2'
          ].map((entry, index) => (
            <li key={index} className='mb-1 text-sm text-gray-900'>
              {t(entry)}
            </li>
          ))}
        </ul>
      </div>
      <div ref={headerAnalysesList} className='pb-4'>
        <div className='mt-8 flex items-center'>
          <span className='text-md font-semibold text-gray-900 mr-2'>
            {t('career.requirement_profile.experts.invited_experts_title')}
          </span>

          <Button.PrimarySM
            onClick={() => setCareerAnalyseDialogOpen(true)}
            text={t('career.requirement_profile.experts.invite_experts_action')}
          />
        </div>
        {isEmpty(careerAnalyses) && (
          <span className='mt-2 block text-sm text-gray-900 italic'>
            {t('career.requirement_profile.experts.nobody_invited')}
          </span>
        )}
        <ul className='mt-2 divide-y divide-gray-200'>
          {careerAnalyses.map((careerAnalyse, index) => (
            <li key={index} className='px-1 py-1 sm:px-2'>
              <div
                className={classNames(
                  'flex items-center justify-between px-3 py-3 sm:px-4 rounded-xl',
                  highlightNotCompletedAnalyses &&
                    careerAnalyse.status !== 'COMPLETED'
                    ? 'bg-yellow-100'
                    : ''
                )}
              >
                <div className='flex-col'>
                  <p className='text-sm font-medium text-blue-600 truncate'>
                    {careerAnalyse.person?.email}
                  </p>
                  <p className='text-sm text-gray-700 mt-0'>
                    {careerAnalyse.status === 'COMPLETED'
                      ? t(
                          TRANSLATION_LOCATION +
                            'expert_analysis_state_fulfilled',
                          {
                            updatedAt: formatDistanceToNow(
                              new Date(careerAnalyse.updatedAt),
                              {
                                addSuffix: true,
                                locale: { de, en }[getLanguage()]
                              }
                            )
                          }
                        )
                      : t(
                          TRANSLATION_LOCATION +
                            'expert_analysis_state_invited',
                          {
                            createdAt: formatDistanceToNow(
                              new Date(careerAnalyse.createdAt),
                              {
                                addSuffix: true,
                                locale: { de, en }[getLanguage()]
                              }
                            )
                          }
                        )}
                  </p>
                </div>
                <div className='flex flex-row items-center'>
                  <div className='flex flex-shrink-0 gap-x-2 mr-4'>
                    {careerAnalyse.id.includes('sample') && (
                      <State color='orange' text='Demo' />
                    )}
                    {careerAnalyse.status === 'COMPLETED' ? (
                      <State
                        color='green'
                        text={t(
                          TRANSLATION_LOCATION +
                            'expert_analysis_state_fulfilled_2'
                        )}
                      />
                    ) : (
                      <State
                        color='grey'
                        text={t(
                          TRANSLATION_LOCATION +
                            'expert_analysis_state_invited_2'
                        )}
                      />
                    )}
                  </div>
                  <div className='flex justify-center items-center'>
                    {careerAnalyse.status !== 'COMPLETED' && (
                      <div
                        data-tooltip-id='remind'
                        className='rounded-full h-8 w-8 flex justify-center items-center cursor-pointer'
                      >
                        {remindExpert === careerAnalyse.id ? (
                          <BeatLoader
                            color={colors.darkGrey}
                            speedMultiplier={0.5}
                            size={7}
                          />
                        ) : (
                          <>
                            <BellAlertIconOutline
                              className='h-5 w-5 text-gray-800'
                              onClick={() => remindExpertHandler(careerAnalyse)}
                            />
                            <ReactTooltip
                              id='remind'
                              content={t(
                                TRANSLATION_LOCATION + 'expert_actions.remind'
                              )}
                              style={{ backgroundColor: '#182033' }}
                              className='font-medium rounded-full'
                              place='top'
                              variant='info'
                            />
                          </>
                        )}
                      </div>
                    )}
                    <div
                      data-tooltip-id='copy_link'
                      className='rounded-full h-8 w-8 flex justify-center items-center cursor-pointer'
                      onClick={() =>
                        copyInvitationLinkToClipboard(careerAnalyse)
                      }
                    >
                      <LinkIconOutline className='h-5 w-5 text-gray-800' />
                      <ReactTooltip
                        id='copy_link'
                        content={t(
                          TRANSLATION_LOCATION + 'expert_actions.copy_link'
                        )}
                        className='font-medium rounded-full'
                        style={{ backgroundColor: '#182033' }}
                        place='top'
                        variant='info'
                      />
                    </div>
                    <div
                      data-tooltip-id='delete'
                      className='rounded-full h-8 w-8 flex justify-center items-center cursor-pointer'
                    >
                      <TrashIconOutline
                        className='h-5 w-5 text-gray-800'
                        onClick={() => deleteCareerAnalyse(careerAnalyse)}
                      />
                      <ReactTooltip
                        id='delete'
                        content={t(
                          TRANSLATION_LOCATION + 'expert_actions.delete'
                        )}
                        style={{ backgroundColor: '#182033' }}
                        className='font-medium rounded-full'
                        place='top'
                        variant='info'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
        {careerAnalyses.length > 0 && (
          <div className='mt-8'>
            <ScoresContainer
              careerId={career.id}
              careerAnalyses={careerAnalyses}
              setAnalyseIsValid={setAnalyseIsValid}
              verifiedCount={careerAnalysesVerifiedCount}
            />
          </div>
        )}
        <div className='mt-8 sm:mt-16 flex flex-row justify-between items-center'>
          <Button.SecondaryLG
            onClick={handleBackNavigation}
            isLoading={isLoadingBack}
            text={t('create_career.back_action')}
          />
          <div className='flex gap-x-2'>
            <Button.SecondaryLG
              className='mr-auto'
              text={t(TRANSLATION_LOCATION + 'requirement_profile_action')}
              onClick={generateRequirementProfile}
              disabled={careerAnalysesVerifiedCount < 3}
            />
            <Button.PrimaryLG
              text={t('create_career.next_action')}
              onClick={() => {
                if (careerAnalyses.length !== careerAnalysesVerifiedCount) {
                  showNotAllAnalysesCompletedHint()
                  return
                }

                handleNextNavigation({
                  // career_analyse is calculated by summarize || select preset
                  onSuccess: () => {
                    trackEvent(trackEvents.FINISH_CAREER_ANALYSE_BY_EXPERT)
                  }
                })
              }}
              isLoading={isLoadingNext}
              disabled={
                // if (analyseIsValid === null) // default state -> if on click button
                careerAnalysesVerifiedCount < 3 || analyseIsValid === false
              }
            />
          </div>
        </div>
      </div>

      <InviteCareerAnalyseDialog
        key={uuid}
        open={careerAnalyseDialogOpen}
        setOpen={setCareerAnalyseDialogOpen}
        submitItems={(experts) => inviteExperts(experts)}
        careerAnalyses={careerAnalyses}
        isLoading={invitingExperts}
      />

      <Modal
        open={displayCopyAnalysesSuccess}
        setOpen={handleCloseCopyAnalysesSuccessModal}
        size='2xl'
      >
        <h2 className='text-3xl text-center font-extrabold tracking-tight text-gray-900'>
          {t('create_career.invite_experts.modal_copy_analyses.title')}
        </h2>
        <p className='mt-4 text-center text-sm text-gray-900'>
          {t('create_career.invite_experts.modal_copy_analyses.description')}
        </p>
        <div className='mt-8 flex justify-center'>
          <Button.PrimaryLG
            text={t(
              'create_career.invite_experts.modal_copy_analyses.close_action'
            )}
            onClick={handleCloseCopyAnalysesSuccessModal}
          />
        </div>
      </Modal>
    </>
  )
}

InviteExperts.propTypes = {
  career: PropTypes.object.isRequired,
  careerMutation: PropTypes.object.isRequired,
  generateRequirementProfile: PropTypes.func.isRequired,
  careerAnalyses: PropTypes.array.isRequired,
  refetchCareerAnalyses: PropTypes.func.isRequired
}

export default InviteExperts
