import {
  Fragment,
  forwardRef,
  useState,
  useEffect,
  useImperativeHandle
} from 'react'
import {
  Dialog,
  DialogTitle,
  Transition,
  TransitionChild
} from '@headlessui/react'
import { isEmpty } from 'lodash'

import { classNames } from '../helpers'

const Modal = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false)
  const [alertData, setAlertData] = useState({})

  useEffect(() => {
    if (isEmpty(alertData)) return
    setVisible(true)
  }, [alertData])

  useImperativeHandle(ref, () => ({
    alert(title, messages, buttons, iconComponent, children, maxwmax) {
      const toArray = Array.isArray(messages)
        ? messages.filter((message) => message)
        : [messages]

      setAlertData({
        title,
        messages: toArray,
        buttons,
        iconComponent,
        children,
        maxwmax
      })
    }
  }))

  return (
    <Transition show={visible} as={Fragment}>
      <Dialog
        as='div'
        auto-reopen='true'
        className='fixed z-20 inset-0 overflow-y-auto'
        onClose={setVisible}
      >
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <TransitionChild
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div
              className='fixed inset-0 bg-slate-900/25 backdrop-blur-sm transition-opacity'
              aria-hidden='true'
            />
          </TransitionChild>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div
              className={classNames(
                'inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:py-8 sm:px-16',
                alertData?.maxwmax ? 'sm:max-w-max' : 'sm:max-w-lg'
              )}
            >
              <div>
                <div className='mx-auto flex items-center justify-center'>
                  {alertData?.iconComponent}
                </div>
                <div className='mt-3 text-center'>
                  <DialogTitle
                    as='h3'
                    className='text-2xl leading-6 font-bold text-gray-900'
                  >
                    {alertData?.title}
                  </DialogTitle>
                  <div className='mt-6 pb-px'>
                    {(alertData?.messages || []).map((message, index) => (
                      <p key={index} className='text-sm text-gray-900 mb-2'>
                        {message}
                      </p>
                    ))}
                  </div>
                </div>
              </div>

              <div>{alertData?.children}</div>

              <div
                className={classNames(
                  alertData?.buttons?.length > 1
                    ? 'sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense'
                    : 'w-2/3 mx-auto',
                  'mt-8'
                )}
              >
                {alertData?.buttons?.map(({ onPress, style, text }, index) => (
                  <button
                    key={index}
                    type='button'
                    onClick={() => {
                      onPress && onPress()
                      setVisible(false)
                    }}
                    className={classNames(
                      index === 0 &&
                        alertData?.buttons?.length > 1 &&
                        'sm:col-start-2',
                      index === 1 && 'mt-3 sm:col-start-1',
                      style === 'cancel'
                        ? 'border-gray-300 bg-white  text-gray-700 hover:bg-gray-50'
                        : 'bg-blue-600 text-white border-transparent hover:bg-blue-700',
                      style === 'destructive' &&
                        'bg-red-600 hover:bg-red-500 border-transparent text-white focus:ring-red-500',
                      'inline-flex justify-center w-full rounded-md border shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm sm:mt-0'
                    )}
                  >
                    {text}
                  </button>
                ))}
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  )
})

// Modal.propTypes = {
//   title: PropTypes.string.isRequired,
//   messages: PropTypes.string.isRequired,
//   buttons: PropTypes.arrayOf(PropTypes.object).isRequired, // TODO defice object containing keys text, onPress, style
//   visible: PropTypes.bool.isRequired,
//   setVisible: PropTypes.func.isRequired,
// };

export default Modal
