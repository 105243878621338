import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import AivyIcon from '../assets/images/aivyicon.svg'

const Maintenance = ({ prefix, homepage, support }) => {
  const { t } = useTranslation()

  return (
    <div className='bg-white pt-32'>
      <main className='mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8'>
        <div className='flex flex-shrink-0 justify-center'>
          <a href='/' className='inline-flex'>
            <span className='sr-only'>aivy-icon</span>
            <img className='h-16 w-auto' src={AivyIcon} alt='' />
          </a>
        </div>
        <div className='pb-8 pt-8'>
          <div className='flex flex-col items-center text-center'>
            <h1 className='mt-2 font-bold tracking-tight text-gray-900 text-4xl sm:text-5xl'>
              {t(prefix + 'title')}
            </h1>
            <p className='mt-4 max-w-md text-base text-gray-900'>
              {t(prefix + 'message')}
            </p>
            {homepage && (
              <div className='mt-6'>
                <a
                  href='/'
                  className='text-base font-medium text-blue-600 hover:text-blue-500'
                >
                  {t('error_unhandled.go_to_home')}
                  <span aria-hidden='true'> &rarr;</span>
                </a>
              </div>
            )}
          </div>
        </div>
      </main>

      <footer className='mx-auto w-full max-w-7xl flex-shrink-0 px-4 sm:px-6 lg:px-8 pt-8'>
        <nav className='flex justify-center space-x-4'>
          {support && (
            <a
              href='/support'
              className='text-sm font-medium text-blue-500 hover:text-blue-600'
            >
              {t('error_unhandled.contact_support')}
            </a>
          )}
        </nav>
      </footer>
    </div>
  )
}

Maintenance.propTypes = {
  prefix: PropTypes.string.isRequired,
  homepage: PropTypes.bool,
  support: PropTypes.bool,
  email: PropTypes.bool,
  small: PropTypes.bool
}

Maintenance.defaultProps = {
  homepage: false,
  support: false
}

export default Maintenance
