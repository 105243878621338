import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'

import Modal from '../tailwind/Modal'
import { useTranslation } from 'react-i18next'
import Button from '../tailwind/Button'
import Checkbox from '../checkbox'
import { localStorage } from '../../helpers/local-storage'
import { useUserContext } from '../../context/user-context'

const InviteYourselfModal = () => {
  const { t } = useTranslation()
  const { active_space } = useUserContext()
  const { firstname, lastname, invitation_email, email } = active_space.person

  const navigate = useNavigate()
  const { state: locationState } = useLocation()

  const [open, setOpen] = useState(false)
  const [dontShowMessageAgain, setDontShowMessageAgain] = useState(false)

  const onCloseInviteYourselfModal = () => {
    setOpen(false)

    if (dontShowMessageAgain) {
      localStorage.setItem(
        'TRIAL:DISPLAY_INVITE_YOURSELF_MODAL',
        JSON.stringify(false)
      )
    }
  }

  useEffect(() => {
    const { displayInviteYourselfModal } = locationState || {}

    if (!displayInviteYourselfModal) return

    const showMessageAgain = JSON.parse(
      localStorage.getItem('TRIAL:DISPLAY_INVITE_YOURSELF_MODAL') || 'true'
    )

    if (showMessageAgain) {
      setOpen(true)
    }
  }, [locationState])

  return (
    <Modal
      open={open}
      setOpen={onCloseInviteYourselfModal}
      size='2xl'
      zIndex='z-30'
      withCloseIcon={false}
    >
      <div className='flex flex-col items-center'>
        <h2 className='text-3xl font-extrabold tracking-tight text-gray-900'>
          {t('trial.invite_yourself_modal.title_1')}
        </h2>
        <span className='text-lg font-bold tracking-tight text-gray-700'>
          {t('trial.invite_yourself_modal.title_2')}
        </span>
        <p className='mt-4 text-center text-gray-700'>
          {t('trial.invite_yourself_modal.description')}
        </p>
        <div className='mt-8 flex gap-x-2'>
          <Button.SecondaryXL
            onClick={onCloseInviteYourselfModal}
            text={t('trial.invite_yourself_modal.cancel_action')}
          />
          <Button.PrimaryXL
            onClick={() => {
              onCloseInviteYourselfModal()

              setTimeout(
                () =>
                  navigate('/talents', {
                    state: {
                      inviteYourself: {
                        firstname,
                        lastname,
                        email: email || invitation_email
                      }
                    },
                    replace: true
                  }),
                1000 * 0.4
              )
            }}
            text={t('trial.invite_yourself_modal.invite_action')}
          />
        </div>
      </div>
      <div className='flex mt-6 w-full'>
        <Checkbox
          id='persist-invite-yourself'
          label={t('trial.invite_yourself_modal.dont_show_again')}
          checked={dontShowMessageAgain}
          onChange={(event) => setDontShowMessageAgain(event.target.checked)}
        />
      </div>
    </Modal>
  )
}

export default InviteYourselfModal
