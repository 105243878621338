import PropTypes from 'prop-types'

import Input from './input'

const InputFormik = ({
  id,
  type,
  label,
  description,
  placeholder,
  autoComplete,
  formik,
  onBlur,
  disabled
}) => {
  return (
    <Input
      id={id}
      type={type}
      autoComplete={autoComplete}
      label={label}
      description={description}
      placeholder={placeholder}
      onChange={formik.handleChange}
      onBlur={onBlur || formik.handleBlur}
      value={formik.values[id]}
      touched={formik.touched[id]}
      error={formik.errors[id]}
      disabled={formik.isSubmitting || disabled}
    />
  )
}

InputFormik.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  formik: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func
}

InputFormik.defaultProps = {
  type: 'text',
  label: undefined,
  description: undefined,
  placeholder: '',
  autoComplete: undefined,
  disabled: false
}

export default InputFormik
