import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { AcademicCapIcon } from '@heroicons/react/24/solid'
import { isNumber } from 'lodash'

import TimeIcon from '../assets/images/time-icon.png'
import { classNames, convertNumberToTime, hexToRGB } from '../helpers'
import { useLanguageContext } from '../context'

const ChallengeCard = ({ challenge, callback }) => {
  const { language } = useLanguageContext()

  const {
    backgroundColor: background,
    title,
    timeToComplete,
    card,
    relativeWeight,
    dimensionSynonym
  } = challenge

  const { minutes } = useMemo(
    () => convertNumberToTime(timeToComplete / 60000),
    [timeToComplete]
  )

  return (
    <div
      className='relative overflow-hidden group'
      style={{
        width: 328 / 1.3,
        height: 152 / 1.3,
        borderRadius: 18,
        background
      }}
    >
      <div
        style={{ padding: '12px 14px' }}
        className={classNames(
          'relative flex flex-col w-full h-full cursor-default',
          callback && 'group-hover:blur-sm ease-in duration-200'
        )}
      >
        <div
          style={{ zIndex: 5 }}
          className='flex justify-between items-center'
        >
          <span className='block text-lg font-semibold text-white truncate'>
            {title[language]}
          </span>
          <div className='flex items-center'>
            <span
              className='text-sm font-semibold text-white'
              style={{
                marginRight: 4,
                textShadow: `1px 1px 2px ${background}, 0 0 0.8em ${background}, 0 0 0.4em ${background}`
              }}
            >
              {minutes + ' MIN'}
            </span>
            <img
              style={{ display: 'inline-block', width: 16, height: 16 }}
              src={TimeIcon}
            />
          </div>
        </div>
        <div style={{ zIndex: 5 }} className='flex items-center'>
          <span
            style={{
              textShadow: `1px 1px 2px ${background}, 0 0 0.8em ${background}, 0 0 0.4em ${background}`
            }}
            className='block text-sm font-medium italic text-white'
          >
            {dimensionSynonym[language]}
          </span>
        </div>
        {isNumber(relativeWeight) && (
          <div style={{ zIndex: 5 }} className='mt-auto flex'>
            {[
              relativeWeight > 0,
              relativeWeight > 5,
              relativeWeight > 10,
              relativeWeight > 20,
              relativeWeight > 30
            ].map((filled, index) => (
              <div
                key={index}
                className='w-4 h-4 flex items-center justify-center rounded-full border-2 border-white'
              >
                <div
                  style={{
                    background: filled ? 'white' : background,
                    borderColor: background
                  }}
                  className={classNames('w-3 h-3 rounded-full border-2')}
                ></div>
              </div>
            ))}
          </div>
        )}
        <div
          style={{
            position: 'absolute',
            right: card.imagePosition.right,
            bottom: card.imagePosition.bottom
          }}
        >
          <img
            style={{
              display: 'inline-block',
              width: 'auto',
              height: card.imageDimension.height / 1.8
            }}
            src={card.image}
          />
        </div>
      </div>
      {callback && (
        <div
          className={classNames(
            'absolute w-full h-full top-0 left-0 cursor-pointer',
            'hidden group-hover:flex justify-center items-center'
          )}
          onClick={callback}
        >
          <div
            style={{ background: hexToRGB('#ffffff', 0.9) }}
            className='w-12 h-12 rounded-full flex justify-center items-center'
          >
            <AcademicCapIcon
              style={{ color: background }}
              className='w-10 h-10'
            />
          </div>
        </div>
      )}
    </div>
  )
}

ChallengeCard.propTypes = {
  challenge: PropTypes.object.isRequired,
  callback: PropTypes.func
}

ChallengeCard.defaultProps = {
  callback: undefined
}

export default ChallengeCard
