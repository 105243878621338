import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import {
  ChartPieIcon,
  ListBulletIcon,
  ArrowDownTrayIcon,
  InformationCircleIcon
} from '@heroicons/react/24/outline'
import { Tooltip } from 'react-tooltip'
import html2canvas from 'html2canvas'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'

import { useLanguageContext, useNotificationContext } from '../../context'
import RadarChart from '../react-chart/radar-chart'
import SlideoverDimensions from './slideover-dimensions'
import SlideoverConfiguration from './slideover-configuration'
import { trackEvent } from '../../helpers/analytics'
import trackEvents from '../../constants/track-events'
import { useChartData } from '../../hooks/use-chart-data'
import { classNames } from '../../helpers'
import Loader from '../activity-indicator'
import LinearChart from './linear-chart'
import Button from '../tailwind/Button'
import LinearDimensionExampleWithCareer from './examples/linear-dimension-example-with-career'
import LinearDimensionExample from './examples/linear-dimension-example'
import Careers from './careers'

const Analyse = ({ spaces, career, id, careers, setSelectedCareer }) => {
  const { t } = useTranslation()
  const { language } = useLanguageContext()
  const { error } = useNotificationContext()

  const [showDimensions, setShowDimensions] = useState(false)
  const [showConfiguration, setShowConfiguration] = useState(false)
  const [isDownloadingPng, setIsDownloadingPng] = useState(false)

  const { data, setData, extractChartData, options } = useChartData({ spaces })

  useEffect(() => {
    if (career) setData({ careers: [{ ...career, visible: true }] })
    else setData({ careers: [] })
  }, [career, setData])

  const chartData = extractChartData({
    spaces: data.spaces.filter((s) => s.visible),
    dimensions: data.dimensions.filter((d) => d.visible),
    careers: data.careers.filter((c) => c.visible)
  })

  const downloadTeamAnalyseAsPng = () => {
    const container = document.getElementById('TEAMANALYSE')

    html2canvas(container).then(function (canvas) {
      const img = canvas.toDataURL('image/png')

      const a = document.createElement('a')
      a.href = img
      a.download = `${id}.png`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      setIsDownloadingPng(false)
    })
  }

  const showSpace = ({ id }) => {
    const item = data.spaces.find((s) => s.id === id)
    item.visible = true

    setData({ spaces: data.spaces })
  }

  const hideSpace = ({ id }) => {
    if (data.spaces.filter((s) => s.visible).length === 1) {
      error(t('team_analyse.slideover_configuration.min_1_space_error'))
      return
    }

    const item = data.spaces.find((s) => s.id === id)
    item.visible = false

    setData({ spaces: data.spaces })
  }

  const activeCategory = data.categories.find(({ active }) => active)

  const category_is_invisible = data.dimensions
    .filter(({ category }) => category === activeCategory.id)
    .every(({ visible }) => visible === false)

  const no_data_for_category =
    chartData.dimensions.filter(
      ({ category }) => category === activeCategory.id
    ).length === 0

  return (
    <>
      <div className='border-b border-gray-200 pb-5 sm:flex sm:items-end sm:justify-between'>
        <div>
          <h3 className='text-xl font-semibold leading-6 text-gray-900'>
            {t('team_analyse.analyse.title')}
          </h3>
          <p className='mt-2 max-w-4xl text-sm text-gray-500'>
            {t('team_analyse.analyse.description')}
          </p>
        </div>
        <div className='mt-3 flex sm:ml-4 sm:mt-0 sm:gap-x-2'>
          <Button.SecondaryLG
            text={t('team_analyse.analyse.open_dimensions_action')}
            onClick={() => {
              trackEvent(trackEvents.CLICK_MORE_INFORMATION_TEAMANALYSIS)
              setShowDimensions(true)
            }}
          />
          <Button.PrimaryLG
            text={t('team_analyse.analyse.open_settings_action')}
            onClick={() => {
              trackEvent(trackEvents.CLICK_CONFIGURATION_TEAMANALYSIS)
              setShowConfiguration(true)
            }}
          />
          <div
            className={classNames(data.mode === 'CHART' ? 'block' : 'hidden')}
          >
            <a
              data-tooltip-id='export-png-button'
              data-tooltip-content={t('team_analyse.export.title')}
              data-tooltip-place='top'
              onClick={() => {
                trackEvent(trackEvents.CLICK_EXPORT_TO_PNG_TEAMANALYSIS)
                downloadTeamAnalyseAsPng()
              }}
            >
              <button className='rounded-full bg-blue-600 p-2 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'>
                <ArrowDownTrayIcon className='h-5 w-5' aria-hidden='true' />
              </button>
            </a>

            <Tooltip
              id='export-png-button'
              offset={24}
              className='text-sm font-semibold'
            />
          </div>
        </div>
      </div>
      <div className={classNames('py-4')}>
        <span className='block text-sm font-medium leading-6 text-gray-900 mb-2'>
          {t('team_analyse.view_option_title')}
        </span>
        <div className='rounded-md'>
          {[
            {
              Icon: ListBulletIcon,
              active: data.mode === 'LINEAR',
              onClick: () => setData({ mode: 'LINEAR' }),
              text: t('team_analyse.list_view.title')
            },
            {
              Icon: ChartPieIcon,
              active: data.mode === 'CHART',
              onClick: () => setData({ mode: 'CHART' }),
              text: t('team_analyse.radar_chart.title')
            }
          ].map(({ Icon, active, onClick, text }, index) => (
            <button
              key={index}
              onClick={onClick}
              type='button'
              className={classNames(
                'group relative inline-flex items-center  -ml-px',
                'first:rounded-l-md last:rounded-r-md px-4 py-3 text-sm font-semibold',
                'ring-1 ring-inset ring-gray-300',
                active ? 'bg-gray-200/70' : 'bg-white hover:bg-gray-50'
              )}
            >
              <Icon
                className={classNames(
                  'w-5 h-5',
                  active
                    ? 'text-gray-700'
                    : 'text-gray-500 group-hover:text-gray-700'
                )}
              />
              <span className='ml-2 font-medium'>{text}</span>
            </button>
          ))}
        </div>
      </div>

      {data.mode === 'CHART' && (
        <>
          <div className='mt-3 mb-10'>
            <Careers
              careers={careers}
              onChange={(id) => {
                // for -1 selected career is undefined
                setSelectedCareer(careers.find((c) => c.id === id))
              }}
            />
          </div>
          <div className='xl:flex xl:justify-between xl:items-end mb-10'>
            <div>
              <span className='block text-sm font-medium leading-6 text-gray-900'>
                {t('team_analyse.linear_chart.talents.title')}
              </span>
              <span className='block text-xs font-normal text-gray-500 mb-3'>
                <InformationCircleIcon className='h-5 w-5 text-gray-500 inline-block mr-1' />
                {t('team_analyse.linear_chart.talents.display.title')}
              </span>
              <div className='flex gap-x-6 gap-y-2 flex-wrap'>
                {data.spaces.map((item, index) => {
                  const {
                    id,
                    borderColor,
                    backgroundColor,
                    identification,
                    visible
                  } = item

                  return (
                    <div
                      key={index}
                      onClick={
                        visible
                          ? () => hideSpace({ id })
                          : () => showSpace({ id })
                      }
                      className='flex items-center cursor-pointer group'
                    >
                      {career ? (
                        <>
                          {visible ? (
                            <EyeIcon className='w-6 text-gray-500 group-hover:text-gray-700' />
                          ) : (
                            <EyeSlashIcon className='w-6 text-gray-500 group-hover:text-gray-700' />
                          )}
                        </>
                      ) : (
                        <div
                          style={{ backgroundColor }}
                          className='w-5 h-5 rounded-full p-1'
                        >
                          <div
                            style={{ backgroundColor: borderColor }}
                            className='w-3 h-3 rounded-full'
                          />
                        </div>
                      )}
                      <span
                        className={classNames(
                          'ml-2 text-sm text-gray-700',
                          !visible && 'line-through'
                        )}
                      >
                        {identification}
                      </span>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div id='TEAMANALYSE' className='relative h-auto w-full'>
            <RadarChart
              data={{
                labels: chartData.dimensions.map(
                  ({ property }) => property[language]
                ),
                datasets: [
                  ...chartData.spaces.map((space) => ({
                    ...space,
                    data: space.data.map(({ result }) => result)
                  })),
                  ...chartData.careers.map((career) => ({
                    ...career,
                    data: career.data.map(({ optimal }) => optimal)
                  }))
                ]
              }}
              options={{
                ...options,
                plugins: {
                  ...options.plugins,
                  legend: {
                    display: false
                  }
                }
              }}
            />
          </div>
          <p className='mt-8 text-sm text-gray-700 italic text-center'>
            {t('radar_chart.show_hide_hint')}
          </p>
        </>
      )}
      {data.mode === 'LINEAR' && (
        <div>
          <div className='mt-3'>
            <div className='mb-10'>
              <span className='block text-sm font-medium leading-6 text-gray-900 mb-2'>
                {t('team_analyse.linear_chart.category.title')}
              </span>
              <div
                className={classNames(
                  data.mode === 'LINEAR' ? 'flex space-x-4' : 'hidden'
                )}
              >
                {data.categories.map(({ id, active, name, image }, index) => (
                  <button
                    key={index}
                    onClick={() => {
                      const { categories } = data
                      categories.forEach((c) => {
                        if (c.id === id) c.active = true
                        else c.active = false
                      })

                      setData({ categories })
                    }}
                    className={classNames(
                      active
                        ? 'bg-indigo-100 text-blue-700'
                        : 'text-gray-500 hover:text-gray-700',
                      'rounded-md px-3 py-2 text-sm font-medium flex flex-row items-center'
                    )}
                  >
                    <img src={image} className={classNames('w-auto h-5')} />
                    <span className={classNames('ml-2.5 block')}>
                      {name[language]}
                    </span>
                  </button>
                ))}
              </div>
            </div>
            <div className='my-10'>
              <Careers
                careers={careers}
                onChange={(id) => {
                  // for -1 selected career is undefined
                  setSelectedCareer(careers.find((c) => c.id === id))
                }}
              />
            </div>
            <div className='xl:flex xl:justify-between xl:items-end'>
              <div>
                <span className='block text-sm font-medium leading-6 text-gray-900'>
                  {t('team_analyse.linear_chart.talents.title')}
                </span>
                <span className='block text-xs font-normal text-gray-500 mb-3'>
                  <InformationCircleIcon className='h-5 w-5 text-gray-500 inline-block mr-1' />
                  {t('team_analyse.linear_chart.talents.display.title')}
                </span>
                <div className='flex gap-x-6 gap-y-2 flex-wrap'>
                  {data.spaces.map((item, index) => {
                    const {
                      id,
                      borderColor,
                      backgroundColor,
                      identification,
                      visible
                    } = item

                    return (
                      <div
                        key={index}
                        onClick={
                          visible
                            ? () => hideSpace({ id })
                            : () => showSpace({ id })
                        }
                        className='flex items-center cursor-pointer group'
                      >
                        {career ? (
                          <>
                            {visible ? (
                              <EyeIcon className='w-6 text-gray-500 group-hover:text-gray-700' />
                            ) : (
                              <EyeSlashIcon className='w-6 text-gray-500 group-hover:text-gray-700' />
                            )}
                          </>
                        ) : (
                          <div
                            style={{ backgroundColor }}
                            className='w-5 h-5 rounded-full p-1'
                          >
                            <div
                              style={{ backgroundColor: borderColor }}
                              className='w-3 h-3 rounded-full'
                            />
                          </div>
                        )}
                        <span
                          className={classNames(
                            'ml-2 text-sm text-gray-700',
                            !visible && 'line-through'
                          )}
                        >
                          {identification}
                        </span>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className='flex mt-12 xl:mt-0'>
                <div className='ml-auto' />
                {career ? (
                  <LinearDimensionExampleWithCareer />
                ) : (
                  <>
                    <div className='xl:mb-6' />
                    <LinearDimensionExample />
                  </>
                )}
              </div>
            </div>
          </div>

          <div className='mt-10' />
          <LinearChart
            data={{
              datasets: chartData.spaces,
              // only use one career because of presentation purposes
              career: chartData.careers[0],
              dimensions: chartData.dimensions.filter(
                ({ category }) => category === activeCategory.id
              )
            }}
            setShowConfiguration={setShowConfiguration}
          />
          {category_is_invisible && (
            <div className='mt-24 mb-12 flex justify-center'>
              <button
                onClick={() => setShowConfiguration(true)}
                className='p-2 max-w-lg text-center text-gray-700 hover:text-blue-600 text-sm italic'
              >
                {t('team_analyse.linear_chart.category_invisible_hint')}
                <span className='ml-0.5' aria-hidden='true'>
                  &rarr;
                </span>
              </button>
            </div>
          )}
          {no_data_for_category && !category_is_invisible && (
            <div className='mt-24 mb-12 flex justify-center'>
              <span className='max-w-lg text-center text-gray-700 text-sm italic'>
                {t('team_analyse.linear_chart.no_data_for_category_hint')}
              </span>
            </div>
          )}
        </div>
      )}
      <SlideoverDimensions open={showDimensions} setOpen={setShowDimensions} />
      <SlideoverConfiguration
        open={showConfiguration}
        setOpen={setShowConfiguration}
        data={data}
        setData={setData}
        showSpace={showSpace}
        hideSpace={hideSpace}
      />
      {isDownloadingPng && (
        <div className='absolute top-0 left-0 w-full h-full flex items-center justify-center bg-slate-900/30'>
          <div className='bg-white flex items-center justify-center rounded-lg p-16'>
            <Loader className={'py-0'} />
          </div>
        </div>
      )}
    </>
  )
}

Analyse.propTypes = {
  spaces: PropTypes.array.isRequired,
  careers: PropTypes.array.isRequired,
  setSelectedCareer: PropTypes.func.isRequired,
  career: PropTypes.object,
  id: PropTypes.string.isRequired
}

Analyse.defaultProps = {
  career: undefined
}

export default Analyse
