const TRANSLATION_LOCATION = 'constants.planned_new_hires_per_year.'

const plannedNewHiresPerYear = [-1, 10, 15, 35, 85, 175, 250]

export const getPlannedNewHiresPerYear = () => {
  // [ key, value ]
  return plannedNewHiresPerYear.map((hires) => [
    hires,
    TRANSLATION_LOCATION + String(hires)
  ])
}
