import { useParams } from 'react-router'
import { useQuery } from '@tanstack/react-query'

import { query } from '../graphql'
import CareersMatching from '../components/talent/careers-matching'
import { ActivityIndicator } from '../components'
import { getSpacesTableData } from '../helpers/spaces-table-data'
import SpaceHeader from '../components/talent/space-header'
import SpaceHistory from '../components/talent/space-history'
import SpaceComment from '../components/talent/space-comment'
import PageHeading from '../components/tailwind/PageHeading'
import SpaceActionsDropdown from '../components/talent/space-actions-dropdown'
import SEO from '../components/seo'
import SpaceCompletedChallenges from '../components/talent/space-completed-challenges'
import { useSpaceActions } from '../hooks/use-space-actions'
import Error404 from './error-404'
import SpaceDetailsProfile from '../components/talent/space-details-profile'
import SpaceDetailsAssessment from '../components/talent/space-details-assessment'
import SpaceSetStateHint from '../components/talent/space-set-state-hint'
import Feedback from '../components/feedback'
import SpaceRadarChart from '../components/talent/space-radar-chart'

const Talent = () => {
  const { id } = useParams() // CHECK SECURITY

  const {
    status: spaceQueryStatus,
    data: space,
    refetch: refetchSpace
  } = useQuery({
    queryKey: ['space', id],
    queryFn: () => query({ query: 'getSpace', variables: { id } }),
    select: (space) => {
      if (space.soft_deleted) return null

      const result = getSpacesTableData({ spaces: [space] })

      return result[0]
    }
  })

  // amplify bug! - updateSpace: res - { comment: null }
  // const spaceMutation = useMutationAndUpdateQuery({
  //   queryKey: ['space', id],
  //   mutation: 'updateSpace'
  // })

  const { career_id, assessment_id } = space || {}

  const {
    status: careerQueryStatus,
    fetchStatus: careerFetchStatus,
    data: career
  } = useQuery({
    queryKey: ['career', career_id],
    queryFn: () => query({ query: 'getCareer', variables: { id: career_id } }),
    enabled:
      !!assessment_id &&
      assessment_id !== 'TALENTPOOL' &&
      assessment_id !== 'ATTRACT'
  })

  const spaceActions = useSpaceActions({ spaces: space ? [space] : [] })

  const loading = [
    spaceQueryStatus,
    careerFetchStatus !== 'idle' && careerQueryStatus
  ].includes('pending')

  if (loading) {
    return (
      <div className='mt-32 flex justify-center items-center'>
        <ActivityIndicator />
      </div>
    )
  }

  if (!space) return <Error404 />

  return (
    <>
      <SEO title={`Talent ${space.name || space.identification}`} />
      <PageHeading
        withActions={
          <div className='ml-auto'>
            <SpaceActionsDropdown
              space={space}
              refetchSpace={refetchSpace}
              career={career}
              spaceActions={spaceActions}
            />
          </div>
        }
        sticky
      >
        <SpaceHeader space={space} />
      </PageHeading>
      <div className='min-h-full mb-8 px-4 sm:px-6 lg:px-8 mt-8'>
        <div className='mb-4 mx-auto grid grid-cols-1 gap-4 xl:grid-flow-col-dense xl:grid-cols-3'>
          <div className='space-y-4 xl:col-start-1 xl:col-span-2'>
            {space.progress === 100 && (
              <SpaceSetStateHint space={space} spaceActions={spaceActions} />
            )}
            <SpaceDetailsProfile space={space} career={career} />
            <SpaceDetailsAssessment
              space={space}
              refetchSpace={refetchSpace}
              career={career}
              spaceActions={spaceActions}
            />
          </div>
          <div className='xl:col-start-3 xl:col-span-1 '>
            <SpaceHistory space={space} />
            {space.progress === 100 && (
              <>
                {space.history?.length && <div className='h-4' />}
                <SpaceRadarChart space={space} career={career} />
              </>
            )}
          </div>
        </div>
        <div className='mx-auto'>
          <SpaceCompletedChallenges space={space} career={career} />
        </div>
        <div className='mx-auto md:flex md:items-center md:justify-between mt-4'>
          <CareersMatching space={space} refetchSpace={refetchSpace} />
        </div>
        <div className='mt-4'>
          <SpaceComment
            data={{ space, refetchSpace }}
            // amplify bug! - updateSpace: res - { comment: null }
            // updateHandler={({ input, onSuccess }) =>
            //   spaceMutation.mutate({ input }, { onSuccess })
            // }
          />
        </div>
      </div>
      <Feedback context='TALENT' />
    </>
  )
}

export default Talent
