import PropTypes from 'prop-types'

const Container = ({ children, containerStyle, withVerticalPadding }) => (
  <div
    style={containerStyle}
    className={`${
      withVerticalPadding && 'py-6'
    } max-w-7xl px-4 sm:px-6 lg:px-8`}
  >
    {children}
  </div>
)

Container.propTypes = {
  children: PropTypes.node.isRequired,
  containerStyle: PropTypes.object,
  withVerticalPadding: PropTypes.bool
}

export default Container

// // Container before mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'
