import { SMART_PREDICT_SPACE_STATES } from './space-states'

const TRANSLATION_LOCATION = 'constants.smart_predict.'
export const APPLICANT_STATUS = 'APPLICANT_STATUS'

const SPACE_STATES = [
  {
    id: 'NOT_SET',
    label: TRANSLATION_LOCATION + 'state_open_label',
    ...SMART_PREDICT_SPACE_STATES.OPEN
  },
  {
    id: 'INVITED_NEXT_ROUND',
    label: TRANSLATION_LOCATION + 'state_invited_next_round_label',
    description: TRANSLATION_LOCATION + 'state_invited_next_round_description',
    ...SMART_PREDICT_SPACE_STATES.INVITED_NEXT_ROUND
  },
  {
    id: 'HIRED',
    label: TRANSLATION_LOCATION + 'state_hired_label',
    displayOptions: true,
    ...SMART_PREDICT_SPACE_STATES.HIRED
  },
  {
    id: 'TRIAL_PERIOD_SURVIVED',
    label: TRANSLATION_LOCATION + 'state_trial_period_survived_label',
    ...SMART_PREDICT_SPACE_STATES.TRIAL_PERIOD_SURVIVED
  },
  {
    id: 'REJECTED',
    label: TRANSLATION_LOCATION + 'state_rejected_label',
    displayOptions: true,
    displaySendMail: true,
    ...SMART_PREDICT_SPACE_STATES.REJECTED
  },
  {
    id: 'APPLICANT_CANCELLED',
    label: TRANSLATION_LOCATION + 'state_applicant_cancelled_label',
    ...SMART_PREDICT_SPACE_STATES.APPLICANT_CANCELLED
  }
]

const SPACE_STATES_OPTIONS = [
  {
    id: 'NOT_SUITABLE',
    label: TRANSLATION_LOCATION + 'state_option_not_suitable_label'
  },
  {
    id: 'LESS_SUITABLE',
    label: TRANSLATION_LOCATION + 'state_option_less_suitable_label'
  },
  {
    id: 'MORE_SUITABLE',
    label: TRANSLATION_LOCATION + 'state_option_more_suitable_label',
    default: true
  },
  {
    id: 'VERY_SUITABLE',
    label: TRANSLATION_LOCATION + 'state_option_very_suitable_label'
  }
]

export const getSpaceStates = () => {
  return SPACE_STATES
}

export const getSpaceState = (id) => {
  return SPACE_STATES.find((state) => state.id === id)
}

export const getSpaceStateOptions = () => {
  return SPACE_STATES_OPTIONS
}
