import { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { ClipboardDocumentListIcon, CheckIcon } from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next'
import { getInviteToAssessmentLink } from '../../helpers'

const SpaceInviteLink = ({ spaceID, language }) => {
  const { t } = useTranslation()
  const [copied, setCopied] = useState(false)

  const inviteLink = useMemo(
    () => getInviteToAssessmentLink({ space_id: spaceID, language }),
    [spaceID, language]
  )

  const copySpaceInviteLinkToClipboard = () => {
    navigator.clipboard.writeText(inviteLink)
    setCopied(true)
    setTimeout(() => setCopied(false), 1000 * 3)
  }

  return (
    <div className='rounded-md bg-gray-50 px-4 py-2'>
      <div className='flex items-center'>
        <div>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='currentColor'
            className='w-5 h-5'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244'
            />
          </svg>
        </div>
        <code className='ml-2 mr-4 text-xs text-gray-900 truncate'>
          {inviteLink}
        </code>
        <div
          title={t('career.public_invitation_link.copy')}
          className='ml-auto flex justify-center items-center cursor-pointer h-8 w-8 rounded-full bg-blue-600 hover:bg-blue-500 shrink-0'
          onClick={copySpaceInviteLinkToClipboard}
        >
          {copied ? (
            <CheckIcon className='h-4 w-4 text-white' />
          ) : (
            <ClipboardDocumentListIcon className='h-4 w-4 text-white' />
          )}
        </div>
      </div>
    </div>
  )
}

SpaceInviteLink.propTypes = {
  spaceID: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired
}

export default SpaceInviteLink
