import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { localStorage } from '../helpers/local-storage'
import { EN, isLanguageAvailable } from '../constants/languages'

export const useI18n = () => {
  const { i18n } = useTranslation()

  const [currentLang, setCurrentLang] = useState(null)

  useEffect(() => {
    const getActiveLanguage = () => {
      // is set if a language change from inside settings happens
      const languageFromStore = localStorage.getItem('language')
      if (languageFromStore) return languageFromStore

      // browser / device language
      const navigatorLanguage = navigator.language.split('-').shift()
      if (isLanguageAvailable(navigatorLanguage)) return navigatorLanguage

      return EN
    }

    const language = getActiveLanguage()

    setCurrentLang(language)
    i18n.changeLanguage(language)
  }, [i18n])

  return { language: currentLang }
}
