import PropTypes from 'prop-types'
import { useEffect, useState, useRef } from 'react'
import { motion, animate } from 'framer-motion'
import { useTranslation } from 'react-i18next'

import { useMount } from '../hooks/use-mount'
import { useUserContext } from '../context/user-context'
import Button from './tailwind/Button'
import { sendSlackMessage } from '../helpers'
import { mutation, query } from '../graphql'

const { version } = require('../../package.json')

const Feedback = ({ context }) => {
  const { active_space } = useUserContext()
  const [feedback, setFeedback] = useState(null)
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false)
  const [createFeedbackSuccess, setCreateFeedbackSuccess] = useState(null)
  const { t } = useTranslation()

  const div = useRef(null)

  const FEEDBACK_ID = `${active_space.id}-${context}`

  useMount(() => {
    query({ query: 'getFeedback', variables: { id: FEEDBACK_ID } }).then(
      (res) => setFeedbackSubmitted(!!res)
    )
  })

  useEffect(() => {
    if (!feedback?.rating) return
    setTimeout(() => {
      div.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }, 200)
  }, [feedback?.rating])

  useEffect(() => {
    if (createFeedbackSuccess !== true) return
    setTimeout(() => {
      setFeedbackSubmitted(true)
    }, 3000)
  }, [createFeedbackSuccess])

  const onPressSendFeedback = () => {
    // no auth rules specified
    mutation({
      mutation: 'createFeedback',
      input: {
        id: FEEDBACK_ID,
        rating: feedback.rating === 'positive' ? 5 : 0,
        ref_link: window?.location?.href || 'NOT_SET',
        rating_text: feedback.rating_text,
        context,
        version,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      },
      options: { disableLastUpdatedBy: true }
    }).then(() => {
      setCreateFeedbackSuccess(true)
      if (feedback.rating === 'negative') {
        sendSlackMessage(
          'engineering',
          `New Dashboard Feedback: ${context} - ${feedback.rating_text}`
        )
      }
    })
  }

  if (feedbackSubmitted) return null

  if (createFeedbackSuccess === true) {
    return (
      <div className='w-full flex items-center justify-center my-6' ref={div}>
        <div className='bg-gray-100 py-4 max-w-lg w-full px-4 rounded-xl'>
          <p className='text-center text-green-600'>{t('feedback.thankyou')}</p>
        </div>
      </div>
    )
  }

  return (
    <div className='w-full flex items-center justify-center my-6' ref={div}>
      <div className='bg-gray-100 py-4 max-w-lg w-full px-4 rounded-xl'>
        <p className='text-base font-semibold leading-7 text-gray-900 text-center'>
          {t('feedback.title')}
        </p>
        <div className='flex flex-row items-center justify-center mt-2'>
          <motion.button
            className='block text-3xl mr-4 negative-button'
            onClick={() => {
              animate('.negative-button', { scale: 1.2 }, { type: 'spring' })
              animate('.positive-button', { scale: 1.0 }, { type: 'spring' })
              setFeedback({ ...feedback, rating: 'negative' })
            }}
          >
            🙁
          </motion.button>
          <motion.button
            className='block text-3xl positive-button'
            onClick={() => {
              animate('.positive-button', { scale: 1.2 }, { type: 'spring' })
              animate('.negative-button', { scale: 1.0 }, { type: 'spring' })
              setFeedback({ ...feedback, rating: 'positive' })
            }}
          >
            🙂
          </motion.button>
        </div>

        {feedback?.rating === 'positive' && createFeedbackSuccess === null && (
          <div className='flex items-center justify-center mt-4'>
            <Button.Text
              text={t('feedback.send')}
              onClick={onPressSendFeedback}
            />
          </div>
        )}

        {feedback?.rating === 'negative' && (
          <div className='mt-4 w-full'>
            <textarea
              value={feedback?.rating_text}
              onChange={(event) =>
                setFeedback({
                  ...feedback,
                  rating_text: event.target.value
                })
              }
              rows={4}
              name='comment'
              id='comment'
              placeholder={'Optional eine Nachricht schreiben ...'}
              className='block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-sm text-gray-900 placeholder:text-sm placeholder:text-gray-700 placeholder:italic'
            />
          </div>
        )}
        {feedback?.rating === 'negative' && (
          <div className='flex flex-col w-full items-end'>
            <button
              type='submit'
              disabled={feedback === null}
              onClick={onPressSendFeedback}
              style={{ opacity: feedback === null ? 0.3 : 1 }}
              className='inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 mt-5'
            >
              {t('feedback.send')}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

Feedback.propTypes = {
  context: PropTypes.string.isRequired
}

Feedback.defaultProps = {}

export default Feedback
