/* CUSTOM QUERIES */

import { ADMIN_PARTNER } from './schema/admin-partner'
import { ANALYSE } from './schema/analyse'
import { ANSWER } from './schema/answer'
import { API_KEY } from './schema/api-key'
import { CAREER } from './schema/career'
import { CAREER_ANALYSE } from './schema/career-analyse'
import { FORM } from './schema/form'
import { INTEGRATION } from './schema/integration'
import { PARTNER } from './schema/partner'
import { PAYMENT } from './schema/payment'
import { PERSON } from './schema/person'
import { SPACE } from './schema/space'
import { TRIMMED_SPACE } from './schema/trimmed/space'
import { SYSTEM } from './schema/system'
import { TRIMMED_PARTNER } from './schema/trimmed/partner'
import { USER } from './schema/user'

export const stripeControl = /* GraphQL */ `
  query StripeControl(
    $action: String!
    $product_id: ID
    $price_id: ID
    $email: String
  ) {
    stripeControl(
      action: $action
      product_id: $product_id
      price_id: $price_id
      email: $email
    )
  }
`

export const listPartners = /* GraphQL */ `
  query ListPartners(
    $filter: ModelPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items ${ADMIN_PARTNER}
      nextToken
    }
  }
`

export const generatePdf = /* GraphQL */ `
  query GeneratePdf(
    $user_id: ID
    $career_id: ID
    $type: String
    $partner_user_id: ID
    $partner_id: ID
    $show_old_ui: Boolean
    $language: String
  ) {
    generatePdf(
      user_id: $user_id
      career_id: $career_id
      type: $type
      partner_user_id: $partner_user_id
      partner_id: $partner_id
      show_old_ui: $show_old_ui
      language: $language
    )
  }
`

export const summarizeAnalyses = /* GraphQL */ `
  query SummarizeAnalyses($career_id: ID!, $force_update: Boolean) {
    summarizeAnalyses(career_id: $career_id, force_update: $force_update) {
      upadtedCareer ${CAREER}
    }
  }
`

export const matchMeIfYouCan = /* GraphQL */ `
  query MatchMeIfYouCan($partner_id: ID, $user_id: ID) {
    matchMeIfYouCan(partner_id: $partner_id, user_id: $user_id)
  }
`

export const paymentByPartnerIdByCreatedAt = /* GraphQL */ `
  query PaymentByPartnerIdByCreatedAt(
    $partner_id: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    paymentByPartnerIdByCreatedAt(
      partner_id: $partner_id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items ${PAYMENT}
      nextToken
    }
  }
`

export const getSpace = /* GraphQL */ `
  query GetSpace($id: ID!) {
    getSpace(id: $id) ${SPACE}
  }
`

export const spacesByPartnerIdByUpdatedAt = /* GraphQL */ `
  query SpacesByPartnerIdByUpdatedAt(
    $partner_id: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spacesByPartnerIdByUpdatedAt(
      partner_id: $partner_id
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items ${SPACE}
      nextToken
    }
  }
`

export const getPerson = /* GraphQL */ `
  query GetPerson($id: ID!) {
    getPerson(id: $id) ${PERSON}
  }
`

export const careerAnalyseByCareer = /* GraphQL */ `
  query CareerAnalyseByCareer(
    $career_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCareerAnalyseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    careerAnalyseByCareer(
      career_id: $career_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items ${CAREER_ANALYSE}
      nextToken
    }
  }
`

export const apiKeysByPartner = /* GraphQL */ `
  query ApiKeysByPartner(
    $partner_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelApiKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    apiKeysByPartner(
      partner_id: $partner_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items ${API_KEY}
      nextToken
    }
  }
`

export const getCareer = /* GraphQL */ `
  query GetCareer($id: ID!) {
    getCareer(id: $id) ${CAREER}
  }
`

export const careerByPartnerByDate = /* GraphQL */ `
  query CareerByPartnerByDate(
    $partner_id: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCareerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    careerByPartnerByDate(
      partner_id: $partner_id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items ${CAREER}
      nextToken
    }
  }
`

export const getPartner = /* GraphQL */ `
  query GetPartner($id: ID!) {
    getPartner(id: $id) ${PARTNER}
  }
`

export const userControl = /* GraphQL */ `
  query UserControl(
    $space_id: ID
    $user_type: USER_TYPES
    $username: String
    $company_name: String
    $lead_id: ID
    $language: String
    $ref_link: String
    $referrer_link: String
    $firstname: String
    $lastname: String
    $role: String
    $groupname: String
    $email: String
    $action: String!
    $action_settings: ActionSettingsInput
  ) {
    userControl(
      space_id: $space_id
      user_type: $user_type
      username: $username
      company_name: $company_name
      lead_id: $lead_id
      language: $language
      ref_link: $ref_link
      referrer_link: $referrer_link
      firstname: $firstname
      lastname: $lastname
      role: $role
      groupname: $groupname
      email: $email
      action: $action
      action_settings: $action_settings
    )
  }
`

export const partnerControl = /* GraphQL */ `
  query PartnerControl(
    $action: String!
    $partner_id: ID
    $formID: ID
    $email: String
    $language: String
  ) {
    partnerControl(
      action: $action
      partner_id: $partner_id
      formID: $formID
      email: $email
      language: $language
    )
  }
`

export const inviteTalent = /* GraphQL */ `
  query InviteTalent(
    $send_mail: Boolean
    $career_id: ID
    $partner_id: ID
    $partner_user_id: ID
    $firstname: String
    $lastname: String
    $email: String
    $phone: String
    $postcode: String
    $city: String
    $email_template: String
    $space_id: ID
    $language: String
    $all_challenges_unlocked: Boolean
  ) {
    inviteTalent(
      send_mail: $send_mail
      language: $language
      career_id: $career_id
      partner_id: $partner_id
      partner_user_id: $partner_user_id
      firstname: $firstname
      lastname: $lastname
      email: $email
      phone: $phone
      postcode: $postcode
      city: $city
      email_template: $email_template
      space_id: $space_id
      all_challenges_unlocked: $all_challenges_unlocked
    ) ${SPACE}
  }
`

export const pinpointControl = /* GraphQL */ `
  query PinpointControl(
    $action: String!
    $channel_type: CHANNEL_TYPES
    $user_id: ID!
    $topic: PinpointTopics
    $address: String
    $user_attributes: AWSJSON
    $attributes: AWSJSON
    $endpoint_attributes: AWSJSON
    $location: PinpointControlLocationInput
    $demographic: PinpointControlDemographicInput
  ) {
    pinpointControl(
      action: $action
      channel_type: $channel_type
      user_id: $user_id
      topic: $topic
      address: $address
      user_attributes: $user_attributes
      attributes: $attributes
      endpoint_attributes: $endpoint_attributes
      location: $location
      demographic: $demographic
    )
  }
`

export const sendTemplateMail = /* GraphQL */ `
  query SendTemplateMail(
    $to_addresses: [String]
    $to_user_id: ID
    $template_id: ID!
    $input: AWSJSON
    $just_return_text: Boolean
    $use_local_data: Boolean
  ) {
    sendTemplateMail(
      to_addresses: $to_addresses
      to_user_id: $to_user_id
      template_id: $template_id
      input: $input
      just_return_text: $just_return_text
      use_local_data: $use_local_data
    ) {
      subject
    }
  }
`

export const publicSqlQuery = /* GraphQL */ `
  query PublicSqlQuery($action: String, $input: AWSJSON) {
    publicSqlQuery(action: $action, input: $input) {
      Items
    }
  }
`

export const getSystem = /* GraphQL */ `
  query GetSystem($id: ID!) {
    getSystem(id: $id) ${SYSTEM}
  }
`

export const matchBatchIfYouCan = /* GraphQL */ `
  query MatchBatchIfYouCan($careers: [ID], $users: [ID]) {
    matchBatchIfYouCan(careers: $careers, users: $users)
  }
`

export const spaceControl = /* GraphQL */ `
  query SpaceControl(
    $action: String!
    $user_id: ID
    $space_id: ID
    $authorization_role: ROLE_NAMES
  ) {
    spaceControl(
      action: $action
      user_id: $user_id
      space_id: $space_id
      authorization_role: $authorization_role
    )
  }
`

export const answersByOwner = /* GraphQL */ `
  query AnswersByOwner(
    $owner: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    answersByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items ${ANSWER}
      nextToken
    }
  }
`

export const careerControl = /* GraphQL */ `
  query CareerControl(
    $action: String!
    $career_id: ID
    $from_career_id: ID
    $to_career_id: ID
  ) {
    careerControl(
      action: $action
      career_id: $career_id
      from_career_id: $from_career_id
      to_career_id: $to_career_id
    )
  }
`

export const integrationControl = /* GraphQL */ `
  query IntegrationControl(
    $action: String!
    $user_id: ID
    $space_id: ID
    $partner_id: ID
    $integration_id: ID
  ) {
    integrationControl(
      action: $action
      user_id: $user_id
      space_id: $space_id
      partner_id: $partner_id
      integration_id: $integration_id
    )
  }
`

export const listIntegrationsByPartner = /* GraphQL */ `
  query ListIntegrationsByPartner(
    $partner_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelIntegrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntegrationsByPartner(
      partner_id: $partner_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items ${INTEGRATION}
      nextToken
    }
  }
`

export const getForm = /* GraphQL */ `
  query GetForm($id: ID!) {
    getForm(id: $id) ${FORM}
  }
`

export const listAnalysesByPartner = /* GraphQL */ `
  query ListAnalysesByPartner(
    $partner_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnalyseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnalysesByPartner(
      partner_id: $partner_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items ${ANALYSE}
      nextToken
    }
  }
`

export const getAnalyse = /* GraphQL */ `
  query GetAnalyse($id: ID!) {
    getAnalyse(id: $id) ${ANALYSE}
  }
`

export const helperControl = /* GraphQL */ `
  query HelperControl(
    $user_id: ID
    $career_id: ID
    $challenge_id: ID
    $action: String
  ) {
    helperControl(
      user_id: $user_id
      career_id: $career_id
      challenge_id: $challenge_id
      action: $action
    )
  }
`

export const sendMail = /* GraphQL */ `
  query SendMail(
    $toAddresses: [String]
    $body: String
    $subject: String
    $source: String
  ) {
    sendMail(
      toAddresses: $toAddresses
      body: $body
      subject: $subject
      source: $source
    )
  }
`

export const getFeedback = /* GraphQL */ `
  query GetFeedback($id: ID!) {
    getFeedback(id: $id) {
      id
      rating
      rating_text
      message
      context
      ref_link
      version
      createdAt
      updatedAt
      __typename
    }
  }
`

export const spacesByOwner = /* GraphQL */ `
  query SpacesByOwner(
    $owner: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spacesByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items ${TRIMMED_SPACE}
    }
  }
`

export const getTrimmedPartner = /* GraphQL */ `
  query GetTrimmedPartner($id: ID!) {
    getPartner(id: $id) ${TRIMMED_PARTNER}
  }
`

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) ${USER}
  }
`
