import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { de, enUS as en } from 'date-fns/locale'

import Panel from '../tailwind/Panel'
import { isNumber } from 'lodash'
import constants_work_experience from '../../constants/work-experience'
import { useLanguageContext } from '../../context'
import SpaceDetailItem from './space-detail-item'

const SpaceDetailsProfile = ({ space, career }) => {
  const { t } = useTranslation()
  const { language } = useLanguageContext()

  const { firstname, lastname, email, partner_user_id, createdAt } = space

  const { title: career_title, app_settings } = career || {}

  const application_input_additional_fields = JSON.parse(
    app_settings?.application_input_additional_fields || '[]'
  )

  const additional_requested_data = JSON.parse(
    space.additional_requested_data || '{}'
  )

  const { work_experience } = additional_requested_data

  const profile = {
    basics: [
      {
        available: true,
        term: t('talent.state_invited'),
        details: format(new Date(createdAt), 'd. MMMM yyyy', {
          locale: { de, en }[language]
        })
      },
      {
        available: !!firstname,
        term: t('pages.talent.space_details_firstname_label'),
        details: firstname
      },
      {
        available: !!lastname,
        term: t('pages.talent.space_details_lastname_label'),
        details: lastname
      },
      {
        available: !!email,
        term: t('pages.talent.space_details_email_label'),
        details: email
      },
      {
        available: isNumber(work_experience),
        term: t(
          `pages.talent.space_details_${
            career_title ? 'career' : 'overall'
          }_work_experience_label`,
          { career_title }
        ),
        details: isNumber(work_experience)
          ? constants_work_experience[work_experience].label[language]
          : ''
      },
      {
        available: !!partner_user_id,
        term: t('pages.talent.space_details_partner_user_id_label'),
        details: partner_user_id
      },
      ...Object.keys(additional_requested_data).map((key) => {
        const field =
          application_input_additional_fields.find(({ id }) => id === key) || {}
        const data = additional_requested_data[key]

        return {
          available: true,
          term:
            field.label ||
            t('pages.talent.space_details_removed_hint', {
              label: data.label
            }),
          details: data.input
        }
      })
    ].filter(({ available }) => available)
  }

  return (
    <Panel>
      <section
        className='md:h-full'
        aria-labelledby='applicant-information-title'
      >
        <div className='md:h-full'>
          <div className='sm:flex pb-5'>
            <div>
              <h2
                id='applicant-information-title'
                className='text-lg leading-6 font-medium text-gray-900'
              >
                {t('pages.talent.space_details_profile_header')}
              </h2>
              <p className='mt-1 max-w-2xl text-sm text-gray-500'>
                {t('pages.talent.space_details_profile_description')}
              </p>
            </div>
          </div>
          <div className='border-t border-gray-300 pt-5'>
            <dl className='grid grid-cols-1 gap-4 sm:grid-cols-2'>
              {profile.basics.map(({ term, details, onClick }, index) => (
                <SpaceDetailItem key={index} {...{ term, details, onClick }} />
              ))}
            </dl>
          </div>
        </div>
      </section>
    </Panel>
  )
}

SpaceDetailsProfile.propTypes = {
  space: PropTypes.object.isRequired,
  career: PropTypes.object
}

SpaceDetailsProfile.defaultProps = {
  career: null
}

export default SpaceDetailsProfile
