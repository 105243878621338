import { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { trackEvent } from '../../helpers/analytics'
import trackEvents from '../../constants/track-events'
import Modal from '../tailwind/Modal'
import { useNotificationContext, useUserContext } from '../../context'
import Button from '../tailwind/Button'
import { mutation } from '../../graphql'
import Datepicker from '../react-datepicker/datepicker'

const ResetExpired = ({ spaces, updateHandler, open, setOpen }) => {
  const { t } = useTranslation()
  const { cognitoUser } = useUserContext()
  const { success } = useNotificationContext()

  const space = spaces.length === 1 ? spaces[0] : null

  const [isLoading, setIsLoading] = useState(false)
  const [expiresAt, setExpiresAt] = useState(
    space && new Date(space.expiresAt) > new Date()
      ? new Date(space.expiresAt)
      : new Date(Date.now() + 12096e5) // fortnight away
  )

  const updateExpiresAt = () => {
    setIsLoading(true)

    const event = {
      eventName: 'SYSTEM',
      afterStatus: 'RESET_EXPIRED',
      createdAt: new Date().toISOString(),
      eventFiredBy: cognitoUser.username
    }

    const onSuccess = () => {
      success(t('space_actions.reset_expired_success'))
      trackEvent(trackEvents.RESET_EXPIRED_TALENTS)
      setIsLoading(false)
      setOpen(false)
    }

    expiresAt.setHours(23)
    expiresAt.setMinutes(59)
    expiresAt.setSeconds(59)

    Promise.all(
      spaces.map(({ id, history }) =>
        mutation({
          mutation: 'updateSpace',
          input: {
            id,
            history: (history || []).concat(event),
            expiresAt: expiresAt.toISOString()
          }
        })
      )
    )
      .then(() => updateHandler({ onSuccess }))
      .catch(() => setIsLoading(false))
  }

  return (
    <Modal open={open} setOpen={() => setOpen(false)} size='2xl'>
      <div className='mt-3 text-center sm:mt-5'>
        <h2 className='text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
          {t('space_actions.modal_reset_expired_title')}
        </h2>
        {spaces.length > 1 && (
          <p className='mt-2 max-w-lg mx-auto text-sm text-gray-700'>
            {t('space_actions.modal_reset_expired_description', {
              number: spaces.length
            })}
          </p>
        )}
      </div>
      <div className='mt-12 mx-4'>
        <p className='block text-sm font-medium text-gray-700'>
          {t('space_actions.reset_expired_title')}
        </p>
        <div className='mt-2'>
          <Datepicker
            selected={expiresAt}
            onChange={(date) => setExpiresAt(date)}
            minDate={new Date()}
          />
        </div>
        <p className='text-gray-500 text-xs mt-2 italic'>
          {t('space_actions.reset_expired_description')}
        </p>
      </div>

      <div className='mt-12 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense md:flex md:justify-center'>
        <Button.SecondaryXL
          text={t('space_actions.modal_reset_expired_cancel_action')}
          onClick={() => setOpen(false)}
        />
        <Button.PrimaryXL
          text={t('space_actions.modal_reset_expired_submit_action')}
          onClick={updateExpiresAt}
          isLoading={isLoading}
        />
      </div>
    </Modal>
  )
}

ResetExpired.propTypes = {
  spaces: PropTypes.array.isRequired,
  updateHandler: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
}

export default ResetExpired
