export const ADMIN_PARTNER = `{
  id
  name
  display_name
  authorized_create
  authorized_read
  authorized_update
  career_site_url
  last_activity
  planned_new_hires_per_year
  logo
  createdAt
  updatedAt
}`
