import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'

import { sendSlackMessage } from '../../../helpers'
import { ActivityIndicator } from '../..'
import NetworkError from '../../network-error'
import {
  getCountAnalyzesScoreItemProperties,
  getAnalyseScoreItemProperties
} from '../../../constants/scores'
import { usePartnerContext, usePaymentContext } from '../../../context'
import { query } from '../../../graphql'
import Alert from '../../alert'

const ScoresContainer = ({
  careerId,
  verifiedCount,
  careerAnalyses,
  setAnalyseIsValid
}) => {
  const { t } = useTranslation()
  const { partner } = usePartnerContext()
  const { is_trial } = usePaymentContext()

  const queryClient = useQueryClient()

  const [state, setState] = useState('IS_LOADING')
  const [career, setCareer] = useState(null)

  useEffect(() => {
    setState('IS_LOADING')

    if (verifiedCount < 3) {
      setState('ANALYZES_COMPLETED_SMALLER_THREE')
      return
    }

    const refetchCareer = () => {
      queryClient.refetchQueries({
        queryKey: ['create-career', careerId],
        type: 'active'
      })
    }

    if (verifiedCount >= 3 && careerAnalyses.length !== verifiedCount) {
      // summarize because of the pdf, but then stop here
      query({
        query: 'summarizeAnalyses',
        variables: { career_id: careerId }
      }).then(() => {
        refetchCareer()
        setState('NOT_ALL_ANALYZES_COMPLETED')
      })

      return
    }

    query({
      query: 'summarizeAnalyses',
      variables: { career_id: careerId }
    }).then(({ upadtedCareer }) => {
      refetchCareer()
      // set career triggers the next use effect
      setCareer(upadtedCareer)
    })
  }, [careerAnalyses, careerId, verifiedCount, queryClient])

  useEffect(() => {
    const { career_analyse } = career || {}

    if (career_analyse) {
      const dimensionsWithUncertaintyWeight = []
      const { scores } = JSON.parse(career.career_analyse)

      Object.keys(scores).forEach((key) => {
        if (scores[key].uncertaintyWeight) {
          dimensionsWithUncertaintyWeight.push(key)
        }
      })

      const finallywearehere = dimensionsWithUncertaintyWeight.length >= 4
      setState(finallywearehere ? 'ANALYSE_IS_VALID' : 'ANALYSE_NOT_VALID')
      setAnalyseIsValid(finallywearehere)

      if (dimensionsWithUncertaintyWeight.length < 4) {
        sendSlackMessage(
          'bugsnag_customer-dashboard',
          `${partner.name} ${
            is_trial ? '(TRIAL)' : ''
          } hat für die Stelle ${careerId} kein valides Anforderungsprofil erreicht.`
        )
      }
    }
  }, [career, partner, is_trial, careerId, setAnalyseIsValid])

  if (state === 'IS_LOADING') {
    return <ActivityIndicator className='flex justify-center py-4' />
  }

  if (state === 'ERROR') {
    return <NetworkError />
  }

  const one = getCountAnalyzesScoreItemProperties(state)
  const two = getAnalyseScoreItemProperties(state)

  return (
    <div className='flex flex-col gap-y-4'>
      <Alert
        type={one.type}
        title={t(one.title)}
        description={t(one.description)}
      />
      <Alert
        type={two.type}
        title={t(two.title)}
        description={t(two.description)}
      />
    </div>
  )
}

ScoresContainer.propTypes = {
  careerId: PropTypes.string.isRequired,
  verifiedCount: PropTypes.number.isRequired,
  careerAnalyses: PropTypes.array.isRequired,
  setAnalyseIsValid: PropTypes.func.isRequired
}

export default ScoresContainer
