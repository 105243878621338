import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import PropTypes from 'prop-types'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { getJobCategory } from '../../constants/job-categories'
import { getLanguage } from '../../constants/languages'
import { convertNumberToTime, sendSlackMessage } from '../../helpers'
import { trackEvent } from '../../helpers/analytics'
import trackEvents from '../../constants/track-events'
// import { usePaymentRestrictions } from '../../hooks/use-payment-restrictions'
import { query } from '../../graphql'
import { ActivityIndicator } from '..'
import { useMount } from '../../hooks/use-mount'
import {
  useLanguageContext,
  useAivyContext,
  useProductTourContext,
  usePartnerContext,
  usePaymentContext
} from '../../context'
import { useCreateCareerNavigation } from '../../hooks/use-create-career-navigation'
import Button from '../tailwind/Button'

const Overview = ({ career, careerMutation }) => {
  const { t } = useTranslation()

  const { language } = useLanguageContext()
  const { is_trial, showPaywall } = usePaymentContext()
  const { system } = useAivyContext()
  const { partner } = usePartnerContext()

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const {
    setProductTourState,
    productTourState: { tourActive }
  } = useProductTourContext()

  useMount(() => {
    if (tourActive) {
      setTimeout(() => {
        setProductTourState({ run: true })
      }, 0.4 * 1000)
    }
  })

  const {
    isLoadingBack,
    isLoadingNext,
    handleBackNavigation,
    handleNextNavigation
  } = useCreateCareerNavigation({ career, careerMutation })

  const { career_preset_id } = JSON.parse(career.career_analyse)

  // const { data: activeCareersCount, status: activeCareersCountStatus } =
  //   useQuery({
  //     queryKey: ['active-careers-count', partner.id],
  //     queryFn: () =>
  //       query({
  //         query: 'careerByPartnerByDate',
  //         variables: {
  //           partner_id: partner.id,
  //           filter: {
  //             id: { notContains: 'sample_' },
  //             status: { eq: 'ACTIVE' },
  //             archived: { ne: true }
  //           }
  //         }
  //       }),
  //     initialData: -1,
  //     select: (items) => items.length
  //   })

  const { data: preset, status: presetStatus } = useQuery({
    queryKey: ['career-preset', career_preset_id],
    queryFn: () =>
      query({
        query: 'publicSqlQuery',
        variables: {
          action: 'query-career-preset',
          input: JSON.stringify({ career_preset_id })
        }
      }),
    select: (response) => JSON.parse(response.Items)[0],
    enabled: !!career_preset_id
  })

  const { data: careerAnalyses, status: careerAnalysesStatus } = useQuery({
    queryKey: ['career-analyses', career.id],
    queryFn: () =>
      query({
        query: 'careerAnalyseByCareer',
        variables: { career_id: career.id }
      }),
    enabled: !career_preset_id
  })

  // const restricted = usePaymentRestrictions({
  //   key: 'career_count',
  //   value: activeCareersCount
  // })

  const restricted = false

  const is_loading = [
    !!career_preset_id && presetStatus === 'pending',
    !career_preset_id && careerAnalysesStatus === 'pending'
  ]

  if (is_loading.includes(true)) {
    return <ActivityIndicator />
  }

  const overview = [
    {
      title: t('create_career.overview.career_title_title'),
      value: career.title
    },
    career.external_custom_id && {
      title: t('create_career.overview.external_custom_id_title'),
      value: career.external_custom_id
    },
    career.language && {
      title: t('create_career.overview.language_title'),
      value: t(getLanguage(career.language))
    },
    {
      title: t('create_career.overview.category_title'),
      value: t(getJobCategory(career.category))
    }
  ]

  if (career_preset_id) {
    overview.push({
      title: t('create_career.overview.preset_title'),
      value: JSON.parse(preset.name)[language]
    })
  } else {
    overview.push({
      title: t('create_career.overview.invited_experts_title'),
      value: careerAnalyses.map(({ person }) => person.email).join(', ')
    })
  }

  const challenges = JSON.parse(career.app_settings.challenges)
  const { minutes, seconds } = convertNumberToTime(
    challenges
      .map(({ exam_id }) => system.challenges[exam_id].timeToComplete)
      .reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      ) / 60000
  )

  overview.push(
    {
      title: t('create_career.overview.challenges_title'),
      value: challenges
        .map(({ exam_id }) => system.challenges[exam_id].title[language])
        .join(', ')
    },
    {
      title: t('create_career.overview.duration_title'),
      value: t('create_career.overview.duration_value', {
        minutes,
        seconds
      })
    }
  )

  const onFinishOverview = () => {
    if (restricted) return showPaywall('general')

    handleNextNavigation({
      onSuccess: ({ id, title }) => {
        if (!id.includes('sample')) {
          trackEvent(trackEvents.FINISH_CREATE_CAREER)

          if (is_trial) {
            queryClient.refetchQueries({
              queryKey: ['trial-data', partner.id],
              type: 'active'
            })
          }

          sendSlackMessage(
            'kunden-controlling',
            `Die Stelle *${title}* (${id}) von _${partner.name}_ ${
              is_trial ? '(TRIAL)' : ''
            } ist nun aktiv.`
          )
        }

        navigate(`/career/${id}`, { replace: true })
      }
    })
  }

  return (
    <>
      <span className='text-3xl font-extrabold tracking-tight text-gray-900'>
        {t('create_career.overview.title')}
      </span>
      <p className='mt-4 max-w-lg text-sm text-gray-900'>
        {t('create_career.overview.description')}
      </p>
      <div className='mt-8 border-t border-gray-200'>
        <dl className='divide-y divide-gray-200'>
          {overview
            .filter((item) => item)
            .map(({ title, value }, index) => (
              <div
                key={index}
                className='py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5'
              >
                <dt className='my-auto text-sm text-gray-700'>{title}</dt>
                <dd className='mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
                  <span className='flex-grow'>{value}</span>
                  {/* <span className='ml-4 flex-shrink-0 my-auto'>
                  <button
                    type='button'
                    onClick={onClick}
                    className='rounded-md bg-white font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
                  >
                    bearbeiten
                  </button>
                </span> */}
                </dd>
              </div>
            ))}
        </dl>
      </div>
      <div className='mt-8 sm:mt-12 flex justify-end gap-x-2'>
        <div className='flex gap-x-2'>
          <Button.SecondaryLG
            onClick={handleBackNavigation}
            isLoading={isLoadingBack}
            text={t('create_career.back_action')}
          />
          <Button.PrimaryLG
            text={t('create_career.overview.submit_action')}
            isLoading={isLoadingNext}
            onClick={onFinishOverview}
          />
        </div>
        {/* <CreateCareerNavigation
          career={career}
          nextText={}
          nextOnSuccess={}
          nextRestricted={restricted}
        /> */}
      </div>
    </>
  )
}

Overview.propTypes = {
  career: PropTypes.object.isRequired,
  careerMutation: PropTypes.object.isRequired
}

export default Overview
