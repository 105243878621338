import { API, graphqlOperation, Auth } from 'aws-amplify'
import { isError } from 'lodash'

import * as queries from './queries'
import * as mutations from './mutations'

import { getUser as getUserSingleton } from '../helpers/user'
import { getNotification as getNotificationSingleton } from '../helpers/notification'
import { convertGraphqlError, notifyBugsnag } from '../helpers'

export function query({ query, variables = {}, authMode, options = {} }) {
  if (!query) throw new Error('Missing parameter')

  const { error, not_authorized_error_message } = getNotificationSingleton()

  const promise = new Promise((resolve, reject) => {
    const possibleItems = []

    const rejectPromise = (err) => {
      notifyBugsnag(err)
      reject(err)
    }

    const request = (nextToken) => {
      API.graphql({
        ...graphqlOperation(queries[query], {
          limit: 200, // can be overwritten by variables
          ...variables,
          nextToken
        }),
        authMode
      })
        .then(({ data, errors }) => {
          const origin = options.origin || query

          if (errors) {
            rejectPromise(convertGraphqlError({ errors }, { query, variables }))
            return
          }

          if (!data[origin]) {
            resolve(null)
            return
          }

          if (data[origin].items) {
            const items = data[origin].items
            const nextToken = data[origin].nextToken

            possibleItems.push(...items)

            if (nextToken) request(nextToken)
            else resolve(possibleItems)
          } else {
            resolve(data[origin])
          }
        })
        .catch((err) => {
          if (isError(err)) {
            rejectPromise(err)
            return
          }

          const graphql_error = convertGraphqlError(err, { query, variables })

          if (graphql_error.message.includes(`${query} / NoPermissionsError`)) {
            // reject the error and show error notification
            reject(new Error('NOT_AUTHORIZED'))
            error(not_authorized_error_message)
            return
          }

          rejectPromise(graphql_error)
        })
    }

    request()
  })

  return promise
}

export function mutation({ mutation, input, authMode, options = {} }) {
  const { username } = getUserSingleton()
  const { error, not_authorized_error_message } = getNotificationSingleton()

  if (!username && !input.lastUpdatedBy) {
    throw new Error('graphql mutation error: username not found')
  }

  if (
    !options.disableLastUpdatedBy &&
    !mutation.includes('delete') &&
    !input.lastUpdatedBy
  ) {
    input.lastUpdatedBy = username
  }

  const promise = new Promise((resolve, reject) => {
    const rejectPromise = (err) => {
      notifyBugsnag(err)
      reject(err)
    }

    API.graphql({
      ...graphqlOperation(mutations[mutation], { input }),
      authMode
    })
      .then(({ data, errors }) => {
        if (errors) {
          rejectPromise(convertGraphqlError({ errors }, { mutation, input }))
          return
        }

        resolve(data[mutation])
      })
      .catch((err) => {
        if (isError(err)) {
          rejectPromise(err)
          return
        }

        const graphql_error = convertGraphqlError(err, { mutation, input })

        if (
          graphql_error.message.includes(`Not Authorized to access ${mutation}`)
        ) {
          // reject the error and show error notification
          reject(new Error('NOT_AUTHORIZED'))
          error(not_authorized_error_message)
          return
        }

        rejectPromise(graphql_error)
      })
  })

  return promise
}

export function currentAuthenticatedUser() {
  return Auth.currentAuthenticatedUser({ bypassCache: true })
}
