import { useCallback, useEffect, useMemo } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

import { fromBase64, toBase64 } from '../helpers'
import { decodeFilter, encodeFilter } from '../helpers/filters'
import { localStorage } from '../helpers/local-storage'

export const useFiltersFromURL = ({ columns, setColumnFilters }) => {
  const { pathname } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  const urlFilters = useMemo(() => {
    const result = []
    const filter = searchParams.get('filter')
    const computed = searchParams.get('computed')

    if (filter) {
      const from = fromBase64(filter)
      result.push(
        ...(from || []).map((item) => ({
          ...decodeFilter(item),
          filterKey: 'filter'
        }))
      )
    }

    if (computed) {
      const from = fromBase64(computed)
      result.push(
        ...(from || []).map((item) => ({
          ...decodeFilter(item),
          filterKey: 'computed'
        }))
      )
    }

    return result
  }, [searchParams])

  // set filters for url via filter param
  const setActiveFiltersForTable = useCallback(() => {
    const filterValues = new Map()

    columns
      .map(({ filter }) => filter)
      .filter((filter) => filter)
      .forEach((filter) => {
        const { field, operator, value } =
          urlFilters.find(({ field }) => field === filter.id) || {}

        filter.options.forEach((option) => {
          const checked =
            field && operator === 'in' && value.includes(option.value)

          if (!checked) return

          const values = filterValues.get(filter.column) || []
          values.push(option.value)
          filterValues.set(filter.column, values)
        })
      })

    setColumnFilters(
      Array.from(filterValues.entries()).map(([key, values]) => ({
        id: key,
        value: values
      }))
    )
  }, [columns, urlFilters, setColumnFilters])

  useEffect(() => {
    setActiveFiltersForTable()
  }, [columns, urlFilters, setActiveFiltersForTable])

  // set url filter param on click
  const setFilterUrl = useCallback(
    ({ column, value, checked }) => {
      let urlFilter = urlFilters.find(
        ({ field }) => field === column.columnDef.filter.column
      )

      if (!urlFilter) {
        urlFilter = {
          field: column.columnDef.filter.column,
          operator: column.columnDef.filter.operator,
          filterKey: column.columnDef.filter.filterKey,
          value: []
        }

        urlFilters.push(urlFilter)
      }

      if (checked) {
        urlFilter.value.push(value)
      }

      if (!checked) {
        urlFilter.value = urlFilter.value.filter((filter) => filter !== value)
      }

      const params = {
        filter: urlFilters
          .filter(({ filterKey }) => filterKey === 'filter')
          .filter(({ value }) => value.length),
        computed: urlFilters
          .filter(({ filterKey }) => filterKey === 'computed')
          .filter(({ value }) => value.length)
      }

      if (params.filter.length) {
        params.filter = `filter=${toBase64(
          params.filter.map((item) => encodeFilter(item))
        )}`
      } else {
        params.filter = null
      }

      if (params.computed.length) {
        params.computed = `computed=${toBase64(
          params.computed.map((item) => encodeFilter(item))
        )}`
      } else {
        params.computed = null
      }

      const search_params = [params.filter, params.computed]
        .filter((param) => param)
        .join('&')

      localStorage.setItem(pathname, search_params)
      setSearchParams(search_params, { replace: true })
    },
    [setSearchParams, urlFilters, pathname]
  )

  return { setFilterUrl }
}
