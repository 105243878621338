import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const TRANSLATION_LOCATION = 'components.footer.'

const Footer = ({ className }) => {
  const { t } = useTranslation()

  return (
    <footer className={className}>
      <span className='block text-sm text-gray-900'>
        {`© ${new Date().getFullYear()} Aivy GmbH`}
      </span>
      <span className='block text-sm text-gray-900 ml-4'>
        <a
          href='https://www.aivy.app/impressum'
          target='_blank'
          rel='noreferrer'
        >
          {t(TRANSLATION_LOCATION + 'imprint')}
        </a>
        {' | '}
        <a
          href='https://www.aivy.app/datenschutz'
          target='_blank'
          rel='noreferrer'
        >
          {t(TRANSLATION_LOCATION + 'data_protection')}
        </a>
      </span>
    </footer>
  )
}

Footer.propTypes = {
  className: PropTypes.string
}

Footer.defaultProps = {
  className:
    'flex justify-between items-center w-full h-20 bg-gray-50 px-4 sm:px-6 lg:px-8'
}

export default Footer
