import PropTypes from 'prop-types'
import {
  ExclamationTriangleIcon,
  XCircleIcon,
  CheckCircleIcon,
  InformationCircleIcon
} from '@heroicons/react/24/solid'

import { classNames } from '../helpers'

const types = {
  warning: {
    Icon: () => <ExclamationTriangleIcon className='h-5 w-5 text-yellow-400' />,
    background: 'bg-yellow-50',
    titleColor: 'text-yellow-800',
    textColor: 'text-yellow-700'
  },
  error: {
    Icon: () => <XCircleIcon className='h-5 w-5 text-red-400' />,
    background: 'bg-red-50',
    titleColor: 'text-red-800',
    textColor: 'text-red-700'
  },
  success: {
    Icon: () => <CheckCircleIcon className='h-5 w-5 text-green-400' />,
    background: 'bg-green-50',
    titleColor: 'text-green-800',
    textColor: 'text-green-700'
  },
  info: {
    Icon: () => <InformationCircleIcon className='h-5 w-5 text-blue-400' />,
    background: 'bg-blue-50',
    titleColor: 'text-blue-800',
    textColor: 'text-blue-700'
  }
}

const Alert = ({ type, title, description }) => {
  const { Icon, background, titleColor, textColor } = types[type]

  return (
    <div className={classNames('rounded-md p-4', background)}>
      <div className='flex'>
        <div className='flex-shrink-0'>
          <Icon />
        </div>
        <div className='ml-2'>
          <h3 className={classNames('text-sm font-medium', titleColor)}>
            {title}
          </h3>
          {description && (
            <div className={classNames('mt-1 text-sm', textColor)}>
              <p>{description}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

Alert.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string
}

Alert.defaultProps = {
  type: 'warning',
  description: null
}

export default Alert
