import PropTypes from 'prop-types'
import { classNames } from '../../helpers'
/**
 * TailwindUI Application UI / Layout / Badge
 * Supporting Basic badges, large badges
 * https://tailwindui.com/components/application-ui/layout/panels#component-31e2b25b60c9773cc3cb2446975b65bf
 */
const Panel = ({ className, children, onClick }) => (
  <div
    className={classNames(
      'overflow-hidden rounded-lg bg-gray-50 w-full',
      className,
      onClick && 'hover:bg-gray-100 cursor-pointer'
    )}
    onClick={onClick}
  >
    <div className='px-4 py-5 sm:p-6'>{children}</div>
  </div>
)

Panel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func
}

Panel.defaultProps = {
  className: undefined,
  onClick: undefined
}

export default Panel
