import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Button from '../tailwind/Button'
import { useSetState } from '../../hooks/use-set-state'
import { useNotificationContext } from '../../context'
import { useNavigate } from 'react-router'
import SelectMenu from '../select-menu'
import { trackEvent } from '../../helpers/analytics'
import trackEvents from '../../constants/track-events'

const LIKERT_SCALE = {
  0: 'team_analyse.new.research_likert_scale_0',
  1: 'team_analyse.new.research_likert_scale_1',
  2: 'team_analyse.new.research_likert_scale_2',
  3: 'team_analyse.new.research_likert_scale_3',
  4: 'team_analyse.new.research_likert_scale_4'
}

const QUESTIONS = [
  {
    id: '1',
    question: 'team_analyse.new.research_questions_1',
    scale: LIKERT_SCALE
  },
  {
    id: '2',
    question: 'team_analyse.new.research_questions_2',
    scale: LIKERT_SCALE
  },
  {
    id: '3',
    question: 'team_analyse.new.research_questions_3',
    scale: LIKERT_SCALE
  },
  {
    id: '4',
    question: 'team_analyse.new.research_questions_4',
    scale: LIKERT_SCALE
  },
  {
    id: '5',
    question: 'team_analyse.new.research_questions_5',
    scale: LIKERT_SCALE
  },
  {
    id: '6',
    question: 'team_analyse.new.research_questions_6',
    scale: LIKERT_SCALE
  },
  {
    id: '7',
    question: 'team_analyse.new.research_questions_7',
    scale: LIKERT_SCALE
  },
  {
    id: '8',
    question: 'team_analyse.new.research_questions_8',
    scale: LIKERT_SCALE
  }
]

const Research = ({ analyse, analyseMutation }) => {
  const { t } = useTranslation()
  const { alert } = useNotificationContext()

  const navigate = useNavigate()

  const [submitting, setSubmitting] = useState(null)
  const [answers, setAnswers] = useSetState(
    analyse.research_answers || { duration: '0-1', remote: 'NO' }
  )

  const next = () => {
    alert(
      t('team_analyse.new.research.modal_title'),
      t('team_analyse.new.research.modal_description'),
      [
        {
          onPress: () => {
            setSubmitting('next')

            const input = {
              id: analyse.id,
              input: JSON.stringify({
                ...JSON.parse(analyse.input),
                status: 'ACTIVE',
                research_answers: answers
              })
            }

            analyseMutation.mutate(
              { input },
              {
                onSuccess: ({ id }) => {
                  setSubmitting(null)
                  trackEvent(trackEvents.COMPLETE_CREATE_TEAMANALYSIS)
                  navigate(`/team-analyse/${id}`, {
                    state: { showIntro: true },
                    replace: true
                  })
                },
                onError: () => setSubmitting(null)
              }
            )
          },
          text: t('team_analyse.new.research.modal_submit_action')
        },
        {
          text: t('team_analyse.new.research.modal_cancel_action'),
          style: 'cancel'
        }
      ]
    )
  }

  const back = () => {
    setSubmitting('back')

    const input = {
      id: analyse.id,
      input: JSON.stringify({
        ...JSON.parse(analyse.input),
        status: 'WAIT_SPACES',
        research_answers: answers
      })
    }

    analyseMutation.mutate(
      { input },
      {
        onSuccess: () => setSubmitting(null),
        onError: () => setSubmitting(null)
      }
    )
  }

  return (
    <div className='mx-auto max-w-2xl pb-32'>
      <span className='text-3xl font-extrabold tracking-tight text-gray-900'>
        {t('team_analyse.new.research.title')}
      </span>
      <p className='mt-4 max-w-lg text-sm  bg-primaryBlue p-4 text-white rounded-xl'>
        {t('team_analyse.new.research.description')}
      </p>
      <div className='mt-12 flex flex-col gap-y-8'>
        {QUESTIONS.map(({ id: question_id, question, scale }, index) => (
          <div key={index} className='py-4'>
            <span className='block text-base text-gray-900'>{t(question)}</span>
            <div className='relative mt-2 md:flex justify-between px-4 '>
              {Object.keys(scale).map((scale_key, index) => (
                <div
                  key={index}
                  onClick={() => setAnswers({ [question_id]: scale_key })}
                  className='md:flex flex-col items-center cursor-pointer md:mt-0 mt-2'
                >
                  <div className='inline-block md:flex rounded-full w-6 h-6 bg-white justify-center items-center cursor-pointer'>
                    <input
                      type='radio'
                      checked={answers[question_id] === scale_key}
                      readOnly
                      className='focus:ring-white h-4 w-4 text-blue-600 border-gray-300 cursor-pointer'
                    />
                  </div>
                  <label className='text-xs text-gray-700 font-semibold cursor-pointer'>
                    {t(scale[scale_key])}
                  </label>
                </div>
              ))}
              <div
                // 10 - between top-2 & top-3
                style={{ top: 10 }}
                className='hidden md:block absolute w-full h-1 bg-gray-200 left-0 -z-10'
              />
            </div>
          </div>
        ))}
      </div>
      <div className='mt-12 flex-col'>
        <SelectMenu
          id='remote'
          label={t('team_analyse.new.research_home_office')}
          options={[
            ['YES', t('global.yes')],
            ['NO', t('global.no')]
          ]}
          translate={false}
          defaultValue={answers.remote}
          onChange={(option) => setAnswers({ remote: option })}
        />
        <SelectMenu
          className={'mt-8'}
          id='duration'
          label={t('team_analyse.new.research_work_length')}
          options={[
            ['0-1', t('team_analyse.new.research_work_length_select_option_1')],
            ['1-2', t('team_analyse.new.research_work_length_select_option_2')],
            ['2-3', t('team_analyse.new.research_work_length_select_option_3')],
            ['3-4', t('team_analyse.new.research_work_length_select_option_4')],
            ['4-X', t('team_analyse.new.research_work_length_select_option_5')]
          ]}
          translate={false}
          defaultValue={answers.duration}
          onChange={(option) => setAnswers({ duration: option })}
        />
      </div>
      <div className='mt-12 sm:mt-16 flex justify-end gap-x-2'>
        <Button.SecondaryLG
          onClick={back}
          text={t('team_analyse.new.back_action')}
          isLoading={submitting === 'back'}
        />
        <Button.PrimaryLG
          text={t('team_analyse.new.finish_action')}
          onClick={next}
          disabled={Object.keys(answers).length !== QUESTIONS.length + 2}
          isLoading={submitting === 'next'}
        />
      </div>
    </div>
  )
}

Research.propTypes = {
  analyse: PropTypes.object.isRequired,
  analyseMutation: PropTypes.object.isRequired
}

export default Research
