import { CAREER_HISTORY } from './career-history'
import { MAIL_TEMPLATE } from './mail-template'

export const CAREER = `{
  id
  name
  title
  status
  archived
  history ${CAREER_HISTORY}
  is_available_in_attract
  was_seen_times_in_attract_funnel
  logo
  photo_url
  language
  category
  partner_id
  authorized_create
  authorized_read
  authorized_update
  authorized_delete
  partner_name
  external_link
  related_career
  riasec_id
  R
  I
  A
  S
  E
  C
  app_settings {
    components
    challenges
    application_input_additional_fields
    custom_apply_mail_template
    mail_template ${MAIL_TEMPLATE}
    mail_template_reminder ${MAIL_TEMPLATE}
    mail_template_reject ${MAIL_TEMPLATE}
    usedAlgorithm
    hello_text
    custom_data_policy
    notifications {
      webhook
      mail
    }
  }
  forced_dimension
  challenge_config_id
  career_analyse
  createdAt
  updatedAt
  redirect_url
  copy_from_career_id
  external_custom_id
}`
