import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

const BigDivider = ({ percentage }) => {
  return (
    <div
      style={{ left: `calc(${percentage}% - 2px)`, bottom: -10 }}
      className='absolute h-6 w-1 bg-gray-200 rounded' // height: 24px
    />
  )
}

BigDivider.propTypes = { percentage: PropTypes.number.isRequired }

const Average = ({ percentage }) => {
  const { t } = useTranslation()
  return (
    <>
      <div
        style={{ left: `calc(${percentage}% - 2px)`, bottom: -10 }}
        className='absolute h-6 w-1 bg-orange-300 rounded' // height: 32px
      />
      <span
        style={{ left: `calc(${percentage}% - 34px)`, bottom: -28 }}
        className='absolute text-xs text-gray-500'
      >
        {t('team_analyse.linear_chart.average_label')}
      </span>
    </>
  )
}

Average.propTypes = { percentage: PropTypes.number.isRequired }

function calculateAverage(numbers) {
  if (numbers.length === 0) {
    return 0 // Return 0 if the array is empty to avoid division by zero
  }

  const sum = numbers.reduce((acc, num) => acc + num, 0)
  return sum / numbers.length
}

const LinearDimensionExample = () => {
  const { t } = useTranslation()

  const datasets = [
    {
      id: 'd03dbbaa-436c-4937-9866-cd9404675374',
      data: {
        exam_id: 'RIASEC_IMAGES',
        score: 'elevation',
        result: 72
      },
      borderWidth: 1,
      backgroundColor: 'rgba(190, 46, 221,0.2)',
      borderColor: 'rgba(190, 46, 221,1.0)'
    },
    {
      id: '74dc433b-1f20-4a9a-ac3f-49011a64361d',
      hint: t('team_analyse.linear_chart.fit_label'),
      data: {
        exam_id: 'RIASEC_IMAGES',
        score: 'elevation',
        result: 36
      },
      borderWidth: 1,
      backgroundColor: 'rgba(186, 220, 88,0.2)',
      borderColor: 'rgba(186, 220, 88,1.0)'
    }
  ]

  return (
    <div className='w-96 flex items-center justify-center'>
      <div
        className='w-full relative h-1 bg-gray-200 rounded' // height: 4px
      >
        <BigDivider percentage={50} />
        <Average
          percentage={calculateAverage(
            datasets.map((item) => item.data.result)
          )}
        />
        {datasets.map((item, index) => {
          const {
            hint,
            borderColor,
            backgroundColor,
            data: { result }
          } = item

          return (
            <Fragment key={index}>
              <div
                style={{
                  left: `calc(${result}% - 8px)`,
                  bottom: -8,
                  backgroundColor: 'white'
                }}
                className='absolute w-5 h-5 rounded-full' // height: 20px
              >
                <div
                  style={{ backgroundColor }}
                  className='w-5 h-5 rounded-full p-1'
                >
                  <div
                    style={{ backgroundColor: borderColor }}
                    className='w-3 h-3 rounded-full'
                  />
                </div>
              </div>
              {hint && (
                <span
                  style={{ left: `calc(${result}% - 56px)`, bottom: 16 }}
                  className='absolute text-xs text-gray-500'
                >
                  {hint}
                </span>
              )}
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}

export default LinearDimensionExample
