import { useState } from 'react'
import { format } from 'date-fns'
import { de, enUS as en } from 'date-fns/locale'
import { useTranslation } from 'react-i18next'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router'
import PropTypes from 'prop-types'

import { getJobCategory } from '../../../constants/job-categories'
import { useLanguageContext, usePartnerContext } from '../../../context'
import { ActivityIndicator } from '../..'
import { query } from '../../../graphql'
import Button from '../../tailwind/Button'

const SelectCareer = ({ career }) => {
  const { t } = useTranslation()
  const { partner } = usePartnerContext()
  const { language } = useLanguageContext()

  const [selectedCareerId, setSelectedCareerId] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { data: careers, status } = useQuery({
    queryKey: ['careers-with-analyses', partner.id],
    queryFn: async () => {
      const careers_response = await query({
        query: 'careerByPartnerByDate',
        variables: { partner_id: partner.id }
      })

      const active_careers = careers_response.filter(
        ({ status, archived }) => status === 'ACTIVE' && !archived
      )

      for (const career of active_careers) {
        const analyses = await query({
          query: 'careerAnalyseByCareer',
          variables: { career_id: career.id }
        })

        career.analyses = analyses
      }

      const careers_with_analyses = active_careers.filter(
        ({ analyses }) => !!analyses.length
      )

      return careers_with_analyses
    }
  })

  if (status === 'pending') return <ActivityIndicator />

  const copyCareerAnalyses = async () => {
    setIsLoading(true)

    try {
      await query({
        query: 'careerControl',
        variables: {
          action: 'copyCareerAnalysesFromCareerToCareer',
          from_career_id: selectedCareerId,
          to_career_id: career.id
        }
      })

      await queryClient.fetchQuery({
        queryKey: ['career-analyses', career.id],
        queryFn: () =>
          query({
            query: 'careerAnalyseByCareer',
            variables: { career_id: career.id }
          })
      })
    } catch (err) {
      return
    }

    navigate('#invite-experts', { state: { displayCopyAnalysesSuccess: true } })
  }

  return (
    <>
      <div className='mt-5'>
        <span className='text-3xl font-extrabold tracking-tight text-gray-900'>
          {t('create_career.career_analyse.select_career.title')}
        </span>
      </div>
      <div className='mt-4 max-w-lg'>
        <p className='text-sm text-gray-900'>
          {t('create_career.career_analyse.select_career.description')}
        </p>
      </div>
      <ul role='list' className='mt-8 divide-y divide-gray-200'>
        {careers.map((item, index) => (
          <li
            key={index}
            onClick={() => setSelectedCareerId(item.id)}
            className='relative group flex gap-x-6 px-4 py-3 hover:bg-gray-50 cursor-pointer sm:px-6 lg:px-8'
          >
            <div className='flex gap-x-4 pr-6 sm:w-1/2 sm:flex-none'>
              {/* <img
              className='h-12 w-12 flex-none rounded-full object-contain bg-gray-50'
              src={item.logo || partner.logo}
              alt=''
            /> */}
              <div className='flex flex-col justify-center'>
                <p className='text-sm font-semibold leading-6 text-gray-900'>
                  {item.title}
                </p>
                <p className='mt-px flex text-xs leading-5 text-gray-500'>
                  {t(getJobCategory(item.category || '-1'))}
                </p>
              </div>
            </div>
            <div className='w-full flex items-center justify-between gap-x-4'>
              <div className='hidden sm:block'>
                {/* <p className='text-sm leading-6 text-gray-900'>
                {t(getJobCategory(item.category || '-1'))}
              </p> */}
                <div>
                  <span className='text-xs text-gray-500 italic'>
                    {t(
                      'create_career.career_analyse.select_career.last_updated_at'
                    )}
                  </span>
                  <span className='ml-1 text-sm text-gray-900'>
                    {format(new Date(item.updatedAt), 'd. MMMM yyyy', {
                      locale: { de, en }[language]
                    })}
                  </span>
                </div>
              </div>
              {selectedCareerId === item.id ? (
                <CheckCircleIcon
                  className='h-6 w-6 flex-none text-green-500'
                  aria-hidden='true'
                />
              ) : (
                <>
                  <div className='group-hover:hidden p-1 h-6 w-6 flex-none rounded-full bg-gray-200'>
                    <div className='h-4 w-4 flex-none rounded-full bg-white'></div>
                  </div>
                  <CheckCircleIcon
                    className='hidden group-hover:block h-6 w-6 flex-none text-gray-300'
                    aria-hidden='true'
                  />
                </>
              )}
            </div>
          </li>
        ))}
        {!careers.length && (
          <p className='pt-2 text-gray-700 text-sm italic max-w-lg'>
            {t('create_career.career_analyse.select_career.empty_careers_hint')}
          </p>
        )}
      </ul>
      <div className='mt-8 flex justify-end'>
        <Button.SecondaryXL
          text={t(
            'create_career.career_analyse.select_career.copy_analyses_action'
          )}
          onClick={copyCareerAnalyses}
          isLoading={isLoading}
          disabled={!selectedCareerId}
        />
      </div>
    </>
  )
}

SelectCareer.propTypes = {
  career: PropTypes.object.isRequired
}

export default SelectCareer
