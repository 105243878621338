import { SPACE_LAST_INTERACTION_KEYS } from '../constants/space-last-interaction-keys'
import { APPLICANT_STATUS, getSpaceState } from '../constants/smart-predict'
import {
  ASSESSMENT_SPACE_STATES,
  SMART_PREDICT_SPACE_STATES
} from '../constants/space-states'

const SPACE_STATES = {
  ...ASSESSMENT_SPACE_STATES,
  ...SMART_PREDICT_SPACE_STATES
}

const {
  EMAIL_BOUNCED,
  SMART_PREDICT,
  FULFILLED,
  EXPIRED_VIA_EMAIL,
  EXPIRED_VIA_LINK,
  STARTED_VIA_EMAIL,
  STARTED_VIA_LINK,
  RESET_EXPIRED_VIA_EMAIL,
  RESET_EXPIRED_VIA_LINK,
  REMINDED,
  INVITED_VIA_EMAIL,
  INVITED_VIA_LINK
} = SPACE_LAST_INTERACTION_KEYS

const EMPTY_STATE = {
  key: 'EMPTY_STATE',
  status: { filterValue: 'empty', sortWeight: '9' }
}

export const getSpacesTableData = ({ spaces = [] }) => {
  return spaces.map((space) => mapSpaceForSpacesTable({ space }))
}

const mapSpaceForSpacesTable = ({ space }) => {
  const result = { ...space }

  const { id, partner_user_id, person, context, career_id } = space

  const assessment_status = getAssessmentStatus({ space })
  const smart_predict_status = getSmartPredictStatus({ space })
  const created_status = getCreatedStatus({ space })

  result.score = getScore(space)
  result.assessment_status = assessment_status
  result.smart_predict_status = smart_predict_status
  result.status = { assessment_status, smart_predict_status }
  result.created_status = created_status
  result.identification = partner_user_id || id
  result.via = 'LINK'

  if (context === 'SELECT') result.assessment_id = career_id
  else if (context === 'ATTRACT') result.assessment_id = 'ATTRACT'
  else if (context === 'TALENTPOOL') result.assessment_id = 'TALENTPOOL'

  if (!person) return result

  // pbc-space could come with for example person: { firstname: 'Max' }
  const space_has_email = !!(person.email || person.invitation_email)

  result.firstname = person.invitation_firstname || person.firstname
  result.lastname = person.invitation_lastname || person.lastname

  const { firstname, lastname } = result
  const email = person.invitation_email || person.email

  result.email = email
  result.identification = email
  result.phone = person.phone
  result.via = space_has_email ? 'EMAIL' : 'LINK'
  result.name = getName(firstname, lastname)
  result.initials = getInitials(result.name)

  return result
}

const getSmartPredictStatus = ({ space }) => {
  const { progress, history } = space

  const applicantStatusHistory = (history || [])
    // important!
    .filter(({ eventName }) => eventName === APPLICANT_STATUS)
    .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))

  if (progress === 100) {
    const event = applicantStatusHistory[applicantStatusHistory.length - 1]

    if (!event) {
      return {
        key: SMART_PREDICT,
        action: event ? null : 'setSpaceForStateCTA',
        status: getSpaceState('NOT_SET')
      }
    }

    if (event.afterStatus === 'REJECTED_VIA_EMAIL') {
      event.afterStatus = 'REJECTED'
    }

    if (event.afterStatus === 'OPEN') {
      event.afterStatus = 'NOT_SET'
    }

    return {
      key: SMART_PREDICT,
      status: getSpaceState(event.afterStatus)
    }
  }

  return EMPTY_STATE
}

const getAssessmentStatus = ({ space }) => {
  const {
    progress,
    expiresAt,
    reset_expiredAt_at,
    invitation_reminder_at,
    person,
    history
  } = space

  if (progress === 100) {
    return {
      key: FULFILLED,
      status: {
        label: 'talents.state_fulfilled',
        ...SPACE_STATES.FULFILLED,
        progress
      }
    }
  }

  const space_has_email = !!(person?.email || person?.invitation_email)

  const emailBouncedEvent = (history || []).find(
    ({ eventName, afterStatus }) =>
      eventName === 'SYSTEM' && afterStatus === 'EMAIL_BOUNCED'
  )

  if (emailBouncedEvent) {
    return {
      key: EMAIL_BOUNCED,
      status: {
        label: 'talents.state_email_bounced',
        ...SPACE_STATES.EMAIL_BOUNCED
      }
    }
  }

  if (expiresAt && new Date() > new Date(expiresAt)) {
    return {
      key: space_has_email ? EXPIRED_VIA_EMAIL : EXPIRED_VIA_LINK,
      status: {
        label: 'talents.state_expired',
        ...SPACE_STATES.EXPIRED
      }
    }
  }

  // added on 11/2023
  const remindedEvent = (history || []).find(
    ({ eventName, afterStatus }) =>
      eventName === 'SYSTEM' && afterStatus === 'REMINDED'
  )

  // remove invitation_reminder_at in 2025 maybe
  if (invitation_reminder_at || remindedEvent) {
    return {
      key: REMINDED,
      status: {
        label: 'talents.state_reminded',
        ...SPACE_STATES.REMINDED
      }
    }
  }

  // added on 11/2023
  const resetExpiredEvent = (history || []).find(
    ({ eventName, afterStatus }) =>
      eventName === 'SYSTEM' && afterStatus === 'RESET_EXPIRED'
  )

  // remove reset_expiredAt_at in 2025 maybe
  if (reset_expiredAt_at || resetExpiredEvent) {
    return {
      key: space_has_email ? RESET_EXPIRED_VIA_EMAIL : RESET_EXPIRED_VIA_LINK,
      status: {
        label: 'talents.state_reset',
        ...SPACE_STATES.RESET
      }
    }
  }

  if (progress > 0) {
    return {
      key: space_has_email ? STARTED_VIA_EMAIL : STARTED_VIA_LINK,
      status: {
        label: 'talents.state_started',
        progress,
        ...SPACE_STATES.STARTED
      }
    }
  }

  return {
    key: space_has_email ? INVITED_VIA_EMAIL : INVITED_VIA_LINK,
    status: {
      label: 'talents.state_invited',
      ...SPACE_STATES.INVITED
    }
  }
}

const SIX_MONTH_BACK = Date.now() - 1000 * 60 * 60 * 24 * 30 * 6

const getCreatedStatus = ({ space }) => {
  const result = { createdAt: space.createdAt }

  if (Date.parse(space.createdAt) < SIX_MONTH_BACK) {
    result.filterValue = 'BEFORE_6_MONTH'
  }

  return result
}

const getInitials = (name) => {
  if (!name) return ''

  return name.includes(' ') ? name : name[0]
}

const getName = (firstname, lastname) => {
  if (!firstname && !lastname) return undefined
  if (firstname && !lastname) return firstname
  if (!firstname && lastname) return lastname

  return `${firstname} ${lastname}`
}

const getScore = (space) => {
  return typeof space.matching_score_to_space_career === 'number'
    ? Number(space.matching_score_to_space_career.toFixed(0))
    : '-'
}
