export const CREATE_CAREER = 'CREATE_CAREER'
export const UPDATE_STATUS = 'UPDATE_STATUS'
export const UPDATE_ARCHIVED = 'UPDATE_ARCHIVED'
export const UPDATE_CHALLENGES = 'UPDATE_CHALLENGES'
// export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'
// export const UPDATE_TITLE = 'UPDATE_TITLE'
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS'
export const UPDATE_PRESET = 'UPDATE_PRESET'

export const updateCareerHistory = (history, eventName, before, after, sub) => {
  const event = {
    eventName,
    before,
    after,
    createdAt: new Date().toISOString(),
    message: null,
    eventFiredBy: sub
  }

  return (history || []).concat([event])
}
