import { useMemo, useRef } from 'react'
// import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import Input from '../input'
import { getAllJobCategories } from '../../constants/job-categories'
import SelectMenu from '../select-menu'
// import SelectRiasecSearchInput from '../select-riasec-search-input'
import { trackEvent } from '../../helpers/analytics'
import { notifyBugsnag, uuidSmall } from '../../helpers'
import {
  updateCareerHistory,
  UPDATE_STATUS
} from '../../helpers/career-history'
import { DE, getAllLanguages } from '../../constants/languages'
import trackEvents from '../../constants/track-events'
import { mutation, query } from '../../graphql'
import Button from '../tailwind/Button'
import { useMount } from '../../hooks/use-mount'
import {
  useUserContext,
  usePartnerContext,
  usePaymentContext,
  useProductTourContext,
  useNotificationContext
} from '../../context'
import { getCreateCareerInput } from '../../helpers/career'

const TRANSLATION_LOCATION = 'components.careers.create_career_dialog.'

const CreateNewCareer = ({ career, careerMutation, isDuplicate }) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { is_trial } = usePaymentContext()
  const { error } = useNotificationContext()

  const externalIdRef = useRef(career.external_custom_id || '')

  const { partner } = usePartnerContext()
  const { cognitoUser } = useUserContext()

  const {
    setProductTourState,
    productTourState: { tourActive }
  } = useProductTourContext()

  useMount(() => {
    if (tourActive) {
      setTimeout(() => {
        setProductTourState({ run: true })
      }, 0.4 * 1000)
    }
  })

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const external_id_placeholder = useMemo(uuidSmall, [])

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      title: career.title || '',
      category: career.category || '-1',
      language: career.language || DE,
      external_id: externalIdRef.current
    },
    validationSchema: Yup.object({
      title: Yup.string().required(
        t(TRANSLATION_LOCATION + 'input_career_title_validation')
      ),
      external_id: Yup.string().matches(
        /^[a-zA-Z0-9-]+$/,
        t(TRANSLATION_LOCATION + 'input_external_id_validation')
      )
    })
  })

  const checkIfExternalIdExists = async ({ external_id }) => {
    const items = await query({
      query: 'careerByPartnerByDate',
      variables: {
        partner_id: partner.id
      }
    })

    return !!items.find(
      ({ external_custom_id }) => external_custom_id === external_id
    )
  }

  const getUpdateCareerInput = ({ title, category, language }) => {
    return {
      id: career.id,
      title,
      category,
      language,
      status: isDuplicate ? 'ACTIVE' : 'WAIT_EXPERTS',
      history: updateCareerHistory(
        career.history,
        UPDATE_STATUS,
        career.status,
        isDuplicate ? 'ACTIVE' : 'WAIT_EXPERTS',
        cognitoUser.username
      )
    }
  }

  const recreateCareer = async ({ title, category, language, external_id }) => {
    const createCareerInput = getCreateCareerInput({
      title,
      category,
      language,
      partner_id: partner.id,
      username: cognitoUser.username,
      status: isDuplicate ? 'ACTIVE' : 'WAIT_EXPERTS'
    })

    const copyCareerInput = {}
    copyCareerInput.app_settings = career.app_settings
    copyCareerInput.copy_from_career_id = career.copy_from_career_id
    copyCareerInput.challenge_config_id = career.challenge_config_id
    copyCareerInput.redirect_url = career.redirect_url
    copyCareerInput.career_analyse = career.career_analyse

    try {
      const { id: new_career_id } = await mutation({
        mutation: 'createCareer',
        input: {
          id: external_id ? `${external_id}-p-${partner.id}` : undefined,
          external_custom_id: external_id || undefined,
          ...createCareerInput,
          ...copyCareerInput
        }
      })

      await query({
        query: 'careerControl',
        variables: {
          action: 'copyCareerAnalysesFromCareerToCareer',
          from_career_id: career.id,
          to_career_id: new_career_id
        }
      })

      await query({
        query: 'careerControl',
        variables: {
          action: 'deleteCareerAndAnalyses',
          career_id: career.id
        }
      })

      return new_career_id
    } catch (err) {
      error()
      notifyBugsnag(err)
      return null
    }
  }

  const handleSubmit = async ({ title, category, language, external_id }) => {
    formik.setSubmitting(true)

    const externalIdExists =
      external_id !== externalIdRef.current
        ? await checkIfExternalIdExists({ external_id })
        : false

    if (externalIdExists) {
      formik.setSubmitting(false)
      formik.setFieldError(
        'external_id',
        t(TRANSLATION_LOCATION + 'input_external_id_already_in_use')
      )

      return
    }

    if (is_trial) {
      queryClient.refetchQueries({
        queryKey: ['trial-data', partner.id],
        type: 'active'
      })
    }

    if (!id) {
      // create a new career

      let new_career
      try {
        new_career = await mutation({
          mutation: 'createCareer',
          input: {
            id: external_id ? `${external_id}-p-${partner.id}` : undefined,
            external_custom_id: external_id || undefined,
            ...getCreateCareerInput({
              title,
              category,
              language,
              partner_id: partner.id,
              username: cognitoUser.username,
              status: 'WAIT_EXPERTS'
            })
          }
        })
      } catch (err) {
        formik.setSubmitting(false)
        return
      }

      navigate(`/career/new/${new_career.id}`, { replace: true })
    } else {
      if (external_id !== externalIdRef.current) {
        // recreate an existing career

        const new_career_id = await recreateCareer({
          title,
          category,
          language,
          external_id
        })

        if (!new_career_id) {
          formik.setSubmitting(false)
          return
        }

        const path = isDuplicate ? '/career/' : '/career/new/'

        navigate(`${path}${new_career_id}`, { replace: true })
      } else {
        // update the career

        careerMutation.mutate(
          {
            input: getUpdateCareerInput({ title, category, language })
          },
          {
            onSuccess: (data) => {
              if (isDuplicate) navigate(`${'/career/'}${data.id}`)
            },
            onError: () => formik.setSubmitting(false)
          }
        )
      }
    }
  }

  // const setSelectedJobCallback = useCallback((item) => setSelectedJob(item), [])

  return (
    <div className='mx-auto max-w-3xl'>
      <span className='text-3xl font-extrabold tracking-tight text-gray-900'>
        {t(TRANSLATION_LOCATION + 'dialog_title')}
      </span>
      <p className='mt-4 max-w-lg text-sm text-gray-900'>
        {t(TRANSLATION_LOCATION + 'dialog_description')}
      </p>
      <form className='mt-2 '>
        {[
          {
            id: 'title',
            sr_only: t(
              TRANSLATION_LOCATION + 'input_career_title_label_sronly'
            ),
            label: t(TRANSLATION_LOCATION + 'input_career_title_label'),
            placeholder: t(
              TRANSLATION_LOCATION + 'input_career_title_placeholder'
            )
          },
          !career.id?.includes('sample') && {
            id: 'external_id',
            sr_only: t(TRANSLATION_LOCATION + 'input_external_id_label_sronly'),
            label: t(TRANSLATION_LOCATION + 'input_external_id_label'),
            placeholder: external_id_placeholder,
            description: t(
              TRANSLATION_LOCATION + 'input_external_id_description'
            )
          }
        ]
          .filter((item) => item)
          .map((input, index) => (
            <div key={index} className='mt-6'>
              <label htmlFor={input.id} className='sr-only'>
                {input.sr_only}
              </label>
              <Input
                {...input}
                onChange={formik.handleChange}
                value={formik.values[input.id]}
                error={formik.errors[input.id]}
                touched={formik.touched[input.id]}
                onBlur={formik.handleBlur}
              />
            </div>
          ))}
        <div id='product-tour-language' className='mt-6'>
          <SelectMenu
            id='language'
            label={t(TRANSLATION_LOCATION + 'language_label')}
            options={getAllLanguages()}
            defaultValue={formik.values.language}
            onChange={(language) => formik.setFieldValue('language', language)}
          />
        </div>
        <div className='mt-6'>
          <SelectMenu
            id='career_category'
            label={t(TRANSLATION_LOCATION + 'category_label')}
            options={getAllJobCategories()}
            defaultValue={formik.values.category}
            onChange={(category) => formik.setFieldValue('category', category)}
          />
        </div>
        {/* <div className='mt-6'>
            <SelectRiasecSearchInput
              callback={setSelectedJobCallback}
              optional
            />
          </div> */}
        <div className='mt-8 sm:mt-12 flex justify-end gap-x-2'>
          <Button.SecondaryLG
            onClick={() => {
              trackEvent(trackEvents.ABORT_CREATE_CAREER)
              navigate('/careers')
            }}
            text={t('create_career.cancel_action')}
          />
          <Button.PrimaryLG
            text={
              isDuplicate
                ? t('create_career.overview.submit_action')
                : t('create_career.next_action')
            }
            onClick={() => handleSubmit(formik.values)}
            disabled={!formik.isValid}
            isLoading={formik.isSubmitting}
          />
        </div>
      </form>
    </div>
  )
}

CreateNewCareer.propTypes = {
  career: PropTypes.object.isRequired,
  careerMutation: PropTypes.object.isRequired,
  isDuplicate: PropTypes.bool.isRequired
}

export default CreateNewCareer
