export const career_analyse = JSON.stringify({
  career_preset_id: '11-2021.00',
  count: 3,
  dimensions: [
    'ANALOGICAL_REASONING_score',
    'DIGIT_SPAN_score',
    'EMOTIONS_GAME_score',
    'FLANKER_score',
    'HEROS_JOURNEY_score_coopcomp',
    'HEROS_JOURNEY_score_orderchaos',
    'NBACK_score',
    'PATH_FINDER_score',
    'RIASEC_IMAGES_a_score',
    'RIASEC_IMAGES_c_score',
    'RIASEC_IMAGES_e_score',
    'RIASEC_IMAGES_i_score',
    'RIASEC_IMAGES_r_score',
    'RIASEC_IMAGES_s_score',
    'TOWER_OF_LONDON_score'
  ],
  icc_score: 1,
  scores: {
    ANALOGICAL_REASONING_score: {
      deviate: 6.12825877028341,
      importance: 1,
      inputValues: [64.6666666666667, 64.6666666666667, 64.6666666666667],
      lowerBoundCI: 52.4101491260998,
      max: 67.73,
      min: 61.6,
      optimal: 64.6666666666667,
      uncertaintyWeight: 0.754869649188663,
      upperBoundCI: 76.9231842072335
    },
    DIGIT_SPAN_score: {
      deviate: 0,
      importance: 1,
      inputValues: [50, 50, 50],
      lowerBoundCI: 50,
      max: 50,
      min: 50,
      optimal: 50,
      uncertaintyWeight: 1,
      upperBoundCI: 50
    },
    EMOTIONS_GAME_score: {
      deviate: 0,
      importance: 1,
      inputValues: [66.6666666666667, 66.6666666666667, 66.6666666666667],
      lowerBoundCI: 66.6666666666667,
      max: 66.67,
      min: 66.67,
      optimal: 66.6666666666667,
      uncertaintyWeight: 1,
      upperBoundCI: 66.6666666666667
    },
    FLANKER_score: {
      deviate: 3.06412938514171,
      importance: 1,
      inputValues: [49, 49, 49],
      lowerBoundCI: 42.8717412297166,
      max: 50.53,
      min: 47.47,
      optimal: 49,
      uncertaintyWeight: 0.877434824594332,
      upperBoundCI: 55.1282587702834
    },
    HEROS_JOURNEY_score_coopcomp: {
      deviate: 4.03312756676772,
      importance: 1,
      inputValues: [22.1666666666667, 22.1666666666667, 22.1666666666667],
      lowerBoundCI: 14.1004115331312,
      max: 24.18,
      min: 20.15,
      optimal: 22.1666666666667,
      uncertaintyWeight: 0.838674897329291,
      upperBoundCI: 30.2329218002021
    },
    HEROS_JOURNEY_score_orderchaos: {
      deviate: 4.03312756676772,
      importance: 1,
      inputValues: [77.8333333333333, 77.8333333333333, 77.8333333333333],
      lowerBoundCI: 69.7670781997979,
      max: 79.85,
      min: 75.82,
      optimal: 77.8333333333333,
      uncertaintyWeight: 0.838674897329291,
      upperBoundCI: 85.8995884668688
    },
    NBACK_score: {
      deviate: 6.12825877028341,
      importance: 1,
      inputValues: [48, 48, 48],
      lowerBoundCI: 35.7434824594332,
      max: 51.06,
      min: 44.94,
      optimal: 48,
      uncertaintyWeight: 0.754869649188664,
      upperBoundCI: 60.2565175405668
    },
    PATH_FINDER_score: {
      deviate: 8.9566858950296,
      importance: 1,
      inputValues: [50, 50, 50],
      lowerBoundCI: 32.0866282099408,
      max: 54.48,
      min: 45.52,
      optimal: 50,
      uncertaintyWeight: 0.641732564198816,
      upperBoundCI: 67.9133717900592
    },
    RIASEC_IMAGES_a_score: {
      deviate: 4.03312756676772,
      importance: 1,
      inputValues: [44.5, 44.5, 44.5],
      lowerBoundCI: 36.4337448664646,
      max: 46.52,
      min: 42.48,
      optimal: 44.5,
      uncertaintyWeight: 0.838674897329291,
      upperBoundCI: 52.5662551335354
    },
    RIASEC_IMAGES_c_score: {
      deviate: 4.03312756676772,
      importance: 1,
      inputValues: [72.1666666666667, 72.1666666666667, 72.1666666666667],
      lowerBoundCI: 64.1004115331312,
      max: 74.18,
      min: 70.15,
      optimal: 72.1666666666667,
      uncertaintyWeight: 0.838674897329291,
      upperBoundCI: 80.2329218002021
    },
    RIASEC_IMAGES_e_score: {
      deviate: 4.03312756676772,
      importance: 1,
      inputValues: [100, 100, 100],
      lowerBoundCI: 91.9337448664646,
      max: 100,
      min: 97.98,
      optimal: 100,
      uncertaintyWeight: 0.919337448664646,
      upperBoundCI: 100
    },
    RIASEC_IMAGES_i_score: {
      deviate: 4.03312756676772,
      importance: 1,
      inputValues: [22.1666666666667, 22.1666666666667, 22.1666666666667],
      lowerBoundCI: 14.1004115331312,
      max: 24.18,
      min: 20.15,
      optimal: 22.1666666666667,
      uncertaintyWeight: 0.838674897329291,
      upperBoundCI: 30.2329218002021
    },
    RIASEC_IMAGES_r_score: {
      deviate: 4.03312756676772,
      importance: 1,
      inputValues: [0, 0, 0],
      lowerBoundCI: 0,
      max: 2.02,
      min: 0,
      optimal: 0,
      uncertaintyWeight: 0.919337448664646,
      upperBoundCI: 8.06625513353543
    },
    RIASEC_IMAGES_s_score: {
      deviate: 4.03312756676772,
      importance: 1,
      inputValues: [27.8333333333333, 27.8333333333333, 27.8333333333333],
      lowerBoundCI: 19.7670781997979,
      max: 29.85,
      min: 25.82,
      optimal: 27.8333333333333,
      uncertaintyWeight: 0.838674897329291,
      upperBoundCI: 35.8995884668688
    },
    TOWER_OF_LONDON_score: {
      deviate: 4.4783429475148,
      importance: 1,
      inputValues: [54.1666666666667, 54.1666666666667, 54.1666666666667],
      lowerBoundCI: 45.2099807716371,
      max: 56.41,
      min: 51.93,
      optimal: 54.1666666666667,
      uncertaintyWeight: 0.820866282099408,
      upperBoundCI: 63.1233525616963
    }
  }
})

export const app_settings = {
  challenges: JSON.stringify([
    {
      exam_id: 'EMOTIONS_GAME'
    },
    {
      exam_id: 'DIGIT_SPAN'
    },
    {
      exam_id: 'RIASEC_IMAGES'
    },
    {
      exam_id: 'FLANKER'
    },
    {
      exam_id: 'HEROS_JOURNEY'
    },
    {
      exam_id: 'TOWER_OF_LONDON'
    },
    {
      exam_id: 'NBACK'
    },
    {
      exam_id: 'ANALOGICAL_REASONING'
    },
    {
      exam_id: 'PATH_FINDER'
    }
  ])
}

export const tech_1 = {
  id: '55c8ee48-27bd-4cf3-bf54-8b0e9d46f7d7',
  owner: '55c8ee48-27bd-4cf3-bf54-8b0e9d46f7d7',
  language: 'de',
  progress: 82,
  matching_score_to_space_career: null,
  person: {
    id: '55c8ee48-27bd-4cf3-bf54-8b0e9d46f7d7',
    owner: '55c8ee48-27bd-4cf3-bf54-8b0e9d46f7d7',
    invitation_email: 'tech+1@aivy.app',
    email: 'tech+1@aivy.app',
    createdAt: '2023-07-27T12:21:42.915Z',
    updatedAt: '2023-07-27T12:21:42.915Z'
  },
  context: 'SELECT',
  expiresAt: '2027-08-06T12:21:42.337Z',
  createdAt: '2023-07-27T12:21:42.414Z',
  updatedAt: '2023-07-27T12:21:42.414Z'
}

export const tech_2 = {
  id: 'ecc4d08a-e0c8-4cc8-b76d-f1545c8d22e2',
  owner: 'ecc4d08a-e0c8-4cc8-b76d-f1545c8d22e2',
  language: 'de',
  progress: 100,
  matching_score_to_space_career: 78,
  person: {
    id: 'ecc4d08a-e0c8-4cc8-b76d-f1545c8d22e2',
    owner: 'ecc4d08a-e0c8-4cc8-b76d-f1545c8d22e2',
    invitation_email: 'tech+2@aivy.app',
    email: 'tech+2@aivy.app',
    createdAt: '2023-07-27T12:22:02.734Z',
    updatedAt: '2023-07-27T12:22:02.734Z'
  },
  context: 'SELECT',
  expiresAt: '2027-08-06T12:22:02.293Z',
  createdAt: '2023-07-27T12:22:02.293Z',
  updatedAt: '2023-07-27T12:22:02.293Z'
}
