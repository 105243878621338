import { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import PersonHeart from '../../assets/images/illustrations/person-heart.png'
import SelectMenu from '../select-menu'
import Button from '../tailwind/Button'
import Modal from '../tailwind/Modal'
import SpaceInviteLink from './space-invite-link'
import { query } from '../../graphql'
import { useMailTemplates } from '../../hooks/use-mail-templates'
import EditMailTemplate from '../mail-template/edit-mail-template'

const ReinviteSpaceModal = ({ space, updateHandler, open, setOpen }) => {
  const { t } = useTranslation()

  const space_has_email = !!(
    space.person?.email || space.person?.invitation_email
  )

  const [context, setContext] = useState(space_has_email ? 'EMAIL' : 'LINK')

  const [state, setState] = useState('INITIAL')
  const [showMailTemplatePreview, setShowMailTemplatePreview] = useState(false)

  const { mailTemplate, mailTemplateMacros } = useMailTemplates({
    isCrossmode: true
  })

  const reinviteSpace = () => {
    setState('IS_LOADING')

    const variables = {
      space_id: space.id,
      all_challenges_unlocked: true
    }

    if (context === 'EMAIL') {
      variables.email = space.email
      variables.language = space.language
      variables.career_id = space.career_id
      variables.partner_id = space.partner_id
      variables.email_template = 'unlock'
    }

    query({ query: 'inviteTalent', variables })
      .then(() => {
        updateHandler()
        space.all_challenges_unlocked = true

        if (context === 'EMAIL') setState('SUCCESS_VIA_EMAIL')
        else setState('SUCCESS_VIA_LINK')
      })
      .catch(() => setState('INITIAL'))
  }

  const handleClose = () => {
    setTimeout(() => setState('INITIAL'), 0.4 * 1000)
    setOpen(false)
  }

  return (
    <Modal open={open} setOpen={handleClose} size='2xl'>
      <h2 className='text-3xl font-extrabold tracking-tight text-gray-900'>
        {t('spaces.reinvite_space_modal.modal_title')}
      </h2>
      {['INITIAL', 'IS_LOADING'].includes(state) && (
        <>
          <p className='mt-2 max-w-2xl text-sm text-gray-700'>
            {t('spaces.reinvite_space_modal.modal_description_1')}
          </p>
          <div className='mt-4'>
            <div className='mt-6 max-w-2xl'>
              <SelectMenu
                id='context'
                label={t(
                  'spaces.reinvite_space_modal.invite_via_select_menu_label'
                )}
                options={[
                  [
                    'EMAIL',
                    t('spaces.reinvite_space_modal.invite_via_email_option')
                  ],
                  [
                    'LINK',
                    t('spaces.reinvite_space_modal.invite_via_link_option')
                  ]
                ]}
                onChange={setContext}
                defaultValue={context}
                disabled={!space_has_email}
                translate={false}
              />
            </div>
            {context === 'LINK' && (
              <p className='mt-6 max-w-2xl text-sm text-gray-700 italic'>
                {t('spaces.reinvite_space_modal.modal_description_2')}
              </p>
            )}
            {context === 'EMAIL' && (
              <div className='mt-6 flex justify-between items-center max-w-2xl'>
                <div>
                  <span className='block text-sm font-medium text-gray-700'>
                    {t('spaces.reinvite_space_modal.mail_template_label')}
                  </span>
                  <span className='mt-1 block text-sm font-semibold text-gray-900'>
                    {mailTemplate.subject}
                  </span>
                </div>
                <Button.SoftSM
                  text={t(
                    'spaces.reinvite_space_modal.show_preview_mail_template_action'
                  )}
                  onClick={() => setShowMailTemplatePreview(true)}
                />
              </div>
            )}
            <div className='flex mt-8 gap-x-4'>
              <Button.SecondaryXL
                fullWidth
                disabled={state === 'IS_LOADING'}
                onClick={handleClose}
                text={t('spaces.reinvite_space_modal.cancel_action')}
              />
              <Button.PrimaryXL
                fullWidth
                isLoading={state === 'IS_LOADING'}
                text={t(
                  'spaces.reinvite_space_modal.' +
                    (context === 'EMAIL'
                      ? 'invite_action'
                      : 'invite_action_link')
                )}
                onClick={reinviteSpace}
              />
            </div>
          </div>
        </>
      )}
      {['SUCCESS_VIA_EMAIL', 'SUCCESS_VIA_LINK'].includes(state) && (
        <div className='mt-4'>
          <div className='px-4 flex justify-between items-center'>
            <span className='block w-1/2 text-sm text-gray-700 text-center'>
              {state === 'SUCCESS_VIA_EMAIL' &&
                t('spaces.reinvite_space_modal.success_via_email')}
              {state === 'SUCCESS_VIA_LINK' &&
                t('spaces.reinvite_space_modal.success_via_link')}
            </span>
            <img
              className='hidden sm:block w-64'
              alt='Person Heart'
              src={PersonHeart}
            />
          </div>
          {state === 'SUCCESS_VIA_LINK' && (
            <div className='mt-8 px-8'>
              <SpaceInviteLink spaceID={space.id} language={space.language} />
            </div>
          )}
          <div className='flex mt-12 gap-x-4'>
            <Button.SecondaryXL
              fullWidth
              onClick={handleClose}
              text={t('spaces.reinvite_space_modal.done_action')}
            />
          </div>
        </div>
      )}
      <EditMailTemplate
        open={showMailTemplatePreview}
        setOpen={setShowMailTemplatePreview}
        mailTemplate={mailTemplate}
        mailTemplateMacros={mailTemplateMacros}
        preview
      />
    </Modal>
  )
}

ReinviteSpaceModal.propTypes = {
  space: PropTypes.object.isRequired,
  updateHandler: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
}

export default ReinviteSpaceModal
