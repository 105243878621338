import { useState } from 'react'
import {
  CheckIcon,
  HandThumbUpIcon,
  UserIcon,
  XMarkIcon,
  HandThumbDownIcon,
  PlayCircleIcon,
  BellIcon,
  EllipsisVerticalIcon,
  AcademicCapIcon,
  ArrowPathIcon,
  TrashIcon
} from '@heroicons/react/24/solid'
import { format } from 'date-fns'
import { de, enUS as en } from 'date-fns/locale'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { classNames } from '../../helpers'
import Panel from '../tailwind/Panel'
import { useLanguageContext } from '../../context'
import { useWindowDimensions } from '../../hooks/use-window-dimensions'

const HISTORY_UI_ITEMS = {
  TALENT_INVITED: {
    icon: UserIcon,
    bgColor: 'bg-gray-400',
    textColor: 'text-white'
  },
  ASSESSMENT_LAUNCHED: {
    icon: HandThumbUpIcon,
    bgColor: 'bg-blue-500',
    textColor: 'text-white'
  },
  // ASSESSMENT_STARTED: {
  //   icon: HandThumbUpIcon,
  //   bgColor: 'bg-blue-500',
  //   textColor: 'text-white'
  // },
  ASSESSMENT_COMPLETED: {
    // is missing inside on answer
    // do we need this event?
    icon: CheckIcon,
    bgColor: 'bg-green-100',
    textColor: 'text-green-800'
  },
  EMAIL_BOUNCED: {
    icon: XMarkIcon,
    bgColor: 'bg-red-400',
    textColor: 'text-white'
  },
  HIRED: {
    icon: CheckIcon,
    bgColor: 'bg-green-100',
    textColor: 'text-green-800',
    fallbackKey: 'pages.talent.HIRED'
  },
  REJECTED: {
    icon: HandThumbDownIcon,
    bgColor: 'bg-red-400',
    textColor: 'text-white',
    fallbackKey: 'pages.talent.REJECTED'
  },
  REJECTED_VIA_EMAIL: {
    icon: HandThumbDownIcon,
    bgColor: 'bg-red-400',
    textColor: 'text-white',
    fallbackKey: 'pages.talent.REJECTED_VIA_EMAIL'
  },
  RESET_EXPIRED: {
    icon: PlayCircleIcon,
    bgColor: 'bg-orange-400',
    textColor: 'text-white'
  },
  REMINDED: {
    icon: BellIcon,
    bgColor: 'bg-orange-400',
    textColor: 'text-white'
  },
  INVITED_NEXT_ROUND: {
    icon: AcademicCapIcon,
    bgColor: 'bg-orange-400',
    textColor: 'text-white'
  },
  APPLICANT_CANCELLED: {
    icon: HandThumbDownIcon,
    bgColor: 'bg-yellow-400',
    textColor: 'text-white'
  },
  SOFT_DELETED: {
    icon: TrashIcon,
    bgColor: 'bg-red-400',
    textColor: 'text-white'
  },
  RECOVERED: {
    icon: ArrowPathIcon,
    bgColor: 'bg-orange-400',
    textColor: 'text-white'
  }
}

const SpaceHistoryItem = ({ item, index, history }) => {
  return (
    <div
      className={classNames(
        'block relative',
        index !== history.length - 1 && 'pb-8'
      )}
    >
      {index !== history.length - 1 ? (
        <span
          className='absolute top-4 left-4 -ml-0.5 h-full w-px bg-gray-300'
          aria-hidden='true'
        />
      ) : null}
      <div className='relative flex space-x-3'>
        <div>
          <span
            className={classNames(
              item.bgColor,
              'h-7 w-7 rounded-full flex items-center justify-center ring-2 ring-white'
            )}
          >
            <item.icon
              className={classNames('w-5 h-5', item.textColor)}
              aria-hidden='true'
            />
          </span>
        </div>
        <div className='min-w-0 flex-1 pt-1.5'>
          <div>
            <p className='text-sm text-gray-900'>
              {item.content}{' '}
              <a href='#' className='font-medium text-gray-900'>
                {item.target}
              </a>
            </p>
          </div>
          <div className='mt-1 text-right text-xs italic whitespace-nowrap text-gray-500'>
            <p>{item.date}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

SpaceHistoryItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  history: PropTypes.array.isRequired
}

const SpaceHistory = ({ space }) => {
  const { t } = useTranslation()
  const { language } = useLanguageContext()

  const { width } = useWindowDimensions()

  const [extended, setExtended] = useState(false)

  const history = (space.history || [])
    .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
    .map(({ afterStatus, statusOption, createdAt, message }, index) => {
      const ui_item = HISTORY_UI_ITEMS[afterStatus]

      if (!ui_item) return null

      const translationKey = ['pages.talent', afterStatus, statusOption]
        .filter((item) => item)
        .join('.')

      const createdAtDate = new Date(createdAt)
      const date = format(createdAtDate, 'd. MMMM yyyy', {
        locale: { de, en }[language]
      })
      const time = format(createdAtDate, 'HH:mm', {
        locale: { de, en }[language]
      })

      const { bgColor, icon, textColor, fallbackKey } = ui_item

      return {
        id: index,
        bgColor,
        icon,
        textColor,
        date: t('pages.talent.date', { date, time }),
        target: null,
        content: t([translationKey, fallbackKey || ''], {
          reason: message
        })
      }
    })
    .filter((item) => item)

  if (!history.length) return null

  const first_item = history[0]
  const last_item = history[history.length - 1]

  const could_be_extended = history.length > 2 && width >= 1440

  return (
    <div className='relative'>
      <Panel>
        <section className='md:h-full' aria-labelledby='timeline-title'>
          <div className='px-4 sm:px-6 md:h-full'>
            <div className='lg:mt-2 flow-root'>
              <ul
                role='list'
                className={classNames(
                  could_be_extended ? (extended ? 'block' : 'hidden') : 'block'
                )}
              >
                {history.map((item, index) => (
                  <li key={index}>
                    <SpaceHistoryItem {...{ item, index, history }} />
                  </li>
                ))}
              </ul>
              <ul
                role='list'
                className={classNames(
                  could_be_extended ? (extended ? 'hidden' : 'block') : 'hidden'
                )}
              >
                <li>
                  <SpaceHistoryItem
                    {...{ item: first_item, index: 0, history }}
                  />
                </li>
                <li className='mt-8 mb-4 flex'>
                  <EllipsisVerticalIcon
                    style={{ marginLeft: 1 }}
                    className={classNames('w-8 h-8 text-gray-500')}
                    aria-hidden='true'
                  />
                  <div
                    style={{ marginLeft: 7 }}
                    className='mt-px w-full flex items-center'
                  >
                    <span
                      onClick={() => setExtended(true)}
                      className='p-2 text-xs text-gray-700 hover:text-blue-600 cursor-pointer'
                    >
                      {t('pages.talent.show_more')}
                    </span>
                  </div>
                </li>
                <SpaceHistoryItem
                  {...{ item: last_item, index: history.length - 1, history }}
                />
              </ul>
              {extended && (
                <div className='mt-8 w-full flex justify-center'>
                  <span
                    onClick={() => setExtended(false)}
                    className='p-2 text-xs text-gray-700 hover:text-blue-600 cursor-pointer'
                  >
                    {t('pages.talent.show_less')}
                  </span>
                </div>
              )}
            </div>
          </div>
        </section>
      </Panel>
    </div>
  )
}

SpaceHistory.propTypes = {
  space: PropTypes.object.isRequired
}

export default SpaceHistory
