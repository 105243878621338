import { useState } from 'react'
import { useNavigate } from 'react-router'

import { useUserContext } from '../context'
import { trackEvent } from '../helpers/analytics'
import { UPDATE_STATUS, updateCareerHistory } from '../helpers/career-history'

const CAREER_STATES = [
  null,
  'WAIT_EXPERTS',
  'WAIT_CONFIG',
  'WAIT_ACTIVE',
  'ACTIVE'
]

export const useCreateCareerNavigation = ({ career, careerMutation }) => {
  const { cognitoUser } = useUserContext()

  const [isLoadingBack, setIsLoadingBack] = useState(false)
  const [isLoadingNext, setIsLoadingNext] = useState(false)

  const navigate = useNavigate()

  const handleBackNavigation = () => {
    // can stay true because a new component gets rendered
    setIsLoadingBack(true)

    const { id, status, history } = career
    const backStatus = CAREER_STATES[CAREER_STATES.indexOf(status) - 1]

    const event = ['from', status, 'to', backStatus || 'WAIT_BASICS']
    trackEvent(event.join('_').toUpperCase())

    const input = {
      id,
      status: backStatus,
      history: updateCareerHistory(
        history || [],
        UPDATE_STATUS,
        status,
        backStatus,
        cognitoUser.username
      )
    }

    const onSuccess = () => {
      navigate('', { replace: true }) // remove hash
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    careerMutation.mutate(
      { input },
      { onSuccess, onError: () => setIsLoadingNext(false) }
    )
  }

  const handleNextNavigation = ({ input, onSuccess }) => {
    // can stay true because a new component gets rendered
    setIsLoadingNext(true)

    const { id, status, history } = career
    const nextStatus = CAREER_STATES[CAREER_STATES.indexOf(status) + 1]

    const event = ['from', status || 'WAIT_BASICS', 'to', nextStatus]
    trackEvent(event.join('_').toUpperCase())

    const onSuccessShadow = (data) => {
      navigate('', { replace: true }) // remove hash
      window.scrollTo({ top: 0, behavior: 'smooth' })
      onSuccess && onSuccess(data)
    }

    careerMutation.mutate(
      {
        input: {
          id,
          status: nextStatus,
          history: updateCareerHistory(
            history,
            UPDATE_STATUS,
            status,
            nextStatus,
            cognitoUser.username
          ),
          ...(input || {})
        }
      },
      { onSuccess: onSuccessShadow, onError: () => setIsLoadingNext(false) }
    )
  }

  return {
    isLoadingBack,
    handleBackNavigation,
    isLoadingNext,
    handleNextNavigation
  }
}
