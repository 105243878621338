import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import SettingsContainer from './settings-container'
import SEO from '../seo'
import SelectMenu from '../select-menu'
import { mutation } from '../../graphql'
import {
  useNotificationContext,
  usePartnerContext,
  usePaymentContext
} from '../../context'
import Alert from '../alert'

const TRANSLATION_LOCATION = 'settings.assessment.'

const Assessment = () => {
  const { t } = useTranslation()
  const { success } = useNotificationContext()
  const { partner, refetchPartner } = usePartnerContext()
  const { automations: automationsProductAvailable } = usePaymentContext()

  const [conflict, setConflict] = useState(null)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      assessment_expires_after_days:
        partner.app_settings?.assessment_expires_after_days || 14
    },
    validate: ({ assessment_expires_after_days }) => {
      const app_settings = partner.app_settings || {}
      const automations = JSON.parse(app_settings.automations || '{}')

      const {
        automation_assessment_reminder_days: aar_days,
        automation_assessment_reminder_active: aar_active
      } = automations

      if (
        aar_active &&
        automationsProductAvailable &&
        assessment_expires_after_days <= aar_days
      ) {
        // assessment_expires_after_days should be bigger than days
        setConflict({
          title: t('settings.assessment.conflict_title'),
          description: t('settings.assessment.conflict_description')
        })
      } else setConflict(null)

      return {}
    },
    onSubmit: async ({ assessment_expires_after_days }) => {
      formik.setSubmitting(true)

      const app_settings = partner.app_settings || {}

      app_settings.assessment_expires_after_days = assessment_expires_after_days
      const input = { id: partner.id, app_settings }

      try {
        await mutation({ mutation: 'updatePartner', input })
      } catch (err) {
        formik.setSubmitting(false)
        return
      }

      await refetchPartner()

      success(t(TRANSLATION_LOCATION + 'success_message'))
      formik.setSubmitting(false)
    }
  })

  return (
    <SettingsContainer
      title={t(TRANSLATION_LOCATION + 'title')}
      description={t(TRANSLATION_LOCATION + 'description')}
      isLoading={formik.isSubmitting}
      dirty={conflict ? false : formik.dirty}
      onSubmit={formik.handleSubmit}
    >
      <SEO title={t('seo.titles.settings_assessment')} />
      <SelectMenu
        id='assessment_expires_after_days'
        label={t(TRANSLATION_LOCATION + 'assessment_expires_after_days_title')}
        description={t(
          TRANSLATION_LOCATION + 'assessment_expires_after_days_description'
        )}
        options={[
          ...Array.from({ length: 30 }, (_, index) => index + 1).map((days) => [
            days,
            t(
              `${TRANSLATION_LOCATION}assessment_expires_after_days_${
                days === 1 ? 'day' : 'days'
              }`,
              { number: days }
            )
          ])
        ]}
        defaultValue={formik.values.assessment_expires_after_days}
        onChange={(assessment_expires_after_days) =>
          formik.setFieldValue(
            'assessment_expires_after_days',
            assessment_expires_after_days
          )
        }
        className='w-full sm:w-2/3'
        translate={false}
      />
      {conflict && (
        <div className='max-w-lg'>
          <Alert type='error' {...conflict} />
        </div>
      )}
    </SettingsContainer>
  )
}

export default Assessment
