import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { flatten, isEqual } from 'lodash'
import PropTypes from 'prop-types'

import {
  LOCALSTORAGE_ITEMS,
  classNames,
  convertNumberToTime,
  hexToRGB
} from '../../helpers'
import ChallengeCard from '../challenge-card'
import {
  MinusCircleIcon,
  PlusCircleIcon,
  InformationCircleIcon,
  PencilSquareIcon,
  PuzzlePieceIcon
} from '@heroicons/react/24/solid'
import State from '../state'
import { ClockIcon, RocketLaunchIcon } from '@heroicons/react/24/outline'
// import Popup from '../popup'
// import Dimension from './dimension'
// import UncertaintyWeight from './uncertainty-weight'
import Button from '../tailwind/Button'
import { getAppSettings } from '../../constants/app-settings'
import Modal from '../tailwind/Modal'
import { DialogTitle } from '@headlessui/react'
import { localStorage } from '../../helpers/local-storage'
import ChallengeInfoModal from '../challenge-info-modal'
import { EMPTY_CHALLENGE } from '../../constants/challenge'
import { useProductTourContext, useAivyContext } from '../../context'
import { useMount } from '../../hooks/use-mount'
import { useCreateCareerNavigation } from '../../hooks/use-create-career-navigation'
import { sortDimensions } from '../../constants/dimensions'

// uncertaintyWeight = agreement of the raters
// importance = estimated importance
// info = uncertaintyWeight * importance
// relativeWeight = info / sum(info) -> how much % does the dimension enter the matching
const prepareCareerAnalyseScores = ({ career_analyse, dimensions }) => {
  const careerAnalyse = JSON.parse(career_analyse) // saved as a string in the database

  const removeDimensions = (...dimensions) => {
    dimensions.forEach((dimension) => {
      delete careerAnalyse.scores[dimension]
    })
  }

  // @Jasmine - 2021/11/10
  removeDimensions(
    'SPEEDACC_score',
    'TROLLEY_score',
    'TRUST_score',
    'RESPDECISION_score',
    'FLYINGDUTCH_score'
  )

  let sum_info = 0
  let result = new Map()

  // RIASEC_IMAGES_elevation score is missing for presets
  if (careerAnalyse.career_preset_id) {
    careerAnalyse.scores.RIASEC_IMAGES_elevation = {
      uncertaintyWeight: 0,
      importance: 0
    }
  }

  Object.keys(careerAnalyse.scores).forEach((key) => {
    const { uncertaintyWeight, importance } = careerAnalyse.scores[key]
    const info = uncertaintyWeight * importance ?? 1

    sum_info += info

    result.set(key, {
      key,
      uncertaintyWeight,
      importance,
      info
    })
  })

  // add relative weight & cumulative weight
  for (const score of result.values()) {
    score.relativeWeight = score.info / sum_info
  }

  // number to percent
  const toPercent = (number) => Math.round(number * 10000) / 100

  // round to four decimals
  const round = (number) => Math.round(number * 10000) / 10000

  let sum_cumulativeWeight = 0
  // sort map by info, importance or uncertainty weight
  result = new Map(
    [...result.entries()]
      .sort((a, b) => sortDimensions(a[1], b[1]))
      .map(([key, value]) => {
        sum_cumulativeWeight += value.relativeWeight
        return [key, { ...value, cumulativeWeight: sum_cumulativeWeight }]
      })
      .map(([key, value]) => {
        const result = {}
        result.key = value.key
        result.info = toPercent(value.info)
        result.importance = round(value.importance)
        result.relativeWeight = toPercent(value.relativeWeight)
        result.cumulativeWeight = toPercent(value.cumulativeWeight)
        result.uncertaintyWeight = toPercent(value.uncertaintyWeight)
        result.exam_id = dimensions[value.key].exam_id

        return [key, result]
      })
  )

  return result
}

const mapTestbatteryToCoveredDimensions = ({ testbattery, dimensions_map }) => {
  const getAllDimensions = (exam_id) => {
    return Array.from(dimensions_map.values())
      .filter((dimension) => dimension.exam_id === exam_id)
      .map(({ key }) => key)
  }

  return flatten(testbattery.map((exam_id) => getAllDimensions(exam_id)))
    .map((dimension) => dimensions_map.get(dimension))
    .filter(({ uncertaintyWeight }) => uncertaintyWeight > 0)
    .sort(sortDimensions)
    .map(({ key }) => key)
}

const SelectChallenges = ({ career, careerMutation }) => {
  const { t } = useTranslation()
  const { system } = useAivyContext()

  const [showIntro, setShowIntro] = useState(
    localStorage.getItem(
      LOCALSTORAGE_ITEMS.CREATE_CAREER_CONFIGURATION_DISABLE_INTRO
    ) === null
  )

  const {
    setProductTourState,
    productTourState: { tourActive }
  } = useProductTourContext()

  useMount(() => {
    if (tourActive) {
      setShowIntro(false)
      setTimeout(() => {
        setProductTourState({ run: true })
      }, 0.4 * 1000)
    }
  })

  const {
    isLoadingBack,
    isLoadingNext,
    handleBackNavigation,
    handleNextNavigation
  } = useCreateCareerNavigation({ career, careerMutation })

  const [testbattery, setTestbattery] = useState([])
  const [moreChallenges, setMoreChallenges] = useState([])
  const [challenge, setChallenge] = useState(EMPTY_CHALLENGE)

  const { minutes: duration } = useMemo(() => {
    if (!testbattery) return 0

    const timeToComplete = testbattery
      .map((exam_id) => system.challenges[exam_id].timeToComplete)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0)

    return convertNumberToTime(timeToComplete / 60000)
  }, [testbattery, system])

  const stats = [
    {
      name: t('create_career.configuration.duration_title'),
      stat: t('create_career.configuration.duration_value', { duration }),
      icon: ClockIcon
    }
  ]

  const { dimensions_map, tradeoff, relativeWeights } = useMemo(() => {
    const { career_analyse, app_settings } = career

    const dimensions_map = prepareCareerAnalyseScores({
      dimensions: system.dimensions,
      career_analyse
    })

    const allChallengesSet = new Set()
    const tradeoffSet = new Set()
    const moreChallengesArray = []
    const relativeWeightsObject = {}

    Array.from(dimensions_map.values()).forEach(({ exam_id }) =>
      allChallengesSet.add(exam_id)
    )

    // find tradeoff - all dimensions where relative weight > 0
    Array.from(dimensions_map.values())
      .filter(({ relativeWeight }) => relativeWeight > 0)
      .forEach(({ exam_id }) => tradeoffSet.add(exam_id))

    Array.from(allChallengesSet.keys()).forEach((exam_id) => {
      let sum_relativeWeight = 0

      Object.keys(system.dimensions)
        .map((key) => ({ ...system.dimensions[key], key }))
        .filter((dimension) => dimension.exam_id === exam_id)
        .forEach(({ key }) => {
          sum_relativeWeight += dimensions_map.get(key).relativeWeight
        })

      relativeWeightsObject[exam_id] = sum_relativeWeight
    })

    const challenges = JSON.parse(app_settings?.challenges || '[]').map(
      ({ exam_id }) => exam_id
    )

    if (challenges.length) {
      Array.from(allChallengesSet.keys()).forEach((key) => {
        if (challenges.includes(key)) return
        moreChallengesArray.push(key)
      })
      setTestbattery(challenges)
    } else {
      Array.from(allChallengesSet.keys()).forEach((key) => {
        if (Array.from(tradeoffSet.keys()).includes(key)) return
        moreChallengesArray.push(key)
      })
      setTestbattery(Array.from(tradeoffSet.keys()))
    }

    setMoreChallenges(moreChallengesArray)

    return {
      dimensions_map,
      tradeoff: Array.from(tradeoffSet.keys()),
      relativeWeights: relativeWeightsObject
    }
  }, [career, system])

  const addToTestbattery = (exam_id) => {
    setTestbattery((t) => t.concat(exam_id))
    setMoreChallenges((mc) => mc.filter((id) => id !== exam_id))
  }

  const removeFromTestbattery = (exam_id) => {
    setTestbattery((t) => t.filter((id) => id !== exam_id))
    setMoreChallenges((mc) => mc.concat(exam_id))
  }

  const setChallengeForInfoModal = ({ exam_id }) => {
    setChallenge({
      ...system.challenges[exam_id],
      dimensions: Object.keys(system.dimensions)
        .map((key) => ({ ...system.dimensions[key], key }))
        .filter((dimension) => dimension.exam_id === exam_id),
      category: system.categories[system.challenges[exam_id].category_id],
      stats: [
        {
          name: t('create_career.configuration.duration_title'),
          stat: t('create_career.configuration.duration_value', {
            duration: convertNumberToTime(
              system.challenges[exam_id].timeToComplete / 60000
            ).minutes
          }),
          icon: ClockIcon
        },
        {
          name: t('create_career.configuration.score_title'),
          stat: relativeWeights[exam_id],
          icon: RocketLaunchIcon
        }
      ],
      displayInfoModal: true
    })
  }

  return (
    <>
      <div>
        <span className='text-3xl font-extrabold tracking-tight text-gray-900'>
          {t('create_career.configuration.configuration_title')}
        </span>
        {/* <div className='mt-4 w-full md:w-9/12'>
          <ul className='ml-6 list-disc'>
            {[
              'create_career.configuration.configuration_description_entry_1',
              'create_career.configuration.configuration_description_entry_2',
              'create_career.configuration.configuration_description_entry_3'
            ].map((entry, index) => (
              <li key={index} className='mb-1 text-sm text-gray-900'>
                {t(entry)}
              </li>
            ))}
          </ul>
        </div> */}
      </div>
      <div className='my-8 flex'>
        {stats.map((item, index) => (
          <div key={index} className='flex'>
            <div className='rounded-full h-12 w-12 bg-blue-500 p-3'>
              <item.icon className='h-6 w-6 text-white' aria-hidden='true' />
            </div>
            <div className='ml-4 flex flex-col justify-center'>
              <span className='text-sm font-medium text-gray-500'>
                {item.name}
              </span>
              <span className='-mt-1 text-2xl font-semibold text-gray-900'>
                {item.stat}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div className='mt-8 flex items-center'>
        <span className='text-xl font-bold tracking-tight text-gray-900'>
          {t('create_career.configuration.testbattery_title')}
        </span>
        {/* .slice() because .sort() sorts an array in place */}
        {isEqual(testbattery.slice().sort(), tradeoff.slice().sort()) && (
          <div className='ml-2'>
            <State
              text={t('create_career.configuration.recommended_state')}
              color='green'
            />
          </div>
        )}
      </div>
      <div className='mt-4 flex flex-wrap gap-4'>
        {testbattery
          .map((exam_id) => ({
            exam_id,
            ...system.challenges[exam_id],
            relativeWeight: relativeWeights[exam_id]
          }))
          .sort((a, b) => b.relativeWeight - a.relativeWeight)
          .map((challenge, index) => (
            <div key={index} className='relative group'>
              <ChallengeCard
                challenge={challenge}
                callback={() =>
                  setChallengeForInfoModal({ exam_id: challenge.exam_id })
                }
              />
              {/* first four challenges are required */}
              {index > 3 && (
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    background: hexToRGB('#ffffff', 0.9)
                  }}
                  onClick={() => removeFromTestbattery(challenge.exam_id)}
                  className={classNames(
                    'w-12 h-12 -right-4 rounded-full cursor-pointer'
                  )}
                >
                  <MinusCircleIcon
                    style={{ color: '#FE79A7' }}
                    className='w-12 h-12'
                  />
                </div>
              )}
            </div>
          ))}
      </div>
      {!!moreChallenges.length && (
        <span className='block mt-8 text-xl font-bold tracking-tight text-gray-900'>
          {t('create_career.configuration.more_challenges_title')}
        </span>
      )}
      <div className='mt-4 flex flex-wrap gap-4'>
        {moreChallenges
          .map((exam_id) => ({
            exam_id,
            ...system.challenges[exam_id],
            relativeWeight: relativeWeights[exam_id]
          }))
          .sort((a, b) => b.relativeWeight - a.relativeWeight)
          .map((challenge, index) => (
            <div key={index} className='relative group'>
              <ChallengeCard
                challenge={challenge}
                callback={() =>
                  setChallengeForInfoModal({ exam_id: challenge.exam_id })
                }
              />
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  background: hexToRGB('#ffffff', 0.9)
                }}
                onClick={() => addToTestbattery(challenge.exam_id)}
                className={classNames(
                  'w-12 h-12 -right-4 rounded-full cursor-pointer'
                )}
              >
                <PlusCircleIcon
                  style={{ color: '#29CCC7' }}
                  className='w-12 h-12'
                />
              </div>
            </div>
          ))}
      </div>
      <div className='mt-12'>
        <div className='flex items-center'>
          {[true, true, true, true, true].map((filled, index) => (
            <div
              key={index}
              className='w-4 h-4 flex items-center justify-center rounded-full border-2 border-blue-500 mr-1'
            >
              <div
                style={{
                  // #6366f1 -> blue-500
                  background: filled ? '#6366f1' : 'white',
                  borderColor: 'white'
                }}
                className={classNames('w-3 h-3 rounded-full border-2')}
              ></div>
            </div>
          ))}
          <p className='ml-2 text-sm italic text-gray-900'>
            <span>
              {t('create_career.configuration.legend_very_high_importance_1')}
            </span>
            <span className='font-bold'>
              {t('create_career.configuration.legend_very_high_importance_2')}
            </span>
            <span>
              {t('create_career.configuration.legend_very_high_importance_3')}
            </span>
          </p>
        </div>
        <div className='mt-2 flex items-center'>
          {[false, false, false, false, false].map((filled, index) => (
            <div
              key={index}
              className='w-4 h-4 flex items-center justify-center rounded-full border-2 border-blue-500 mr-1'
            >
              <div
                style={{
                  // #6366f1 -> blue-500
                  background: filled ? '#6366f1' : 'white',
                  borderColor: 'white'
                }}
                className={classNames('w-3 h-3 rounded-full border-2')}
              ></div>
            </div>
          ))}
          <p className='ml-2 text-sm italic text-gray-900'>
            <span>
              {t('create_career.configuration.legend_no_importance_1')}
            </span>
            <span className='font-bold'>
              {t('create_career.configuration.legend_no_importance_2')}
            </span>
            <span>
              {t('create_career.configuration.legend_no_importance_3')}
            </span>
          </p>
        </div>
      </div>
      <div className='mt-8 flex justify-end'>
        <div className='flex gap-x-2'>
          <Button.SecondaryLG
            onClick={handleBackNavigation}
            isLoading={isLoadingBack}
            text={t('create_career.back_action')}
          />
          <Button.PrimaryLG
            text={t('create_career.next_action')}
            isLoading={isLoadingNext}
            onClick={() =>
              handleNextNavigation({
                input: {
                  challenge_config_id:
                    career.challenge_config_id ||
                    system.currentChallengeConfigId,
                  app_settings: getAppSettings(
                    career.app_settings,
                    testbattery.map((exam_id) => ({ exam_id }))
                  ),
                  career_analyse: JSON.stringify({
                    ...JSON.parse(career.career_analyse),
                    dimensions: mapTestbatteryToCoveredDimensions({
                      testbattery,
                      dimensions_map
                    })
                  })
                }
              })
            }
          />
        </div>
      </div>
      <Modal open={showIntro} setOpen={setShowIntro} size={'xl'}>
        <DialogTitle className='text-2xl font-medium leading-6 text-gray-900'>
          {t('create_career.configuration.configuration_intro_title')}
        </DialogTitle>
        <div className='my-8'>
          <div className='flex flex-row items-center my-3'>
            <PuzzlePieceIcon className='h-12 w-12 text-blue-600 mr-4' />
            <span className='block text-sm'>
              {t(
                'create_career.configuration.configuration_description_entry_1'
              )}
            </span>
          </div>
          <div className='flex flex-row items-center my-3'>
            <PencilSquareIcon className='h-12 w-12 text-blue-600 mr-4' />
            <span className=' text-sm block'>
              {t(
                'create_career.configuration.configuration_description_entry_2'
              )}
            </span>
          </div>
          <div className='flex flex-row items-center my-3'>
            <InformationCircleIcon className='h-12 w-12 text-blue-600 mr-4' />
            <span className='block text-sm'>
              {t(
                'create_career.configuration.configuration_description_entry_3'
              )}
            </span>
          </div>
        </div>

        <div className='flex flex-row justify-evenly items-center bg-gray-100 py-4'>
          <Button.Text
            text='Nicht nochmal anzeigen'
            onClick={() => {
              localStorage.setItem(
                LOCALSTORAGE_ITEMS.CREATE_CAREER_CONFIGURATION_DISABLE_INTRO,
                'true'
              )
              setShowIntro(false)
            }}
          />
          <Button.PrimaryBase
            text='Verstanden'
            onClick={() => setShowIntro(false)}
          />
        </div>
      </Modal>
      <ChallengeInfoModal
        open={challenge.displayInfoModal}
        setOpen={() => setChallenge({ ...challenge, displayInfoModal: false })}
        challenge={challenge}
        // dimensions={dimensions}
      />
    </>
  )
}

SelectChallenges.propTypes = {
  career: PropTypes.object.isRequired,
  careerMutation: PropTypes.object.isRequired
}

export default SelectChallenges
