import { useRef } from 'react'
import ReactJoyride from 'react-joyride'
import PropTypes from 'prop-types'

import { styles } from './styles'
import { locales } from './locales'
import { NODE_ENV } from '../../helpers'

const Joyride = ({ steps, onFinish }) => {
  const helpers = useRef({})

  // const { prev, next, go, close, skip, reset, info } = helpers.current

  return (
    <ReactJoyride
      steps={steps}
      styles={styles}
      locale={locales}
      continuous
      callback={({ status }) => {
        if (status === 'finished') {
          onFinish()
        }
      }}
      getHelpers={(storeHelpers) => (helpers.current = storeHelpers)}
      hideCloseButton
      showProgress
      disableCloseOnEsc
      disableOverlayClose
      disableScrolling
      disableScrollParentFix
      debug={NODE_ENV !== 'production'}
    />
  )
}

Joyride.propTypes = {
  steps: PropTypes.array.isRequired,
  onFinish: PropTypes.func
}

Joyride.defaultProps = {
  onFinish: () => {}
}

export default Joyride
