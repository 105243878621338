import { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { DialogTitle } from '@headlessui/react'

import { mutation } from '../../graphql'
import Modal from '../tailwind/Modal'
import Button from '../tailwind/Button'
import { useNotificationContext, useUserContext } from '../../context'

const RecoverSpacesAction = ({ spaces, updateHandler, open, setOpen }) => {
  const { t } = useTranslation()
  const { cognitoUser } = useUserContext()
  const { success } = useNotificationContext()

  const [isLoading, setIsLoading] = useState(false)

  const recoverSpaces = () => {
    setIsLoading(true)

    const recoverSpace = ({ space_id, history }) => {
      const event = {
        eventName: 'SYSTEM',
        afterStatus: 'RECOVERED',
        createdAt: new Date().toISOString(),
        eventFiredBy: cognitoUser.username
      }

      return mutation({
        mutation: 'updateSpace',
        input: {
          id: space_id,
          soft_deleted: null,
          history: (history || []).concat(event)
        }
      })
    }

    Promise.all(
      spaces.map(({ id: space_id, history }) =>
        recoverSpace({ space_id, history })
      )
    )
      .then(() => {
        updateHandler()

        success(t('recover_talents.recover_space_success'))
        setIsLoading(false)
        setOpen(false)
      })
      .catch(() => setIsLoading(false))
  }

  return (
    <Modal open={open} setOpen={() => setOpen(false)} size='lg'>
      <DialogTitle className='text-2xl font-medium leading-6 text-gray-900 text-center'>
        {t('recover_talents.recover_action')}
      </DialogTitle>
      <span className='text-base text-gray-600 block mt-2 text-center'>
        {t('recover_talents.recover_description')}
      </span>
      <div className='flex flex-row gap-3 mt-8'>
        <Button.SecondaryXL
          fullWidth
          text={t('recover_talents.recover_cancel_action')}
          onClick={() => setOpen(false)}
        />
        <Button.PrimaryXL
          fullWidth
          text={
            spaces.length === 1
              ? t('recover_talents.recover_action_single', {
                  count: spaces.length
                })
              : t('recover_talents.recover_action_multiple', {
                  count: spaces.length
                })
          }
          onClick={recoverSpaces}
          isLoading={isLoading}
        />
      </div>
    </Modal>
  )
}

RecoverSpacesAction.propTypes = {
  spaces: PropTypes.array.isRequired,
  updateHandler: PropTypes.func,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
}

RecoverSpacesAction.defaultProps = {
  updateHandler: () => undefined
}

export default RecoverSpacesAction
