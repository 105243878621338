import { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild
  // Switch
} from '@headlessui/react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

import {
  Bars3Icon,
  Cog8ToothIcon,
  CpuChipIcon,
  RectangleStackIcon,
  UserGroupIcon,
  ChartPieIcon,
  XMarkIcon,
  InformationCircleIcon as InformationCircleIconOutline,
  QuestionMarkCircleIcon
} from '@heroicons/react/24/outline'
import { Tooltip } from 'react-tooltip'

import AivyIcon from '../assets/images/aivyicon.svg'
import { classNames } from '../helpers'
import ProfileDropdown from './profile-dropdown'
import ChooseGroupSlideOver from './choose-group-slide-over'
import Announcement from './announcement'
import Footer from './footer'
import AccessExpired from './access-expired'
import { trackEvent } from '../helpers/analytics'
import trackEvents from '../constants/track-events'
import { localStorage } from '../helpers/local-storage'
import {
  useAivyContext,
  useUserContext,
  usePartnerContext,
  usePaymentContext
} from '../context'
import Badge from './tailwind/Badge'
import HelpSupportSlideover from './help-support-slideover'
import SpaceSwitch from './space-switch'

const TRANSLATION_LOCATION = 'components.layout.'

const Sidebar = ({ navigation, isSidebarCollapsed }) => {
  const { t } = useTranslation()
  const { spacesByOwner } = useUserContext()
  // const { adminInsights, toggleAdminInsights } = useAivyContext()

  const getNavigationItem = (item, index) => {
    const Element = item.to
      ? Link
      : // eslint-disable-next-line
        (props) => <div {...props}>{props.children}</div>

    return (
      <Fragment key={index}>
        <Element
          id={item.id}
          to={item.to}
          state={item.state}
          replace={item.replace}
          onClick={item.onClick}
          className={classNames(
            item.current
              ? 'bg-gray-800 text-white'
              : 'text-gray-400 hover:text-white hover:bg-gray-800',
            'group flex gap-x-3 rounded-md text-sm leading-6 font-semibold cursor-pointer',
            item.className,
            isSidebarCollapsed ? 'flex items-center justify-center p-3' : 'p-2'
          )}
          data-tooltip-id='my-tooltip'
          data-tooltip-content={item.name}
          data-tooltip-place='right'
        >
          <item.icon className='h-6 w-6 shrink-0' aria-hidden='true' />
          <span
            className={classNames(
              'whitespace-nowrap',
              isSidebarCollapsed ? 'hidden' : 'inline-block'
            )}
          >
            {item.name}
          </span>
          {item.withNewBadge && !isSidebarCollapsed && (
            <Badge text={t('global.new')} color={'pink'} type={'basic'} />
          )}
        </Element>
        <Tooltip
          id='my-tooltip'
          offset={16}
          className='z-30 text-sm font-semibold'
          hidden={!isSidebarCollapsed}
        />
      </Fragment>
    )
  }

  const employees = spacesByOwner.filter(
    ({ context }) => context === 'EMPLOYEE'
  )

  return (
    <div className='flex min-h-0 flex-1 flex-col bg-gray-900'>
      <div className='flex flex-1 flex-col pb-4'>
        <div className='flex h-20 flex-shrink-0 items-center bg-gray-900 px-6'>
          <img className='h-8 w-auto' src={AivyIcon} alt='Aivy GmbH' />

          {/* {!isSidebarCollapsed && is_trial && (
            <button
              onClick={() => navigate('/upgrade')}
              type='button'
              className='inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
            >
              <SparklesIcon className='-ml-0.5 h-5 w-5' aria-hidden='true' />
              Upgrade
            </button>
          )} */}
          {/* {isAdmin && !isSidebarCollapsed && (
              <Switch
                checked={adminInsights}
                onChange={toggleAdminInsights}
                className={`${
                  adminInsights ? 'bg-blue-600' : 'bg-gray-400'
                } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span
                  className={`${
                    adminInsights ? 'translate-x-6' : 'translate-x-1'
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
            )} */}
        </div>
        <nav className='mt-5 flex-1 space-y-1 px-4'>
          {navigation[0].map((item, index) => getNavigationItem(item, index))}
          <div className='px-2 py-4'>
            <div className='h-px bg-gray-700' />
          </div>
          {navigation[1].map((item, index) => getNavigationItem(item, index))}
          {employees.length > 1 && (
            <>
              <div className='h-4' />
              {isSidebarCollapsed ? (
                <div className='h-5' />
              ) : (
                <span className='px-2 text-xs font-semibold leading-6 text-gray-400'>
                  {t(TRANSLATION_LOCATION + 'companys_area_title')}
                </span>
              )}

              <div className='h-px' />
              <div className={classNames(!isSidebarCollapsed && 'px-2')}>
                <SpaceSwitch isSidebarCollapsed={isSidebarCollapsed} />
              </div>
            </>
          )}
        </nav>
      </div>

      <ProfileDropdown isSidebarCollapsed={isSidebarCollapsed} />
    </div>
  )
}

Sidebar.propTypes = {
  navigation: PropTypes.array.isRequired,
  isSidebarCollapsed: PropTypes.bool
}

Sidebar.defaultProps = { isSidebarCollapsed: false }

const Layout = ({ children }) => {
  const { t } = useTranslation()

  const { accessExpired } = usePaymentContext()
  const { system, restartDashboard } = useAivyContext()
  const { isAdmin, active_space } = useUserContext()
  const { partner } = usePartnerContext()

  const {
    create: can_create,
    update: can_update,
    delete: can_delete
  } = active_space.authorization_role.crud

  const { pathname } = useLocation()

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [isVisibleChooseGroup, setVisibilityChooseGroup] = useState(false)
  const [isVisibleAnnouncement, setIsVisibleAnnouncement] = useState(null)
  const [isVisibleHelpAndSupport, setIsVisibleHelpAndSupport] = useState(false)
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    !!active_space.ui_settings_general.is_sidebar_collapsed
  )

  useEffect(() => {
    const { announcement } = system
    if (!announcement) return

    const toDate = new Date(announcement.to_date)
    const fromDate = new Date(announcement.from_date)

    if (announcement.active && new Date() >= fromDate && new Date() <= toDate) {
      setIsVisibleAnnouncement(announcement)
    }
  }, [system])

  const handleRemoveAdmin = async () => {
    localStorage.removeItem('admin')
    restartDashboard()
  }

  const navigation = [
    [
      {
        id: 'link-to-talents',
        name: t(TRANSLATION_LOCATION + 'navigation_talents'),
        onClick: () => trackEvent(trackEvents.PRESSED_TALENTS),
        to: '/talents',
        current: pathname.includes('talent'),
        icon: UserGroupIcon
      },
      {
        id: 'link-to-careers',
        name: t(TRANSLATION_LOCATION + 'navigation_careers'),
        onClick: () => trackEvent(trackEvents.PRESSED_CAREERS),
        to: '/careers',
        current: pathname.includes('career'),
        icon: RectangleStackIcon
      },
      {
        id: 'link-to-team-analyses',
        name: t(TRANSLATION_LOCATION + 'navigation_teamanalyses'),
        onClick: () => trackEvent(trackEvents.PRESSED_TEAMANALYSES),
        to: '/team-analyses',
        current: pathname.includes('team-analyse'),
        icon: ChartPieIcon,
        withNewBadge: false
      }
    ],
    [
      // {
      //  name: 'Erhalte Inspiration',
      //  onClick: () => setIsVisibleInspiration(true),
      //  current: false,
      //  icon: LightBulbIcon
      // },
      can_create && can_update && can_delete
        ? {
            name: t(TRANSLATION_LOCATION + 'navigation_producttour'),
            to: '/careers',
            state: { startProductTour: true },
            replace: pathname === '/careers',
            onClick: () => trackEvent(trackEvents.PRESSED_PRODUCTTOUR),
            current: false,
            icon: InformationCircleIconOutline,
            className: 'hidden xl:flex'
          }
        : null,
      // {
      //   id: 'support',
      //   name: t(TRANSLATION_LOCATION + 'navigation_support'),
      //   to: '/support',
      //   onClick: () => trackEvent(trackEvents.NAVIGATE_TO_SUPPORT),
      //   current: pathname === '/support',
      //   icon: QuestionMarkCircleIcon
      // },
      {
        id: 'nav_support_hub',
        name: t(TRANSLATION_LOCATION + 'navigation_support'),
        onClick: () => {
          trackEvent(trackEvents.PRESSED_TALENTS)
          setIsVisibleHelpAndSupport(true)
        },
        current: false,
        icon: QuestionMarkCircleIcon
      },
      {
        id: 'settings',
        name: t(TRANSLATION_LOCATION + 'navigation_settings'),
        to: '/settings#personal',
        current: pathname === '/settings',
        icon: Cog8ToothIcon
      },
      isAdmin && {
        name: 'Gruppe wechseln',
        onClick: () => setVisibilityChooseGroup(true),
        current: false,
        icon: CpuChipIcon
      }
    ].filter((item) => item)
  ]

  return (
    <>
      <div>
        <Transition show={sidebarOpen} as={Fragment}>
          <Dialog
            as='div'
            className='relative z-40 lg:hidden'
            onClose={setSidebarOpen}
          >
            <TransitionChild
              as={Fragment}
              enter='transition-opacity ease-linear duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity ease-linear duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
            </TransitionChild>

            <div className='fixed inset-0 z-40 flex'>
              <TransitionChild
                as={Fragment}
                enter='transition ease-in-out duration-300 transform'
                enterFrom='-translate-x-full'
                enterTo='translate-x-0'
                leave='transition ease-in-out duration-300 transform'
                leaveFrom='translate-x-0'
                leaveTo='-translate-x-full'
              >
                <DialogPanel className='relative flex w-full max-w-xs flex-1 flex-col bg-gray-800'>
                  <TransitionChild
                    as={Fragment}
                    enter='ease-in-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-300'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='absolute top-0 right-0 -mr-12 pt-2'>
                      <button
                        type='button'
                        className='ml-1 flex h-10 w-10 items-center justify-center rounded-full bg-gray-100'
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className='sr-only'>Close sidebar</span>
                        <XMarkIcon
                          className='h-6 w-6 text-gray-900'
                          aria-hidden='true'
                        />
                      </button>
                    </div>
                  </TransitionChild>
                  <Sidebar navigation={navigation} />
                </DialogPanel>
              </TransitionChild>
              <div className='w-14 flex-shrink-0'>
                {/* Force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition>

        {/* Static sidebar for desktop */}
        <div
          className={clsx(
            'hidden z-20 lg:fixed lg:inset-y-0 lg:flex lg:flex-col relative transition-[width] duration-300',
            isSidebarCollapsed ? 'lg:w-20' : 'lg:w-72'
          )}
        >
          <div
            onClick={() => {
              trackEvent(trackEvents.CLICK_SIDEBAR_COLLAPSE)
              active_space.updateUiSettingsGeneral({
                key: 'is_sidebar_collapsed',
                value: !isSidebarCollapsed
              })
              setIsSidebarCollapsed(!isSidebarCollapsed)
            }}
            className='absolute -right-3 bottom-28 h-7 w-7 cursor-pointer rounded-full bg-blue-600 hover:bg-blue-500 flex items-center justify-center'
          >
            {isSidebarCollapsed ? (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 16 16'
                fill='#fff'
                className='w-4 h-4'
              >
                <path
                  fillRule='evenodd'
                  d='M6.22 4.22a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06l-3.25 3.25a.75.75 0 0 1-1.06-1.06L8.94 8 6.22 5.28a.75.75 0 0 1 0-1.06Z'
                  clipRule='evenodd'
                />
              </svg>
            ) : (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 16 16'
                fill='#fff'
                className='w-4 h-4'
              >
                <path
                  fillRule='evenodd'
                  d='M9.78 4.22a.75.75 0 0 1 0 1.06L7.06 8l2.72 2.72a.75.75 0 1 1-1.06 1.06L5.47 8.53a.75.75 0 0 1 0-1.06l3.25-3.25a.75.75 0 0 1 1.06 0Z'
                  clipRule='evenodd'
                />
              </svg>
            )}
          </div>
          <Sidebar
            navigation={navigation}
            isSidebarCollapsed={isSidebarCollapsed}
          />
        </div>
        <div
          className={clsx(
            'flex flex-1 flex-col min-h-screen transition-[width] duration-300',
            isSidebarCollapsed ? 'lg:pl-20' : 'lg:pl-72'
          )}
        >
          <div className='sticky top-0 z-10 pl-1 pt-1 sm:pl-3 sm:pt-3 lg:hidden'>
            <button
              type='button'
              className='-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-full text-gray-900 bg-white'
              onClick={() => setSidebarOpen(true)}
            >
              <span className='sr-only'>Open sidebar</span>
              <Bars3Icon className='h-6 w-6' aria-hidden='true' />
            </button>
          </div>
          <main className='flex-grow'>
            {isVisibleAnnouncement && (
              <Announcement
                onClose={() => setIsVisibleAnnouncement(null)}
                type={isVisibleAnnouncement.type}
                text={isVisibleAnnouncement.message}
              />
            )}
            {!!localStorage.getItem('admin') && (
              <div className='sticky top-0 z-50'>
                <Announcement
                  onClose={handleRemoveAdmin}
                  type={'ERROR'}
                  text={t(TRANSLATION_LOCATION + 'admin_hint', {
                    partner: (
                      partner.display_name || partner.name
                    ).toUpperCase()
                  })}
                />
              </div>
            )}
            <div>
              {accessExpired && pathname !== '/support' ? (
                <AccessExpired />
              ) : (
                children
              )}
            </div>
          </main>

          {pathname === '/settings' && <Footer />}
        </div>
      </div>

      {isAdmin && (
        <ChooseGroupSlideOver
          open={isVisibleChooseGroup}
          setOpen={setVisibilityChooseGroup}
        />
      )}
      <HelpSupportSlideover
        isVisibleHelpAndSupport={isVisibleHelpAndSupport}
        setIsVisibleHelpAndSupport={setIsVisibleHelpAndSupport}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
