import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'

const DebouncedInput = ({
  id,
  initialValue,
  onChange,
  placeholder,
  debounce
}) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [debounce, onChange, value])

  return (
    <div className='relative rounded-md shadow-sm'>
      <div
        className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'
        aria-hidden='true'
      >
        <MagnifyingGlassIcon
          className='mr-3 h-4 w-4 text-gray-400'
          aria-hidden='true'
        />
      </div>
      <input
        id={id}
        type='text'
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset pl-9 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
        placeholder={placeholder}
      />
    </div>
  )
}

DebouncedInput.propTypes = {
  id: PropTypes.string.isRequired,
  initialValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  debounce: PropTypes.number
}

DebouncedInput.defaultProps = {
  placeholder: '',
  debounce: 500
}

export default DebouncedInput
