export const ANALYSE = `{
  id
  input
  partner_id
  authorized_create
  authorized_read
  authorized_update
  authorized_delete
  context
  createdAt
  updatedAt
  lastUpdatedBy
}`
