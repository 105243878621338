import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

function SEO({ description, lang, meta, title }) {
  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`${title} - Aivy®`}
      meta={[
        //   {
        //     name: `description`,
        //     content: metaDescription,
        //   },
        //   {
        //     property: `og:title`,
        //     content: title,
        //   },
        //   {
        //     property: `og:description`,
        //     content: metaDescription,
        //   },
        //   {
        //     property: `og:type`,
        //     content: `website`,
        //   },
        //   {
        //     name: `twitter:card`,
        //     content: `summary`,
        //   },
        //   {
        //     name: `twitter:creator`,
        //     content: site.siteMetadata.author,
        //   },
        //   {
        //     name: `twitter:title`,
        //     content: title,
        //   },
        //   {
        //     name: `twitter:description`,
        //     content: metaDescription,
        //   },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `de`,
  meta: [],
  description: ``
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
}

export default SEO
