import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router'
import {
  CircleStackIcon as CircleStackIconOutline,
  AtSymbolIcon as AtSymbolIconOutline,
  BeakerIcon as BeakerIconOutline
} from '@heroicons/react/24/outline'
import { ArrowLongLeftIcon } from '@heroicons/react/20/solid'
import { useTranslation } from 'react-i18next'

import {
  useLanguageContext,
  useNotificationContext,
  useProductTourContext
} from '../../context'
import { useMount } from '../../hooks/use-mount'
import InviteExperts from './career-analyse/invite-experts'
import UsePresets from './career-analyse/use-presets'
import Actions from '../tailwind/actions'
import SelectCareer from './career-analyse/select-career'
import Button from '../tailwind/Button'
import { query } from '../../graphql'
import { notifyBugsnag } from '../../helpers'
import { useCreateCareerNavigation } from '../../hooks/use-create-career-navigation'
import { trackEvent } from '../../helpers/analytics'
import trackEvents from '../../constants/track-events'
import { useQuery } from '@tanstack/react-query'
import { ActivityIndicator } from '..'

const TRANSLATION_LOCATION = 'components.career.expert_analysis.'

const CareerAnalyse = ({ career, careerMutation }) => {
  const { t } = useTranslation()
  const { language } = useLanguageContext()
  const { success, error } = useNotificationContext()

  const navigate = useNavigate()
  const { hash } = useLocation()

  const {
    setProductTourState,
    productTourState: { tourActive }
  } = useProductTourContext()

  useMount(() => {
    if (tourActive) {
      navigate('#invite-experts')
      setTimeout(() => {
        setProductTourState({ run: true })
      }, 0.4 * 1000)
    }
  })

  const {
    data: careerAnalyses,
    status: careerAnalysesStatus,
    refetch: refetchCareerAnalyses
  } = useQuery({
    queryKey: ['career-analyses', career.id],
    queryFn: () =>
      query({
        query: 'careerAnalyseByCareer',
        variables: { career_id: career.id }
      })
  })

  useEffect(() => {
    if (hash) return

    // .length is 0 if status equals 'success'
    if (!careerAnalyses) return

    const options = { replace: true }
    const { career_preset_id } = JSON.parse(career.career_analyse || '{}')

    if (careerAnalyses.length) navigate('#invite-experts', options)
    else if (career_preset_id) navigate('#use-presets', options)
    else navigate('#options', options)
  }, [hash, career, careerAnalyses, navigate])

  const { isLoadingBack, handleBackNavigation } = useCreateCareerNavigation({
    career,
    careerMutation
  })

  async function generateRequirementProfile() {
    const pdfDocument = window.open('', '_blank')
    pdfDocument.document.write(
      t(TRANSLATION_LOCATION + 'requirement_profile_preparation')
    )

    try {
      const pdfResponse = await query({
        query: 'generatePdf',
        variables: { career_id: career.id, language }
      })

      if (pdfResponse) {
        pdfDocument.location.href = JSON.parse(pdfResponse).headers.Location
        success(t(TRANSLATION_LOCATION + 'requirement_profile_success'))
      }
    } catch (err) {
      error(t(TRANSLATION_LOCATION + 'requirement_profile_error'))
      notifyBugsnag(err)
      pdfDocument.close()
    }
  }

  if (careerAnalysesStatus === 'pending') return <ActivityIndicator />

  return (
    <div className='pt-4 mx-auto max-w-3xl'>
      {hash !== '#options' && (
        <div className='flex'>
          <div
            onClick={() => navigate('#options', { replace: true })}
            className='group flex items-center cursor-pointer'
          >
            <ArrowLongLeftIcon
              className='mr-3 h-5 w-5 text-gray-700 group-hover:text-gray-900'
              aria-hidden='true'
            />
            <span className='pr-1 text-sm font-medium text-gray-700 group-hover:text-gray-900'>
              {t(TRANSLATION_LOCATION + 'back_to_actions')}
            </span>
          </div>
        </div>
      )}
      {hash === '#options' && (
        <>
          <div className='mt-4'>
            <Actions
              title={t(TRANSLATION_LOCATION + 'actions_title')}
              description={t(TRANSLATION_LOCATION + 'actions_description')}
              actions={[
                {
                  name: t(TRANSLATION_LOCATION + 'action_invite_experts_name'),
                  description: t(
                    TRANSLATION_LOCATION + 'action_invite_experts_description'
                  ),
                  onClick: () => {
                    trackEvent(trackEvents.PRESSED_TAB_INVITE_EXPERTS)
                    navigate('#invite-experts', { replace: true })
                  },
                  iconColor: 'bg-emerald-400/90 group-hover:bg-emerald-400',
                  icon: AtSymbolIconOutline
                },
                {
                  name: t(TRANSLATION_LOCATION + 'action_select_career_name'),
                  description: t(
                    TRANSLATION_LOCATION + 'action_select_career_description'
                  ),
                  onClick: () => {
                    trackEvent(trackEvents.PRESSED_TAB_SELECT_CAREER)
                    navigate('#select-career', { replace: true })
                  },
                  iconColor: 'bg-sky-400/90 group-hover:bg-sky-400',
                  icon: CircleStackIconOutline
                },
                {
                  name: t(TRANSLATION_LOCATION + 'action_use_presets_name'),
                  description: t(
                    TRANSLATION_LOCATION + 'action_use_presets_description'
                  ),
                  onClick: () => {
                    trackEvent(trackEvents.PRESSED_TAB_USE_PRESETS)
                    navigate('#use-presets', { replace: true })
                  },
                  iconColor: 'bg-cyan-400/90 group-hover:bg-cyan-400',
                  icon: BeakerIconOutline
                }
              ]}
            />
          </div>
          <div className='mt-8 sm:mt-24 flex flex-row justify-between items-center'>
            <Button.SecondaryLG
              onClick={handleBackNavigation}
              isLoading={isLoadingBack}
              text={t('create_career.back_action')}
            />
          </div>
        </>
      )}
      {hash === '#invite-experts' && (
        <InviteExperts
          career={career}
          careerMutation={careerMutation}
          careerAnalyses={careerAnalyses}
          refetchCareerAnalyses={refetchCareerAnalyses}
          generateRequirementProfile={generateRequirementProfile}
        />
      )}
      {hash === '#select-career' && <SelectCareer career={career} />}
      {hash === '#use-presets' && (
        <UsePresets
          career={career}
          careerMutation={careerMutation}
          generateRequirementProfile={generateRequirementProfile}
        />
      )}
    </div>
  )
}

CareerAnalyse.propTypes = {
  career: PropTypes.object.isRequired,
  careerMutation: PropTypes.object.isRequired
}

export default CareerAnalyse
