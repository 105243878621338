import PropTypes from 'prop-types'

const Italic = ({ className }) => {
  return (
    <div className={className}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='currentColor'
        viewBox='0 0 24 24'
      >
        <path d='M15 20H7v-2h2.927l2.116-12H9V4h8v2h-2.927l-2.116 12H15v2z'></path>
      </svg>
    </div>
  )
}

Italic.propTypes = {
  className: PropTypes.string
}

Italic.defaultProps = {
  className: undefined
}

export default Italic
