import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

const BigDivider = ({ percentage }) => {
  return (
    <div
      style={{ left: `calc(${percentage}% - 2px)`, bottom: -10 }}
      className='absolute h-6 w-1 bg-gray-200 rounded' // height: 24px
    />
  )
}

BigDivider.propTypes = { percentage: PropTypes.number.isRequired }

const Range = ({ from, to, average }) => {
  const { t } = useTranslation()

  return (
    <>
      <div
        style={{
          left: `calc(${from}%)`,
          width: `calc(${to - from}%)`,
          bottom: 0
        }}
        className='absolute h-1 bg-blue-300 rounded' // height: 32px
      />
      <div
        style={{ left: `calc(${average}% - 2px)`, bottom: -10 }}
        className='absolute h-6 w-1 bg-blue-400 rounded' // height: 16px
      />
      <span
        style={{ left: `calc(${average}% - 24px)`, bottom: 16 }}
        className='absolute text-xs text-gray-500'
      >
        {t('team_analyse.linear_chart.ideal_label')}
      </span>
      <div
        style={{
          left: `calc(${from}%)`,
          width: `calc(${to - from}%)`,
          bottom: -22
        }}
        className='absolute h-3 border-r border-l border-gray-300' // height: 32px
      />
      <div
        style={{
          left: `calc(${from}%)`,
          width: `calc(${to - from}%)`,
          bottom: -16.5
        }}
        className='absolute h-px bg-gray-300' // height: 32px
      />
      <span
        style={{ left: `calc(${average}% - 30px)`, bottom: -35 }}
        className='absolute text-xs text-gray-500'
      >
        {t('team_analyse.linear_chart.range_label')}
      </span>
    </>
  )
}

Range.propTypes = {
  from: PropTypes.number.isRequired,
  to: PropTypes.number.isRequired,
  average: PropTypes.number.isRequired
}

const getBackgroundColorPassung = (value, alpha) => {
  const match = Math.round(value)

  if (match >= 71 && match <= 100) {
    return alpha ? 'rgba(41, 204, 199, 0.5)' : '#29CCC7'
  } else if (match >= 41 && match < 71) {
    return alpha ? 'rgba(255, 154, 59, 0.5)' : '#FF9A3B'
  } else {
    return alpha ? 'rgba(255, 138, 176, 0.5)' : '#ff8ab0'
  }
}

const LinearDimensionExampleWithCareer = () => {
  const { t } = useTranslation()

  const datasets = [
    {
      id: 'd03dbbaa-436c-4937-9866-cd9404675374',
      data: {
        exam_id: 'RIASEC_IMAGES',
        score: 'elevation',
        result: 64
      },
      borderWidth: 1,
      backgroundColor: 'rgba(190, 46, 221,0.2)',
      borderColor: 'rgba(190, 46, 221,1.0)'
    }
  ]

  const dataset_career = {
    id: '4cd8f4c4-d0cf-4f26-a013-36b8371cde22',
    data: {
      exam_id: 'RIASEC_IMAGES',
      score: 'elevation',
      min: 14.62,
      max: 56.72,
      optimal: 35.67
    },
    borderWidth: 1,
    backgroundColor: 'rgba(255, 121, 121,0.2)',
    borderColor: 'rgba(255, 121, 121,1.0)'
  }

  const { min, max, optimal } = dataset_career.data

  return (
    <div>
      <div className='w-96 flex items-center justify-center'>
        <div
          className='w-full relative h-1 bg-gray-200 rounded' // height: 4px
        >
          <BigDivider percentage={50} />
          <Range from={min} to={max} average={optimal} />
          {datasets.map((item, index) => {
            const {
              data: { result }
            } = item

            const match = 100 - Math.abs(result - optimal)
            const borderColor = getBackgroundColorPassung(match, false)
            const backgroundColor = getBackgroundColorPassung(match, true)

            return (
              <Fragment key={index}>
                <div
                  style={{
                    left: `calc(${result}% - 8px)`,
                    bottom: -8,
                    backgroundColor: 'white'
                  }}
                  className='absolute w-5 h-5 rounded-full' // height: 20px
                >
                  <div
                    style={{ backgroundColor }}
                    className='w-5 h-5 rounded-full p-1'
                  >
                    <div
                      style={{ backgroundColor: borderColor }}
                      className='w-3 h-3 rounded-full'
                    />
                  </div>
                </div>
                <span
                  style={{ left: `calc(${result}% - 48px)`, bottom: 16 }}
                  className='absolute text-xs text-gray-500'
                >
                  {t('team_analyse.linear_chart.fit_label_career')}
                </span>
              </Fragment>
            )
          })}
        </div>
      </div>
      <div className='flex gap-x-4 pt-12'>
        {[
          {
            label: t('team_analyse.linear_chart.fit_label_career_high'),
            match: 84
          },
          {
            label: t('team_analyse.linear_chart.fit_label_career_medium'),
            match: 64
          },
          {
            label: t('team_analyse.linear_chart.fit_label_career_low'),
            match: 40
          }
        ].map(({ label, match }, index) => {
          const borderColor = getBackgroundColorPassung(match, false)
          const backgroundColor = getBackgroundColorPassung(match, true)

          return (
            <div key={index} className='flex items-center'>
              <div
                style={{ backgroundColor }}
                className='w-4 h-4 rounded-full p-1'
              >
                <div
                  style={{ backgroundColor: borderColor }}
                  className='w-2 h-2 rounded-full'
                />
              </div>
              <span className='ml-1.5 text-xs text-gray-500'>{label}</span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default LinearDimensionExampleWithCareer
