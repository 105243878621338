import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import {
  CircleStackIcon as CircleStackIconOutline,
  AtSymbolIcon as AtSymbolIconOutline,
  BeakerIcon as BeakerIconOutline
} from '@heroicons/react/24/outline'

import GeneralFields from './general-fields'
import AdditionalFields from './additional-fields'
import Description from './description'
import { useSetState } from '../../../hooks/use-set-state'
import Steps from '../../tailwind/steps'
import { useNotificationContext } from '../../../context'
import { trackEvent } from '../../../helpers/analytics'
import trackEvents from '../../../constants/track-events'
import Modal from '../../tailwind/Modal'
import Actions from '../../tailwind/actions'

// const STATES = {
const ACTIONS = 'ACTIONS'
const GENERAL_FIELDS = 'GENERAL_FIELDS'
const ADDITIONAL_FIELDS = 'ADDITIONAL_FIELDS'
const DESCRIPTION = 'DESCRIPTION'
// }

const CreateOrUpdateFlex = ({
  open,
  setOpen,
  career,
  updateHandler,
  create,
  update
}) => {
  const { t } = useTranslation()
  const { success } = useNotificationContext()

  const [state, setState] = useSetState({
    current: (create && GENERAL_FIELDS) || (update && ACTIONS)
  })

  const { current, isLoading } = state

  const submitData = ({ data, onSuccess, onError }) => {
    const {
      customLandingPageText,
      customLandingPageFields,
      application_input_additional_fields: aiaf
    } = data

    const app_settings = career.app_settings || {}
    const components = JSON.parse(app_settings.components || '{}')

    app_settings.application_input_additional_fields = JSON.stringify(aiaf)
    components.customLandingPageFields = customLandingPageFields
    components.customLandingPageText = customLandingPageText

    app_settings.components = JSON.stringify(components)

    updateHandler({
      input: { id: career.id, app_settings },
      onSuccess,
      onError
    })
  }

  const steps = [
    {
      id: '01',
      name: t('career.flex.general_fields.title'),
      description: t('career.flex.general_fields.description'),
      current: current === GENERAL_FIELDS,
      complete: [ADDITIONAL_FIELDS, DESCRIPTION].includes(current),
      action: {
        onClick: () => setState({ current: GENERAL_FIELDS }),
        iconColor: 'bg-emerald-400/90 group-hover:bg-emerald-400',
        icon: AtSymbolIconOutline
      }
    },
    {
      id: '02',
      name: t('career.flex.additional_fields.title'),
      description: t('career.flex.additional_fields.description'),
      current: current === ADDITIONAL_FIELDS,
      complete: [DESCRIPTION].includes(current),
      action: {
        onClick: () => setState({ current: ADDITIONAL_FIELDS }),
        iconColor: 'bg-sky-400/90 group-hover:bg-sky-400',
        icon: CircleStackIconOutline
      }
    },
    {
      id: '03',
      name: t('career.flex.description.title'),
      description: t('career.flex.description.description'),
      current: current === DESCRIPTION,
      complete: false,
      action: {
        onClick: () => setState({ current: DESCRIPTION }),
        iconColor: 'bg-cyan-400/90 group-hover:bg-cyan-400',
        icon: BeakerIconOutline
      }
    }
  ]

  const handleClose = () => {
    setOpen(false)
    setTimeout(
      () =>
        setState({
          current: (create && GENERAL_FIELDS) || (update && ACTIONS)
        }),
      1000 * 0.6
    )
  }

  const getCurrentValues = () => {
    const { app_settings } = career
    const { customLandingPageFields, customLandingPageText } = JSON.parse(
      app_settings?.components || '{}'
    )

    return {
      customLandingPageText,
      customLandingPageFields,
      application_input_additional_fields: JSON.parse(
        app_settings?.application_input_additional_fields || '[]'
      )
    }
  }

  const updateWithState = (s) => {
    setState({ ...s, isLoading: true })

    submitData({
      data: { ...getCurrentValues(), ...s },
      onSuccess: () => {
        setState({ isLoading: false })
        success(t('career.flex.create_flex.update_success'))
        trackEvent(trackEvents.UPDATE_PUBLIC_LINK_INVITATION)
      },
      onError: () => setState({ isLoading: false })
    })
  }

  const handleUpdateGeneralFields = (s) => {
    if (create) setState({ ...s, current: ADDITIONAL_FIELDS })
    else if (update) updateWithState(s)
  }

  const handleUpdateAdditionalFields = (s) => {
    if (create) setState({ ...s, current: DESCRIPTION })
    else if (update) updateWithState(s)
  }

  const handleUpdateDescription = (s) => {
    if (create) {
      setState({ ...s, isLoading: true })
      // pass data because set state is async
      submitData({
        data: { ...state, ...s },
        onSuccess: () => {
          setState({ current: GENERAL_FIELDS, isLoading: false })
          handleClose()
          success(t('career.flex.create_flex.create_success'))
          trackEvent(trackEvents.ACTIVATE_PUBLIC_LINK_INVITATION)
        },
        onError: () => setState({ isLoading: false })
      })
    } else if (update) updateWithState(s)
  }

  const backHandler = () => {
    setState({ current: ACTIONS })
  }

  return (
    <Modal open={open} setOpen={handleClose} size='4xl'>
      {create && <Steps steps={steps} />}
      <div className='h-8' />
      <div className='flex flex-col justify-center'>
        <h2 className='mb-0.5 text-xl font-semibold text-gray-900'>
          {steps.find(({ current: c }) => c)?.name}
        </h2>
        <span className='text-sm text-gray-900'>
          {steps.find(({ current: c }) => c)?.description}
        </span>
      </div>
      <div className='h-4' />
      {current === GENERAL_FIELDS && (
        <GeneralFields
          career={career}
          updateHandler={handleUpdateGeneralFields}
          backHandler={backHandler}
          create={create}
          update={update}
          isLoading={isLoading}
        />
      )}
      {current === ADDITIONAL_FIELDS && (
        <AdditionalFields
          career={career}
          updateHandler={handleUpdateAdditionalFields}
          backHandler={backHandler}
          create={create}
          update={update}
          isLoading={isLoading}
        />
      )}
      {current === DESCRIPTION && (
        <Description
          career={career}
          updateHandler={handleUpdateDescription}
          backHandler={backHandler}
          create={create}
          update={update}
          isLoading={isLoading}
        />
      )}
      {current === ACTIONS && (
        <div className='-mt-4 pb-16'>
          <Actions
            title={t('career.flex.title')}
            description={t('career.flex.description', {
              career_title: career.title
            })}
            actions={steps.map((step) => ({ ...step, ...step.action }))}
          />
        </div>
      )}
    </Modal>
  )
}

CreateOrUpdateFlex.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  career: PropTypes.object.isRequired,
  updateHandler: PropTypes.func.isRequired,
  create: PropTypes.bool,
  update: PropTypes.bool
}

CreateOrUpdateFlex.defaultProps = {
  create: false,
  update: false
}

export default CreateOrUpdateFlex
