import PropTypes from 'prop-types'

import LinearDimension from './linear-dimension'

const LinearChart = ({ data }) => {
  const { dimensions, datasets, career } = data

  // if all dimensions for a category are invisible
  // or the spaces have no data for the selected category
  if (!dimensions.length) return null

  return (
    <div className='flex flex-col gap-y-4'>
      {dimensions.map((dimension, index) => (
        <div key={index} className='w-full'>
          <LinearDimension
            dimension={{
              ...dimension,
              datasets: datasets.map((space) => ({
                ...space,
                data: space.data.find(
                  ({ exam_id, score }) =>
                    exam_id === dimension.exam_id && score === dimension.score
                )
              })),
              dataset_career: career && {
                ...career,
                data: career.data.find(
                  ({ exam_id, score }) =>
                    exam_id === dimension.exam_id && score === dimension.score
                )
              }
            }}
          />
        </div>
      ))}
    </div>
  )
}

LinearChart.propTypes = { data: PropTypes.object.isRequired }

export default LinearChart
