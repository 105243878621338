export const sortDimensions = (a, b) => {
  if (a.info === 0 && b.info === 0) {
    return b.importance - a.importance
  }

  if (a.info === b.info) {
    return b.uncertaintyWeight - a.uncertaintyWeight
  }

  return b.info - a.info
}
