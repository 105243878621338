import PropTypes from 'prop-types'
import AivyIcon from '../assets/images/aivyicon.svg'

const Loader = ({ loadingText }) => {
  return (
    <div className='flex flex-col min-h-screen items-center justify-center'>
      <img className='block mb-32 h-24 w-auto' src={AivyIcon} alt='Aivy Logo' />
      <p className='hidden text-center font-medium text-darkBlack'>
        {loadingText}
      </p>
    </div>
  )
}

Loader.propTypes = {
  loadingText: PropTypes.string
}

Loader.defaultProps = {
  loadingText: ''
}

export default Loader
