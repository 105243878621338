import { useEffect, useState } from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import ActivityIndicator from 'react-spinners/BeatLoader'

import { useLanguageContext } from '../../../context'
import { query } from '../../../graphql'

const SelectPreset = ({ callback, defaultValue, onChange }) => {
  const { t } = useTranslation()
  const { language } = useLanguageContext()

  // const presetsRef = useRef([])

  const [input, setInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [presets, setPresets] = useState([])

  const [hardcodedInput, setHardcodedInput] = useState(defaultValue)

  useEffect(() => {
    if (!input || input.length < 3) {
      setPresets([])
      setIsLoading(false)
      return
    }

    setIsLoading(true)

    const timeout = setTimeout(() => {
      query({
        query: 'publicSqlQuery',
        variables: {
          action: 'query-career-syns-by-name',
          input: JSON.stringify({ career_name: input })
        }
      }).then((response) => {
        const items = JSON.parse(response.Items).map((item) => {
          item.name = JSON.parse(item.name)
          item.displayValue = item.name[language]
          item.syns = JSON.parse(item.syns)

          const synonyms = item.syns[language]
          Object.keys(item.syns).forEach((languageKey) => {
            if (languageKey === language) return

            synonyms.push(...item.syns[languageKey])
          })

          // finds the first entry - dashboard language has a higher prio
          item.synonym = synonyms.find((synonym) =>
            synonym.toLowerCase().includes(input.toLowerCase())
          )

          return item
        })

        items.length = 5

        setPresets(items)
        setIsLoading(false)
      })
    }, 1000 * 0.8)

    return () => clearTimeout(timeout)
  }, [input, language])

  return (
    <div className='relative'>
      <div className='mt-1 relative border-b border-gray-300 focus-within:border-blue-600'>
        <MagnifyingGlassIcon
          className='absolute h-5 w-5 left-2 top-2 text-blue-600'
          aria-hidden='true'
        />
        <input
          id='search-input-backend'
          name='search-input-backend'
          type='text'
          className='pl-10 block w-full border-0 border-b border-transparent bg-white focus:border-blue-600 focus:ring-0 text-sm placeholder-shown:italic'
          placeholder={t(
            'career.requirement_profile.presets.search_input_placeholder'
          )}
          value={hardcodedInput || input}
          onChange={(event) => {
            setHardcodedInput(null)
            setInput(event.target.value)
            onChange(event)
          }}
          // onBlur={() => {
          //   presetsRef.current = presets
          //   setPresets([])
          // }}
          // onFocus={() => {
          //   setPresets(presetsRef.current)
          //   presetsRef.current = []
          // }}
        />
        <ul className='absolute w-full z-10 rounded-lg mt-1 px-6 pb-2 bg-white shadow-xl divide-y divide-gray-200'>
          {presets.map((item, index) => (
            <li
              key={index}
              onClick={() => {
                callback(item)
                setPresets([])
                setHardcodedInput(item.displayValue)
                setInput('')
              }}
              className='p-2 hover:cursor-pointer hover:bg-gray-50'
            >
              <span className='text-sm font-medium text-gray-900'>
                {item.displayValue}
              </span>
              {item.synonym && (
                <span className='ml-1 text-sm italic text-gray-700'>
                  {item.synonym}
                </span>
              )}
            </li>
          ))}
        </ul>
      </div>
      {isLoading && (
        <div className='absolute right-2 top-2'>
          <ActivityIndicator color='#111827' size={12} />
        </div>
      )}
    </div>
  )
}

SelectPreset.propTypes = {
  callback: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export default SelectPreset
