export const getCountAnalyzesScoreItemProperties = (state) => {
  return scores['count-analyzes-scores'].find((score) =>
    score.states.includes(state)
  )
}

export const getAnalyseScoreItemProperties = (state) => {
  return scores['analyse-scores'].find((score) => score.states.includes(state))
}

const scores = {
  'count-analyzes-scores': [
    {
      states: ['ANALYSE_IS_VALID', 'ANALYSE_NOT_VALID'],
      type: 'success',
      title: 'constants.scores.all_analyzes_completed_header',
      description: 'constants.scores.all_analyzes_completed_description'
    },
    {
      states: ['ANALYZES_COMPLETED_SMALLER_THREE'],
      type: 'warning',
      title: 'constants.scores.analyzes_completed_smaller_three_header',
      description:
        'constants.scores.analyzes_completed_smaller_three_description'
    },
    {
      states: ['NOT_ALL_ANALYZES_COMPLETED'],
      type: 'success',
      title: 'constants.scores.not_all_analyzes_completed_header',
      description: 'constants.scores.not_all_analyzes_completed_description'
    }
  ],
  'analyse-scores': [
    {
      states: ['ANALYSE_IS_VALID'],
      type: 'success',
      title: 'constants.scores.analyse_is_valid_header',
      description: 'constants.scores.analyse_is_valid_description'
    },
    {
      states: ['ANALYSE_NOT_VALID'],
      type: 'error',
      title: 'constants.scores.analyse_not_valid_header',
      description: 'constants.scores.analyse_not_valid_description'
    },
    {
      states: [
        'ANALYZES_COMPLETED_SMALLER_THREE',
        'NOT_ALL_ANALYZES_COMPLETED'
      ],
      type: 'info',
      title: 'constants.scores.analyse_score_not_all_analyzes_completed_header',
      description:
        'constants.scores.analyse_score_not_all_analyzes_completed_description'
    }
  ]
}
