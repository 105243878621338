import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { XMarkIcon } from '@heroicons/react/24/solid'

import Button from '../tailwind/Button'
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import { trackEvent } from '../../helpers/analytics'
import trackEvents from '../../constants/track-events'

const Intro = ({ handleNavigateBackAction, handleStartAction }) => {
  const { t } = useTranslation()

  const { key, title, description } = {
    key: 'INTRO',
    title: t('leadfunnel.intro.title'),
    description: t('leadfunnel.intro.description')
  }

  return (
    <>
      <div
        className='absolute right-8 top-8 cursor-pointer z-10'
        onClick={() => handleNavigateBackAction({ question: key })}
      >
        <XMarkIcon className='h-6 w-6 text-gray-900' />
      </div>
      <div className='mt-24'>
        <div className='mx-auto max-w-xl'>
          <h3 className='text-lg leading-6 font-medium text-gray-900'>
            {title}
          </h3>
          <p className='max-w-lg text-sm mt-1 text-gray-700'>{description}</p>
          <div className='border-l-4 border-yellow-400 bg-yellow-50 p-4 my-4'>
            <div className='flex'>
              <div className='flex-shrink-0'>
                <ExclamationTriangleIcon
                  className='h-5 w-5 text-yellow-400'
                  aria-hidden='true'
                />
              </div>
              <div className='ml-3'>
                <p className='text-sm text-yellow-700'>
                  {t('leadfunnel.intro.talent_hint')}{' '}
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={() => {
                      trackEvent(
                        trackEvents.CLICK_FUNNEL_DISCOVER_STRENGTH_WEBAPP
                      )
                    }}
                    href='https://webapp.aivy.app'
                    className='font-medium text-yellow-700 underline hover:text-yellow-600'
                  >
                    {t('leadfunnel.intro.here_you_go')}
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className='mt-12 flex justify-end gap-x-2'>
            <Button.SecondaryLG
              onClick={() => handleNavigateBackAction({ question: key })}
              text={t('leadfunnel.cancel')}
            />
            <Button.PrimaryLG
              text={t('leadfunnel.lets_go')}
              onClick={handleStartAction}
            />
          </div>
        </div>
      </div>
    </>
  )
}

Intro.propTypes = {
  handleNavigateBackAction: PropTypes.func.isRequired,
  handleStartAction: PropTypes.func.isRequired
}

export default Intro
