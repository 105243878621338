import { useTranslation } from 'react-i18next'
import { forwardRef, useMemo } from 'react'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'

import { uuidSmall } from '../helpers'

const Markdown = ({ children }) => {
  return (
    <ReactMarkdown
      components={{
        p: ({ children }) => (
          <p className='block text-2xl font-light tracking-tight text-gray-900'>
            {children}
          </p>
        ),
        strong: ({ children }) => (
          <span className='font-medium'>{children}</span>
        )
      }}
    >
      {children}
    </ReactMarkdown>
  )
}

Markdown.propTypes = { children: PropTypes.node.isRequired }

const InputTypeform = forwardRef(({ id, label, description, formik }, ref) => {
  const { t } = useTranslation()

  // use a random id to hide browser suggestions
  const input_id = useMemo(() => `${id}_${uuidSmall()}`, [id])

  return (
    <>
      <div className='relative w-full'>
        <label htmlFor={input_id} className='block'>
          <Markdown>{label}</Markdown>
        </label>
        <input
          id={input_id}
          ref={ref}
          type='text'
          value={formik.values[id]}
          onChange={(event) => {
            formik
              .setFieldValue(id, event.target.value)
              .then(() => formik.setFieldTouched(id))
          }}
          autoComplete='false'
          className='block w-full mt-2 pl-0 pr-24 border-0 focus:ring-0 text-3xl tracking-tight text-gray-900'
        />
        <div className='h-0.5 w-full bg-gray-900' />
        {formik.touched[id] && formik.errors[id] && (
          <span className='block absolute right-2 bottom-3 text-red-600 text-sm text-right'>
            {formik.errors[id]}
          </span>
        )}
        {!formik.errors[id] && (
          <div className='absolute right-2 bottom-3'>
            <ReactMarkdown
              components={{
                strong: ({ children }) => (
                  <span
                    style={{ fontSize: 10 }}
                    className='font-semibold text-gray-700'
                  >
                    {children}
                  </span>
                ),
                p: ({ children }) => (
                  <span style={{ fontSize: 10 }} className='text-gray-700'>
                    {children}
                  </span>
                )
              }}
            >
              {t('complete_signup.enter_hint')}
            </ReactMarkdown>
            <span
              className='ml-1 text-gray-700'
              style={{
                fontSize: 9,
                fontFamily: 'Arial, Helvetica, sans-serif'
              }}
            >
              &#x23CE;
            </span>
          </div>
        )}
      </div>
      {description && (
        <div className='mt-4 max-w-lg'>
          <p className='text-sm text-gray-700'>{description}</p>
        </div>
      )}
    </>
  )
})

InputTypeform.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  formik: PropTypes.object.isRequired
}

InputTypeform.defaultProps = {
  description: null
}

export default InputTypeform
