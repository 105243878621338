import { useEffect, useState } from 'react'

import { localStorage } from '../helpers/local-storage'
import { NODE_ENV } from '../helpers'

export const usePersist = ({ key, initialState, skip }) => {
  const [data, setData] = useState(
    skip && NODE_ENV !== 'production'
      ? initialState
      : JSON.parse(localStorage.getItem(key) || initialState.toString())
  )

  useEffect(() => {
    if (skip && NODE_ENV !== 'production') return

    localStorage.setItem(key, JSON.stringify(data))
  }, [key, data, skip])

  return [data, setData]
}
