import { createContext, useContext, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { Modal, Notification } from '../components'

const NotificationContext = createContext({
  success: () => undefined,
  error: () => undefined,
  alert: () => undefined
})

NotificationContext.displayName = 'NotificationContext'

export function NotificationContextProvider(props) {
  const { t } = useTranslation()

  const modalRef = useRef()
  const notificationRef = useRef()

  const value = useMemo(
    () => ({
      success: (message) => {
        notificationRef.current.alertWithType('success', message)
      },
      error: (message) => {
        notificationRef.current.alertWithType(
          'error',
          message || t('app.notification_error_default')
        )
      },
      alert: (title, messages, buttons, iconComponent, children, maxwmax) => {
        modalRef.current.alert(
          title,
          messages,
          buttons,
          iconComponent,
          children,
          maxwmax
        )
      }
    }),
    [t]
  )

  return (
    <>
      <NotificationContext.Provider value={value} {...props} />
      <Modal ref={modalRef} />
      <Notification ref={notificationRef} />
    </>
  )
}

export function useNotificationContext() {
  const context = useContext(NotificationContext)

  if (!context) {
    throw new Error('useNotificationContext must be used within a AppProvider')
  }

  return context
}
