export const PERSON = `{
  id
  owner
  authorized_create
  authorized_read
  authorized_update
  authorized_delete
  invitation_firstname
  firstname
  invitation_lastname
  lastname
  invitation_email
  email
  phone
  postcode
  city
  createdAt
  updatedAt
}`
