import { DialogTitle } from '@headlessui/react'
import PropTypes from 'prop-types'

import Modal from './tailwind/Modal'
import { useTranslation } from 'react-i18next'
import Button from './tailwind/Button'
import { trackEvent } from '../helpers/analytics'
import trackEvents from '../constants/track-events'
import { useLanguageContext } from '../context'

const ChallengeInfoModal = ({ open, setOpen, challenge }) => {
  const { language } = useLanguageContext()

  const { t } = useTranslation()

  return (
    <Modal open={open} setOpen={setOpen} size={'3xl'} hideCloseButton>
      <div>
        <div className='flex flex-row justify-between items-center'>
          <div>
            <DialogTitle className='text-2xl font-medium leading-6 text-gray-900'>
              {challenge.title[language]}
            </DialogTitle>
            <span className='text-sm text-gray-700 block mt-1'>
              {challenge.category.name[language]}
            </span>
          </div>
          <div className='w-16'>
            <img src={challenge.category.image} alt='category' />
          </div>
        </div>
        <p className='text-sm text-gray-700 mt-6 w-full sm:w-2/3'>
          {challenge.introScreen.intro[language]}
        </p>
        <p className='text-lg font-medium text-gray-900 mt-6'>
          {t('create_career.configuration.dimensions_title')}
        </p>
        <span className='block mt-1 max-w-lg text-sm text-gray-700'>
          {t('create_career.configuration.dimensions_description')}
        </span>
        <ul className='mt-2 list-disc ml-8'>
          {challenge.dimensions.map(({ property }, index) => {
            // .sort((a, b) => {
            //  // sort dimensions with importance <= 0.5 to the end
            //  const importanceA = a.importance > 0.5 ? a.importance : 0
            //  const importanceB = b.importance > 0.5 ? b.importance : 0

            //  const weightingA = a.uncertaintyWeight * importanceA
            //  const weightingB = b.uncertaintyWeight * importanceB

            //  return weightingB - weightingA
            // })

            // const considered = uncertaintyWeight > 0 && importance > 0.5

            return (
              <div key={index}>
                <li className='text-sm text-gray-700 mb-1'>
                  <span>{property[language]}</span>
                </li>
              </div>
            )
          })}
        </ul>
        <div className='flex flex-row justify-end'>
          <Button.PrimaryLG
            className='mt-8'
            text={t('create_career.configuration.tryout')}
            onClick={() => {
              trackEvent(trackEvents.PRESSED_TRYOUT_CHALLENGE)
              window.open(
                `http://webapp.aivy.app/?challenge=${challenge.exam_id}`
              )
            }}
          />
        </div>
      </div>
    </Modal>
  )
}

ChallengeInfoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  challenge: PropTypes.object.isRequired
}

export default ChallengeInfoModal
