export const talentContextFilterInput = {
  or: [
    { context: { eq: 'TALENTPOOL' } },
    { context: { eq: 'SELECT' } },
    { context: { eq: 'ATTRACT' } }
  ]
}

export const employeeContextFilterInput = {
  context: { eq: 'EMPLOYEE' }
}

export const careerActiveFilterInput = {
  status: { eq: 'ACTIVE' }
  // archived: { ne: true }
}
