export const SPACE_LAST_INTERACTION_KEYS = {
  SMART_PREDICT: 'SMART_PREDICT',
  FULFILLED: 'FULFILLED',
  STARTED_VIA_EMAIL: 'STARTED_VIA_EMAIL',
  STARTED_VIA_LINK: 'STARTED_VIA_LINK',
  REMINDED: 'REMINDED', // can only be reached via INVITED_VIA_EMAIL
  INVITED_VIA_EMAIL: 'INVITED_VIA_EMAIL',
  INVITED_VIA_LINK: 'INVITED_VIA_LINK',
  EXPIRED_VIA_EMAIL: 'EXPIRED_VIA_EMAIL',
  EXPIRED_VIA_LINK: 'EXPIRED_VIA_LINK',
  RESET_EXPIRED_VIA_EMAIL: 'RESET_EXPIRED_VIA_EMAIL',
  RESET_EXPIRED_VIA_LINK: 'RESET_EXPIRED_VIA_LINK',
  EMAIL_BOUNCED: 'EMAIL_BOUNCED'
}
