import PropTypes from 'prop-types'

import { classNames } from '../../helpers'

const SimpleStats = ({ stats, background }) => {
  return (
    <div>
      <div className='mx-auto max-w-7xl'>
        <div className='grid grid-cols-1 gap-px bg-gray-900/10 sm:grid-cols-2 xl:grid-cols-4'>
          {stats.map(({ name, value, unit, onClick }, index) => (
            <div
              key={index}
              onClick={onClick}
              className={classNames(
                'px-4 py-6 sm:px-6 lg:px-8',
                background,
                onClick && 'group cursor-pointer'
              )}
            >
              <p className='text-sm text-gray-500'>{name}</p>
              <p className='mt-2 flex items-baseline gap-x-2'>
                <span className='text-4xl font-semibold tracking-tight text-gray-900 group-hover:text-blue-600'>
                  {value}
                </span>
                {unit ? (
                  <span className='text-sm text-gray-700 group-hover:text-blue-500'>
                    {unit}
                  </span>
                ) : null}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

SimpleStats.propTypes = {
  stats: PropTypes.array,
  background: PropTypes.string
}

SimpleStats.defaultProps = {
  stats: [
    { name: 'Number of deploys', value: '405' },
    { name: 'Average deploy time', value: '3.65', unit: 'mins' },
    { name: 'Number of servers', value: '3' },
    { name: 'Success rate', value: '98.5%' }
  ],
  background: 'bg-white'
}

export default SimpleStats
