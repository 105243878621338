import { useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@tanstack/react-query'

import { query } from '../../graphql'
import { ActivityIndicator } from '..'
import { classNames } from '../../helpers'
import { getJobCategory } from '../../constants/job-categories'
import { useTranslation } from 'react-i18next'
import Table from '../react-table/table'
import Panel from '../tailwind/Panel'
import Divider from '../divider'
import { sortCrossComparison } from '../../helpers/sortings'
import Paywall from '../paywall'
import {
  useLanguageContext,
  useNotificationContext,
  usePartnerContext,
  usePaymentContext
} from '../../context'
import Tooltip from '../tooltip'
import Button from '../tailwind/Button'
import ReinviteSpaceModal from '../talents/reinvite-space-modal'
import { trackEvent } from '../../helpers/analytics'
import trackEvents from '../../constants/track-events'
import LockedIcon from '../icons/locked-icon'

const TRANSLATION_LOCATION = 'pages.talent.'

const CareersMatching = ({ space, refetchSpace }) => {
  const { t } = useTranslation()
  const { partner } = usePartnerContext()
  const { error } = useNotificationContext()
  const { getLanguage } = useLanguageContext()
  const { crossComparison, showPaywall } = usePaymentContext()

  const [showReinviteSpaceModal, setShowReinviteSpaceModal] = useState(false)

  const getScore = ({ matching_score, complete_dataset, isScoreVisible }) => {
    let value
    let color // the round div at the beginning of a row

    if (complete_dataset) value = Math.round(matching_score)
    else value = t('talents.crossmode.complete_dataset_false')

    if (!complete_dataset) {
      color = '#e5e7eb'
    } else if (value >= 71 && value <= 100) {
      color = '#29CCC7'
    } else if (value >= 41 && value < 71) {
      color = '#FF9A3B'
    } else {
      color = '#FF8AB0'
    }

    if (complete_dataset && !isScoreVisible) value = '-'

    return { value, color, complete_dataset }
  }

  const queryMatchMeIfYouCanEnabled = !!space.owner && space.progress === 100

  const { status, data } = useQuery({
    queryKey: ['match-me-if-you-can', space.owner, partner.id],
    queryFn: () =>
      query({
        query: 'matchMeIfYouCan',
        variables: {
          partner_id: partner.id,
          user_id: space.owner
        }
      }),
    enabled: queryMatchMeIfYouCanEnabled,
    select: (response) => {
      const result = JSON.parse(response)
        .filter((career) => !career.archived)
        .map((career) => ({
          ...career,
          categoryName: getJobCategory(career.category),
          score: getScore({ ...career, isScoreVisible: partner.isScoreVisible })
        }))
        .sort((a, b) => {
          if (!a.complete_dataset || !b.complete_dataset) return -1
          return b.score.value - a.score.value
        })

      if (!crossComparison) {
        return result
          .filter(({ complete_dataset }) => complete_dataset)
          .slice(0, 5)
      }

      return result
    }
  })

  const matchMeIfYouCan = data || []

  const generatePDF = (careerID) => {
    const variables = { career_id: careerID, language: getLanguage() }

    space.partner_user_id && (variables.partner_user_id = space.partner_user_id)
    !space.partner_user_id && (variables.user_id = space.owner)

    const pdfDocument = window.open('', '_blank')
    pdfDocument.document.write(t(TRANSLATION_LOCATION + 'pdf_preparation'))

    query({ query: 'generatePdf', variables })
      .then((response) => {
        pdfDocument.location.href = JSON.parse(response).headers.Location
      })
      .catch(() => error() || pdfDocument.close())
  }

  const columns = [
    {
      accessorKey: 'title',
      enableSorting: crossComparison,
      header: (
        <span className='text-sm font-semibold text-gray-900'>
          {t('pages.talent.careers_matching_careers_table_column_careers')}
        </span>
      ),
      cell: ({ cell }) => {
        const { title, external_custom_id, score } = cell.row.original

        const career_title = external_custom_id
          ? `${title} | ${external_custom_id}`
          : title

        return (
          <div className='flex items-center space-x-3'>
            <div
              className='flex-shrink-0 w-3 h-3 rounded-full'
              aria-hidden='true'
              style={{ backgroundColor: score.color }}
            />
            <span className='text-sm font-medium text-gray-900'>
              {career_title}
            </span>
          </div>
        )
      }
    },
    {
      accessorKey: 'score',
      enableSorting: crossComparison,
      header: (
        <span className='text-sm font-semibold text-gray-900'>
          {t('pages.talent.careers_matching_careers_table_column_score')}
        </span>
      ),
      sortingFn: sortCrossComparison,
      cell: ({ getValue }) => {
        const { complete_dataset, value } = getValue() || {}
        const displayTooltip = !complete_dataset && crossComparison

        return (
          <div className='flex items-center'>
            <span
              className={classNames(
                'text-sm text-gray-500',
                complete_dataset ? null : 'italic',
                crossComparison ? null : 'blur'
              )}
            >
              {crossComparison ? value : 'blur'}
            </span>
            {displayTooltip && (
              <div className='ml-2'>
                <Tooltip
                  id='complete_dataset_false_tooltip'
                  place='top'
                  tip={t('talents.crossmode.complete_dataset_false_tooltip')}
                />
              </div>
            )}
          </div>
        )
      }
    },
    {
      accessorKey: 'category',
      enableSorting: crossComparison,
      header: (
        <span className='text-sm font-semibold text-gray-900'>
          {t('pages.talent.careers_matching_careers_table_column_category')}
        </span>
      ),
      cell: ({ getValue }) => (
        <span className='text-sm text-gray-500'>
          {getValue() ? t(getJobCategory(getValue())) : ''}
        </span>
      )
    },
    {
      accessorKey: 'id',
      enableSorting: false,
      header: (
        <span className='ml-auto text-sm font-semibold text-gray-900'>
          {t('pages.talent.careers_matching_careers_table_column_result')}
        </span>
      ),
      cell: ({ getValue, cell }) => {
        const available = crossComparison && cell.row.original.complete_dataset

        return (
          <span
            className={classNames(
              'text-sm flex justify-end items-center',
              available
                ? 'text-blue-500 hover:text-blue-600 cursor-pointer'
                : 'text-gray-500 italic'
            )}
            onClick={available ? () => generatePDF(getValue()) : undefined}
          >
            {t(
              TRANSLATION_LOCATION +
                (available
                  ? 'careers_matching_careers_table_column_result_action'
                  : 'careers_matching_careers_table_column_result_not_available')
            )}
          </span>
        )
      }
    }
  ]

  if (queryMatchMeIfYouCanEnabled && status === 'pending') {
    return <ActivityIndicator className='mx-auto my-16' />
  }

  if (space.progress < 100 && crossComparison) {
    return (
      <Panel>
        <div className='flex justify-center items-center w-full'>
          <p className='max-w-lg text-center text-sm italic text-gray-500'>
            {t(TRANSLATION_LOCATION + 'careers_matching_progress_smaller_100')}
          </p>
        </div>
      </Panel>
    )
  }

  const { all_challenges_unlocked } = space
  const matchings_have_not_complete_dataset = matchMeIfYouCan.some(
    ({ complete_dataset }) => complete_dataset === false
  )

  const alreadyReinvited =
    all_challenges_unlocked && matchings_have_not_complete_dataset

  const showReinviteOption =
    !all_challenges_unlocked && matchings_have_not_complete_dataset

  return (
    <>
      <Panel>
        <div className='flex-1 w-full'>
          <div className='border-b border-gray-200 pb-5 flex justify-between items-center'>
            <div>
              <div className='flex flex-row items-center'>
                <h2 className='text-lg font-medium text-gray-900'>
                  {t(TRANSLATION_LOCATION + 'cross_matching_title')}
                </h2>
                {!crossComparison && (
                  <div
                    className='cursor-pointer'
                    onClick={() => showPaywall('general')}
                  >
                    <LockedIcon
                      place={'left'}
                      text={t('tooltip.locked.redirect_talent_url')}
                    />
                  </div>
                )}
              </div>
              <p className='mt-1 max-w-4xl text-sm text-gray-500'>
                {t(TRANSLATION_LOCATION + 'cross_matching_description')}
              </p>
            </div>
            <div>
              {showReinviteOption && (
                <Button.SecondaryBase
                  onClick={() => {
                    trackEvent(
                      trackEvents.CLICK_REQUEST_CHALLENGES_TALENT_DETAILS
                    )
                    setShowReinviteSpaceModal(true)
                  }}
                  text={t('talents.open_reinvite_space_modal_action')}
                />
              )}
              {alreadyReinvited && (
                <span className='text-sm text-gray-500 italic'>
                  {t('talents.already_reinvited_hint')}
                </span>
              )}
            </div>
          </div>
          {crossComparison && matchMeIfYouCan.length > 0 && (
            <Table
              className='bg-gray-50'
              classNameTableHeader=''
              columns={columns}
              data={matchMeIfYouCan}
              initialState={{ sorting: [{ id: 'score', desc: false }] }}
              disableHover
              disableVirtual
            />
          )}
          {crossComparison && !matchMeIfYouCan.length && (
            <span className='block mt-4 text-sm italic text-gray-500'>
              {t(TRANSLATION_LOCATION + 'careers_matching_no_active_careers')}
            </span>
          )}
          {!crossComparison && matchMeIfYouCan.length > 0 && (
            <>
              <Table
                className='bg-gray-50'
                classNameTableHeader=''
                columns={columns}
                data={matchMeIfYouCan}
                initialState={{ sorting: [{ id: 'score', desc: false }] }}
                disableHover
                disableVirtual
                disableFilter
              />
              <Divider marginY={32} />
              <div className='mt-4 mb-2'>
                <Paywall type='static' paywall='general' />
              </div>
            </>
          )}
          {!crossComparison && !matchMeIfYouCan.length && (
            <div className='mt-8 mb-2'>
              <Paywall type='static' paywall='general' />
            </div>
          )}
        </div>
      </Panel>
      <ReinviteSpaceModal
        space={space}
        updateHandler={refetchSpace}
        open={!!showReinviteSpaceModal}
        setOpen={() => setShowReinviteSpaceModal(false)}
      />
    </>
  )
}

CareersMatching.propTypes = {
  space: PropTypes.object.isRequired,
  refetchSpace: PropTypes.func.isRequired
}

export default CareersMatching
