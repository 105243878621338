import PropTypes from 'prop-types'

const IndeterminateCheckbox = ({
  id,
  checked,
  onChange,
  disabled
  // indeterminate
}) => {
  // const ref = useRef()

  // useEffect(() => {
  //  if (typeof indeterminate === 'boolean') {
  //    ref.current.indeterminate = !checked && indeterminate
  //  }
  // }, [ref, indeterminate])

  return (
    <input
      id={id}
      type='checkbox'
      className='h-4 w-4 rounded border-gray-300 text-blue-600 focus:outline-none focus:ring-blue-500 cursor-pointer'
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
  )
}

IndeterminateCheckbox.propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

IndeterminateCheckbox.defaultProps = {
  id: undefined,
  disabled: false
}

export default IndeterminateCheckbox
