import initials from 'initials'
import PropTypes from 'prop-types'

const SpaceHeader = ({ space }) => {
  const { name, identification } = space

  return (
    <div className='flex items-center space-x-4'>
      <div className='flex-shrink-0 h-14 w-14'>
        <span className='inline-flex items-center justify-center h-14 w-14 rounded-full bg-gray-300'>
          <span className='text-2xl font-medium leading-none text-white'>
            {initials(space.initials)}
          </span>
        </span>
      </div>
      <div>
        <h1 className='text-2xl font-bold text-gray-900'>
          {name || identification}
        </h1>
      </div>
    </div>
  )
}

SpaceHeader.propTypes = {
  space: PropTypes.object.isRequired
}

export default SpaceHeader
