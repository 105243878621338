import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { formatDistance, subSeconds } from 'date-fns'
import { de, enUS as en } from 'date-fns/locale'

import { useLanguageContext } from '../context'
import { ActivityIndicator, Seo } from '../components'
import PageHeading from '../components/tailwind/PageHeading'
import { useTeamAnalyseQuery } from '../hooks/use-team-analyse-query'
import Analyse from '../components/team-analyse/analyse'
import Feedback from '../components/feedback'
import Intro from '../components/team-analyse/intro'
import Spaces from '../components/team-analyse/spaces'
import { useCareersQuery } from '../hooks/use-careers-query'

const TeamAnalyse = () => {
  const { id } = useParams()
  const { t } = useTranslation()
  const { language } = useLanguageContext()

  const [selectedCareer, setSelectedCareer] = useState(undefined)

  const {
    data: teamAnalyse,
    status: teamAnalyseStatus,
    dataUpdatedAt,
    fetchStatus
  } = useTeamAnalyseQuery({
    id
  })

  const { data: careers, status: careersStatus } = useCareersQuery({
    select: (items) => {
      return items
        .filter(({ status }) => status === 'ACTIVE')
        .filter(({ id }) => !id.includes('PRODUCT_TOUR'))
        .filter(({ archived }) => !archived)
    }
  })

  if ([teamAnalyseStatus, careersStatus].includes('pending')) {
    return (
      <div className='mx-12'>
        <ActivityIndicator />
      </div>
    )
  }

  const getFetchingSubtitle = () => {
    if (teamAnalyseStatus === 'error') return null

    // data updated at defaults to 0 for an empty cache
    if (!dataUpdatedAt) {
      return t('team_analyse.fetching_subtitle.is_loading')
    }

    if (fetchStatus === 'fetching') {
      return t('team_analyse.fetching_subtitle.is_updating')
    }

    return t('team_analyse.fetching_subtitle.last_updated_at', {
      distance_sub_seconds: formatDistance(
        subSeconds(new Date(dataUpdatedAt), 3),
        new Date(),
        { locale: { de, en }[language] }
      )
    })
  }

  return (
    <>
      <Seo
        title={`${t('team_analyse.analyse.title')} - ${teamAnalyse.analyse.title}`}
      />
      <PageHeading
        title={teamAnalyse.analyse.title}
        subtitle={getFetchingSubtitle()}
      />
      <div className='max-w-3xl px-4 sm:px-6 lg:px-8 lg:max-w-7xl'>
        <div className='mt-8'>
          <Spaces spaces={teamAnalyse.spaces} />
        </div>

        <div className='mt-14'>
          <Analyse
            spaces={teamAnalyse.spaces}
            career={selectedCareer}
            setSelectedCareer={setSelectedCareer}
            careers={careers}
            id={id}
          />
        </div>
      </div>
      <div className='h-16' />
      <Feedback context='TEAM_ANALYSE' />
      <div className='h-32' />
      <Intro />
    </>
  )
}

export default TeamAnalyse
