import { useEffect, useState } from 'react'
import { DialogTitle } from '@headlessui/react'
import { useTranslation } from 'react-i18next'

import Modal from '../tailwind/Modal'
import Button from '../tailwind/Button'
import { localStorage } from '../../helpers/local-storage'
import { LOCALSTORAGE_ITEMS } from '../../helpers'
import { useLocation, useNavigate } from 'react-router'

const { HIDE_TEAM_ANALYSE_INTRO } = LOCALSTORAGE_ITEMS

const Intro = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const location = useLocation()

  const [showIntro, setShowIntro] = useState(false)

  useEffect(() => {
    const { pathname, state } = location

    if (
      state &&
      state.showIntro &&
      localStorage.getItem(HIDE_TEAM_ANALYSE_INTRO) === null
    ) {
      setShowIntro(true)
      // remove state.displayIntro
      navigate(pathname, { replace: true })
    }
  }, [location, navigate])

  return (
    <Modal open={showIntro} setOpen={setShowIntro} size={'2xl'}>
      <DialogTitle className='px-4 text-2xl font-medium leading-6 text-gray-900'>
        {t('team_analyse.intro.title')}
      </DialogTitle>
      <div className='my-8 px-4'>
        {[
          t('team_analyse.intro.paragraph_1'),
          t('team_analyse.intro.paragraph_2'),
          t('team_analyse.intro.paragraph_3'),
          t('team_analyse.intro.paragraph_4')
        ].map((paragraph, index) => (
          <p key={index} className='mb-4 text-sm text-gray-900'>
            {paragraph}
          </p>
        ))}
      </div>

      <div className='flex justify-center items-center bg-gray-50 p-4 rounded-lg gap-x-4'>
        <Button.Text
          text={t('team_analyse.intro.dont_show_again_action')}
          onClick={() => {
            localStorage.setItem(HIDE_TEAM_ANALYSE_INTRO, 'true')
            setShowIntro(false)
          }}
        />
        <Button.PrimaryBase
          text={t('team_analyse.intro.ok_action')}
          onClick={() => setShowIntro(false)}
        />
      </div>
    </Modal>
  )
}

export default Intro
