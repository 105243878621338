import { Fragment, useState } from 'react'
import initials from 'initials'
import { JSONTree } from 'react-json-tree'
import PropTypes from 'prop-types'
import { formatDistanceToNow } from 'date-fns'
import { de, enUS as en } from 'date-fns/locale'

import {
  useAivyContext,
  useLanguageContext,
  useUserContext
} from '../../../context'
import { useTranslation } from 'react-i18next'
import { classNames, notifyBugsnag, uuidSmall } from '../../../helpers'
import {
  ROLES,
  getAuthorizationRole
} from '../../../helpers/authorization-roles'
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import ModalEditUser from './modal-edit-user'
import clsx from 'clsx'

const getName = ({ firstname, lastname }) => {
  if (!firstname && !lastname) return ''
  if (!firstname) return lastname
  if (!lastname) return firstname

  return `${firstname} ${lastname}`
}

const Users = ({ users, refetchUsers }) => {
  const { t } = useTranslation()
  const { active_space } = useUserContext()
  const { language } = useLanguageContext()
  const { adminInsights } = useAivyContext()

  const [editUser, setEditUser] = useState({})
  const [uuid, setUuid] = useState(uuidSmall())
  const [openUpdateUser, setOpenUpdateUser] = useState(false)

  const has_admin_role = active_space.authorization_role.id === ROLES.ADMIN.id

  return (
    <>
      <ul role='list' className='divide-y divide-gray-100'>
        {[
          users.find((u) => u.id === active_space.id),
          ...users
            .filter((u) => u.id !== active_space.id)
            .sort((a, b) => new Date(b.last_seen) - new Date(a.last_seen))
        ]
          .filter((item) => item)
          .map((space, index) => {
            const {
              id: space_id,
              authorization_roles,
              person,
              last_seen,
              owner,
              partner_id
            } = space

            if (!person) {
              const message = `/settings#user: person is null - space_id: ${space_id}`
              notifyBugsnag(new Error(message))

              return null
            }

            const is_current_user = space_id === active_space.id

            const firstname = person.firstname || person.invitation_firstname
            const lastname = person.lastname || person.invitation_lastname

            const name = getName({ firstname, lastname })
            const email = person.email || person.invitation_email

            const { id: role_id, title: role_title } = getAuthorizationRole({
              user: {
                username: owner,
                groups: authorization_roles.map(
                  (role) => `${role}_${partner_id}`
                ),
                partner_id
              }
            })

            return (
              <Fragment key={index}>
                {adminInsights && (
                  <JSONTree
                    data={person}
                    shouldExpandNodeInitially={() => false}
                  />
                )}
                <li
                  key={index}
                  className={classNames(
                    'flex justify-between gap-x-6 py-5',
                    has_admin_role && !is_current_user && 'cursor-pointer group'
                  )}
                  onClick={
                    has_admin_role && !is_current_user
                      ? () => {
                          setEditUser({
                            space_id,
                            email,
                            firstname,
                            lastname,
                            role_id
                          })
                          // destroy the component for the new user
                          setUuid(uuidSmall())

                          setTimeout(() => setOpenUpdateUser(true), 0.05 * 1000)
                        }
                      : undefined
                  }
                >
                  <div className='flex min-w-0 gap-x-4'>
                    <span className='inline-flex items-center justify-center h-12 w-12 rounded-full bg-gray-500 relative'>
                      <span className='font-medium leading-none text-white'>
                        {initials(name)}
                      </span>
                      {is_current_user && (
                        <span className='absolute right-0 top-0 block h-3 w-3 rounded-full bg-green-400 ring-2 ring-white' />
                      )}
                    </span>
                    <div className='min-w-0 flex-auto'>
                      <p className='text-sm font-semibold leading-6 text-gray-900'>
                        {name}
                      </p>
                      <p className='mt-0.5 flex text-xs leading-5 text-gray-500'>
                        {email}
                      </p>
                    </div>
                  </div>
                  <div className='flex shrink-0 items-center gap-x-4'>
                    <div className={`hidden sm:flex sm:flex-col sm:items-end`}>
                      <p className='text-sm leading-6 text-gray-900'>
                        {t(role_title)}
                      </p>
                      {is_current_user ? (
                        <div className='mt-0.5 flex items-center gap-x-1.5'>
                          <p className='text-xs leading-5 text-gray-500'>
                            Online
                          </p>
                        </div>
                      ) : (
                        <p className='mt-0.5 text-xs leading-5 text-gray-500'>
                          {last_seen
                            ? formatDistanceToNow(new Date(last_seen), {
                                addSuffix: true,
                                locale: { de, en }[language]
                              })
                            : 'n/a'}
                        </p>
                      )}
                    </div>

                    <ChevronRightIcon
                      className={clsx(
                        'h-5 w-5 flex-none text-gray-300 group-hover:text-gray-700',
                        !(has_admin_role && !is_current_user) && 'opacity-0'
                      )}
                      aria-hidden='true'
                    />
                  </div>
                </li>
              </Fragment>
            )
          })}
      </ul>
      <ModalEditUser
        key={uuid}
        open={openUpdateUser}
        setOpen={setOpenUpdateUser}
        data={{ ...editUser, mode: 'update' }}
        onSuccess={() => refetchUsers()}
      />
    </>
  )
}

Users.propTypes = {
  users: PropTypes.array.isRequired,
  refetchUsers: PropTypes.func.isRequired
}

export default Users
