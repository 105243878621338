import { createContext, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

const AivyContext = createContext()

AivyContext.displayName = 'AivyContext'

export function AivyContextProvider(props) {
  const [adminInsights, setAdminInsights] = useState(false)

  const value = useMemo(
    () => ({
      system: props.system,
      adminInsights,
      toggleAdminInsights: () => {
        setAdminInsights(!adminInsights)
      },
      restartDashboard: props.restartDashboard
    }),
    [adminInsights, props.system, props.restartDashboard]
  )

  return <AivyContext.Provider value={value} {...props} />
}

AivyContextProvider.propTypes = {
  system: PropTypes.object.isRequired,
  restartDashboard: PropTypes.func.isRequired
}

export function useAivyContext() {
  const context = useContext(AivyContext)

  if (!context) {
    throw new Error('useAivyContext must be used within a AppProvider')
  }

  return context
}
