import { PERSON } from './person'

export const CAREER_ANALYSE = `{
  id
  input
  status
  career_id
  partner_id
  authorized_create
  authorized_read
  authorized_update
  authorized_delete
  person_id
  person ${PERSON}
  createdAt
  updatedAt
}`
