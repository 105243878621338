import { XMarkIcon } from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import PersonHeart from '../../assets/images/illustrations/person-heart-without-shapes.png'

const Outro = ({ handleNavigateBackAction }) => {
  const { t } = useTranslation()
  const { key, title, description } = {
    key: 'OUTRO',
    title: t('leadfunnel.outro.title'),
    description: t('leadfunnel.outro.description')
  }

  return (
    <>
      <div
        className='absolute right-8 top-8 cursor-pointer z-10'
        onClick={() => handleNavigateBackAction({ question: key })}
      >
        <XMarkIcon className='h-6 w-6 text-gray-900' />
      </div>
      <div className='mt-24 relative'>
        <div className='mx-auto max-w-xl'>
          <h3 className='text-lg leading-6 font-medium text-gray-900'>
            {title}
          </h3>
          <p className='max-w-lg text-sm mt-1 text-gray-700'>{description}</p>
          <div className='hidden md:block absolute right-0 -bottom-40 opacity-80'>
            <img className='w-48' alt='Person Heart' src={PersonHeart} />
          </div>
        </div>
      </div>
    </>
  )
}

Outro.propTypes = {
  handleNavigateBackAction: PropTypes.func.isRequired
}

export default Outro
