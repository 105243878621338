import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Storage } from 'aws-amplify'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router'

import { classNames, notifyBugsnag } from '../../helpers'
import Input from '../input'
import { trackEvent } from '../../helpers/analytics'
import { getAllJobCategories } from '../../constants/job-categories'
import SelectMenu from '../select-menu'
import {
  updateCareerHistory,
  UPDATE_ARCHIVED,
  UPDATE_SETTINGS
} from '../../helpers/career-history'
import CareerSettingsContainer from './career-settings-container'
import Divider from '../divider'
import MailTemplatePreviewCard from '../mail-template/mail-template-preview-card'
import EditMailTemplate from '../mail-template/edit-mail-template'
import UploadPicture from '../upload-picture'
import { DE, getAllLanguages } from '../../constants/languages'
import trackEvents from '../../constants/track-events'
import { localStorage } from '../../helpers/local-storage'
import {
  useNotificationContext,
  useUserContext,
  usePartnerContext,
  usePaymentContext
} from '../../context'
import Button from '../tailwind/Button'
import ImageCropAssessment from '../image-crop-assessment'
import { useMailTemplates } from '../../hooks/use-mail-templates'

const TRANSLATION_LOCATION = 'pages.career.'

const AssessmentSettings = ({ career, updateHandler }) => {
  const { t } = useTranslation()
  const { partner } = usePartnerContext()
  const { cognitoUser, active_space } = useUserContext()
  const { redirectTalentUrl, showPaywall } = usePaymentContext()
  const { success, error, alert } = useNotificationContext()

  const navigate = useNavigate()

  const {
    mailTemplateMacros,
    mailTemplateMacrosReject,
    mailTemplate,
    mailTemplateReminder,
    mailTemplateReject
  } = useMailTemplates({ career })

  const [showEditMailTemplate, setShowEditMailTemplate] = useState(false)
  const [showEditMailTemplateReminder, setShowEditMailTemplateReminder] =
    useState(false)
  const [showEditMailTemplateReject, setShowEditMailTemplateReject] =
    useState(false)

  const [photoUrl, setPhotoUrl] = useState(career.logo || partner.logo)

  const formikGeneralSettings = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object({
      title: Yup.string().required(
        t(TRANSLATION_LOCATION + 'validation_required')
      )
    }),
    initialValues: {
      title: career.title,
      category: career.category || '-1',
      language: career.language || DE
    },
    onSubmit: ({ title, category, language }) => {
      updateHandler({
        input: {
          id: career.id,
          title,
          category,
          language,
          history: updateCareerHistory(
            career.history,
            UPDATE_SETTINGS,
            JSON.stringify({
              title: career.title,
              category: career.category,
              language: career.language
            }),
            JSON.stringify({
              title,
              category,
              language
            }),
            cognitoUser.username
          )
        },
        onSuccess: () => {
          success(t('career.flex.update_career_success'))
          trackEvent(trackEvents.UPDATE_CAREER_ASSESSMENT_SETTINGS)
          formikGeneralSettings.setSubmitting(false)
        },
        onError: () => formikGeneralSettings.setSubmitting(false)
      })
    }
  })

  const formikRedirectUrl = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object({
      redirect_url: Yup.string().url(
        t(
          'components.settings.company_profile.input_career_site_url_validation'
        )
      )
    }),
    initialValues: {
      redirect_url: career.redirect_url || ''
    },
    onSubmit: ({ redirect_url }) => {
      updateHandler({
        input: {
          id: career.id,
          redirect_url,
          history: updateCareerHistory(
            career.history,
            UPDATE_SETTINGS,
            JSON.stringify({
              redirect_url: career.redirect_url
            }),
            JSON.stringify({
              redirect_url
            }),
            cognitoUser.username
          )
        },
        onSuccess: () => {
          success(t('career.flex.update_career_success'))
          trackEvent(trackEvents.UPDATE_CAREER_ASSESSMENT_SETTINGS)
          formikRedirectUrl.setSubmitting(false)
        },
        onError: () => formikRedirectUrl.setSubmitting(false)
      })
    }
  })

  const updateURL = ({ values }) => {
    if (values.redirect_url && !values.redirect_url.startsWith('http')) {
      formikRedirectUrl.setFieldValue(
        'redirect_url',
        ['https://', values.redirect_url].join('')
      )
    }
  }

  const uploadPhoto = async ({ image }) => {
    formikGeneralSettings.setSubmitting(true)

    try {
      const extension = image.name.split('.').pop()
      const result = await Storage.put(
        `career-photos/${career.id}.${extension}`,
        image
      )
      const updatedPhotoUrl = (await Storage.get(result.key)).split('?')[0]

      updateHandler({
        input: {
          id: career.id,
          logo: updatedPhotoUrl
        },
        onSuccess: () => {
          trackEvent(trackEvents.UPDATE_CAREER_ASSESSMENT_SETTINGS_PHOTO_URL)
          setPhotoUrl(updatedPhotoUrl)
          success(t('career.flex.update_career_success'))
        },
        onError: () => formikGeneralSettings.setSubmitting(false)
      })
    } catch (err) {
      error()
      notifyBugsnag(err)
    } finally {
      formikGeneralSettings.setSubmitting(false)
    }
  }

  const archiveCareer = () => {
    const { delete: can_delete } = active_space.authorization_role.crud

    if (!can_delete) {
      error(t('not_authorized_error_message'))
      return
    }

    alert(
      t(TRANSLATION_LOCATION + 'modal_archive_title'),
      [t(TRANSLATION_LOCATION + 'modal_archive_text')],
      [
        {
          onPress: () => {
            updateHandler({
              input: {
                id: career.id,
                archived: true,
                history: updateCareerHistory(
                  career.history,
                  UPDATE_ARCHIVED,
                  career.status,
                  'ARCHIVED',
                  cognitoUser.username
                )
              },
              onSuccess: () => {
                trackEvent(trackEvents.ARCHIVE_CAREER)
                success(t(TRANSLATION_LOCATION + 'archive_success'))

                // make sure this career is not selected inside filter bar /talents
                localStorage.setItem('/talents', '')

                navigate('/careers')
              }
            })
          },
          text: t(TRANSLATION_LOCATION + 'modal_archive_confirm_action'),
          style: 'destructive'
        },
        {
          text: t(TRANSLATION_LOCATION + 'modal_cancel_action'),
          style: 'cancel'
        }
      ]
    )
  }

  return (
    <div className='mb-12 mt-4'>
      <div className='my-6 md:flex md:items-center'>
        <div className='md:flex-auto'>
          <h1 className='text-xl font-semibold text-gray-900'>
            {t('career.assessmentsettings.title')}
          </h1>
          <p className='mt-2 max-w-lg lg:max-w-2xl text-sm text-gray-700'>
            {t('career.assessmentsettings.description', {
              career_title: career.title
            })}
          </p>
        </div>
      </div>
      <Divider />
      <CareerSettingsContainer
        title={t('career.assessmentsettings.basis.title')}
        description={t('career.assessmentsettings.basis.description')}
        dirty={formikGeneralSettings.dirty}
        onSubmit={formikGeneralSettings.handleSubmit}
        isLoading={formikGeneralSettings.isSubmitting}
      >
        <div className='max-w-xl'>
          <Input
            id={'title'}
            label={t(TRANSLATION_LOCATION + 'edit_career_title_label')}
            type='text'
            placeholder={t(
              TRANSLATION_LOCATION + 'edit_career_title_placeholder'
            )}
            onChange={formikGeneralSettings.handleChange}
            onBlur={formikGeneralSettings.handleBlur}
            value={formikGeneralSettings.values.title}
            touched={formikGeneralSettings.touched.title}
            error={formikGeneralSettings.errors.title}
            disabled={formikGeneralSettings.isSubmitting}
          />
        </div>
        <div>
          <SelectMenu
            id='career_category'
            label={t(TRANSLATION_LOCATION + 'category_label')}
            options={getAllJobCategories()}
            defaultValue={formikGeneralSettings.values.category}
            onChange={(category) =>
              formikGeneralSettings.setFieldValue('category', category)
            }
            className='w-full sm:w-2/3'
          />
        </div>
        <div>
          <SelectMenu
            id='language'
            label={t(TRANSLATION_LOCATION + 'language_label')}
            options={getAllLanguages()}
            defaultValue={formikGeneralSettings.values.language}
            onChange={(language) =>
              formikGeneralSettings.setFieldValue('language', language)
            }
            className='w-full sm:w-2/3'
          />
        </div>
        <div>
          <UploadPicture
            title={t('career.assessmentsettings.basis.photo_url_title')}
            subtitle={t(
              'components.settings.company_profile.input_partner_logo_hint'
            )}
            imageSrc={photoUrl}
            onSubmit={uploadPhoto}
            ImageCropComponent={ImageCropAssessment}
          />
        </div>
      </CareerSettingsContainer>
      <Divider />
      <CareerSettingsContainer
        title={t('career.assessmentsettings.mail_template.title')}
        description={t('career.assessmentsettings.mail_template.description')}
        background='bg-white'
        padding='px-2'
        withNewBadge
      >
        <div className='-mt-4 divide-y divide-gray-100'>
          <MailTemplatePreviewCard
            template={{
              ...mailTemplate,
              badgeColor: 'green',
              subtitle: t(
                'career.assessmentsettings.mail_template.template_subtitle'
              )
            }}
            actions={
              <Button.PrimarySM
                text={t('career.assessmentsettings.mail_template.edit_action')}
                onClick={() => setShowEditMailTemplate(true)}
              />
            }
          />
          <MailTemplatePreviewCard
            template={{
              ...mailTemplateReminder,
              badgeColor: 'yellow',
              subtitle: t(
                'career.assessmentsettings.mail_template.reminder_template_subtitle'
              )
            }}
            actions={
              <Button.PrimarySM
                text={t('career.assessmentsettings.mail_template.edit_action')}
                onClick={() => setShowEditMailTemplateReminder(true)}
              />
            }
          />
          <MailTemplatePreviewCard
            template={{
              ...mailTemplateReject,
              badgeColor: 'red',
              subtitle: t(
                'career.assessmentsettings.mail_template.reject_template_subtitle'
              )
            }}
            actions={
              <Button.PrimarySM
                text={t('career.assessmentsettings.mail_template.edit_action')}
                onClick={() => setShowEditMailTemplateReject(true)}
              />
            }
          />
        </div>
      </CareerSettingsContainer>
      <Divider />
      <CareerSettingsContainer
        title={t('career.assessmentsettings.redirect_url.title')}
        description={t('career.assessmentsettings.redirect_url.description')}
        dirty={formikRedirectUrl.dirty}
        onSubmit={formikRedirectUrl.handleSubmit}
        isLoading={formikRedirectUrl.isSubmitting}
        withLockedIcon={
          redirectTalentUrl ? null : { onClick: () => showPaywall('general') }
        }
      >
        <div
          className={classNames(
            'relative',
            !redirectTalentUrl && 'cursor-pointer'
          )}
          onClick={() => {
            if (!redirectTalentUrl) {
              showPaywall('general')
            }
          }}
        >
          <div
            className={classNames(
              'relative max-w-3xl',
              !redirectTalentUrl && 'blur-sm pointer-events-none'
            )}
          >
            <Input
              id={'redirect_url'}
              label={t('career.flex.redirecturl_title')}
              type='text'
              disabled={!redirectTalentUrl}
              placeholder={
                redirectTalentUrl ? 'https://www.aivy.app' : undefined
              }
              description={t(
                'career.assessmentsettings.redirect_url.input_description'
              )}
              onChange={formikRedirectUrl.handleChange}
              onBlur={(event) =>
                formikRedirectUrl.handleBlur(event) ||
                updateURL(formikRedirectUrl)
              }
              touched={formikRedirectUrl.touched.redirect_url}
              value={formikRedirectUrl.values.redirect_url}
              error={formikRedirectUrl.errors.redirect_url}
            />
          </div>
        </div>
      </CareerSettingsContainer>
      {!career.archived && (
        <>
          <Divider />
          <CareerSettingsContainer
            title={t('career.assessmentsettings.archive.title')}
            description={t(TRANSLATION_LOCATION + 'archive_description')}
          >
            <div>
              <Button.PrimaryLG
                text={t(TRANSLATION_LOCATION + 'archive_action')}
                onClick={archiveCareer}
                className='bg-red-600 hover:bg-red-500'
              />
              <div className='mt-6 text-sm text-gray-700 max-w-lg'>
                <p>{t(TRANSLATION_LOCATION + 'modal_archive_attention_1')}</p>
              </div>
              <div className='mt-2 text-sm leading-6'>
                <button
                  onClick={() => navigate('/recover/careers')}
                  className='font-semibold text-blue-600 hover:text-blue-500'
                >
                  {t('recover_careers.recover_action_text')}
                  <span aria-hidden='true'> &rarr;</span>
                </button>
              </div>
            </div>
          </CareerSettingsContainer>
        </>
      )}
      <EditMailTemplate
        open={showEditMailTemplate}
        setOpen={setShowEditMailTemplate}
        mailTemplate={mailTemplate}
        mailTemplateMacros={mailTemplateMacros}
        handleSubmit={({ result, onSuccess, onError }) => {
          const app_settings = career.app_settings
          app_settings.mail_template = result

          const input = { id: career.id, app_settings }
          updateHandler({ input, onSuccess, onError })
        }}
      />
      <EditMailTemplate
        open={showEditMailTemplateReminder}
        setOpen={setShowEditMailTemplateReminder}
        mailTemplate={mailTemplateReminder}
        mailTemplateMacros={mailTemplateMacros}
        handleSubmit={({ result, onSuccess, onError }) => {
          const app_settings = career.app_settings
          app_settings.mail_template_reminder = result

          const input = { id: career.id, app_settings }
          updateHandler({ input, onSuccess, onError })
        }}
      />
      <EditMailTemplate
        open={showEditMailTemplateReject}
        setOpen={setShowEditMailTemplateReject}
        mailTemplate={mailTemplateReject}
        mailTemplateMacros={mailTemplateMacrosReject}
        handleSubmit={({ result, onSuccess, onError }) => {
          const app_settings = career.app_settings
          app_settings.mail_template_reject = result

          const input = { id: career.id, app_settings }
          updateHandler({ input, onSuccess, onError })
        }}
      />
    </div>
  )
}

AssessmentSettings.propTypes = {
  career: PropTypes.object.isRequired,
  updateHandler: PropTypes.func.isRequired
}

export default AssessmentSettings
