import { useCallback, useMemo } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { usePartnerContext, useUserContext } from '../context'
import { query, mutation } from '../graphql'
import { notifyBugsnag } from '../helpers'

const checkHasChallengeConfigId = ({
  id: career_id,
  status,
  challenge_config_id
}) => {
  if (
    status === 'ACTIVE' &&
    !challenge_config_id &&
    !career_id.includes('PRODUCT_TOUR')
  ) {
    notifyBugsnag(
      new Error(`challenge_config_id is missing - career_id: ${career_id}`)
    )
  }
}

const deleteIfIsProductTourCareer = ({ career_id, user_id }) => {
  if (career_id === `PRODUCT_TOUR-${user_id}`) {
    mutation({ mutation: 'deleteCareer', input: { id: career_id } })
  }
}

export const useCareersQuery = ({ select }) => {
  const { active_space } = useUserContext()
  const { partner } = usePartnerContext()

  const queryClient = useQueryClient()

  const spaces_to_careers = useMemo(() => {
    const { state } =
      queryClient.getQueryCache().find({ queryKey: ['talents', partner.id] }) ||
      {}

    if (state?.status !== 'success') return null

    const result = new Map()

    // queryKey: ['talents', partner.id]
    // queryFn: () => Promise.all([getAllSpaces(), getCareers()])
    // select: [0] = spaces / [1] = careers
    state.data[0].forEach((space) =>
      result.set(
        space.career_id,
        (result.get(space.career_id) || []).concat([{ ...space }])
      )
    )

    return result
  }, [queryClient, partner.id])

  const getNumberOfSpaces = useCallback(
    ({ id, status }) => {
      if (!spaces_to_careers || status !== 'ACTIVE') return 'n/a'
      if (!spaces_to_careers.get(id)) return 0

      return spaces_to_careers.get(id).length
    },
    [spaces_to_careers]
  )

  const use_query = useQuery({
    queryKey: ['careers', partner.id],
    queryFn: async () => {
      const response = await query({
        query: 'careerByPartnerByDate',
        variables: { partner_id: partner.id }
      })

      response.forEach((item) => {
        checkHasChallengeConfigId(item)
        deleteIfIsProductTourCareer({
          career_id: item.id,
          user_id: active_space.id
        })
      })

      return response.map((item) => ({
        ...item,
        number_of_spaces: getNumberOfSpaces(item)
      }))
    },
    select
  })

  return use_query
}
