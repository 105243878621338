import { createContext, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'

const PartnerContext = createContext()

PartnerContext.displayName = 'PartnerContext'

export function PartnerContextProvider(props) {
  const value = useMemo(() => {
    const report_settings = JSON.parse(
      props.partner.app_settings?.report_settings || '{}'
    )

    const isScoreVisible =
      report_settings.score_visible === undefined
        ? true
        : report_settings.score_visible

    return {
      partner: { ...props.partner, isScoreVisible },
      refetchPartner: props.refetchPartner
    }
  }, [props.partner, props.refetchPartner])

  return <PartnerContext.Provider value={value} {...props} />
}

PartnerContextProvider.propTypes = {
  partner: PropTypes.object.isRequired,
  refetchPartner: PropTypes.func.isRequired
}

export function usePartnerContext() {
  const context = useContext(PartnerContext)

  if (!context) {
    throw new Error('usePartnerContext must be used within a AppProvider')
  }

  return context
}
