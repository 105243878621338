let notification = {}

export const setNotification = (n) => {
  notification = n || {}
}

export const getNotification = () => {
  return notification
}

export default notification
