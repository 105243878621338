import { useState } from 'react'
import { renderToString } from 'react-dom/server'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { prepareSource, uuidv4 } from '../../helpers'
import {
  useAivyContext,
  useNotificationContext,
  usePartnerContext,
  useUserContext
} from '../../context'

import Tiptap from '../tiptap/tiptap'
import InputFormik from '../input-formik'
import MailTemplatePreview from './mail-template-preview'
import Button from '../tailwind/Button'
import Modal from '../tailwind/Modal'
import EmailClientIllustration from '../../assets/images/emailclient.png'
import { query } from '../../graphql'
import { trackEvent } from '../../helpers/analytics'
import trackEvents from '../../constants/track-events'

const EditMailTemplate = ({
  open,
  setOpen,
  mailTemplate,
  mailTemplateMacros,
  handleSubmit,
  preview
}) => {
  const { t } = useTranslation()
  const { active_space } = useUserContext()
  const { system } = useAivyContext()
  const { success } = useNotificationContext()
  const { partner } = usePartnerContext()

  const [isLoadingSendPreview, setIsLoadingSendPreview] = useState(false)
  const [sendPreviewSuccess, setSendPreviewSuccess] = useState(false)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      subject: mailTemplate.subject,
      data: JSON.parse(mailTemplate.data)
    },
    validationSchema: Yup.object({
      subject: Yup.string().required(
        t('mail_template.edit_mail_template.subject_validation')
      )
    }),
    onSubmit: ({ subject, data }, { setSubmitting }) => {
      setSubmitting(true)

      const result = {
        id: mailTemplate.id,
        createdAt: mailTemplate.createdAt,
        subject,
        data: JSON.stringify(data),
        updatedAt: new Date().toISOString()
      }

      // create a new template
      if (!result.id) {
        result.id = uuidv4()
        result.createdAt = new Date().toISOString()
      }

      const onSuccess = () => {
        setSubmitting(false)
        success(
          t('mail_template.edit_mail_template.update_mail_templates_success')
        )
      }

      handleSubmit({ result, onSuccess, onError: () => setSubmitting(false) })
    }
  })

  const closeModal = () => {
    setOpen(false)
    formik.resetForm()
  }

  if (preview) {
    return (
      <Modal open={open} setOpen={closeModal} size='4xl'>
        <MailTemplatePreview
          system={system}
          imageUrl={mailTemplate.imageUrl}
          language={mailTemplate.language}
          useInvitationBlock={mailTemplate.useInvitationBlock}
          data={formik.values.data}
          mailTemplateMacros={mailTemplateMacros}
        />
      </Modal>
    )
  }

  const handleSendTestMail = () => {
    trackEvent(trackEvents.SEND_TEST_EMAIL)
    setIsLoadingSendPreview(true)

    const source = prepareSource(`${partner.display_name || partner.name}`)
    const email =
      active_space.person.email || active_space.person.invitation_email

    const variables = {
      // <notification@aivy.app> is set from lambda sendMail
      source: source.replace(' <notification@aivy.app>', ''),
      toAddresses: [email],
      subject: formik.values.subject,
      body: JSON.stringify({
        Html: {
          Charset: 'UTF-8',
          Data: renderToString(
            <MailTemplatePreview
              system={system}
              imageUrl={mailTemplate.imageUrl}
              language={mailTemplate.language}
              useInvitationBlock={mailTemplate.useInvitationBlock}
              data={formik.values.data}
              mailTemplateMacros={mailTemplateMacros}
              createClientMail
            />
          )
        }
      })
    }

    query({ query: 'sendMail', variables }).then(() => {
      setSendPreviewSuccess(true)
      setTimeout(() => {
        setIsLoadingSendPreview(false)
        setSendPreviewSuccess(false)
      }, 1500)
    })
  }

  return (
    <Modal open={open} setOpen={closeModal} size={'5xl'}>
      <h1 className='text-2xl font-bold text-gray-900'>
        {t('mail_template.edit_mail_template.title')}
      </h1>
      <div className='mt-4 grid gap-x-8 gap-y-4 grid-cols-1 grid-rows-2 lg:grid-rows-1 lg:grid-cols-2'>
        <div className='w-full'>
          <h2 className='font-semibold text-gray-900 mb-1'>
            {t('mail_template.edit_mail_template.content_label')}
          </h2>
          <div>
            <InputFormik {...{ id: 'subject', type: 'text', formik }} />
            <div className='mt-4'>
              <Tiptap
                inputContainerStyle={{ height: 350, overflow: 'scroll' }}
                initialContent={formik.initialValues.data}
                macros={mailTemplateMacros}
                onUpdate={({ editor }) => {
                  formik.setFieldValue('data', editor.getJSON())
                }}
              />
            </div>
          </div>
        </div>
        <div className=' w-full'>
          <h2 className='font-semibold text-gray-900 mb-1'>
            {t('mail_template.edit_mail_template.preview_label')}
          </h2>
          <div>
            <div className='h-10 flex items-center'>
              <span className='text-base font-bold text-gray-900'>
                {formik.values.subject}
              </span>
            </div>
            <img
              src={EmailClientIllustration}
              className='w-full h-auto mt-4 rounded-t-xl overflow-hidden'
            />
            <div
              style={{ height: 360 }}
              className='overflow-scroll rounded-b-xl border border-gray-200 '
            >
              <MailTemplatePreview
                system={system}
                imageUrl={mailTemplate.imageUrl}
                language={mailTemplate.language}
                useInvitationBlock={mailTemplate.useInvitationBlock}
                data={formik.values.data}
                mailTemplateMacros={mailTemplateMacros}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='mt-8 flex justify-between items-center gap-x-2'>
        <div className='flex flex-col items-start'>
          <Button.Text
            text={t('mail_template.edit_mail_template.send_test_action')}
            isLoading={isLoadingSendPreview}
            onClick={handleSendTestMail}
          />
          {sendPreviewSuccess && (
            <span className='block text-sm text-green-600 mt-2'>
              {t('mail_template.edit_mail_template.send_test_action_success')}
            </span>
          )}
        </div>
        <div className='flex gap-x-2 items-center'>
          <Button.SecondaryLG
            text={t('mail_template.edit_mail_template.cancel_button_text')}
            onClick={closeModal}
          />
          <Button.PrimaryLG
            text={t('mail_template.edit_mail_template.save_button_text')}
            onClick={formik.handleSubmit}
            disabled={!formik.dirty}
            isLoading={formik.isSubmitting}
          />
        </div>
      </div>
    </Modal>
  )
}

EditMailTemplate.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  mailTemplate: PropTypes.object.isRequired,
  mailTemplateMacros: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func,
  preview: PropTypes.bool
}

EditMailTemplate.defaultProps = {
  handleSubmit: () => undefined,
  preview: false
}

export default EditMailTemplate
