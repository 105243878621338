import PropTypes from 'prop-types'

const EmailBadge = ({ text, onClick }) => (
  <span
    className='inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-blue-100 text-blue-700 cursor-pointer'
    onClick={onClick}
  >
    {text}
    <button
      type='button'
      className='flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-blue-400 hover:bg-blue-200 hover:text-blue-500 focus:outline-none focus:bg-blue-500 focus:text-white'
    >
      <svg
        className='h-2 w-2'
        stroke='currentColor'
        fill='none'
        viewBox='0 0 8 8'
      >
        <path strokeLinecap='round' strokeWidth='1.5' d='M1 1l6 6m0-6L1 7' />
      </svg>
    </button>
  </span>
)

EmailBadge.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func
}

EmailBadge.defaultProps = {
  onClick: undefined
}

export default EmailBadge
