import PropTypes from 'prop-types'
import { classNames } from '../../helpers'

/**
 * TailwindUI Application UI / Elements / Badge
 * Supporting Basic badges, large badges
 * https://tailwindui.com/components/application-ui/elements/badges
 */
const Badge = ({ type, text, color }) => {
  function getColorClassname() {
    switch (color) {
      case 'gray':
        return 'bg-gray-100 text-gray-800'
      case 'red':
        return 'bg-red-100 text-red-800'
      case 'yellow':
        return 'bg-yellow-100 text-yellow-800'
      case 'green':
        return 'bg-green-100 text-green-800'
      case 'blue':
        return 'bg-blue-100 text-blue-800'
      case 'indigo':
        return 'bg-indigo-100 text-indigo-800'
      case 'purple':
        return 'bg-purple-100 text-purple-800'
      case 'pink':
        return 'bg-pink-100 text-pink-800'

      default:
        break
    }
  }

  return (
    <span
      className={classNames(
        'inline-flex items-center rounded-full py-0.5 font-medium',
        getColorClassname(),
        type === 'basic' ? 'px-2.5 text-xs' : 'px-3 text-sm'
      )}
    >
      {text}
    </span>
  )
}

Badge.propTypes = {
  type: PropTypes.oneOf(['basic', 'large']),
  text: PropTypes.string.isRequired,
  color: PropTypes.oneOf([
    'gray',
    'red',
    'yellow',
    'green',
    'blue',
    'indigo',
    'purple',
    'pink'
  ])
}

Badge.defaultProps = {
  type: 'basic',
  text: 'missing proptype text',
  color: 'gray'
}

export default Badge
