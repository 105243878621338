import { useCallback, useEffect, useState } from 'react'
import { Hub } from 'aws-amplify'

import { currentAuthenticatedUser, mutation, query } from '../graphql'
import { notifyBugsnag, setUserIdToAmplifyAnalytics } from '../helpers'
import { trackEvent } from '../helpers/analytics'
import trackEvents from '../constants/track-events'
import { localStorage } from '../helpers/local-storage'
import { useEventListener } from './use-event-listener'

export const useCognito = ({ restartDashboard }) => {
  // one of ['INITIAL', 'SUCCESS', 'SHOW_LOGIN', 'ERROR']
  const [state, setState] = useState('INITIAL')
  const [cognitoUser, setCognitoUser] = useState(null)

  const fetchCognitoUser = useCallback(
    ({ isOnFocusEventHandler }) => {
      if (!isOnFocusEventHandler) setState('INITIAL')

      currentAuthenticatedUser()
        .then(async (response) => {
          const username =
            response.signInUserSession.accessToken.payload.username
          const cognitoUser = { ...response, username }

          setUserIdToAmplifyAnalytics(username)

          const { payload } = cognitoUser.signInUserSession.accessToken
          const groups = [...(payload['cognito:groups'] || [])]

          const hasDashboardGroup = groups.includes('__customer_dashboard')
          const hasActivePartner = !!groups.find((g) =>
            g.includes('__active_partner_id')
          )

          if (!hasDashboardGroup && !hasActivePartner) {
            // universal-app-user || active_space.context !== 'EMPLOYEE'
            const spaces_by_owner = await query({
              query: 'spacesByOwner',
              variables: { owner: username }
            })

            const employees = spaces_by_owner
              .filter(({ context }) => context === 'EMPLOYEE')
              .sort((a, b) => new Date(b.last_seen) - new Date(a.last_seen))

            if (employees.length) {
              trackEvent(trackEvents.SET_ACTIVE_SPACE_AUTOMATICALLY)

              await mutation({
                mutation: 'updateUser',
                input: {
                  id: username,
                  active_space_id: employees[0].id,
                  lastUpdatedBy: username
                }
              })

              restartDashboard()
              return
            }

            setState('ACCESS_DENIED')

            const message = `access denied / universal-app-user - username: ${cognitoUser.username}`
            notifyBugsnag(new Error(message))
            return
          }

          if (!isOnFocusEventHandler) {
            trackEvent(trackEvents.LOGIN)
            setState('SUCCESS')
            setCognitoUser(cognitoUser)
          }
        })
        .catch((err) => {
          if (
            err === 'The user is not authenticated' &&
            !isOnFocusEventHandler
          ) {
            setCognitoUser(null)
            setState('SHOW_LOGIN')
            return
          } else if (
            err === 'The user is not authenticated' &&
            isOnFocusEventHandler
          ) {
            // do not set the state again
            return
          }

          setCognitoUser(null)
          notifyBugsnag(err)
          setState('ERROR')
        })
    },
    [restartDashboard]
  )

  useEffect(() => {
    fetchCognitoUser({})
  }, [fetchCognitoUser])

  useEffect(() => {
    Hub.listen('auth', ({ payload }) => {
      const { event } = payload

      if (event === 'signIn') {
        fetchCognitoUser({})
      }

      if (event === 'signOut') {
        localStorage.clear()
        restartDashboard()
      }
    })
  }, [fetchCognitoUser, restartDashboard])

  useEventListener({
    event: 'focus',
    handler: () => fetchCognitoUser({ isOnFocusEventHandler: true })
  })

  return {
    state,
    cognitoUser
  }
}
