import { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { trackEvent } from '../../helpers/analytics'
import { mutation, query } from '../../graphql'
import trackEvents from '../../constants/track-events'
import Button from '../button'
import Modal from '../tailwind/Modal'
import { useNotificationContext, useUserContext } from '../../context'
import Datepicker from '../react-datepicker/datepicker'

const TRANSLATION_LOCATION = 'components.career.send_reminder_bulk.'

const SendReminderBulk = ({ data, updateHandler, open, setOpen }) => {
  const { t } = useTranslation()
  const { success } = useNotificationContext()
  const { cognitoUser } = useUserContext()

  const { spaces } = data

  const space = spaces.length === 1 ? spaces[0] : null

  const [isLoading, setIsLoading] = useState(false)
  const [expiresAt, setExpiresAt] = useState(
    space && new Date(space.expiresAt) > new Date()
      ? new Date(space.expiresAt)
      : new Date(Date.now() + 12096e5) // fortnight away
  )

  const updateExpiresAt = () => {
    const event = {
      eventName: 'SYSTEM',
      afterStatus: 'REMINDED',
      createdAt: new Date().toISOString(),
      eventFiredBy: cognitoUser.username
    }

    expiresAt.setHours(23)
    expiresAt.setMinutes(59)
    expiresAt.setSeconds(59)

    return Promise.all(
      spaces.map(({ id, history }) =>
        mutation({
          mutation: 'updateSpace',
          input: {
            id,
            history: (history || []).concat(event),
            expiresAt: expiresAt.toISOString()
          }
        })
      )
    )
  }

  const remindTalents = async () => {
    setIsLoading(true)

    try {
      await updateExpiresAt()

      await Promise.all(
        spaces.map(
          ({ id: space_id, career_id, partner_id, email, language }) => {
            return query({
              query: 'inviteTalent',
              variables: {
                space_id,
                career_id,
                partner_id,
                email,
                language,
                email_template: 'remind'
              }
            })
          }
        )
      )
    } catch (err) {
      setIsLoading(false)
      return
    }

    setIsLoading(false)
    updateHandler()
    trackEvent(trackEvents.REMIND_TALENTS)
    success(t(TRANSLATION_LOCATION + 'send_reminder_success'))
    setOpen(false)
  }

  return (
    <Modal open={open} setOpen={() => setOpen(false)} size='3xl'>
      <div className='mt-3 text-center sm:mt-5'>
        <h2 className='text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
          {t(TRANSLATION_LOCATION + 'send_reminder_modal_title')}
        </h2>
        <p className='mt-2 max-w-lg mx-auto text-sm text-gray-700'>
          {t(TRANSLATION_LOCATION + 'popup_description', {
            number: spaces.length
          })}
        </p>
      </div>
      <div className='mt-8 px-12 flex justify-center'>
        <p className='block max-w-lg text-sm text-gray-700 text-center'>
          {t(TRANSLATION_LOCATION + 'hint')}
        </p>
      </div>
      <div className='mt-8 px-12'>
        <p className='block text-sm font-medium text-gray-700'>
          {t(TRANSLATION_LOCATION + 'reset_expired_title')}
        </p>
        <div className='mt-2'>
          <Datepicker
            selected={expiresAt}
            onChange={(date) => setExpiresAt(date)}
            minDate={new Date()}
          />
        </div>
        <p className='text-gray-500 text-xs mt-2 italic'>
          {t(TRANSLATION_LOCATION + 'reset_expired_description')}
        </p>
      </div>
      <div className='mt-16 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense md:flex md:justify-center'>
        <Button
          text={t(TRANSLATION_LOCATION + 'send_reminder_modal_cancel_action')}
          onClick={() => setOpen(false)}
          appearance='w-full-gray'
          className='md:w-80'
        />
        <Button
          text={t(TRANSLATION_LOCATION + 'send_reminder_modal_submit_action')}
          onClick={remindTalents}
          appearance='w-full-base'
          className='md:w-80'
          isLoading={isLoading}
        />
      </div>
    </Modal>
  )
}

SendReminderBulk.propTypes = {
  data: PropTypes.object.isRequired,
  updateHandler: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
}

export default SendReminderBulk
