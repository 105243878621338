import { useTranslation } from 'react-i18next'
import { Hub } from 'aws-amplify'

import AccessDeniedPNG from '../assets/images/illustrations/access-expired.png'
const { version } = require('../../package.json')

const AccessDenied = () => {
  const { t } = useTranslation()

  return (
    <div className='p-16 flex flex-col items-center'>
      <img className='h-80 w-80 object-contain' src={AccessDeniedPNG} alt='' />
      <div className='py-4 px-6 sm:px-6 sm:py-8 lg:px-8'>
        <div className='mx-auto max-w-2xl text-center'>
          <h2 className='text-lg font-medium text-gray-900'>
            {t('access_denied.title')}
          </h2>
          <p className='mx-auto mt-2 max-w-xl text-sm text-gray-700'>
            {t('access_denied.description')}
          </p>
          <div className='mt-6 flex items-center justify-center gap-x-6'>
            <a
              href='mailto:help@aivy.app?subject=Aivy-Dashboard - Fehlende Berechtigung'
              className='text-sm font-semibold leading-6 text-gray-900'
            >
              {t('access_denied.support_action')}
            </a>
            <button
              onClick={() => Hub.dispatch('auth', { event: 'signOut' })}
              className='text-sm font-semibold leading-6 text-gray-900'
            >
              {t('access_denied.logout_action')}
            </button>
          </div>
        </div>
      </div>
      <div className='mt-4 text-center'>
        <span className='text-xs italic text-gray-700 text-center'>{`v${version}`}</span>
      </div>
    </div>
  )
}

export default AccessDenied
