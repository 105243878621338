import { useEffect, useState, Fragment, useMemo } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  BriefcaseIcon,
  CalendarIcon,
  CodeBracketIcon,
  LinkIcon,
  HomeIcon,
  AcademicCapIcon,
  AdjustmentsHorizontalIcon
} from '@heroicons/react/24/solid'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { format } from 'date-fns'
import { de, enUS } from 'date-fns/locale'
import { truncate } from 'lodash'

import { getJobCategory } from '../constants/job-categories'
import { ActivityIndicator, Container, Seo } from '../components'
import { classNames, notifyBugsnag, toBase64 } from '../helpers'
import { trackEvent } from '../helpers/analytics'
import Overview from '../components/career/overview'
import AssessmentSettings from '../components/career/assessment-settings'
import { mutation, query } from '../graphql'
import PageHeading from '../components/tailwind/PageHeading'
import Attract from '../components/career/attract'
import trackEvents from '../constants/track-events'
import Button from '../components/tailwind/Button'
// import { useMutationAndUpdateQuery } from '../hooks/use-mutation-and-update-query'
import { useMount } from '../hooks/use-mount'
import Flex from '../components/career/flex'
import {
  useLanguageContext,
  useProductTourContext,
  useUserContext,
  usePartnerContext,
  useNotificationContext
} from '../context'
import { getCopyCareerInput, getCreateCareerInput } from '../helpers/career'
import Error404 from './error-404'
import Tabs from '../components/tailwind/tabs'

const TRANSLATION_LOCATION = 'pages.career.'
const locale = { de, en: enUS }

const Career = () => {
  const { id } = useParams() // CHECK SECURITY
  const navigate = useNavigate()
  const { hash } = useLocation()
  const { partner } = usePartnerContext()
  const { cognitoUser } = useUserContext()
  const { getLanguage } = useLanguageContext()
  const { t } = useTranslation()
  const { error } = useNotificationContext()

  const {
    setProductTourState,
    productTourState: { tourActive }
  } = useProductTourContext()

  useMount(() => {
    if (tourActive) {
      setTimeout(() => {
        setProductTourState({ run: true })
      }, 0.4 * 1000)
    }
  })

  const {
    status,
    data: career,
    refetch
  } = useQuery({
    queryKey: ['career', id],
    queryFn: () => query({ query: 'getCareer', variables: { id } })
  })

  // amplify bug! - updateCareer: res - { career_analyse: null }
  // const careerMutation = useMutationAndUpdateQuery({
  //   queryKey: ['career', id],
  //   mutation: 'updateCareer'
  // })

  const careerMutation = {
    // implement react-query use mutation functionality with refetch
    mutate: async ({ input }, callbacks = {}) => {
      const { onSuccess, onError } = callbacks

      let response
      // amplify bug! - updateCareer: res - { career_analyse: null }
      try {
        response = await mutation({ mutation: 'updateCareer', input })
      } catch (err) {
        onError && onError(response)
        return
      }

      await refetch()
      onSuccess && onSuccess(response)
    }
  }

  const queryClient = useQueryClient()

  const copy_from_career = useMemo(() => {
    const { copy_from_career_id } = career || {}
    if (!copy_from_career_id) return null

    const { state } =
      queryClient.getQueryCache().find({ queryKey: ['careers', partner.id] }) ||
      {}

    if (state?.status !== 'success') return { id: copy_from_career_id }

    const copy_from = state.data.find(({ id }) => id === copy_from_career_id)

    return copy_from || { id: copy_from_career_id }
  }, [queryClient, career, partner.id])

  const [isDuplicating, setIsDuplicating] = useState(false)

  const tabs = [
    {
      name: t('career.overview.title'),
      to: '#overview',
      current: hash === '#overview',
      icon: HomeIcon
    },
    {
      name: t(TRANSLATION_LOCATION + 'attract_tab_title'),
      to: '#attract',
      current: hash === '#attract',
      icon: AcademicCapIcon
    },
    {
      name: t(TRANSLATION_LOCATION + 'flex_tab_title'),
      to: '#flex',
      current: hash === '#flex',
      withNewBadge: true,
      icon: LinkIcon
    },
    {
      name: t(TRANSLATION_LOCATION + 'assessment_settings_tab_title'),
      to: '#assessment-settings',
      current: hash === '#assessment-settings',
      icon: AdjustmentsHorizontalIcon
    }
  ]

  useEffect(() => {
    if (!career) return
    if (career.archived) return

    if (!hash || hash === '#requirement-profile') {
      navigate('#overview', { replace: true })
    }
  }, [career, hash, navigate])

  const duplicateCareer = async () => {
    setIsDuplicating(true)
    trackEvent(trackEvents.DUPLICATE_CAREER)

    const createCareerInput = getCreateCareerInput({
      title: career.title,
      category: career.category,
      language: career.language,
      partner_id: partner.id,
      username: cognitoUser.username,
      status: null
    })

    const copyCareerInput = getCopyCareerInput({ fromCareer: career })

    let copied_career_id
    try {
      const create_career = await mutation({
        mutation: 'createCareer',
        input: {
          ...createCareerInput,
          ...copyCareerInput,
          copy_from_career_id: career.id
        }
      })
      copied_career_id = create_career.id
    } catch (err) {
      error()
      notifyBugsnag(err)
      return
    }

    navigate(`/career/new/${copied_career_id}`)
  }

  if (status === 'pending') {
    return (
      <div className='mx-32 my-32'>
        <ActivityIndicator />
      </div>
    )
  }

  if (!career) return <Error404 />

  return (
    <>
      <Seo title={career.title} />
      <PageHeading
        withActions={
          <div className='flex flex-wrap lg:flex-none items-center gap-x-2'>
            {!career.archived && (
              <Button.PrimaryXL
                text={t(TRANSLATION_LOCATION + 'button_title_nav_talents')}
                onClick={() => {
                  const location = {
                    pathname: '/talents',
                    search: `computed=${toBase64([
                      { assessment_id: { in: [id] } }
                    ])}`
                  }

                  localStorage.setItem(location.pathname, location.search)
                  navigate(location.pathname)
                }}
              />
            )}
            {!career.archived && (
              <Button.SecondaryXL
                text={t(TRANSLATION_LOCATION + 'actions.clone')}
                onClick={duplicateCareer}
                isLoading={isDuplicating}
              />
            )}
          </div>
        }
        sticky
      >
        <div>
          <h2 className='font-bold text-gray-900 truncate text-2xl tracking-tight'>
            {truncate(career.title, { length: 60 })}
          </h2>
          <div className='mt-1 hidden xl:flex xl:flex-wrap xl:gap-y-1 xl:gap-x-4'>
            {[
              {
                label: t(getJobCategory(career.category || '-1')),
                Icon: BriefcaseIcon
              },
              {
                label: t(TRANSLATION_LOCATION + 'created_at', {
                  createdAt: format(
                    new Date(career.createdAt),
                    'd. MMMM yyyy',
                    {
                      locale: locale[getLanguage()]
                    }
                  )
                }),
                Icon: CalendarIcon
              },
              career.external_custom_id && {
                label: career.external_custom_id,
                Icon: CodeBracketIcon
              },
              copy_from_career && {
                label: t(TRANSLATION_LOCATION + 'copy_from', {
                  career: copy_from_career.title || copy_from_career.id
                }),
                Icon: LinkIcon,
                navigate: () => navigate(`/career/${copy_from_career.id}`)
              }
            ]
              .filter((item) => item)
              .map(({ label, Icon, navigate }, index) => (
                <div
                  key={index}
                  onClick={navigate}
                  className={classNames(
                    'flex items-center text-sm text-gray-700 whitespace-nowrap',
                    navigate && 'group cursor-pointer hover:text-blue-600'
                  )}
                >
                  <Icon
                    className='mr-1.5 h-5 w-5 flex-shrink-0'
                    aria-hidden='true'
                  />
                  {label}
                </div>
              ))}
          </div>
        </div>
      </PageHeading>
      <Container>
        {career.status === 'ACTIVE' && !career.archived && (
          <Fragment>
            <div className='h-8' />
            <Tabs tabs={tabs} />
            {hash === '#overview' && <Overview career={career} />}
            {hash === '#attract' && (
              <Attract
                career={career}
                updateHandler={({ input, onSuccess, onError }) => {
                  careerMutation.mutate({ input }, { onSuccess, onError })
                }}
              />
            )}
            {hash === '#flex' && (
              <Flex
                career={career}
                updateHandler={({ input, onSuccess, onError }) => {
                  careerMutation.mutate({ input }, { onSuccess, onError })
                }}
              />
            )}
            {hash === '#assessment-settings' && (
              <AssessmentSettings
                career={career}
                updateHandler={({ input, onSuccess, onError }) => {
                  careerMutation.mutate({ input }, { onSuccess, onError })
                }}
              />
            )}
          </Fragment>
        )}
        {career.archived && (
          <div className='mt-12'>
            <div className='my-6'>
              <div>
                <h3 className='mb-2 text-lg leading-6 font-medium text-gray-900'>
                  {t(TRANSLATION_LOCATION + 'archived_title')}
                </h3>
                <p className='mb-4 max-w-2xl text-sm text-gray-600'>
                  {t(TRANSLATION_LOCATION + 'archived_description')}
                </p>
                <p className='mb-4 max-w-2xl text-sm text-gray-600'>
                  {t(TRANSLATION_LOCATION + 'archive_description')}
                </p>
                <p className='max-w-2xl'>
                  <span className='text-sm text-gray-600 italic'>
                    {t(TRANSLATION_LOCATION + 'last_state_label')}
                  </span>
                  {[
                    null,
                    'WAIT_EXPERTS',
                    'WAIT_CONFIG',
                    'WAIT_ACTIVE'
                  ].includes(career.status) && (
                    <span className='ml-1 text-sm text-gray-900 font-bold'>
                      {t(TRANSLATION_LOCATION + 'state_in_progress')}
                    </span>
                  )}
                  {['ACTIVE'].includes(career.status) && (
                    <span className='ml-1 text-sm text-gray-900 font-bold'>
                      {t(TRANSLATION_LOCATION + 'state_active')}
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>
        )}
        <div className='mt-12 mb-4 flex justify-center'>
          <span className='text-xs italic text-gray-700 text-center'>
            {t(TRANSLATION_LOCATION + 'career_id', { career_id: career.id })}
          </span>
        </div>
      </Container>
    </>
  )
}

export default Career
