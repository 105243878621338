import { useMemo } from 'react'
import { usePaymentContext } from '../context'

export const usePaymentRestrictions = ({ product, key, value }) => {
  const restrictions = usePaymentContext()

  const restricted = useMemo(() => {
    // negate the available product to return if its restricted
    if (product) return !restrictions[product]

    const restrictedValue = value >= restrictions[key]

    if (restrictedValue) {
      // TODO: send slack message
    }

    return restrictedValue
  }, [restrictions, product, key, value])

  return restricted
}
