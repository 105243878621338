import { useCallback, useState } from 'react'

// https://github.com/childrentime/reactuse/blob/main/packages/core/hooks/useSetState
export const useSetState = (initialState) => {
  const [state, _setState] = useState(initialState)

  const setState = useCallback((statePartial) => {
    _setState((current) => ({
      ...current,
      ...(typeof statePartial === 'function'
        ? statePartial(current)
        : statePartial)
    }))
  }, [])

  return [state, setState]
}
