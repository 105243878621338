import PropTypes from 'prop-types'

const Steps = ({ steps }) => {
  const currentStep = steps.find(({ current }) => current)

  return (
    <nav className='flex items-center justify-center' aria-label='Progress'>
      <p className='text-sm'>{currentStep.name}</p>
      <ol role='list' className='ml-8 flex items-center space-x-5'>
        {steps.map((step, index) => (
          <li key={index}>
            {step.complete ? (
              <div className='h-2.5 w-2.5 rounded-full bg-blue-600 hover:bg-blue-900'>
                <span className='sr-only'>{step.name}</span>
              </div>
            ) : step.current ? (
              <div
                className='relative flex items-center justify-center'
                aria-current='step'
              >
                <span className='absolute flex h-5 w-5 p-px' aria-hidden='true'>
                  <span className='h-full w-full rounded-full bg-blue-200' />
                </span>
                <span
                  className='relative h-2.5 w-2.5 rounded-full bg-blue-600'
                  aria-hidden='true'
                />
                <span className='sr-only'>{step.name}</span>
              </div>
            ) : (
              <div className='h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400'>
                <span className='sr-only'>{step.name}</span>
              </div>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}

Steps.propTypes = {
  steps: PropTypes.array
}

Steps.defaultProps = {
  steps: [
    {
      id: '01',
      name: 'Job details',
      href: '#',
      current: false,
      complete: true
    },
    {
      id: '02',
      name: 'Application form',
      href: '#',
      current: true,
      complete: false
    },
    { id: '03', name: 'Preview', href: '#' }
  ]
}

export default Steps
