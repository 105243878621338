import PropTypes from 'prop-types'
import { formatDistance } from 'date-fns'
import { de, enUS } from 'date-fns/locale'
import { useQuery } from '@tanstack/react-query'
import initials from 'initials'

import ActivityIndicator from '../activity-indicator'
import { query } from '../../graphql'
import { useLanguageContext } from '../../context'

const locale = { de, en: enUS }

const SpaceCommentListItem = ({ comment }) => {
  const { getLanguage } = useLanguageContext()

  const { status, data: person } = useQuery({
    queryKey: ['person', comment.person_id],
    queryFn: () =>
      query({ query: 'getPerson', variables: { id: comment.person_id } })
  })

  if (status === 'pending') return <ActivityIndicator />

  const name = `${
    person.firstname || person.invitation_firstname
  } ${person.lastname || person.invitation_lastname}`

  return (
    <li>
      <div className='flex space-x-3'>
        <div className='flex-shrink-0'>
          <span className='inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400'>
            <span className='font-medium leading-none text-white'>
              {initials(name)}
            </span>
          </span>
        </div>
        <div>
          <div className='text-sm'>
            <span className='font-medium text-gray-900'>{name}</span>
          </div>
          <div className='mt-1 text-sm text-gray-700'>
            <p>{comment.message}</p>
          </div>
          <div className='mt-2 text-sm space-x-2'>
            <span className='text-gray-500 italic'>
              {formatDistance(new Date(), new Date(comment.createdAt), {
                locale: locale[getLanguage()]
              })}
            </span>{' '}
          </div>
        </div>
      </div>
    </li>
  )
}

SpaceCommentListItem.propTypes = {
  comment: PropTypes.object.isRequired
}

export default SpaceCommentListItem
