import {
  LinkIcon,
  QrCodeIcon,
  AdjustmentsHorizontalIcon
} from '@heroicons/react/24/solid'
import PropTypes from 'prop-types'

import ScreenshotFlex from '../../../assets/images/screenshot-flex.png'
import { useTranslation } from 'react-i18next'

const features = [
  {
    name: 'career.flex.feature_section.link_name',
    description: 'career.flex.feature_section.link_description',
    icon: LinkIcon
  },
  {
    name: 'career.flex.feature_section.qrcode_name',
    description: 'career.flex.feature_section.qrcode_description',
    icon: QrCodeIcon
  },
  {
    name: 'career.flex.feature_section.data_and_description_name',
    description: 'career.flex.feature_section.data_and_description_description',
    icon: AdjustmentsHorizontalIcon
  }
]

const FeatureSection = ({ action }) => {
  const { t } = useTranslation()

  return (
    <div className='xl:flex'>
      <div className='xl:mt-4 max-w-2xl gap-y-16'>
        <div className='lg:pr-8 lg:pt-4'>
          <div className='lg:max-w-lg'>
            <dl className='max-w-xl space-y-8 text-sm text-gray-700 lg:max-w-none'>
              {features.map((feature, index) => (
                <div key={index} className='relative pl-9'>
                  <dt className='inline font-semibold text-gray-900'>
                    <feature.icon
                      className='absolute left-1 top-0 h-5 w-5 text-blue-600'
                      aria-hidden='true'
                    />
                    {t(feature.name)}
                  </dt>{' '}
                  <dd className='inline'>{t(feature.description)}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
        <div className='mt-8 pl-9'>{action}</div>
      </div>
      <img
        src={ScreenshotFlex}
        alt='Product screenshot'
        className='p-4 mt-8 w-full rounded-xl xl:mt-0 max-w-lg'
      />
    </div>
  )
}

FeatureSection.propTypes = {
  action: PropTypes.node.isRequired
}

export default FeatureSection
