import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import Input from '../input'
// import SelectMenu from '../select-menu'
// import { DE, getAllLanguages } from '../../constants/languages'
import { mutation } from '../../graphql'
import Button from '../tailwind/Button'
import { usePartnerContext } from '../../context'
import { trackEvent } from '../../helpers/analytics'
import trackEvents from '../../constants/track-events'

const Create = ({ analyse, analyseMutation }) => {
  const { t } = useTranslation()
  const { partner } = usePartnerContext()

  const navigate = useNavigate()

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      title: analyse.title || ''
      // language: analyse.language || DE // add to schema!
    },
    validationSchema: Yup.object({
      title: Yup.string().required(
        t('team_analyse.new.create.input_title_validation')
      )
    })
  })

  const next = async ({ title, language }) => {
    formik.setSubmitting(true)

    const input = {
      language,
      input: JSON.stringify({
        ...JSON.parse(analyse.input || '{}'),
        title,
        status: 'WAIT_SPACES'
      })
    }

    if (!analyse.id) {
      // create a new team analyse
      input.partner_id = partner.id
      input.authorized_create = ['__create_' + partner.id]
      input.authorized_read = ['__read_low_' + partner.id]
      input.authorized_update = ['__update_' + partner.id]
      input.authorized_delete = ['__delete_' + partner.id]
      input.context = 'TeamAnalyse'

      let analyse_id
      try {
        const { id } = await mutation({ mutation: 'createAnalyse', input })
        analyse_id = id
      } catch (err) {
        formik.setSubmitting(false)
        return
      }

      navigate(`/team-analyse/new/${analyse_id}`, { replace: true })
    } else {
      input.id = analyse.id

      analyseMutation.mutate(
        { input },
        {
          onSuccess: () => formik.setSubmitting(false),
          onError: () => formik.setSubmitting(false)
        }
      )
    }
  }

  return (
    <div className='mx-auto max-w-2xl'>
      <span className='text-3xl font-extrabold tracking-tight text-gray-900'>
        {t('team_analyse.new.create.title')}
      </span>
      <p className='mt-4 max-w-lg text-sm text-gray-900'>
        {t('team_analyse.new.create.description')}
      </p>
      <form className='mt-2'>
        {[
          {
            id: 'title',
            sr_only: t('team_analyse.new.create.input_title_label_sronly'),
            placeholder: t('team_analyse.new.create.input_title_placeholder')
          }
        ].map((input, index) => (
          <div key={index} className='mt-6'>
            <label htmlFor={input.id} className='sr-only'>
              {input.sr_only}
            </label>
            <Input
              {...input}
              onChange={formik.handleChange}
              value={formik.values[input.id]}
              error={formik.errors[input.id]}
              touched={formik.touched[input.id]}
              onBlur={formik.handleBlur}
            />
          </div>
        ))}
        {/* <div className='mt-6'>
          <SelectMenu
            id='language'
            label={t('team_analyse.new.create.language_label')}
            options={getAllLanguages()}
            defaultValue={formik.values.language}
            onChange={(language) => formik.setFieldValue('language', language)}
          />
        </div> */}
        <div className='mt-8 sm:mt-12 flex justify-end gap-x-2'>
          <Button.SecondaryLG
            onClick={() => {
              trackEvent(trackEvents.ABORT_CREATE_TEAMANALYSIS)
              navigate('/team-analyses')
            }}
            text={t('team_analyse.new.cancel_action')}
          />
          <Button.PrimaryLG
            text={t('team_analyse.new.next_action')}
            onClick={() => next(formik.values)}
            disabled={!formik.isValid}
            isLoading={formik.isSubmitting}
          />
        </div>
      </form>
    </div>
  )
}

Create.propTypes = {
  analyse: PropTypes.object.isRequired,
  analyseMutation: PropTypes.object.isRequired
}

export default Create
