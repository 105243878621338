import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DialogTitle } from '@headlessui/react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { TrashIcon } from '@heroicons/react/24/outline'

import Input from '../../input'
import Modal from '../../tailwind/Modal'
import Button from '../../tailwind/Button'
import { ROLES } from '../../../helpers/authorization-roles'
import SelectMenuWithDescription from '../../tailwind/select-menu-with-description'
import { useNotificationContext } from '../../../context'
import { query } from '../../../graphql'
import { trackEvent } from '../../../helpers/analytics'
import trackEvents from '../../../constants/track-events'

const ModalEditUser = ({ open, setOpen, data, onSuccess }) => {
  const { t } = useTranslation()
  const { success } = useNotificationContext()

  const [isUpdating, setIsUpdating] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [askForDeleting, setAskForDeleting] = useState(false)

  const { space_id, email, firstname, lastname, role_id } = data

  const roles = Object.values(ROLES).map(({ id, title, description }) => ({
    id,
    title: t(title),
    description: t(description)
  }))

  const [selectedRole, setSelectedRole] = useState(
    roles.find(({ id }) => id === role_id) || roles[1]
  )

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstname: firstname || '',
      lastname: lastname || ''
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required(
        t('settings.user_management.input_firstname_validation')
      ),
      lastname: Yup.string().required(
        t('settings.user_management.input_lastname_validation')
      )
    })
  })

  const handleCancel = () => {
    setOpen(false)
    setAskForDeleting(false)
    setTimeout(() => {
      setSelectedRole(roles[1])
      formik.resetForm()
    }, 1000 * 0.4)
  }

  const updateRole = () => {
    setIsUpdating(true)

    query({
      query: 'spaceControl',
      variables: {
        action: 'setAuthorizations',
        space_id,
        authorization_role: selectedRole.id
      }
    }).then(() => {
      success(t('settings.user_management.update_user_success'))
      onSuccess()
      setIsUpdating(false)
      setOpen(false)
    })
  }

  const deleteUser = () => {
    setIsDeleting(true)

    query({
      query: 'userControl',
      variables: { action: 'deleteSpaceForPartner', space_id }
    }).then(() => {
      onSuccess()
      setIsDeleting(false)
      setAskForDeleting(false)
      success(t('settings.user_management.delete_alert.success'))
      trackEvent(trackEvents.DELETE_USER)
    })
  }

  return (
    <>
      <Modal open={open} setOpen={handleCancel} size='lg'>
        <div className='mt-4 text-center'>
          <DialogTitle
            as='h3'
            className='text-lg leading-6 font-medium text-gray-900'
          >
            {t('settings.user_management.dialog_title_update')}
          </DialogTitle>
        </div>
        <div className='mt-6'>
          {[
            [
              'firstname',
              t('settings.user_management.input_given_name_label'),
              t('settings.user_management.input_given_name_placeholder')
            ],
            [
              'lastname',
              t('settings.user_management.input_family_name_label'),
              t('settings.user_management.input_family_name_placeholder')
            ]
          ].map((input, index) => (
            <div key={index} className='mt-4'>
              <Input
                id={input[0]}
                label={input[1]}
                placeholder={input[2]}
                type='text'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                touched={formik.touched[input[0]]}
                value={formik.values[input[0]]}
                error={formik.errors[input[0]]}
                disabled
              />
            </div>
          ))}
          <div className='mt-4'>
            <Input
              id='email'
              label={t('settings.user_management.disabled_input_email_label')}
              type='text'
              value={email}
              disabled
            />
          </div>
          <div className='mt-4'>
            <SelectMenuWithDescription
              id='role'
              label={t('settings.user_management.manage_rights_label')}
              selected={selectedRole}
              setSelected={setSelectedRole}
              options={roles}
            />
          </div>
        </div>
        <div className='mt-8 flex justify-end'>
          <div
            className='group flex items-center cursor-pointer'
            onClick={() => {
              setOpen(false)
              setAskForDeleting(true)
            }}
          >
            <div className='pt-0.5'>
              <Button.WarningText
                text={t('settings.user_management.dialog_delete_action')}
              />
            </div>
            <TrashIcon className='h-6 w-6 ml-1.5 text-red-500 group-hover:text-red-600' />
          </div>
        </div>
        <div className='mt-12 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense'>
          <Button.SecondaryLG
            text={t('settings.user_management.dialog_cancel_action')}
            onClick={handleCancel}
            disabled={isUpdating}
          />
          <Button.PrimaryLG
            text={t('settings.user_management.dialog_update_action')}
            onClick={updateRole}
            isLoading={isUpdating}
            disabled={selectedRole?.id === role_id}
          />
        </div>
      </Modal>
      <Modal
        open={askForDeleting}
        setOpen={() => {
          setAskForDeleting(false)
          setOpen(true)
        }}
        size='lg'
      >
        <span className='text-3xl font-extrabold tracking-tight text-gray-900'>
          {t('settings.user_management.delete_alert.title')}
        </span>
        <p className='mt-4 max-w-lg text-sm text-gray-900'>
          {t('settings.user_management.delete_alert.text', { email })}
        </p>
        <div className='mt-12 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense'>
          <Button.SecondaryLG
            text={t('settings.user_management.delete_alert.cancel')}
            onClick={() => {
              setAskForDeleting(false)
              setOpen(true)
            }}
            disabled={isDeleting}
          />
          <Button.WarningLG
            text={t('settings.user_management.delete_alert.delete')}
            onClick={deleteUser}
            isLoading={isDeleting}
          />
        </div>
      </Modal>
    </>
  )
}

ModalEditUser.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired
}

export default ModalEditUser
