import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { XMarkIcon } from '@heroicons/react/24/solid'

const SelectBar = ({ table, withActions }) => {
  const { t } = useTranslation()

  const number_selected = table.getSelectedRowModel().rows.length

  if (!withActions) return null
  if (number_selected === 0) return null

  return (
    <>
      <div
        id='select-bar'
        className='h-14 w-full flex flex-row justify-between items-center bg-blue-600 px-4 sm:px-6 lg:px-8'
      >
        <span className='text-white text-sm font-medium'>
          {number_selected === 1
            ? t('react_table.select_bar.one_selected')
            : t('react_table.select_bar.number_selected', {
                number: number_selected
              })}
        </span>
        <div className='flex flex-row items-center'>
          {withActions}
          {/* <div className='mx-2 h-6 w-px bg-white'></div> */}
          <button
            className='w-8 h-8 rounded-full flex items-center justify-center hover:bg-blue-700 cursor-pointer'
            onClick={() => table.resetRowSelection(true)}
          >
            <XMarkIcon className='w-6 h-6 text-white' />
          </button>
        </div>
      </div>
      <div className='h-0 w-0 border-x-8 border-x-transparent border-b-[10px] border-b-blue-600 relative rotate-180 left-8' />
    </>
  )
}

SelectBar.propTypes = {
  table: PropTypes.object.isRequired,
  withActions: PropTypes.node
}

SelectBar.defaultProps = {
  withActions: undefined
}

export default SelectBar
