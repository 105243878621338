import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useMutation } from '@tanstack/react-query'

import SEO from '../seo'
import SettingsContainer from './settings-container'
import Toggle from '../tailwind/toggle'
import Button from '../tailwind/Button'
import { mutation } from '../../graphql'
import { trackEvent } from '../../helpers/analytics'
import trackEvents from '../../constants/track-events'
import { STORAGE_KEYS, localStorage } from '../../helpers/local-storage'
import {
  useNotificationContext,
  useAivyContext,
  usePartnerContext
} from '../../context'

const TRANSLATION_LOCATION = 'components.settings.result_reports.'

const ResultReports = () => {
  const { success, error } = useNotificationContext()
  const { system } = useAivyContext()
  const { partner, refetchPartner } = usePartnerContext()

  const [isLoadingActivate, setIsLoadingActivate] = useState(false)
  const [activeSettings, setActiveSettings] = useState(
    localStorage.getItem(STORAGE_KEYS.ACTIVATE_REPORT_SETTINGS)
  )

  const { t } = useTranslation()

  const report_settings = JSON.parse(
    partner?.app_settings?.report_settings || '{}'
  )

  const isEmptySettings =
    Object.keys(report_settings).length === 0 && !activeSettings

  const { mutate, isLoading, isSuccess, isError } = useMutation({
    mutationFn: (input) => mutation({ mutation: 'updatePartner', input })
  })

  const dimensions = Object.entries(JSON.parse(system?.dimensions?.data)).map(
    ([key, value]) => ({ key, value })
  )

  const SORT_OPTIONS = [
    {
      text: t(TRANSLATION_LOCATION + 'sort.byImportance'),
      value: 'IMPORTANCE'
    },
    {
      text: t(TRANSLATION_LOCATION + 'sort.byCategory'),
      value: 'CATEGORY'
    }
  ]

  useEffect(() => {
    if (isSuccess === true) {
      success(t(TRANSLATION_LOCATION + 'success'))
      refetchPartner()
    } else if (isError === true) {
      error(t(TRANSLATION_LOCATION + 'error'))
    }
  }, [isSuccess, isError, success, error, t, refetchPartner])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      score_visible:
        report_settings?.score_visible === undefined
          ? true
          : report_settings?.score_visible,
      interpretation_guide_visible:
        report_settings?.interpretation_guide_visible === undefined
          ? true
          : report_settings?.interpretation_guide_visible,
      sort_by: report_settings.sort_by || 'IMPORTANCE',
      hidden_dimensions: report_settings.hidden_dimensions || []
    },
    onSubmit: (values) => {
      trackEvent(trackEvents.UPDATE_RESULT_REPORTS_SETTINGS)
      mutate({
        id: partner.id,
        app_settings: {
          ...(partner.app_settings || {}),
          report_settings: JSON.stringify({
            ...values
          })
        }
      })
    }
  })

  const onPressActivateSettings = () => {
    setIsLoadingActivate(true)
    setTimeout(() => {
      localStorage.setItem(
        STORAGE_KEYS.ACTIVATE_REPORT_SETTINGS,
        JSON.stringify(true)
      )
      setActiveSettings(true)
      setIsLoadingActivate(false)
    }, 400)
  }

  return (
    <div>
      <SEO title={t('seo.titles.settings_userprofile')} />
      <SettingsContainer
        title={t(TRANSLATION_LOCATION + 'title')}
        description={t(TRANSLATION_LOCATION + 'description')}
        onSubmit={isEmptySettings ? null : formik.handleSubmit}
        dirty={formik.dirty}
        isLoading={isLoading}
      >
        {isEmptySettings ? (
          <div className='text-center py-8'>
            <h2 className='text-2xl font-semibold text-gray-900'>
              {t(TRANSLATION_LOCATION + 'lockedScreen.title')}
            </h2>
            <p className='text-base text-gray-600 mt-2 mb-4'>
              {t(TRANSLATION_LOCATION + 'lockedScreen.description')}
            </p>
            <Button.PrimaryXL
              isLoading={isLoadingActivate}
              text={t(TRANSLATION_LOCATION + 'lockedScreen.button')}
              onClick={onPressActivateSettings}
            />
          </div>
        ) : (
          <div className='grid gap-y-8'>
            <div>
              <Toggle
                label={t(TRANSLATION_LOCATION + 'score.title')}
                description={t(TRANSLATION_LOCATION + 'score.description')}
                appearance={'with-left-label-and-description'}
                enabled={formik.values.score_visible}
                setEnabled={() =>
                  formik.setFieldValue(
                    'score_visible',
                    !formik.values.score_visible
                  )
                }
              />
            </div>
            <div>
              <Toggle
                label={t(TRANSLATION_LOCATION + 'interpretation_guide.title')}
                description={t(
                  TRANSLATION_LOCATION + 'interpretation_guide.description'
                )}
                appearance={'with-left-label-and-description'}
                enabled={formik.values.interpretation_guide_visible}
                setEnabled={() =>
                  formik.setFieldValue(
                    'interpretation_guide_visible',
                    !formik.values.interpretation_guide_visible
                  )
                }
              />
            </div>
            <div>
              <label
                htmlFor='location'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                {t(TRANSLATION_LOCATION + 'sort.title')}
              </label>
              <select
                id='location'
                name='location'
                className='mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6'
                defaultValue={
                  SORT_OPTIONS.filter(
                    (o) => o.value === formik.values.sort_by
                  )[0].text
                }
                onChange={(event) => {
                  const value = SORT_OPTIONS.filter(
                    (o) => o.text === event.target.value
                  )[0].value
                  formik.setFieldValue('sort_by', value)
                }}
              >
                {SORT_OPTIONS.map((o) => (
                  <option key={o.value}>{o.text}</option>
                ))}
              </select>
            </div>
            <div>
              <div className='flex flex-row items-center justify-between'>
                <div>
                  <label className='text-sm font-medium text-gray-900'>
                    {t(TRANSLATION_LOCATION + 'dimensions.title')}
                  </label>
                  <p className='text-sm text-gray-500'>
                    {t(TRANSLATION_LOCATION + 'dimensions.description')}
                  </p>
                </div>
                <Button.Text
                  text={t(TRANSLATION_LOCATION + 'dimensions.switchVisibility')}
                  className='mt-2'
                  onClick={() => {
                    const hiddenDimensions = formik.values.hidden_dimensions
                    if (hiddenDimensions.length > 0) {
                      formik.setFieldValue('hidden_dimensions', [])
                    } else {
                      formik.setFieldValue('hidden_dimensions', [
                        ...dimensions.map((d) => d.key)
                      ])
                    }
                  }}
                />
              </div>
              <div className='mt-4 flex justify-end'>
                <span className='text-sm font-normal text-gray-500 block'>
                  {t(TRANSLATION_LOCATION + 'dimensions_visible', {
                    count:
                      dimensions.length - formik.values.hidden_dimensions.length
                  })}
                </span>
              </div>
              <div className='flex flex-row flex-wrap mt-4 p-2 gap-2'>
                {dimensions.map((d) => {
                  const isHidden = formik.values.hidden_dimensions.includes(
                    d.key
                  )
                  return (
                    <div
                      onClick={() => {
                        const isHidden =
                          formik.values.hidden_dimensions.includes(d.key)

                        if (!isHidden) {
                          formik.setFieldValue('hidden_dimensions', [
                            ...formik.values.hidden_dimensions,
                            d.key
                          ])
                        } else {
                          formik.setFieldValue('hidden_dimensions', [
                            ...formik.values.hidden_dimensions.filter(
                              (dimension) => dimension !== d.key
                            )
                          ])
                        }
                      }}
                      key={d.key}
                      style={
                        !isHidden
                          ? { backgroundColor: '#4461D4' }
                          : { borderColor: '#4461D4', color: '#4461D4' }
                      }
                      className={
                        !isHidden
                          ? 'flex h-10 px-4 text-sm items-center justify-center  text-white rounded-full cursor-pointer'
                          : 'flex h-10 px-4 text-sm items-center justify-center  rounded-full cursor-pointer bg-white border'
                      }
                    >
                      {d.value.property.de}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )}
      </SettingsContainer>
    </div>
  )
}

export default ResultReports
