export const SMART_PREDICT_SPACE_STATES = {
  OPEN: {
    sortWeight: '11',
    filterValue: 'constants.smart_predict.state_open_label',
    color: 'grey'
  },
  INVITED_NEXT_ROUND: {
    sortWeight: '12',
    filterValue: 'constants.smart_predict.state_invited_next_round_label',
    color: 'orange'
  },
  HIRED: {
    sortWeight: '13',
    filterValue: 'constants.smart_predict.state_hired_label',
    color: 'green'
  },
  TRIAL_PERIOD_SURVIVED: {
    sortWeight: '14',
    filterValue: 'constants.smart_predict.state_trial_period_survived_label',
    color: 'green'
  },
  REJECTED: {
    sortWeight: '15',
    filterValue: 'constants.smart_predict.state_rejected_label',
    color: 'red'
  },
  APPLICANT_CANCELLED: {
    sortWeight: '16',
    filterValue: 'constants.smart_predict.state_applicant_cancelled_label',
    color: 'yellow'
  }
}

export const ASSESSMENT_SPACE_STATES = {
  INVITED: {
    sortWeight: '2',
    filterValue: 'talents.filter_value_invited',
    color: 'grey'
  },
  STARTED: {
    sortWeight: '3',
    filterValue: 'talents.filter_value_started',
    color: 'orange'
  },
  FULFILLED: {
    sortWeight: '4',
    filterValue: 'talents.filter_value_fulfilled',
    color: 'green'
  },
  REMINDED: {
    sortWeight: '5',
    filterValue: 'talents.filter_value_reminded',
    color: 'grey'
  },
  RESET: {
    sortWeight: '6',
    filterValue: 'talents.filter_value_reset',
    color: 'grey'
  },
  EXPIRED: {
    sortWeight: '7',
    filterValue: 'talents.filter_value_expired',
    color: 'grey'
  },
  EMAIL_BOUNCED: {
    sortWeight: '8',
    filterValue: 'talents.filter_value_email_bounced',
    color: 'red'
  }
}
