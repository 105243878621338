import { ChevronRightIcon, CheckIcon } from '@heroicons/react/20/solid'
import { ArrowsRightLeftIcon } from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next'
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition
} from '@headlessui/react'
import PropTypes from 'prop-types'

import { mutation } from '../graphql'
import { classNames } from '../helpers'
import { useAivyContext, useUserContext } from '../context'
import { useWindowDimensions } from '../hooks/use-window-dimensions'
import { trackEvent } from '../helpers/analytics'
import trackEvents from '../constants/track-events'

const SpaceSwitch = ({ isSidebarCollapsed }) => {
  const { t } = useTranslation()

  const {
    user,
    spacesByOwner,
    active_space: { authorization_role }
  } = useUserContext()

  const { restartDashboard } = useAivyContext()

  const { height } = useWindowDimensions()

  const employees = spacesByOwner.filter(
    ({ context }) => context === 'EMPLOYEE'
  )

  const selected = employees.find(
    ({ id: space_id }) => user.active_space_id === space_id
  )

  const handleOnClick = ({ space_id }) => {
    trackEvent(trackEvents.CLICK_CHANGE_LOCATION)

    mutation({
      mutation: 'updateUser',
      input: { id: user.id, active_space_id: space_id }
    }).then(restartDashboard)
  }

  if (!selected) return null

  return (
    <Popover className='relative w-full'>
      <PopoverButton
        className={classNames(
          'xrelative inline-flex items-center gap-x-1 w-full focus:outline-none',
          isSidebarCollapsed && 'justify-center'
        )}
      >
        <div
          className={classNames(
            'relative rounded-md bg-gray-100 cursor-pointer flex items-center',
            isSidebarCollapsed ? 'justify-center p-0.5' : 'p-2 w-full'
          )}
        >
          <img
            className={classNames(
              'flex-none rounded bg-white object-contain',
              isSidebarCollapsed ? 'h-9 w-9' : 'h-10 w-10'
            )}
            src={selected.partner.logo}
            alt=''
          />
          {!isSidebarCollapsed && (
            <>
              <div className='ml-2 flex flex-col items-start'>
                <p className='text-xs text-left font-medium text-gray-900 w-32 truncate'>
                  {selected.partner.name}
                </p>
                <span
                  style={{ fontSize: 10 }}
                  className='-mt-0.5 text-left text-gray-700'
                >
                  {t(authorization_role.title)}
                </span>
              </div>
              <ArrowsRightLeftIcon className='hidden sm:block ml-auto flex-shrink-0 mr-2 w-5 h-5 text-gray-900' />
            </>
          )}
          {isSidebarCollapsed ? (
            <div className='absolute w-full px-1 left-0 -bottom-1'>
              <div className='rounded-b-md bg-gray-600 h-1' />
            </div>
          ) : (
            <div className='absolute w-full px-2 left-0 -bottom-2'>
              <div className='rounded-b-md bg-gray-600 h-2' />
            </div>
          )}
        </div>
      </PopoverButton>

      <Transition
        enter='transition ease-out duration-200'
        enterFrom='opacity-0 translate-y-1'
        enterTo='opacity-100 translate-y-0'
        leave='transition ease-in duration-150'
        leaveFrom='opacity-100 translate-y-0'
        leaveTo='opacity-0 translate-y-1'
      >
        <PopoverPanel
          className={classNames(
            'absolute max-w-72 sm:w-screen sm:max-w-md z-20 mt-1 flex max-h-96 overflow-y-scroll',
            'rounded-xl bg-white shadow-lg ring-1 ring-gray-900/5',
            height < 800 ? 'bottom-16' : 'left-0'
          )}
        >
          <div className='flex-auto py-1 divide-y divide-gray-100'>
            {employees
              .sort((space) => (selected.id === space.id ? -1 : 1))
              .map((space, index) => {
                const { id: space_id, partner } = space
                const current = index === 0

                return (
                  <div
                    key={space_id}
                    onClick={
                      current ? undefined : () => handleOnClick({ space_id })
                    }
                    className={classNames(
                      'relative flex items-center sm:gap-x-3 px-3 py-2',
                      !current && 'group cursor-pointer hover:bg-gray-50'
                    )}
                  >
                    <img
                      className={classNames(
                        'hidden sm:block h-12 w-12 object-contain flex-none rounded-lg bg-gray-50 border-2',
                        current
                          ? 'border-blue-500'
                          : 'border-gray-50 group-hover:border-gray-100 group-hover:bg-gray-100'
                      )}
                      src={partner.logo}
                      alt=''
                    />
                    <div className='flex flex-col justify-center w-56 lg:w-72'>
                      <p className='text-sm text-left font-medium text-gray-900 truncate'>
                        {partner.name}
                      </p>
                      <span className='text-xs text-left text-gray-700 truncate'>
                        {partner.display_name}
                      </span>
                    </div>
                    <div className='ml-auto' />
                    {current ? (
                      <CheckIcon
                        className='sm:mr-2 pr-1 h-5 w-5 flex-none text-gray-700'
                        aria-hidden='true'
                      />
                    ) : (
                      <ChevronRightIcon
                        className='sm:mr-2 h-5 w-5 flex-none text-gray-700 group-hover:text-gray-900'
                        aria-hidden='true'
                      />
                    )}
                  </div>
                )
              })}
          </div>
        </PopoverPanel>
      </Transition>
    </Popover>
  )
}

SpaceSwitch.propTypes = { isSidebarCollapsed: PropTypes.bool.isRequired }

export default SpaceSwitch
