import { ChevronRightIcon } from '@heroicons/react/24/solid'
import PropTypes from 'prop-types'

import { classNames } from '../../helpers'

const Actions = ({ title, description, actions }) => {
  return (
    <div className='mx-auto max-w-xl'>
      <h2 className='text-base font-semibold leading-6 text-gray-900'>
        {title}
      </h2>
      <p className='mt-1 text-sm text-gray-700'>{description}</p>
      <ul
        role='list'
        className='mt-6 divide-y divide-gray-200 border-b border-t border-gray-200'
      >
        {actions.map((action, index) => (
          <li
            key={index}
            onClick={action.onClick}
            className='group relative flex items-center py-4 cursor-pointer'
          >
            <div
              className={classNames(
                action.iconColor,
                'flex h-10 w-10 items-center justify-center rounded-full shrink-0'
              )}
            >
              <action.icon className='h-6 w-6 text-white' aria-hidden='true' />
            </div>
            <div className='ml-3 flex flex-col justify-center'>
              <span className='text-sm font-medium text-gray-900'>
                {action.name}
              </span>
              <p className='text-sm text-gray-500 group-hover:text-gray-700 mt-1'>
                {action.description}
              </p>
            </div>
            <div className='ml-auto'>
              <ChevronRightIcon
                className='h-5 w-5 text-gray-300 group-hover:text-gray-900'
                aria-hidden='true'
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

Actions.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  actions: PropTypes.array.isRequired
}

export default Actions
