import { CheckIcon, MinusIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'
import { Label, Radio, RadioGroup } from '@headlessui/react'
import { useTranslation } from 'react-i18next'

import { classNames, notifyBugsnag } from '../../helpers'
import { Fragment, useState } from 'react'
import { trackEvent } from '../../helpers/analytics'
import { Button } from './Button'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  QuestionMarkCircleIcon
} from '@heroicons/react/24/outline'
import trackEvents from '../../constants/track-events'
import { query } from '../../graphql'

const formatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR'
})

// eslint-disable-next-line react/prop-types
export function Pricing({ withStripeConnection }) {
  const { t } = useTranslation()

  const frequencies = [
    {
      value: 'month',
      label: t('landingpage.pricing.frequencies.month.label'),
      priceSuffix: t('landingpage.pricing.frequencies.month.price_suffix')
    },
    {
      value: 'year',
      label: t('landingpage.pricing.frequencies.year.label'),
      priceSuffix: t('landingpage.pricing.frequencies.year.price_suffix')
    }
  ]

  const sections = [
    [
      {
        name: t('landingpage.pricing.plans.sections.core_values.title'),
        features: [
          {
            name: t(
              'landingpage.pricing.plans.sections.core_values.features.number_active_positions'
            ),
            tiers: { Lean: '5', Advanced: '10', Pro: '25' }
          },
          {
            name: t(
              'landingpage.pricing.plans.sections.core_values.features.seats'
            ),
            tiers: { Lean: '3', Advanced: '5', Pro: '10' }
          },
          {
            name: t(
              'landingpage.pricing.plans.sections.core_values.features.credits'
            ),
            tiers: { Lean: '100', Advanced: '250', Pro: '500' }
          },
          {
            name: t(
              'landingpage.pricing.plans.sections.core_values.features.languages'
            ),
            tiers: { Lean: '🇩🇪/🇬🇧', Advanced: '🇩🇪/🇬🇧', Pro: '🇩🇪/🇬🇧' }
          }
        ]
      }
    ],
    [
      {
        name: t('landingpage.pricing.plans.sections.features.title'),
        features: [
          {
            name: t(
              'landingpage.pricing.plans.sections.features.features.talentpool'
            ),
            tiers: { Lean: true, Advanced: true, Pro: true }
          },
          {
            name: t(
              'landingpage.pricing.plans.sections.features.features.din33430'
            ),
            tiers: { Lean: true, Advanced: true, Pro: true }
          },
          {
            name: t(
              'landingpage.pricing.plans.sections.features.features.challenge_config'
            ),
            tiers: { Lean: true, Advanced: true, Pro: true }
          },
          {
            name: t(
              'landingpage.pricing.plans.sections.features.features.assessment_validation'
            ),
            tiers: { Lean: true, Advanced: true, Pro: true }
          },
          {
            name: t(
              'landingpage.pricing.plans.sections.features.features.job_profiles'
            ),
            tiers: { Lean: true, Advanced: true, Pro: true }
          },
          {
            name: t(
              'landingpage.pricing.plans.sections.features.features.pdf_report'
            ),
            tiers: { Lean: true, Advanced: true, Pro: true }
          },
          {
            name: t(
              'landingpage.pricing.plans.sections.features.features.mail_templates'
            ),
            tiers: { Lean: true, Advanced: true, Pro: true }
          },
          {
            name: t(
              'landingpage.pricing.plans.sections.features.features.initial_application_flow'
            ),
            tiers: { Lean: true, Advanced: true, Pro: true }
          },
          {
            name: t(
              'landingpage.pricing.plans.sections.features.features.dsgvo'
            ),
            tiers: { Lean: true, Advanced: true, Pro: true }
          }
        ]
      },
      {
        name: t('landingpage.pricing.plans.sections.extra_features.title'),
        features: [
          {
            name: t(
              'landingpage.pricing.plans.sections.extra_features.features.skill_check.title'
            ),
            tiers: { Lean: true, Advanced: true, Pro: true },
            tooltip: t(
              'landingpage.pricing.plans.sections.extra_features.features.skill_check.tooltip'
            )
          },
          {
            name: t(
              'landingpage.pricing.plans.sections.extra_features.features.landing_page.title'
            ),
            tiers: { Lean: true, Advanced: true, Pro: true },
            tooltip: t(
              'landingpage.pricing.plans.sections.extra_features.features.landing_page.tooltip'
            )
          },
          {
            name: t(
              'landingpage.pricing.plans.sections.extra_features.features.api.title'
            ),
            tiers: { Lean: true, Advanced: true, Pro: true },
            tooltip: t(
              'landingpage.pricing.plans.sections.extra_features.features.api.tooltip'
            )
          },
          {
            name: t(
              'landingpage.pricing.plans.sections.extra_features.features.ats.title'
            ),
            tiers: { Lean: true, Advanced: true, Pro: true },
            tooltip: t(
              'landingpage.pricing.plans.sections.extra_features.features.ats.tooltip'
            )
          },
          {
            name: t(
              'landingpage.pricing.plans.sections.extra_features.features.teamanalysis.title'
            ),
            tiers: { Lean: true, Advanced: true, Pro: true },
            tooltip: t(
              'landingpage.pricing.plans.sections.extra_features.features.teamanalysis.tooltip'
            )
          },
          {
            name: t(
              'landingpage.pricing.plans.sections.extra_features.features.branding.title'
            ),
            tiers: { Lean: true, Advanced: true, Pro: true },
            tooltip: t(
              'landingpage.pricing.plans.sections.extra_features.features.branding.tooltip'
            )
          },
          {
            name: t(
              'landingpage.pricing.plans.sections.extra_features.features.cross_matching.title'
            ),
            tiers: { Lean: false, Advanced: true, Pro: true },
            tooltip: t(
              'landingpage.pricing.plans.sections.extra_features.features.cross_matching.tooltip'
            )
          },
          {
            name: t(
              'landingpage.pricing.plans.sections.extra_features.features.orientation_module.title'
            ),
            tiers: { Lean: false, Advanced: true, Pro: true },
            tooltip: t(
              'landingpage.pricing.plans.sections.extra_features.features.orientation_module.tooltip'
            )
          },
          {
            name: t(
              'landingpage.pricing.plans.sections.extra_features.features.redirect.title'
            ),
            tiers: { Lean: false, Advanced: true, Pro: true },
            tooltip: t(
              'landingpage.pricing.plans.sections.extra_features.features.redirect.tooltip'
            )
          },
          {
            name: t(
              'landingpage.pricing.plans.sections.extra_features.features.authorization_roles.title'
            ),
            tiers: { Lean: false, Advanced: false, Pro: true },
            tooltip: t(
              'landingpage.pricing.plans.sections.extra_features.features.authorization_roles.tooltip'
            )
          },
          {
            name: t(
              'landingpage.pricing.plans.sections.extra_features.features.workflows.title'
            ),
            tiers: { Lean: false, Advanced: false, Pro: true },
            tooltip: t(
              'landingpage.pricing.plans.sections.extra_features.features.workflows.tooltip'
            )
          },
          {
            name: t(
              'landingpage.pricing.plans.sections.extra_features.features.smart_predict.title'
            ),
            tiers: {
              Lean: false,
              Advanced: 'Gegen Aufpreis',
              Pro: 'Gegen Aufpreis'
            },
            tooltip: t(
              'landingpage.pricing.plans.sections.extra_features.features.smart_predict.tooltip'
            )
          }
        ]
      },
      {
        name: t('landingpage.pricing.plans.sections.care_and_support.title'),
        features: [
          {
            name: t(
              'landingpage.pricing.plans.sections.care_and_support.features.support_purchase'
            ),
            tiers: {
              Lean: 'Checkliste',
              Advanced: 'Checkliste',
              Pro: 'Individuell'
            }
          },
          {
            name: t(
              'landingpage.pricing.plans.sections.care_and_support.features.data_privacy'
            ),
            tiers: {
              Lean: 'Muster-AVV',
              Advanced: 'Muster-AVV',
              Pro: 'Muster-AVV'
            }
          },
          {
            name: t(
              'landingpage.pricing.plans.sections.care_and_support.features.support_setup'
            ),
            tiers: {
              Lean: 'Zubuchbar',
              Advanced: 'Zubuchbar',
              Pro: 'Zubuchbar'
            }
          },
          {
            name: t(
              'landingpage.pricing.plans.sections.care_and_support.features.service_level_agreement'
            ),
            tiers: {
              Lean: 'Zubuchbar',
              Advanced: 'Zubuchbar',
              Pro: 'Zubuchbar'
            }
          },
          {
            name: t(
              'landingpage.pricing.plans.sections.care_and_support.features.customer_success'
            ),
            tiers: {
              Lean: 'Help Center',
              Advanced: 'Help Center',
              Pro: 'Team'
            }
          },
          {
            name: t(
              'landingpage.pricing.plans.sections.care_and_support.features.consulting'
            ),
            tiers: {
              Lean: 'Zubuchbar',
              Advanced: 'Zubuchbar',
              Pro: 'Zubuchbar'
            }
          }
        ]
      }
    ]
  ]

  const tiers = [
    {
      name: 'Pro',
      id: 'prod_PgEoJYH5tWmnrN',
      prices: [
        {
          id: 'price_1OqsKsLOM44WR2N3ijrGCnTG',
          interval: 'month',
          unit_amount: '88900'
        },
        {
          id: 'price_1OqsKrLOM44WR2N33g33owvN',
          interval: 'year',
          unit_amount: '1066800'
        }
      ],
      href: 'https://aivy.app/gespraech-vereinbaren/',
      priceMonthly: '889€',
      description: 'Quis suspendisse ut fermentum neque vivamus non tellus.',
      mostPopular: false
    },
    {
      name: 'Advanced',
      id: 'prod_PgEnvnXRDkxX7Q',
      prices: [
        {
          id: 'price_1OqsJoLOM44WR2N34NSGYZUo',
          interval: 'month',
          unit_amount: '46900'
        },
        {
          id: 'price_1OqsJoLOM44WR2N3dPVKb7ju',
          interval: 'year',
          unit_amount: '562800'
        }
      ],
      href: 'https://aivy.app/gespraech-vereinbaren/',
      priceMonthly: '469€',
      description: 'Quis eleifend a tincidunt pellentesque. A tempor in sed.',
      mostPopular: true
    },
    {
      name: 'Lean',
      id: 'prod_PgElWeYpT3gort',
      prices: [
        {
          id: 'price_1OqsI7LOM44WR2N3RmTUoMWx',
          interval: 'month',
          unit_amount: '28900'
        },
        {
          id: 'price_1OqsI7LOM44WR2N3s9MJczYX',
          interval: 'year',
          unit_amount: '346800'
        }
      ],
      href: 'https://aivy.app/gespraech-vereinbaren/',
      priceMonthly: '289€',
      description:
        'Orci volutpat ut sed sed neque, dui eget. Quis tristique non.',
      mostPopular: false
    }
  ]

  const [frequency, setFrequency] = useState(frequencies[0])
  const [isPricingCollapsed, setIsPricingCollapsed] = useState(true)
  const [isLoadingStripe, setIsLoadingStripe] = useState(false)

  const createPaymentSession = async ({ product_id, price_id }) => {
    if (!withStripeConnection) return
    try {
      setIsLoadingStripe(product_id)

      // TODO: handle authorizations
      const email = 'tech@aivy.app'

      const stringData = await query({
        query: 'stripeControl',
        variables: {
          action: 'createPaymentSession',
          product_id,
          price_id,
          email
        }
      })

      const parsedData = JSON.parse(stringData)
      const redirectUrl = parsedData.body.createPaymentSession.res.url
      window.location.href = redirectUrl
    } catch (error) {
      notifyBugsnag(error)
    } finally {
      setIsLoadingStripe(null)
    }
  }

  return (
    <section id='pricing' aria-label='Pricing'>
      <div
        className={`bg-white ${
          withStripeConnection ? 'py-8 sm:py-16' : 'py-20 sm:py-32'
        }`}
      >
        <div className='mx-auto max-w-7xl px-6 lg:px-8'>
          <div className='mx-auto max-w-4xl text-center'>
            <h2 className='text-base font-semibold leading-7 text-blue-600'>
              {t('landingpage.pricing.title_annotation')}
            </h2>
            <p className='mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl'>
              {t('landingpage.pricing.title')}
            </p>
          </div>
          <p className='mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600'>
            {t('landingpage.pricing.sub_title')}
          </p>
          <div className='mt-16 flex justify-center'>
            <RadioGroup
              by={'value'}
              value={frequency}
              onChange={setFrequency}
              className='grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200'
            >
              <Label className='sr-only'>Payment frequency</Label>
              {frequencies.map((option) => (
                <Radio
                  key={option.value}
                  value={option}
                  className={({ checked }) =>
                    classNames(
                      checked ? 'bg-blue-600 text-white' : 'text-gray-500',
                      'cursor-pointer rounded-full px-2.5 py-1'
                    )
                  }
                >
                  <span>{option.label}</span>
                </Radio>
              ))}
            </RadioGroup>
          </div>

          {/* xs to lg */}
          <div className='mx-auto mt-12 max-w-md space-y-8 sm:mt-16 lg:hidden'>
            {tiers.map((tier) => (
              <section
                key={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? 'rounded-xl bg-gray-400/5 ring-1 ring-inset ring-gray-200'
                    : '',
                  'p-8'
                )}
              >
                <h3
                  id={tier.id}
                  className='text-sm font-semibold leading-6 text-gray-900'
                >
                  {tier.name}
                </h3>
                <p className='mt-2 flex items-baseline gap-x-1 text-gray-900'>
                  <span className='text-4xl font-bold'>
                    {formatter.format(
                      tier.prices.filter(
                        (p) => p.interval === frequency.value
                      )[0].unit_amount / 100
                    )}
                  </span>
                  <span className='text-sm font-semibold'>{`/${frequency.label}`}</span>
                </p>
                <span className='text-sm text-gray-400'>
                  {t('landingpage.pricing.min_one_year_hint')}
                </span>
                <a
                  href={withStripeConnection ? null : tier.href}
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => {
                    if (withStripeConnection) {
                      createPaymentSession({
                        product_id: tier.id,
                        price_id: tier.prices.filter(
                          (p) => p.interval === frequency.value
                        )[0].id
                      })
                    }
                    trackEvent('TRIAL_ON_CLICK_PRICING', {
                      question: tier.id
                    })
                  }}
                  aria-describedby={tier.id}
                  className={classNames(
                    tier.mostPopular
                      ? 'bg-blue-600 text-white hover:bg-blue-500'
                      : 'text-blue-600 ring-1 ring-inset ring-blue-200 hover:ring-blue-300',
                    'mt-8 cursor-pointer block rounded-full py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                  )}
                >
                  {isLoadingStripe === tier.id
                    ? t('landingpage.pricing.loading')
                    : withStripeConnection
                      ? t('landingpage.pricing.order')
                      : t('landingpage.pricing.start_now')}
                </a>
                <ul
                  role='list'
                  className='mt-10 space-y-4 text-sm leading-6 text-gray-900'
                >
                  {[...sections[0], ...sections[1]].map((section) => (
                    <li key={section.name}>
                      <ul role='list' className='space-y-4'>
                        {section.features.map((feature) =>
                          feature.tiers[tier.name] ? (
                            <li key={feature.name} className='flex gap-x-3'>
                              <CheckIcon
                                className='h-6 w-5 flex-none text-blue-600'
                                aria-hidden='true'
                              />
                              <span>
                                {feature.name}{' '}
                                {typeof feature.tiers[tier.name] ===
                                'string' ? (
                                  <span className='text-sm leading-6 text-gray-500'>
                                    ({feature.tiers[tier.name]})
                                  </span>
                                ) : null}
                              </span>
                            </li>
                          ) : null
                        )}
                      </ul>
                    </li>
                  ))}
                </ul>
              </section>
            ))}
          </div>

          {/* lg+ */}
          <div className='isolate mt-10 hidden lg:block'>
            <div className='relative -mx-8'>
              {tiers.some((tier) => tier.mostPopular) ? (
                <div className='absolute inset-x-4 inset-y-0 -z-10 flex'>
                  <div
                    className='flex w-1/4 px-4'
                    aria-hidden='true'
                    style={{
                      marginLeft: `${
                        (tiers.findIndex((tier) => tier.mostPopular) + 1) * 25
                      }%`
                    }}
                  >
                    <div className='w-full rounded-t-xl border-x border-t border-gray-900/10 bg-gray-400/5' />
                  </div>
                </div>
              ) : null}
              <table className='w-full table-fixed border-separate border-spacing-x-8 text-left'>
                <caption className='sr-only'>Pricing plan comparison</caption>
                <colgroup>
                  <col className='w-1/4' />
                  <col className='w-1/4' />
                  <col className='w-1/4' />
                  <col className='w-1/4' />
                </colgroup>
                <thead>
                  <tr>
                    <td />
                    {tiers.map((tier) => (
                      <th
                        key={tier.id}
                        scope='col'
                        className='px-6 pt-6 xl:px-8 xl:pt-8'
                      >
                        <div className='flex flex-row justify-between items-center w-full'>
                          <span className='text-sm font-semibold leading-7 text-gray-900 inline'>
                            {tier.name}
                          </span>
                          {tier.name === 'Advanced' ? (
                            <p className=' rounded-full bg-blue-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-blue-600'>
                              {t('landingpage.pricing.most_popular')}
                            </p>
                          ) : null}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope='row'>
                      <span className='sr-only'>Price</span>
                    </th>
                    {tiers.map((tier) => (
                      <td key={tier.id} className='px-6  pt-2'>
                        <div className='flex items-baseline  text-gray-900'>
                          <span className='text-4xl font-bold'>
                            {formatter.format(
                              tier.prices.filter(
                                (p) => p.interval === frequency.value
                              )[0].unit_amount / 100
                            )}
                          </span>
                          <span className='text-sm font-semibold leading-6'>
                            {`/${frequency.label}`}
                          </span>
                        </div>
                        <span className='text-sm text-gray-400'>
                          {t('landingpage.pricing.min_one_year_hint')}
                        </span>
                        <a
                          href={withStripeConnection ? null : tier.href}
                          target='_blank'
                          rel='noreferrer'
                          onClick={() => {
                            if (withStripeConnection) {
                              createPaymentSession({
                                product_id: tier.id,
                                price_id: tier.prices.filter(
                                  (p) => p.interval === frequency.value
                                )[0].id
                              })
                            }
                            trackEvent('TRIAL_ON_CLICK_PRICING', {
                              question: tier.id
                            })
                          }}
                          className={classNames(
                            tier.mostPopular
                              ? 'bg-blue-600 text-white hover:bg-blue-500'
                              : 'text-blue-600 ring-1 ring-inset ring-blue-200 hover:ring-blue-300',
                            'mt-8 cursor-pointer block py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 rounded-full'
                          )}
                        >
                          {isLoadingStripe === tier.id
                            ? t('landingpage.pricing.loading')
                            : withStripeConnection
                              ? t('landingpage.pricing.order')
                              : t('landingpage.pricing.start_now')}
                        </a>
                      </td>
                    ))}
                  </tr>
                  {sections[0].map((section, sectionIdx) => (
                    <Fragment key={section.name}>
                      <tr>
                        <th
                          scope='colgroup'
                          colSpan={4}
                          className={classNames(
                            sectionIdx === 0 ? 'pt-8' : 'pt-16',
                            'pb-4 text-sm font-semibold leading-6 text-gray-900'
                          )}
                        >
                          {section.name}
                          <div className='absolute inset-x-8 mt-4 h-px bg-gray-900/10' />
                        </th>
                      </tr>
                      {section.features.map((feature) => (
                        <tr key={feature.name}>
                          <th
                            scope='row'
                            className='py-4 text-sm font-normal leading-6 text-gray-900'
                          >
                            {feature.name}
                            <div className='absolute inset-x-8 mt-4 h-px bg-gray-900/5' />
                          </th>
                          {tiers.map((tier) => (
                            <td key={tier.id} className='px-6 py-4 xl:px-8'>
                              {typeof feature.tiers[tier.name] === 'string' ? (
                                <div className='text-center text-sm leading-6 text-gray-500'>
                                  {feature.tiers[tier.name]}
                                </div>
                              ) : (
                                <>
                                  {feature.tiers[tier.name] === true ? (
                                    <CheckIcon
                                      className='mx-auto h-5 w-5 text-blue-600'
                                      aria-hidden='true'
                                    />
                                  ) : (
                                    <MinusIcon
                                      className='mx-auto h-5 w-5 text-gray-400'
                                      aria-hidden='true'
                                    />
                                  )}

                                  <span className='sr-only'>
                                    {feature.tiers[tier.name] === true
                                      ? 'Included'
                                      : 'Not included'}{' '}
                                    in {tier.name}
                                  </span>
                                </>
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            <div className='flex w-full justify-end text-gray-400 py-1'>
              <span className='text-xs text-right w-full'>
                {t('landingpage.pricing.status')}
              </span>
            </div>
            <div className='flex justify-center py-8'>
              <Button
                onClick={() => {
                  trackEvent(trackEvents.COMPARE_PLAN_DETAILS_LANDINGPAGE)
                  setIsPricingCollapsed(!isPricingCollapsed)
                }}
                color='blue'
              >
                <span>{t('landingpage.pricing.compare_tiers_cta')}</span>
                {isPricingCollapsed ? (
                  <ChevronDownIcon className='h-4 w-4 ml-1' />
                ) : (
                  <ChevronUpIcon className='h-4 w-4 ml-1' />
                )}
              </Button>
            </div>
            {isPricingCollapsed === false && (
              <Fragment>
                <div className='relative -mx-8'>
                  {tiers.some((tier) => tier.mostPopular) ? (
                    <div className='absolute inset-x-4 inset-y-0 -z-10 flex'>
                      <div
                        className='flex w-1/4 px-4'
                        aria-hidden='true'
                        style={{
                          marginLeft: `${
                            (tiers.findIndex((tier) => tier.mostPopular) + 1) *
                            25
                          }%`
                        }}
                      >
                        <div className='w-full border-x  border-gray-900/10 bg-gray-400/5' />
                      </div>
                    </div>
                  ) : null}
                  <table className='w-full table-fixed border-separate border-spacing-x-8 text-left'>
                    <colgroup>
                      <col className='w-1/4' />
                      <col className='w-1/4' />
                      <col className='w-1/4' />
                      <col className='w-1/4' />
                    </colgroup>
                    <thead>
                      <tr>
                        <td />
                        {tiers.map((tier) => (
                          <th
                            key={tier.id}
                            scope='col'
                            className='px-6 pt-6 xl:px-8 xl:pt-8'
                          >
                            <span className='text-sm font-semibold leading-7 text-gray-900 inline'>
                              {tier.name}
                            </span>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {sections[1].map((section, sectionIdx) => (
                        <Fragment key={section.name}>
                          <tr>
                            <th
                              scope='colgroup'
                              colSpan={4}
                              className={classNames(
                                sectionIdx === 0 ? 'pt-8' : 'pt-16',
                                'pb-4 text-sm font-semibold leading-6 text-gray-900'
                              )}
                            >
                              {section.name}
                              <div className='absolute inset-x-8 mt-4 h-px bg-gray-900/10' />
                            </th>
                          </tr>
                          {section.features.map((feature) => (
                            <tr key={feature.name}>
                              <th scope='row' className='py-4 '>
                                <div className='flex flex-row items-center'>
                                  <span className='text-sm font-normal  text-gray-900'>
                                    {feature.name}
                                  </span>
                                  {feature.tooltip && (
                                    <div className='inline-block ml-1'>
                                      <Tooltip
                                        id={`${feature.name}-tooltip`}
                                        content={feature.tooltip}
                                        style={{ backgroundColor: '#182033' }}
                                        className='font-medium rounded-full max-w-sm'
                                        place='top'
                                        variant='info'
                                      />
                                      <QuestionMarkCircleIcon
                                        data-tooltip-id={`${feature.name}-tooltip`}
                                        className='h-5 w-5 text-gray-400'
                                      />
                                    </div>
                                  )}
                                </div>

                                <div className='absolute inset-x-8 mt-4 h-px bg-gray-900/5' />
                              </th>
                              {tiers.map((tier) => (
                                <td key={tier.id} className='px-6 py-4 xl:px-8'>
                                  {typeof feature.tiers[tier.name] ===
                                  'string' ? (
                                    <div className='text-center text-sm leading-6 text-gray-500'>
                                      {feature.tiers[tier.name]}
                                    </div>
                                  ) : (
                                    <>
                                      {feature.tiers[tier.name] === true ? (
                                        <CheckIcon
                                          className='mx-auto h-5 w-5 text-blue-600'
                                          aria-hidden='true'
                                        />
                                      ) : (
                                        <MinusIcon
                                          className='mx-auto h-5 w-5 text-gray-400'
                                          aria-hidden='true'
                                        />
                                      )}

                                      <span className='sr-only'>
                                        {feature.tiers[tier.name] === true
                                          ? 'Included'
                                          : 'Not included'}{' '}
                                        in {tier.name}
                                      </span>
                                    </>
                                  )}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className='flex w-full justify-end text-gray-400 py-1'>
                  <span className='text-xs text-right w-full'>
                    {t('landingpage.pricing.status')}
                  </span>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
