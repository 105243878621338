import { useEffect } from 'react'
import { usePaymentContext } from '../context'

export function useHubspotChat() {
  const { is_trial } = usePaymentContext()
  useEffect(() => {
    if (!is_trial) return

    setTimeout(() => {
      const e = window.document.getElementById(
        'hubspot-messages-iframe-container'
      )
      if (e) {
        e.style.display = 'block'
        e.style.visibility = 'visible'
      }
    }, 2000)
  }, [is_trial])
}
