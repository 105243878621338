import { useState, Fragment, useMemo } from 'react'
import Modal from '../tailwind/Modal'
import PropTypes from 'prop-types'
import { DialogTitle } from '@headlessui/react'
import { useQuery, useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { motion } from 'framer-motion'
import { CloudArrowDownIcon } from '@heroicons/react/24/solid'

import Tooltip from '../tooltip'
import Button from '../button'
import Checkbox from '../checkbox'
import { BUILD_ENV, isSet } from '../../helpers'
import InviteLink from '../invite-link'
import { trackEvent } from '../../helpers/analytics'
import { mutation, query } from '../../graphql'
import { ActivityIndicator } from '..'
import trackEvents from '../../constants/track-events'
import { useNotificationContext, usePartnerContext } from '../../context'
import { QRCodeCanvas } from 'qrcode.react'

const FIELDS = {
  email: {
    key: 'email',
    label: 'career.flex.checkbox_email_fieldlabel'
  },
  firstname: {
    key: 'firstname',
    label: 'career.flex.checkbox_firstname_fieldlabel'
  },
  lastname: {
    key: 'lastname',
    label: 'career.flex.checkbox_lastname_fieldlabel'
  },
  phone: {
    key: 'phone',
    label: 'career.flex.checkbox_phone_fieldlabel'
  },
  postcode: {
    key: 'postcode',
    label: 'career.flex.checkbox_postcode_fieldlabel'
  },
  work_experience: {
    key: 'work_experience',
    label: 'partner.flex.checkbox_work_experience_fieldlabel'
  }
}

const PublicInvitationModal = ({ open, setOpen }) => {
  const { t } = useTranslation()
  const { partner: partnerContext } = usePartnerContext()

  const {
    status,
    data: partner,
    refetch
  } = useQuery({
    queryKey: ['partner', partnerContext.id],
    queryFn: () =>
      query({ query: 'getPartner', variables: { id: partnerContext.id } })
  })

  const updatePartner = useMutation({
    mutationFn: (input) => mutation({ mutation: 'updatePartner', input })
  })

  const { success } = useNotificationContext()

  const [isEditMode, setIsEditMode] = useState(false)

  const components = useMemo(() => {
    return JSON.parse(partner.app_settings?.components || '{}')
  }, [partner])

  const editMode = !isSet(components.customLandingPageText) || isEditMode

  const initialCheckboxValues = useMemo(() => {
    if (!components.customLandingPageFields) {
      return {
        email: true,
        firstname: true,
        lastname: true,
        phone: true,
        postcode: true,
        work_experience: true
      }
    }

    return {
      email: components.customLandingPageFields.includes(FIELDS.email.key),
      firstname: components.customLandingPageFields.includes(
        FIELDS.firstname.key
      ),
      lastname: components.customLandingPageFields.includes(
        FIELDS.lastname.key
      ),
      phone: components.customLandingPageFields.includes(FIELDS.phone.key),
      postcode: components.customLandingPageFields.includes(
        FIELDS.postcode.key
      ),
      work_experience: components.customLandingPageFields.includes(
        FIELDS.work_experience.key
      )
    }
  }, [components])

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object({
      landing_page_text: Yup.string(),
      email: Yup.bool(),
      firstname: Yup.bool(),
      lastname: Yup.bool(),
      phone: Yup.bool(),
      postcode: Yup.bool(),
      work_experience: Yup.bool()
    }),
    initialValues: {
      landing_page_text: components.customLandingPageText || '',
      ...initialCheckboxValues
    },
    onSubmit: async ({
      landing_page_text,
      email,
      firstname,
      lastname,
      phone,
      postcode,
      work_experience
    }) => {
      updatePartner.mutate(
        {
          id: partner.id,
          app_settings: {
            ...(partner.app_settings || {}),
            components: JSON.stringify({
              ...components,
              customLandingPageText: landing_page_text,
              customLandingPageFields: [
                email && FIELDS.email.key,
                firstname && FIELDS.firstname.key,
                lastname && FIELDS.lastname.key,
                phone && FIELDS.phone.key,
                postcode && FIELDS.postcode.key,
                work_experience && FIELDS.work_experience.key
              ].filter((field) => field)
            })
          }
        },
        {
          onSuccess: async () => {
            await refetch()
            success(t('career.flex.update_career_success'))
            trackEvent(trackEvents.ACTIVATE_PUBLIC_LINK_INVITATION_POOL)
            formik.setSubmitting(false)
            setIsEditMode(false)
          },
          onError: () => formik.setSubmitting(false)
        }
      )
    }
  })

  const allRecognitionFieldsUnchecked = (formik) => {
    const { email, firstname, lastname, phone } = formik.values

    return !email && !firstname && !lastname && !phone
  }

  const downloadQRCodeCanvas = () => {
    const a = document.createElement('a')
    const qrcode = document.getElementById('qr-code-flex')

    a.download = `aivy-talentpool.png`
    a.href = qrcode.toDataURL()
    a.click()
  }

  const inviteLink = (partnerID) => {
    const DEVELOP_URL = new URL('https://develop.pbc.aivy.app/invitation/')
    const STAGING_URL = new URL('https://staging.pbc.aivy.app/invitation')
    const PRODUCTION_URL = new URL('https://pbc.aivy.app/invitation/')

    const param = {
      name: 'partner',
      value: partnerID
    }

    DEVELOP_URL.searchParams.set(param.name, param.value)
    STAGING_URL.searchParams.set(param.name, param.value)
    PRODUCTION_URL.searchParams.set(param.name, param.value)

    switch (BUILD_ENV) {
      case 'develop':
        return DEVELOP_URL.toString()
      case 'staging':
        return STAGING_URL.toString()
      default:
        return PRODUCTION_URL.toString()
    }
  }

  return (
    <Modal open={open} setOpen={setOpen} size={'2xl'}>
      <DialogTitle className='text-2xl font-medium leading-6 text-gray-900'>
        {t('career.assessmentsettings.public_link.title')}
      </DialogTitle>
      <span className='text-sm text-gray-500 block mt-2'>
        {t('career.assessmentsettings.public_link.description')}
      </span>
      {status === 'pending' ? (
        <div className='py-14'>
          <ActivityIndicator />
        </div>
      ) : (
        <motion.div layout>
          {!editMode ? (
            <div className='pt-8'>
              {/* ACTIVE MODE */}
              <label className='block text-sm font-medium text-gray-700'>
                {t('career.flex.url_title_partner')}
              </label>
              <div className='mt-1 max-w-3xl'>
                <InviteLink partnerID={partner.id} />
              </div>
              <div className='mt-4'>
                <h1 className='text-lg font-semibold text-gray-900'>
                  {t('career.flex.feature_section.qrcode_name')}
                </h1>
                <p className='mt-1 max-w-lg lg:max-w-2xl text-sm text-gray-700'>
                  {t('career.flex.feature_section.qrcode_description')}
                </p>
                <div
                  className='mt-3 flex p-6 rounded bg-gray-50'
                  style={{ height: 'auto', width: 160 }}
                >
                  <QRCodeCanvas
                    id='qr-code-flex'
                    size={1028}
                    style={{
                      height: 'auto',
                      maxWidth: '100%',
                      width: '100%'
                    }}
                    value={inviteLink(partnerContext.id)}
                  />
                </div>
                <div
                  style={{ maxWidth: 160 }}
                  onClick={downloadQRCodeCanvas}
                  className='mt-2 group flex justify-center items-center py-2 px-3 rounded-md bg-gray-50 hover:bg-blue-600 cursor-pointer'
                >
                  <CloudArrowDownIcon className='h-5 w-5 text-gray-500 group-hover:text-white' />
                  <span className='ml-1 text-xs font-semibold text-gray-700 group-hover:text-white'>
                    {t('career.flex.qr_code_download_action')}
                  </span>
                </div>
              </div>
              <div className='flex justify-end pt-8 pr-2'>
                <button
                  type='button'
                  className='inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
                  onClick={() => {
                    trackEvent(trackEvents.UPDATE_PUBLIC_LINK_INVITATION_POOL)
                    refetch()
                    setIsEditMode(true)
                  }}
                >
                  {t('career.flex.update_action')}
                </button>
              </div>
            </div>
          ) : (
            <Fragment>
              <div className='pt-8'>
                <div>
                  <label
                    htmlFor='landing_page_text'
                    className='block text-sm font-medium text-gray-700'
                  >
                    {t('career.flex.textarea_title')}
                  </label>
                  <div className='mt-1'>
                    <textarea
                      rows={8}
                      name='landing_page_text'
                      id='landing_page_text'
                      className='block w-full rounded-md border-gray-300 shadow-sm focus:border-primaryBlue focus:ring-primaryBlue sm:text-sm'
                      defaultValue={formik.values.landing_page_text}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder={t('career.flex.textarea_placeholder')}
                    />
                  </div>
                  <p className='mt-2 text-sm text-gray-500'>
                    {t('career.flex.textarea_description')}
                  </p>
                </div>
                {/* Fieldset with Checkboxes */}
                <div className='mt-6 max-w-3xl'>
                  <div className='flex items-center'>
                    <label className='block text-sm font-medium text-gray-700'>
                      {t('career.flex.fields_title')}
                    </label>
                    <div className='ml-2'>
                      <Tooltip
                        id='fields_title.tooltip'
                        tip={t('career.flex.fields_title.tooltip')}
                      />
                    </div>
                  </div>
                  <div className='mt-1'>
                    <fieldset className='space-y-5'>
                      <legend className='sr-only'>Formularfelder</legend>
                      {Object.keys(FIELDS)
                        .map((key) => FIELDS[key])
                        .map(({ key, label, description }) => (
                          <div className='flex' key={key}>
                            <Checkbox
                              id={key}
                              onChange={formik.handleChange}
                              checked={formik.values[key]}
                              label={t(label)}
                              description={t(description)}
                            />
                          </div>
                        ))}
                    </fieldset>
                  </div>
                </div>
                {allRecognitionFieldsUnchecked(formik) && (
                  <div className='mt-6 text-xs text-gray-700 italic text-center'>
                    {t(
                      'public_link.all_recognition_fields_unchecked_attention'
                    )}
                  </div>
                )}
              </div>
              <div className='flex justify-end pt-8 pb-6'>
                {isEditMode && (
                  <button
                    type='button'
                    className='mr-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
                    onClick={() => {
                      trackEvent(trackEvents.ABORT_PUBLIC_LINK_INVITATION_POOL)
                      setIsEditMode(false)
                    }}
                  >
                    {t('career.flex.cancel_action')}
                  </button>
                )}
                <Button
                  appearance='save'
                  text={
                    isEditMode
                      ? t('career.flex.update_action')
                      : t('career.flex.save_action')
                  }
                  onClick={formik.handleSubmit}
                  disabled={
                    !components.customLandingPageFields ? false : !formik.dirty
                  }
                  isLoading={formik.isSubmitting}
                />
              </div>
            </Fragment>
          )}
        </motion.div>
      )}
    </Modal>
  )
}

PublicInvitationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
}

export default PublicInvitationModal
