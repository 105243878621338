import PropTypes from 'prop-types'
import { classNames } from '../../helpers'

/**
 * TailwindUI Application UI / Headings / Page Headings
 * Supporting with Actions
 * https://tailwindui.com/components/application-ui/elements/badges
 */
const PageHeading = ({ withActions, title, subtitle, sticky, children }) => {
  return (
    <div
      className={classNames(
        'bg-gray-50 py-4 px-4 md:flex md:items-center md:justify-between sm:px-6 lg:px-8',
        sticky === true && 'sticky top-0 z-10',
        children ? '' : 'md:py-0 md:h-20'
      )}
    >
      {title && (
        <div className='min-w-0 flex-1'>
          <h1 className='text-2xl font-bold leading-7 text-gray-900 md:truncate md:text-3xl md:tracking-tight'>
            {title}
          </h1>
          {subtitle && (
            <span className='block flext text-xs text-gray-700'>
              {subtitle}
            </span>
          )}
        </div>
      )}
      {children && children}
      {withActions && (
        <div className='mt-4 flex md:mt-0 md:ml-4'>{withActions}</div>
      )}
    </div>
  )
}

PageHeading.propTypes = {
  withActions: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  sticky: PropTypes.bool,
  children: PropTypes.node
}

PageHeading.defaultProps = {
  withActions: undefined,
  title: undefined,
  subtitle: undefined,
  sticky: false,
  children: undefined
}

export default PageHeading
