import { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import MultiEmailInput from '../../multi-email-input'
import { EMAIL_REGEX } from '../../../helpers'
import Modal from '../../tailwind/Modal'
import Button from '../../tailwind/Button'
const TRANSLATION_LOCATION = 'components.career.invite_career_analyse_dialog.'

const InviteCareerAnalyseDialog = ({
  open,
  setOpen,
  submitItems,
  careerAnalyses,
  isLoading
}) => {
  const { t } = useTranslation()

  const [items, setItems] = useState([])
  const [value, setValue] = useState('')
  const [error, setError] = useState(null)

  const emailErrorMessage = t(TRANSLATION_LOCATION + 'input_email_validation')
  const emailSchema = Yup.object().shape({
    email: Yup.string()
      .matches(EMAIL_REGEX, emailErrorMessage)
      .required(emailErrorMessage)
  })

  const addEmailToItems = async (callback) => {
    const email = value.toLowerCase()

    emailSchema
      .validate({
        email
      })
      .then(() => {
        if (items.includes(email)) {
          throw new Error(
            t(TRANSLATION_LOCATION + 'input_email_already_specified', { email })
          )
        }

        if (
          careerAnalyses
            .map(({ person }) => person && person.email)
            .includes(email)
        ) {
          throw new Error(
            t(TRANSLATION_LOCATION + 'input_email_already_invited', { email })
          )
        }

        setItems([...items, email])
        setValue('')
        callback && callback(email)
      })
      .catch((err) => setError(err.message))
  }

  const handleSubmit = () => {
    const experts = [...items]

    const inviteExperts = (email) => {
      email && experts.push(email)
      submitItems(experts)
    }

    !value && inviteExperts()
    value && addEmailToItems(inviteExperts)
  }

  const closeModal = () => {
    setOpen(false)
    setItems([])
    setValue('')
    setError(null)
  }

  return (
    <Modal open={open} setOpen={closeModal} size='3xl'>
      <div className='max-w-lg mx-auto mt-8 pb-8'>
        <div>
          <label className='block text-sm font-medium text-gray-700'>
            {t(TRANSLATION_LOCATION + 'input_email_label')}
          </label>
          <div className='mt-1'>
            <MultiEmailInput
              value={value}
              setValue={setValue}
              items={items}
              setItems={setItems}
              error={error}
              setError={setError}
              callback={addEmailToItems}
            />
          </div>
        </div>
        <div className='mt-12 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense'>
          <Button.SecondaryLG
            appearance='w-full-white'
            text={t(TRANSLATION_LOCATION + 'dialog_cancel_action')}
            onClick={closeModal}
          />
          <Button.PrimaryLG
            appearance='w-full-base'
            text={t(TRANSLATION_LOCATION + 'dialog_submit_action')}
            disabled={!value && !items.length}
            onClick={handleSubmit}
            isLoading={isLoading}
          />
        </div>
      </div>
    </Modal>
  )
}

InviteCareerAnalyseDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  submitItems: PropTypes.func.isRequired,
  careerAnalyses: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default InviteCareerAnalyseDialog
