import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Modal from '../tailwind/Modal'
import Button from '../tailwind/Button'

const Introduction = ({ open, next, cancel }) => {
  const { t } = useTranslation()

  return (
    <Modal open={open} setOpen={cancel} size='2xl'>
      <div className='flex flex-col items-center'>
        <h2 className='mt-8 text-3xl font-extrabold tracking-tight text-gray-900'>
          {t('product_tour.introduction.title')}
        </h2>
        <p className='mt-4 text-center text-gray-700 max-w-md'>
          {t('product_tour.introduction.message')}
        </p>
        <p className='mt-4 text-center text-sm text-gray-700 max-w-md'>
          {t('product_tour.introduction.hint')}
        </p>
        <div className='mt-8 flex space-x-2'>
          <Button.SecondaryXL
            onClick={cancel}
            text={t('product_tour.introduction.cancel_action')}
          />
          <Button.PrimaryXL
            onClick={next}
            text={t('product_tour.introduction.start_action')}
          />
        </div>
      </div>
    </Modal>
  )
}

Introduction.propTypes = {
  open: PropTypes.bool.isRequired,
  next: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired
}

export default Introduction
