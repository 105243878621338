import { useVirtual } from 'react-virtual'
import { flexRender } from '@tanstack/react-table'
import PropTypes from 'prop-types'

import { classNames } from '../../helpers'

const TableBody = ({ data, disableVirtual, disableHover }) => {
  const { table, tableContainerRef } = data

  const { rows } = table.getRowModel()

  const { virtualItems: virtualRows, totalSize } = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 100
  })

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0

  const tableRows = disableVirtual ? rows : virtualRows

  return (
    <tbody className='divide-y divide-gray-200'>
      {!disableVirtual && paddingTop > 0 && (
        <tr>
          <td style={{ height: `${paddingTop}px` }} />
        </tr>
      )}
      {tableRows.map((tableRow) => {
        const row = disableVirtual ? tableRow : rows[tableRow.index]

        return (
          <tr
            key={row.id}
            className={classNames(
              disableHover ? '' : 'group cursor-pointer hover:bg-gray-50'
            )}
          >
            {row.getVisibleCells().map((cell) => (
              <td
                key={cell.id}
                className={classNames(
                  'whitespace-nowrap',
                  cell.column.columnDef.invisible ? '' : 'py-4 px-4'
                )}
                onClick={
                  cell.column.columnDef.onClick
                    ? () => cell.column.columnDef.onClick(cell)
                    : undefined
                }
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        )
      })}
      {!disableVirtual && paddingBottom > 0 && (
        <tr>
          <td style={{ height: `${paddingBottom}px` }} />
        </tr>
      )}
    </tbody>
  )
}

TableBody.propTypes = {
  data: PropTypes.object.isRequired,
  disableHover: PropTypes.bool,
  disableVirtual: PropTypes.bool
}

TableBody.defaultProps = {
  disableHover: false,
  disableVirtual: false
}

export default TableBody
