import { useTranslation } from 'react-i18next'

import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Seo } from '../components'
import Modal from '../components/tailwind/Modal'
import { usePartnerContext, useUserContext } from '../context'
import { mutation, query } from '../graphql'
import { uuidv4 } from '../helpers'
import { usePartnerIntegrationCreate } from '../hooks/use-partner-integration-create'

const ConfirmIntegration = () => {
  const [searchParams] = useSearchParams()

  // Get the value of the 'integration partner' parameter
  const integration_service = searchParams.get('integration_service')
  const external_customer_id = searchParams.get('companyId')
  const redirect = searchParams.get('redirect')
  // example localhost:3000/confirm/integration?integration_service=smartrecruiters&redirect=h[…]e40db130da0/settings&companyId=668e8ab3b9bfea0b51630258
  const { t } = useTranslation()
  const { partner } = usePartnerContext()
  const { active_space } = useUserContext()
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState(
    'Hier klicken um die Integration mit ' + integration_service + ' zuzulassen'
  )

  const createIntegration = usePartnerIntegrationCreate()

  const { refetch, data: apiKey } = useQuery({
    queryKey: ['api-keys-by-partner', partner.id],
    queryFn: () =>
      query({
        query: 'apiKeysByPartner',
        variables: { partner_id: partner.id }
      }),
    select: (items) => items[0] || null
  })

  const createApiKey = async () => {
    setIsLoading(true)
    setMessage('API KEY wird erzeugt')
    const auth_token = 'Bearer ' + Buffer.from(uuidv4()).toString('base64')
    const res = await mutation({
      mutation: 'createApiKey',
      input: {
        auth_token,
        partner_id: partner.id,
        authorized_create: ['__create_' + partner.id],
        authorized_read: ['__read_' + partner.id],
        authorized_update: ['__update_' + partner.id],
        authorized_delete: ['__delete_' + partner.id],
        owner: active_space.owner
      }
    })
    await refetch()
    setMessage('API KEY wurde erzeugt')
    setIsLoading(false)
    return res
  }

  async function confirm() {
    setIsLoading(true)
    if (integration_service.toUpperCase() === 'SMARTRECRUITERS') {
      if (!apiKey) {
        setMessage('kein API Key vorhanden')
        await createApiKey()
        return confirm()
      }
      const { auth_token } = apiKey
      const input = {
        authorized_create: ['__create_' + partner.id],
        authorized_read: ['__read_' + partner.id],
        authorized_update: ['__update_' + partner.id],
        authorized_delete: ['__delete_' + partner.id],
        partner_id: partner.id,
        integration_settings: {
          aivy_api_key: auth_token.replace('Bearer ', ''),
          external_customer_id
        },
        remote_service: 'SMARTRECRUITERS'
      }

      createIntegration.mutate(
        { input },
        {
          onSuccess: async (data) => {
            await query({
              query: 'integrationControl',
              variables: {
                action: 'confirmIntegration',
                integration_id: data.id
              }
            })
            setMessage('Confirm Integration now with SMARTRECRUITERS ')
            setTimeout(() => {
              window.location.href = redirect
            }, 2000)
          },
          onError: (error) => alert(error)
        }
      )

      // confirmIntegration mit integrationControl action: confirmIntegration und integration_id
    }
  }

  return (
    <>
      <Seo title={t('seo.titles.confirm_integration')} />
      <main className='px-4 pb-24 pt-8 sm:px-6 xl:max-w-7xl xl:px-8'>
        <Modal open={true} setOpen={() => undefined} hideCloseButton>
          <div className='text-center'>
            <h2 className='mb-4 text-2xl font-bold'>Integration bestätigen</h2>
            <p className='mb-6'>{message}</p>
            <button
              onClick={() => confirm()}
              className='rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-600'
              disabled={isLoading}
            >
              {isLoading ? 'Wird verarbeitet...' : 'Integration akzeptieren'}
            </button>
          </div>
        </Modal>
      </main>
    </>
  )
}

export default ConfirmIntegration
