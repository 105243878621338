import { Fragment } from 'react'
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition
} from '@headlessui/react'
import { ChevronDownIcon, EyeIcon } from '@heroicons/react/24/solid'
import PropTypes from 'prop-types'

import { classNames } from '../../helpers'

const Dropdown = ({ MenuButtonX, actions, beforeEnter, afterLeave }) => {
  return (
    <Menu as='div' className='relative inline-block text-left'>
      <MenuButton>
        <MenuButtonX />
      </MenuButton>

      <Transition
        as={Fragment}
        beforeEnter={beforeEnter}
        afterLeave={afterLeave}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <MenuItems className='absolute right-0 z-10 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <div className='py-2'>
            {actions.map(({ key, label, available, Icon, onClick }) => (
              <MenuItem key={key}>
                {() => (
                  <button
                    className={classNames(
                      'w-full flex text-left text-gray-700 text-sm px-4 py-2 whitespace-nowrap group',
                      available
                        ? 'hover:text-gray-900 hover:bg-gray-50'
                        : 'cursor-default opacity-30'
                    )}
                    onClick={available ? onClick : undefined}
                  >
                    {Icon && (
                      <Icon
                        className={classNames(
                          'mr-3 h-5 w-5 text-gray-500',
                          available && 'group-hover:text-gray-700'
                        )}
                        aria-hidden='true'
                      />
                    )}
                    {label}
                  </button>
                )}
              </MenuItem>
            ))}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  )
}

Dropdown.propTypes = {
  MenuButtonX: PropTypes.func,
  actions: PropTypes.array,
  beforeEnter: PropTypes.func,
  afterLeave: PropTypes.func
}

Dropdown.defaultProps = {
  MenuButtonX: () => (
    <div className='flex rounded-full bg-blue-600 py-2 px-3.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'>
      {'button_title'}
      <ChevronDownIcon className='ml-1 h-5 w-5 text-white' aria-hidden='true' />
    </div>
  ),
  actions: [
    {
      key: 'abc',
      label: 'create new item',
      available: true,
      Icon: EyeIcon,
      onClick: () => {}
    }
  ],
  beforeEnter: () => undefined,
  afterLeave: () => undefined
}

export default Dropdown
