export default {
  0: {
    label: {
      de: 'Starter (keine Berufserfahrung)',
      en: 'Starter (no work experience)'
    }
  },
  1: {
    label: {
      de: 'Junior (bis 2 Jahre Berufserfahrung)',
      en: 'Junior (up to 2 years of work experience)'
    }
  },
  2: {
    label: {
      de: 'Mid-level (3 bis 5 Jahre Berufserfahrung)',
      en: 'Mid-level (3 to 5 years of work experience)'
    }
  },
  3: {
    label: {
      de: 'Senior (über 5 Jahre Berufserfahrung)',
      en: 'Senior (over 5 years of work experience)'
    }
  }
}
