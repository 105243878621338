/* eslint-disable react/prop-types */
import { HashLink } from 'react-router-hash-link'

export function NavLink({ to, children, onClick }) {
  return (
    <HashLink
      onClick={onClick}
      to={to}
      smooth
      className='inline-block rounded-lg px-2 py-1 text-base  text-slate-700 hover:bg-slate-100 hover:text-slate-900'
    >
      {children}
    </HashLink>
  )
}
