import { colors } from '../../constants/colors'

export const styles = {
  options: {
    zIndex: 10000,
    width: '100vw'
  },
  tooltip: {
    maxWidth: 512,
    padding: 32
  },
  buttonSkip: {
    fontSize: 14,
    color: colors.darkBlack,
    fontWeight: 600,
    fontFamily: 'Fira Sans'
  },
  buttonBack: {
    fontSize: 14,
    color: colors.darkBlack,
    fontWeight: 600,
    fontFamily: 'Fira Sans'
  },
  buttonNext: {
    fontSize: 14,
    backgroundColor: colors.primaryBlue,
    borderRadius: 32,
    padding: '12px 24px',
    fontFamily: 'Fira Sans',
    fontWeight: 600
  }
}
