import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { useLanguageContext, useNotificationContext } from '../../context'
import SlideoverWithBackgroundOverlay from '../tailwind/slideover-with-background-overlay'
import Button from '../tailwind/Button'

const SlideoverConfiguration = ({
  open,
  setOpen,
  data,
  setData,
  showSpace,
  hideSpace
}) => {
  const { t } = useTranslation()
  const { error } = useNotificationContext()
  const { language } = useLanguageContext()

  const { spaces, dimensions, categories } = data

  const showDimension = ({ key }) => {
    const item = dimensions.find((d) => d.key === key)
    item.visible = true

    setData({ dimensions })
  }

  const hideDimension = ({ key }) => {
    if (dimensions.filter((d) => d.visible).length === 3) {
      error(t('team_analyse.slideover_configuration.min_3_dimension_error'))
      return
    }

    const item = dimensions.find((d) => d.key === key)
    item.visible = false

    setData({ dimensions })
  }

  const showCategory = ({ id }) => {
    const item = categories.find((c) => c.id === id)
    item.visible = true

    dimensions.forEach((d) => {
      if (d.category === id) d.visible = true
    })

    setData({ dimensions, categories })
  }

  const hideCategory = ({ id }) => {
    if (categories.filter((c) => c.visible).length === 1) {
      error(t('team_analyse.slideover_configuration.min_1_category_error'))
      return
    }

    const item = categories.find((c) => c.id === id)
    item.visible = false

    dimensions.forEach((d) => {
      if (d.category === id) d.visible = false
    })

    setData({ dimensions, categories })
  }

  return (
    <SlideoverWithBackgroundOverlay
      className='w-screen max-w-md'
      title={t('team_analyse.slideover_configuration.title')}
      open={open}
      setOpen={setOpen}
    >
      <div className='mx-auto pb-16'>
        <p className='text-sm text-gray-700'>
          {t('team_analyse.slideover_configuration.description')}
        </p>
        <div className='pt-6'>
          <h2 className='text-xl font-semibold leading-10 tracking-tight text-gray-900'>
            {t('team_analyse.slideover_configuration.spaces_title')}
          </h2>
          <div className='max-w-sm divide-y divide-gray-200'>
            {spaces.map((space, index) => (
              <div
                key={index}
                onClick={
                  space.visible
                    ? () => hideSpace(space)
                    : () => showSpace(space)
                }
                className='py-3 px-2 flex justify-between items-center group cursor-pointer hover:bg-gray-50'
              >
                <p className='text-sm text-gray-900 group-hover:font-medium'>
                  {space.name || space.identification}
                </p>
                {space.visible ? (
                  <EyeIcon className='w-6 text-gray-500 group-hover:text-gray-700' />
                ) : (
                  <EyeSlashIcon className='w-6 text-gray-500 group-hover:text-gray-700' />
                )}
              </div>
            ))}
          </div>
        </div>
        {categories.map((category, index) => (
          <div key={index} className='pt-6'>
            <div className='hidden sm:flex justify-between items-center'>
              <h2 className='text-xl font-semibold leading-10 tracking-tight text-gray-900'>
                {category.name[language]}
              </h2>
              <Button.Text
                text={t(
                  'team_analyse.slideover_configuration.trigger_all_dimensions_action'
                )}
                onClick={
                  category.visible
                    ? () => hideCategory(category)
                    : () => showCategory(category)
                }
              />
            </div>
            <div className='max-w-sm divide-y divide-gray-200'>
              {dimensions
                .filter((dimension) => dimension.category === category.id)
                .map((dimension, index) => (
                  <div
                    key={index}
                    onClick={
                      dimension.visible
                        ? () => hideDimension(dimension)
                        : () => showDimension(dimension)
                    }
                    className='py-3 px-2 flex justify-between items-center group cursor-pointer hover:bg-gray-50'
                  >
                    <p className='text-sm text-gray-900 group-hover:font-medium'>
                      {dimension.property[language]}
                    </p>
                    {dimension.visible ? (
                      <EyeIcon className='w-6 text-gray-500 group-hover:text-gray-700' />
                    ) : (
                      <EyeSlashIcon className='w-6 text-gray-500 group-hover:text-gray-700' />
                    )}
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </SlideoverWithBackgroundOverlay>
  )
}

SlideoverConfiguration.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  showSpace: PropTypes.func.isRequired,
  hideSpace: PropTypes.func.isRequired
}

export default SlideoverConfiguration
