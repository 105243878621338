import { Analytics } from 'aws-amplify'

import { NODE_ENV } from '../helpers'
import { useUserContext } from '../context/user-context'
import { usePartnerContext, usePaymentContext } from '../context'

export const useUpdateEndpoint = () => {
  const { is_trial } = usePaymentContext()
  const { partner } = usePartnerContext()
  const { active_space, cognitoUser, emailEndpoint, refetchEmailEndpoint } =
    useUserContext()

  const updateEndpoint = () => {
    const name = `${
      active_space.person.firstname || active_space.person.invitation_firstname
    } ${active_space.person.lastname || active_space.person.invitation_lastname}`

    const last_seen = emailEndpoint.User
      ? emailEndpoint.User.UserAttributes?.last_seen || []
      : []

    if (last_seen.length === 10) last_seen.shift()
    last_seen.push(new Date().toISOString())

    const userAttributes = { last_seen, name: [name] }

    if (emailEndpoint === 'RESOURCE_NOT_FOUND') {
      userAttributes.partner = [partner.id]
      userAttributes.frontend = ['aivy-customer-dashboard']
      userAttributes.receives_emails = ['PAYMENT', is_trial && 'TRIAL'].filter(
        (item) => item
      )
    }

    const { email, invitation_email } = active_space.person

    const attr = {
      address: email || invitation_email,
      userId: cognitoUser.username,
      channelType: 'EMAIL',
      userAttributes
    }

    if (NODE_ENV === 'development') {
      console.log('updateEndpoint', attr) // eslint-disable-line
      return
    }

    Analytics.updateEndpoint(attr).then(refetchEmailEndpoint)
  }

  return { updateEndpoint }
}
