import PropTypes from 'prop-types'

const ListOrdered = ({ className }) => {
  return (
    <div className={className}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='currentColor'
        viewBox='0 0 24 24'
      >
        <path d='M8 4h13v2H8V4zM5 3v3h1v1H3V6h1V4H3V3h2zM3 14v-2.5h2V11H3v-1h3v2.5H4v.5h2v1H3zm2 5.5H3v-1h2V18H3v-1h3v4H3v-1h2v-.5zM8 11h13v2H8v-2zm0 7h13v2H8v-2z'></path>
      </svg>
    </div>
  )
}

ListOrdered.propTypes = {
  className: PropTypes.string
}

ListOrdered.defaultProps = {
  className: undefined
}

export default ListOrdered
