import { useMutationAndUpdateQuery } from './use-mutation-and-update-query'
import { usePartnerContext } from '../context'

export const usePartnerIntegrationCreate = () => {
  const { partner } = usePartnerContext()

  return useMutationAndUpdateQuery({
    queryKey: ['partner-integrations', partner.id],
    mutation: 'createIntegration',
    updater: ({ prevData, response }) => {
      const newData = prevData ? [...prevData] : []
      newData.push(response)

      return newData
    }
  })
}
