import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { classNames } from '../../helpers'
import Button from '../button'

const TRANSLATION_LOCATION = 'components.settings.settings_container.'

const SettingsContainer = ({
  title,
  description,
  onSubmit,
  actions,
  dirty,
  isLoading,
  hideDescription,
  children
}) => {
  const { t } = useTranslation()

  return (
    <div>
      {!hideDescription && (
        <>
          <h1 className='text-xl font-semibold text-gray-900'>{title}</h1>
          <p className='mt-1 max-w-lg lg:max-w-2xl text-sm text-gray-700'>
            {description}
          </p>
        </>
      )}
      <div className={!hideDescription ? 'mt-5' : ''}>
        <div className='relative sm:rounded-md bg-gray-50'>
          <div
            className={classNames(
              'px-4 py-5 space-y-6 sm:p-6 sm:rounded-t-md',
              !onSubmit && 'sm:rounded-b-md'
            )}
          >
            {children}
          </div>
          {actions && (
            <div
              className={classNames(
                'flex justify-end gap-x-2',
                'px-4 py-3 bg-gray-50 sm:rounded-b-md sm:px-6'
              )}
            >
              {actions}
            </div>
          )}
          {onSubmit && (
            <div className='px-4 py-3 text-right sm:rounded-b-md sm:px-6 bg-gray-100'>
              <Button
                appearance='save'
                text={t(TRANSLATION_LOCATION + 'on_submit_action')}
                onClick={onSubmit}
                disabled={!dirty}
                isLoading={isLoading}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

SettingsContainer.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onSubmit: PropTypes.func,
  actions: PropTypes.node,
  dirty: PropTypes.bool,
  isLoading: PropTypes.bool,
  hideDescription: PropTypes.bool,
  children: PropTypes.node.isRequired
}

SettingsContainer.defaultProps = {
  title: 'Lorem Ipsum',
  description: '',
  onSubmit: undefined,
  actions: undefined,
  dirty: false,
  isLoading: false,
  hideDescription: false
}

export default SettingsContainer
