import { isUndefined } from 'lodash'

export const sortCrossComparison = (a, b, columnId) => {
  const rowA = a.getValue(columnId) || {}
  const rowB = b.getValue(columnId) || {}

  let compareA
  let compareB

  if (['blur', '-'].includes(rowA.value)) compareA = 100
  else if (isUndefined(rowA.value)) compareA = -1
  else if (!rowA.complete_dataset) compareA = 0
  else compareA = rowA.value

  if (['blur', '-'].includes(rowB.value)) compareB = 100
  else if (isUndefined(rowB.value)) compareB = -1
  else if (!rowB.complete_dataset) compareB = 0
  else compareB = rowB.value

  return compareA < compareB ? 1 : -1
}
