import { useMemo, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  // getPaginationRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  useReactTable
} from '@tanstack/react-table'
import {
  Bars3BottomLeftIcon,
  BarsArrowDownIcon,
  BarsArrowUpIcon
} from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { classNames, NODE_ENV } from '../../helpers'
import FilterBar from './filter-bar'
import { useFiltersFromURL } from '../../hooks/use-filters-from-url'
import SelectBar from './select-bar'
import { useRect } from '../../hooks/use-rect'
import TableBody from './table-body'
import { useWindowDimensions } from '../../hooks/use-window-dimensions'

const Table = ({
  data,
  columns,
  initialState,
  globalFilterFn,
  withActions,
  selectionCallback,
  disableHover,
  disableVirtual,
  displayNumberOfEntries,
  className,
  classNameTableHeader,
  withOptions,
  disableFilter
}) => {
  const tableContainerRef = useRef(null)
  const { t } = useTranslation()
  const { height: windowHeight } = useWindowDimensions()

  const {
    rect: { top: filterSelectBarTop },
    ref: filterSelectBarsRef
  } = useRect()

  const {
    rect: { height: numberOfEntriesHeight },
    ref: numberOfEntriesRef
  } = useRect()

  const [rowSelection, setRowSelection] = useState({})
  const [columnFilters, setColumnFilters] = useState([])
  const [globalFilter, setGlobalFilter] = useState('')

  const { setFilterUrl } = useFiltersFromURL({
    columns,
    setColumnFilters
  })

  const filterFns = useMemo(() => {
    const result = {}
    columns.forEach(({ filter }) => filter && (result[filter.id] = filter.fn))

    return result
  }, [columns])

  const table = useReactTable({
    data,
    columns: columns.map((col) => ({
      ...col,
      filterFn: col.filter ? col.filter.id : undefined
    })),
    initialState,
    state: {
      columnFilters,
      globalFilter,
      rowSelection
    },
    filterFns,
    globalFilterFn,
    // onColumnFiltersChange: setColumnFilters,
    enableRowSelection: true,
    // or enable row selection conditionally per row
    // enableRowSelection: row => row.original.age > 18
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: NODE_ENV === 'development'
  })

  useEffect(() => {
    selectionCallback(table)
  }, [selectionCallback, table, rowSelection])

  const filterSelectBarHeight = filterSelectBarsRef.current
    ? filterSelectBarsRef.current.clientHeight
    : 0

  const marginBottom = 32

  const tableHeight =
    windowHeight -
    (filterSelectBarTop +
      filterSelectBarHeight +
      numberOfEntriesHeight +
      marginBottom)

  return (
    <div id='react-table' className={classNames('flex flex-col', className)}>
      {!disableFilter && (
        <div ref={filterSelectBarsRef}>
          <FilterBar
            table={table}
            filters={columns
              .map(({ filter }) => filter)
              .filter((filter) => filter)}
            setFilterUrl={setFilterUrl}
            globalFilter={
              globalFilterFn && {
                value: globalFilter,
                setValue: setGlobalFilter
              }
            }
            withOptions={withOptions}
          />
          <SelectBar table={table} withActions={withActions} />
        </div>
      )}
      <div className='overflow-x-auto'>
        <div
          ref={tableContainerRef}
          className='inline-block min-w-full align-middle md:px-2 lg:px-4'
          style={
            disableVirtual
              ? undefined
              : {
                  height: tableHeight,
                  minHeight: 400,
                  overflow: 'auto'
                }
          }
        >
          <table className='min-w-full divide-y divide-gray-300'>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={classNameTableHeader}
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          className={classNames(
                            'group whitespace-nowrap flex items-center',
                            header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            header.column.columnDef.invisible
                              ? 'invisible'
                              : 'py-4 px-4'
                          )}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {header.column.getCanSort() &&
                            !header.column.getIsSorted() && (
                              <Bars3BottomLeftIcon
                                className={classNames(
                                  'ml-2 h-4 w-4 text-gray-400',
                                  'invisible group-hover:visible'
                                )}
                              />
                            )}
                          {{
                            asc: (
                              <BarsArrowUpIcon className='ml-2 h-4 w-4 text-blue-700' />
                            ),
                            desc: (
                              <BarsArrowDownIcon className='ml-2 h-4 w-4 text-blue-700' />
                            )
                          }[header.column.getIsSorted()] ?? null}
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <TableBody
              data={{ table, tableContainerRef }}
              disableHover={disableHover}
              disableVirtual={disableVirtual}
            />
          </table>
        </div>
      </div>
      {displayNumberOfEntries && (
        <div
          ref={numberOfEntriesRef}
          className='px-2 lg:px-4 h-8 flex flex-col items-end'
        >
          <div className='mt-auto md:px-2 lg:px-4'>
            <span className='block text-xs text-gray-700'>
              {t('react_table.table.entries', {
                number: table.getRowModel().rows.length
              })}
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  initialState: PropTypes.object,
  globalFilterFn: PropTypes.func,
  withActions: PropTypes.node,
  selectionCallback: PropTypes.func,
  disableHover: PropTypes.bool,
  disableVirtual: PropTypes.bool,
  displayNumberOfEntries: PropTypes.bool,
  className: PropTypes.string,
  classNameTableHeader: PropTypes.string,
  withOptions: PropTypes.node,
  disableFilter: PropTypes.bool
}

Table.defaultProps = {
  initialState: undefined,
  globalFilterFn: undefined,
  withActions: undefined,
  selectionCallback: () => {},
  disableHover: false,
  disableVirtual: false,
  displayNumberOfEntries: false,
  className: 'bg-white',
  classNameTableHeader: 'sticky top-0 bg-white',
  withOptions: null,
  disableFilter: false
}

export default Table
