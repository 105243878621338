let user = {}

export const setUser = (u) => {
  user = u || {}
}

export const getUser = () => {
  return user
}

export default user
