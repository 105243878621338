import { CAREER } from './schema/career'
import { PARTNER } from './schema/partner'
import { INTEGRATION } from './schema/integration'
import { FORM } from './schema/form'
import { SPACE } from './schema/space'
import { ANALYSE } from './schema/analyse'

export const createForm = /* GraphQL */ `
  mutation CreateForm(
    $input: CreateFormInput!
    $condition: ModelFormConditionInput
  ) {
    createForm(input: $input, condition: $condition) ${FORM}
  }
`
export const updateForm = /* GraphQL */ `
  mutation UpdateForm(
    $input: UpdateFormInput!
    $condition: ModelFormConditionInput
  ) {
    updateForm(input: $input, condition: $condition) ${FORM}
  }
`
export const updateSpace = /* GraphQL */ `
  mutation UpdateSpace(
    $input: UpdateSpaceInput!
    $condition: ModelSpaceConditionInput
  ) {
    updateSpace(input: $input, condition: $condition) ${SPACE}
  }
`
export const createPerson = /* GraphQL */ `
  mutation CreatePerson(
    $input: CreatePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    createPerson(input: $input, condition: $condition) {
      id
    }
  }
`
export const updatePerson = /* GraphQL */ `
  mutation UpdatePerson(
    $input: UpdatePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    updatePerson(input: $input, condition: $condition) {
      id
    }
  }
`
export const createCareerAnalyse = /* GraphQL */ `
  mutation CreateCareerAnalyse(
    $input: CreateCareerAnalyseInput!
    $condition: ModelCareerAnalyseConditionInput
  ) {
    createCareerAnalyse(input: $input, condition: $condition) {
      id
    }
  }
`
export const deleteCareerAnalyse = /* GraphQL */ `
  mutation DeleteCareerAnalyse(
    $input: DeleteCareerAnalyseInput!
    $condition: ModelCareerAnalyseConditionInput
  ) {
    deleteCareerAnalyse(input: $input, condition: $condition) {
      id
    }
  }
`
export const createApiKey = /* GraphQL */ `
  mutation CreateApiKey(
    $input: CreateApiKeyInput!
    $condition: ModelApiKeyConditionInput
  ) {
    createApiKey(input: $input, condition: $condition) {
      id
    }
  }
`
export const deleteApiKey = /* GraphQL */ `
  mutation DeleteApiKey(
    $input: DeleteApiKeyInput!
    $condition: ModelApiKeyConditionInput
  ) {
    deleteApiKey(input: $input, condition: $condition) {
      id
    }
  }
`
export const createCareer = /* GraphQL */ `
  mutation CreateCareer(
    $input: CreateCareerInput!
    $condition: ModelCareerConditionInput
  ) {
    createCareer(input: $input, condition: $condition) ${CAREER}
  }
`
export const updateCareer = /* GraphQL */ `
  mutation UpdateCareer(
    $input: UpdateCareerInput!
    $condition: ModelCareerConditionInput
  ) {
    updateCareer(input: $input, condition: $condition) ${CAREER}
  }
`
export const updatePartner = /* GraphQL */ `
  mutation UpdatePartner(
    $input: UpdatePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    updatePartner(input: $input, condition: $condition) ${PARTNER}
  }
`
export const deleteSpace = /* GraphQL */ `
  mutation DeleteSpace(
    $input: DeleteSpaceInput!
    $condition: ModelSpaceConditionInput
  ) {
    deleteSpace(input: $input, condition: $condition) {
      id
    }
  }
`

export const createIntegration = /* GraphQL */ `
  mutation CreateIntegration(
    $input: CreateIntegrationInput!
    $condition: ModelIntegrationConditionInput
  ) {
    createIntegration(input: $input, condition: $condition) ${INTEGRATION}
  }
`

export const deleteIntegration = /* GraphQL */ `
  mutation DeleteIntegration(
    $input: DeleteIntegrationInput!
    $condition: ModelIntegrationConditionInput
  ) {
    deleteIntegration(input: $input, condition: $condition) {
      id
    }
  }
`

export const deleteCareer = /* GraphQL */ `
  mutation DeleteCareer(
    $input: DeleteCareerInput!
    $condition: ModelCareerConditionInput
  ) {
    deleteCareer(input: $input, condition: $condition) {
      id
    }
  }
`

export const createAnalyse = /* GraphQL */ `
  mutation CreateAnalyse(
    $input: CreateAnalyseInput!
    $condition: ModelAnalyseConditionInput
  ) {
    createAnalyse(input: $input, condition: $condition) ${ANALYSE}
  }
`

export const updateAnalyse = /* GraphQL */ `
  mutation UpdateAnalyse(
    $input: UpdateAnalyseInput!
    $condition: ModelAnalyseConditionInput
  ) {
    updateAnalyse(input: $input, condition: $condition) ${ANALYSE}
  }
`
export const deleteAnalyse = /* GraphQL */ `
  mutation DeleteAnalyse(
    $input: DeleteAnalyseInput!
    $condition: ModelAnalyseConditionInput
  ) {
    deleteAnalyse(input: $input, condition: $condition) ${ANALYSE}
  }
`
export const createFeedback = /* GraphQL */ `
  mutation CreateFeedback(
    $input: CreateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    createFeedback(input: $input, condition: $condition) {
      id
      rating
      rating_text
      message
      ref_link
      context
      version
      createdAt
      updatedAt
      __typename
    }
  }
`

export const updateFeedback = /* GraphQL */ `
  mutation UpdateFeedback(
    $input: UpdateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    updateFeedback(input: $input, condition: $condition) {
      id
      owner
      rating
      rating_text
      message
      ref_link
      context
      version
      createdAt
      updatedAt
      __typename
    }
  }
`

export const createLog = /* GraphQL */ `
  mutation CreateLog(
    $input: CreateLogInput!
    $condition: ModelLogConditionInput
  ) {
    createLog(input: $input, condition: $condition) {
      id
    }
  }
`

export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
    }
  }
`
