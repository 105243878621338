import {
  useAivyContext,
  useLanguageContext,
  usePartnerContext
} from '../context'

const calculateAssessmentDuration = ({ career, partner, language }) => {
  const { app_settings } = career || partner

  const minutes =
    JSON.parse(app_settings?.challenges || '[]').length * 2.5 || 25

  return {
    de: `${minutes} Minuten`,
    en: `${minutes} minutes`
  }[language]
}

export const useMailTemplateConstants = ({ career, isCrossmode }) => {
  const { system } = useAivyContext()
  const { partner } = usePartnerContext()
  const { language: contextLanguage } = useLanguageContext()

  const language = career?.language || contextLanguage

  const getDefaultMailTemplateMacros = () => {
    return [
      {
        macro: '{{partner.display_name}}',
        label: 'mail_template.edit_mail_template.macro.companyname',
        value: `${partner.display_name || partner.name}`
      },
      career && {
        macro: '{{career.title}}',
        label: 'mail_template.edit_mail_template.macro.career_title',
        value: career.title
      }
    ].filter((macro) => macro)
  }

  const getMailTemplateMacros = () => {
    return [
      ...getDefaultMailTemplateMacros(),
      {
        macro: '{{assessment_duration}}',
        label: 'mail_template.edit_mail_template.macro.assessment_duration',
        value: calculateAssessmentDuration({ career, partner, language })
      }
    ]
      .filter((macro) => macro)
      .map((macro) => ({ ...macro, value: macro.value }))
  }

  // for reject there is no invitation_link
  const getMailTemplateMacrosReject = () => {
    return [...getDefaultMailTemplateMacros()]
      .filter((macro) => macro)
      .map((macro) => ({ ...macro, value: macro.value }))
  }

  const getMailTemplateSubjectMacros = () => {
    const mailTemplateSubjectMacros = [
      partner && {
        macro: '{{partner.display_name}}',
        value: partner.display_name || partner.name,
        description:
          'mail_template.edit_mail_template.subject_input_description_company_signature'
      },
      career && {
        macro: '{{career.title}}',
        value: career.title,
        description:
          'mail_template.edit_mail_template.subject_input_description_position_title'
      }
    ].filter((macro) => macro)

    return mailTemplateSubjectMacros.map((macro) => ({
      ...macro,
      value: macro.value
    }))
  }

  const getDefaultMailTemplate = () => {
    const {
      defaultCareerMailTemplate,
      defaultPartnerMailTemplate,
      defaultCrossmodeMailTemplate
    } = system

    const result = {}

    if (career) {
      result.subject = defaultCareerMailTemplate.subject[language]
      result.data = defaultCareerMailTemplate.data[language]
    } else if (isCrossmode) {
      result.subject = defaultCrossmodeMailTemplate.subject[language]
      result.data = defaultCrossmodeMailTemplate.data[language]
    } else {
      result.subject = defaultPartnerMailTemplate.subject[language]
      result.data = defaultPartnerMailTemplate.data[language]
    }

    return { ...result, data: JSON.stringify(result.data) }
  }

  const getDefaultReminderMailTemplate = () => {
    const {
      defaultCareerMailTemplateReminder,
      defaultPartnerMailTemplateReminder
    } = system

    const result = {}

    if (career) {
      result.subject = defaultCareerMailTemplateReminder.subject[language]
      result.data = defaultCareerMailTemplateReminder.data[language]
    } else {
      result.subject = defaultPartnerMailTemplateReminder.subject[language]
      result.data = defaultPartnerMailTemplateReminder.data[language]
    }

    return { ...result, data: JSON.stringify(result.data) }
  }

  const getDefaultRejectMailTemplate = () => {
    const {
      defaultCareerMailTemplateReject,
      defaultPartnerMailTemplateReject
    } = system

    const result = {}

    if (career) {
      result.subject = defaultCareerMailTemplateReject.subject[language]
      result.data = defaultCareerMailTemplateReject.data[language]
    } else {
      result.subject = defaultPartnerMailTemplateReject.subject[language]
      result.data = defaultPartnerMailTemplateReject.data[language]
    }

    return { ...result, data: JSON.stringify(result.data) }
  }

  return {
    getDefaultMailTemplate,
    getDefaultReminderMailTemplate,
    getDefaultRejectMailTemplate,
    getMailTemplateMacros,
    getMailTemplateMacrosReject,
    getMailTemplateSubjectMacros
  }
}
