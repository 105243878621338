import { useEffect, useState } from 'react'
import {
  AcademicCapIcon,
  XMarkIcon,
  CheckIcon
} from '@heroicons/react/24/solid'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import { classNames } from '../../helpers'
import { useUserContext } from '../../context'

const percentage = (arr) =>
  Math.round((arr.filter((bool) => bool).length / arr.length) * 100)

const Guide = ({ data, insideTutorial, toggleTutorials }) => {
  const { t } = useTranslation()
  const { active_space } = useUserContext()

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { spaces, careers, employees } = data

  const features = [
    {
      id: 'CAREER',
      name: t('trial.guide.steps.first'),
      checked: careers.filter(
        ({ id, status, archived }) =>
          !id.includes('sample_') && status === 'ACTIVE' && !archived
      ).length,
      // navigate into the first created career
      onClick: () => {
        const firstCreated = careers
          .filter(({ id, archived }) => !id.includes('sample_') && !archived)
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .pop()

        if (!firstCreated) {
          navigate('/career/new')
          return
        }

        navigate(`/career/${firstCreated.id}`)
      }
    },
    {
      id: 'TALENT',
      name: t('trial.guide.steps.second'),
      checked: spaces.length,
      onClick: () => navigate('/talents')
    },
    {
      id: 'TEAMMEMBER',
      name: t('trial.guide.steps.third'),
      checked: employees.length > 1,
      onClick: () => navigate('/settings#user')
    }
  ]

  const [displayGuide, setDisplayGuide] = useState(
    !!active_space.ui_settings_general.display_trial_guide
  )

  useEffect(() => {
    insideTutorial && setDisplayGuide(true)
  }, [insideTutorial, pathname])

  return (
    <div className='relative hidden md:block'>
      <div
        className='w-12 h-12 flex justify-center items-center bg-blue-600 shadow-md rounded-full cursor-pointer hover:bg-blue-500'
        onClick={() => {
          active_space.updateUiSettingsGeneral({
            key: 'display_trial_guide',
            value: !displayGuide
          })
          setDisplayGuide(!displayGuide)
        }}
      >
        {displayGuide ? (
          <XMarkIcon className='w-7 h-7 text-white' />
        ) : (
          <AcademicCapIcon className='w-7 h-7 text-white' />
        )}
      </div>
      {displayGuide ? (
        <motion.div
          id='guide'
          initial={{ scale: 0 }}
          animate={{ rotate: 360, scale: 1 }}
          transition={{
            type: 'spring',
            stiffness: 150,
            damping: 20
          }}
          className='absolute w-80 bg-white left-12 bottom-12 z-30 mt-2 rounded-md shadow-md'
        >
          <div className='p-4 rounded-t-md bg-blue-600'>
            <span className='block text-lg font-semibold text-white'>
              {t('trial.guide.title')}
            </span>
            <div className='flex items-center gap-x-2'>
              <span className='text-sm text-white'>
                {percentage(features.map(({ checked }) => checked))}%
              </span>
              <div className='relative w-48 h-2 bg-blue-700 rounded-sm'>
                <div
                  style={{
                    width: `${percentage(
                      features.map(({ checked }) => checked)
                    )}%`
                  }}
                  className='absolute top-0 left-0 h-2 bg-white rounded-sm'
                />
              </div>
            </div>
          </div>
          <div className='p-4 divide-y divide-gray-200'>
            {features.map(({ id, name, checked, onClick }) => (
              <div
                key={id}
                className={classNames(
                  'p-2 flex items-center gap-x-2',
                  checked ? '' : 'cursor-pointer hover:bg-slate-50'
                )}
                onClick={checked ? undefined : onClick}
              >
                <div
                  className={classNames(
                    'w-8 h-8 rounded-full flex justify-center items-center',
                    checked ? 'bg-green-100' : ''
                  )}
                >
                  {checked ? (
                    <CheckIcon
                      className='h-5 w-5 text-green-800'
                      aria-hidden='true'
                    />
                  ) : (
                    <div className='h-4 w-4 rounded-full border-4 border-gray-200'></div>
                  )}
                </div>
                <span
                  className={classNames(
                    'text-sm  text-gray-900',
                    checked ? 'font-normal line-through' : 'font-semibold'
                  )}
                >
                  {name}
                </span>
              </div>
            ))}
          </div>
          <div className='p-4 pt-2 flex justify-end'>{toggleTutorials()}</div>
        </motion.div>
      ) : null}
    </div>
  )
}

Guide.propTypes = {
  data: PropTypes.object.isRequired,
  insideTutorial: PropTypes.bool.isRequired,
  toggleTutorials: PropTypes.func.isRequired
}

export default Guide
