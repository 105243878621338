/* This example requires Tailwind CSS v2.0+ */
import PropTypes from 'prop-types'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { classNames } from '../helpers'

const Announcement = ({ text, onClick, onClose, type }) => (
  <div
    className={classNames(
      'relative',
      type === 'ERROR' ? 'bg-red-400' : 'bg-blue-600',
      onClick && 'cursor-pointer hover:bg-blue-500'
    )}
    onClick={onClick}
  >
    <div className='max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8'>
      <div className='pr-16 sm:text-center sm:px-16'>
        <p className='text-white text-sm'>
          <span>{text}</span>
        </p>
      </div>
      {onClose && (
        <div className='absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start'>
          <button
            type='button'
            className={classNames(
              'flex p-2 rounded-md hover:bg-blue-500',
              type === 'ERROR' && 'hover:bg-red-400'
            )}
          >
            <span className='sr-only'>Dismiss</span>
            <XMarkIcon
              onClick={onClose}
              className='h-6 w-6 text-white'
              aria-hidden='true'
            />
          </button>
        </div>
      )}
    </div>
  </div>
)

Announcement.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  type: PropTypes.oneOf(['INFO', 'ERROR'])
}

Announcement.defaultProps = {
  text: '',
  onClick: undefined,
  onClose: undefined,
  type: 'INFO'
}

export default Announcement
