import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  Fragment
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  ArrowDownTrayIcon as ArrowDownTrayIconSolid,
  EllipsisHorizontalIcon,
  Square3Stack3DIcon,
  UserGroupIcon,
  XMarkIcon
} from '@heroicons/react/24/solid'
import { formatDistance, subSeconds } from 'date-fns'
import { de, enUS } from 'date-fns/locale'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  CubeTransparentIcon,
  ArrowDownTrayIcon as ArrowDownTrayIconOutline
} from '@heroicons/react/24/outline'
import { rankItem } from '@tanstack/match-sorter-utils'
import { motion } from 'framer-motion'
import { HashLoader } from 'react-spinners'
import { PlusIcon, EnvelopeIcon, LinkIcon } from '@heroicons/react/20/solid'
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition
} from '@headlessui/react'

import ActivityIndicator from '../components/activity-indicator'
import NetworkError from '../components/network-error'
import CreateSpaceModal from '../components/talents/create-space-modal'
import { PublicInvitationModal, Seo } from '../components'
import Button from '../components/tailwind/Button'
import { query } from '../graphql'
import Table from '../components/react-table/table'
import PageHeading from '../components/tailwind/PageHeading'
import { trackEvent } from '../helpers/analytics'
import Slideover from '../components/tailwind/slideover'
import { S3_AIVY_LOGO, classNames, uuidSmall } from '../helpers'
import { colors } from '../constants/colors'
import { useSpaceActions } from '../hooks/use-space-actions'
import SetSpaceState from '../components/actions/set-space-state'
import DeleteSpace from '../components/actions/delete-space'
import SendReminderBulk from '../components/actions/send-reminder-bulk'
import ResetExpired from '../components/actions/reset-expired'
import { sortCrossComparison } from '../helpers/sortings'
import Paywall from '../components/paywall'
import trackEvents from '../constants/track-events'
import { useTalentsQuery } from '../hooks/use-talents-query'
import { useMount } from '../hooks/use-mount'
import {
  useLanguageContext,
  useProductTourContext,
  useAivyContext,
  usePartnerContext,
  usePaymentContext,
  useNotificationContext,
  useUserContext
} from '../context'
import ReinviteSpaceModal from '../components/talents/reinvite-space-modal'
import Tooltip from '../components/tooltip'
import { createTalentTableColumns } from '../helpers/spaces-table-columns'
import CustomerEffortScore from '../components/customer-effort-score'
import Dropdown from '../components/tailwind/dropdown'
import CSVExport from '../components/actions/csv-export'
import { useSetState } from '../hooks/use-set-state'
import HelpSupportHint from '../components/HelpSupportHint'
import StorePartnerLogoModal from '../components/talents/store-partner-logo-modal'

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2
    }
  }
}

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
}

const TRANSLATION_LOCATION = 'pages.talents.'
const locale = { de, en: enUS }

const Talents = () => {
  const { t } = useTranslation()

  const { language } = useLanguageContext()
  const { is_trial, crossComparison } = usePaymentContext()
  const { partner } = usePartnerContext()
  const { active_space } = useUserContext()

  const { adminInsights } = useAivyContext()
  const { alert } = useNotificationContext()
  const { state: navigationState } = useLocation()

  const [isCustomerEffortScoreVisible, setIsCustomerEffortScoreVisible] =
    useState(false)

  // use ref because more than one request could be in process
  const crossmodeLoading = useRef([])

  // const [guided_steps] = useState(talents_steps)

  const navigate = useNavigate()

  useEffect(() => {
    const storageSearchParams = localStorage.getItem('/talents')

    if (storageSearchParams) {
      navigate({ search: storageSearchParams }, { replace: true })
    }
  }, [navigate])

  const {
    setProductTourState,
    productTourState: { tourActive }
  } = useProductTourContext()

  useMount(() => {
    if (tourActive) {
      setTimeout(() => {
        setProductTourState({ run: true })
      }, 0.4 * 1000)
    }
  })

  const [selectionState, setSelectionState] = useSetState({
    spaces: [],
    career_ids: []
  })

  const [time, setTime] = useState(null)
  const [showCreateSpaceModal, setShowCreateSpaceModal] = useState(false)
  const [showStorePartnerLogo, setShowStorePartnerLogo] = useState(false)
  const [invitationType, setInvitationType] = useState(null)
  const [showReinviteSpaceModal, setShowReinviteSpaceModal] = useState(null)
  const [isPublicInvitationModalVisible, setIsPublicInvitationModalVisible] =
    useState(false)
  const [crossmode, setCrossmode] = useState(false)
  const [matchBatchIfYouCan, setMatchBatchIfYouCan] = useState({})
  const [resetTableKey, setResetTableKey] = useState(uuidSmall())
  const [spaceForStateCTA, setSpaceForStateCTA] = useState(null)
  const [rerender, setRerender] = useState(uuidSmall())
  const [csvExportModalState, setCsvExportModalState] = useSetState({
    open: false,
    state: null
  })

  const {
    actions: spaceActions,
    displaySendReminder,
    setDisplaySendReminder,
    displaySetSpaceState,
    setDisplaySetSpaceState,
    displayDeleteSpace,
    setDisplayDeleteSpace,
    displayResetExpired,
    setDisplayResetExpired
  } = useSpaceActions({ spaces: selectionState.spaces })

  useEffect(() => {
    if (navigationState === null) return

    if (navigationState.inviteTalentpool === true) {
      setTimeout(() => {
        setShowCreateSpaceModal(true)
      }, 300)
    }
  }, [navigationState])

  useEffect(() => {
    // fetch only if crossmode gets active
    if (!crossmode) return

    // or a career gets added / removed
    if (!selectionState.career_ids.length) return

    const fetchCrossmode = () => {
      crossmodeLoading.current.push('fetching')
      setRerender(uuidSmall())

      query({
        query: 'matchBatchIfYouCan',
        variables: {
          careers: selectionState.career_ids,
          users: selectionState.spaces
            .map(({ owner }) => owner)
            .filter((owner) => owner)
        }
      }).then((response) => {
        setMatchBatchIfYouCan(JSON.parse(response))
        crossmodeLoading.current.pop()
        setRerender(uuidSmall())
      })
    }

    fetchCrossmode()
  }, [selectionState, crossmode])

  const { status, data, refetch, dataUpdatedAt, fetchStatus } =
    useTalentsQuery()

  const fetchingSubtitle = useMemo(() => {
    if (status === 'error') return null

    // data updated at defaults to 0 for an empty cache
    if (!dataUpdatedAt) {
      return t('talents.fetching_subtitle.is_loading')
    }

    if (fetchStatus === 'fetching') {
      return t('talents.fetching_subtitle.is_updating')
    }

    return t('talents.fetching_subtitle.last_updated_at', {
      time, // only used here for the use memo dependencies
      distance_sub_seconds: formatDistance(
        subSeconds(new Date(dataUpdatedAt), 3),
        new Date(),
        {
          locale: locale[language]
        }
      )
    })
  }, [status, dataUpdatedAt, fetchStatus, time, language, t])

  useEffect(() => {
    // update fetching subtitle every minute
    const interval = setInterval(() => setTime(Date.now()), 1000 * 60)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const careers_map = useMemo(() => {
    return new Map([
      ...(data ? data.careers : []).map((career) => [career.id, career]),
      ['TALENTPOOL', { title: t('talents.talentpool_title') }],
      ['ATTRACT', { title: 'Attract' }]
    ])
  }, [data, t])

  const handleNavigate = useCallback(
    (cell) => {
      const { id: space_id } = cell.row.original

      navigate(`/talent/${space_id}`)
    },
    [navigate]
  )

  const columns = useMemo(
    () =>
      createTalentTableColumns({
        adminInsights,
        careers_map,
        data,
        handleNavigate,
        language,
        locale,
        partner,
        t,
        actions: {
          setSpaceForStateCTA: (space) => {
            setSpaceForStateCTA(space)
            trackEvent(trackEvents.PRESS_SPACE_SET_STATE_TALENTTABLE)
          }
        }
      }),
    [data, careers_map, handleNavigate, adminInsights, t, partner, language]
  )

  const globalFilterFn = useCallback(
    (row, columnId, value, addMeta) => {
      let columnValue = row.getValue(columnId)

      if (columnId === 'assessment_id') {
        columnValue = (careers_map.get(columnValue) || {}).title
      }

      const itemRank = rankItem(columnValue, value)

      addMeta({ itemRank })

      return itemRank.passed
    },
    [careers_map]
  )

  const selectionCallback = useCallback(
    (table) => {
      setSelectionState({
        spaces: table
          .getSelectedRowModel()
          .rows.map(({ original, toggleSelected }) => ({
            ...original,
            removeSelected: () => toggleSelected(false)
          }))
      })
    },
    [setSelectionState]
  )

  if (status === 'pending') {
    return <ActivityIndicator />
  }

  // rerender is always set, we only need a trigger
  if (status === 'error' || !rerender) {
    return (
      <div className='w-full'>
        <Seo title={t('seo.titles.talents')} />
        <NetworkError />
      </div>
    )
  }

  const handleOnClickInviteSpace = ({ event, type }) => {
    setInvitationType(type)
    trackEvent(event)

    if (
      !active_space.has_seen_store_partner_logo_hint &&
      partner.logo === S3_AIVY_LOGO
    ) {
      setShowStorePartnerLogo(true)
      return
    }

    setShowCreateSpaceModal(true)
  }

  const closeStorePartnerLogoModal = () => {
    setShowStorePartnerLogo(false)
    setTimeout(() => setShowCreateSpaceModal(true), 0.3 * 1000)
  }

  const pageHeadingParams = {
    title: t('seo.titles.talents'),
    subtitle: fetchingSubtitle,
    withActions: (
      <div className='flex flex-wrap items-center gap-x-2'>
        <Button.SecondaryXL
          text={t('career.assessmentsettings.public_link.title')}
          onClick={() => {
            trackEvent('pressedPublicPartnerInvitationButton')
            setIsPublicInvitationModalVisible(true)
          }}
        />
        <Menu as='div' className='relative inline-block text-left'>
          <MenuButton
            id='btn-invite-talent'
            type='button'
            className='rounded-full w-40 inline-flex items-center justify-center gap-x-1 bg-blue-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
          >
            <PlusIcon className='-ml-0.5 h-5 w-5' aria-hidden='true' />
            {t(TRANSLATION_LOCATION + 'open_create_space_modal')}
          </MenuButton>
          <Transition
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <MenuItems className='absolute right-0 z-10 mt-2 w-40 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
              <div className='py-1'>
                <MenuItem>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        handleOnClickInviteSpace({
                          event: trackEvents.INVITE_VIA_EMAIL,
                          type: 'EMAIL'
                        })
                      }}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'group flex items-center px-4 py-2 text-sm w-full'
                      )}
                    >
                      <EnvelopeIcon
                        className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                        aria-hidden='true'
                      />
                      {t(
                        TRANSLATION_LOCATION +
                          'open_create_space_modal_viaemail'
                      )}
                    </button>
                  )}
                </MenuItem>
                <MenuItem>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        handleOnClickInviteSpace({
                          event: trackEvents.INVITE_VIA_LINK,
                          type: 'LINK'
                        })
                      }}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'group flex items-center px-4 py-2 text-sm w-full'
                      )}
                    >
                      <LinkIcon
                        className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                        aria-hidden='true'
                      />
                      {t(
                        TRANSLATION_LOCATION + 'open_create_space_modal_vialink'
                      )}
                    </button>
                  )}
                </MenuItem>
              </div>
            </MenuItems>
          </Transition>
        </Menu>
      </div>
    )
  }

  if (!data.spaces.length) {
    return (
      <>
        <Seo title={t('seo.titles.talents')} />
        <PageHeading {...pageHeadingParams} />
        <div className='px-4 flex flex-col justify-center items-center'>
          <UserGroupIcon
            className='mx-auto mt-24 h-16 w-16 text-gray-700'
            aria-hidden='true'
          />
          <h3 className='mt-4 text-xl text-center font-medium text-gray-900'>
            {t(TRANSLATION_LOCATION + 'title')}
          </h3>
          <p className='mt-4 max-w-xl text-sm text-center text-gray-700 whitespace-pre-line'>
            {t(TRANSLATION_LOCATION + 'introduction')}
          </p>
        </div>
        <CreateSpaceModal
          data={{ ...data, careers_map }}
          refetchSpaces={refetch}
          open={showCreateSpaceModal}
          invitationType={invitationType}
          closeCreateSpaceModal={() => setShowCreateSpaceModal(false)}
          openCreateSpaceModal={() => setShowCreateSpaceModal(true)}
        />
        <StorePartnerLogoModal
          open={showStorePartnerLogo}
          setOpen={closeStorePartnerLogoModal}
        />
        <PublicInvitationModal
          open={isPublicInvitationModalVisible}
          setOpen={setIsPublicInvitationModalVisible}
        />
      </>
    )
  }

  const displayDefaultActionNotAvailableHint = () => {
    alert(
      t('talents.action_not_availabe.modal_title'),
      [t('talents.action_not_availabe.modal_text')],
      [
        {
          text: t('talents.action_not_availabe.modal_close_action'),
          style: 'cancel'
        }
      ]
    )
  }

  const displayCrossmodeActionNotAvailableHint = () => {
    alert(
      t('talents.crossmode_not_availabe.modal_title'),
      [t('talents.crossmode_not_availabe.modal_text')],
      [
        {
          text: t('talents.crossmode_not_availabe.modal_close_action'),
          style: 'cancel'
        }
      ]
    )
  }

  const displayCrossmodeMax18SpacesHint = () => {
    alert(
      t('talents.crossmode_max_18.modal_title'),
      [t('talents.crossmode_max_18.modal_text')],
      [
        {
          text: t('talents.crossmode_max_18.modal_close_action'),
          style: 'cancel'
        }
      ]
    )
  }

  return (
    <>
      <Seo title={t('seo.titles.talents')} />
      <PageHeading {...pageHeadingParams} />

      <Table
        key={resetTableKey}
        data={data.spaces}
        columns={columns}
        globalFilterFn={globalFilterFn}
        initialState={{ sorting: [{ id: 'updatedAt', desc: true }] }}
        withActions={
          <motion.div
            variants={container}
            initial='hidden'
            animate='visible'
            className='mr-8 flex items-center gap-x-2'
          >
            {[
              {
                id: 'action-crossmode',
                onClick: () => {
                  const { spaces: s } = selectionState
                  if (s.length > 18) displayCrossmodeMax18SpacesHint()
                  else setCrossmode(true)
                },
                available: partner.isScoreVisible,
                handleActionNotAvailable:
                  displayCrossmodeActionNotAvailableHint,
                icons: { outline: CubeTransparentIcon },
                label: t('react_table.select_bar.action_crossmode')
              },
              {
                id: 'csv-export',
                onClick: () => {
                  setCsvExportModalState({ open: true, state: 'selected' })
                },
                label: t('talents.options.csv_export_title'),
                available: true,
                icons: { outline: ArrowDownTrayIconOutline }
              },
              ...spaceActions.map((action) => ({
                ...action,
                handleActionNotAvailable: displayDefaultActionNotAvailableHint
              }))
            ].map(
              (
                {
                  id,
                  onClick,
                  available,
                  handleActionNotAvailable,
                  icons,
                  label
                },
                index
              ) => {
                const Icon = icons.outline

                return (
                  <div key={index}>
                    <motion.button
                      id={id}
                      data-tooltip-id={id}
                      className={classNames(
                        'group px-1 h-8 rounded-full flex items-center justify-center',
                        'hover:bg-blue-700 cursor-pointer'
                      )}
                      onClick={available ? onClick : handleActionNotAvailable}
                      variants={item}
                    >
                      <Icon
                        className={classNames(
                          'w-6 h-6',
                          available ? 'text-white' : 'text-blue-400'
                        )}
                      />
                    </motion.button>
                    <ReactTooltip
                      id={id}
                      className='font-medium rounded-full'
                      content={label}
                      style={{ backgroundColor: '#182033' }}
                      place='top'
                      variant='info'
                    />
                  </div>
                )
              }
            )}
          </motion.div>
        }
        selectionCallback={selectionCallback}
        disableVirtual={is_trial}
        displayNumberOfEntries
        withOptions={
          <Dropdown
            MenuButtonX={() => (
              <div className='h-7 w-7 bg-gray-100 rounded-full flex justify-center items-center group hover:bg-gray-200 cursor-pointer'>
                <EllipsisHorizontalIcon className='h-5 w-5 text-gray-700 group-hover:text-gray-900' />
              </div>
            )}
            actions={[
              {
                key: 'csv-export',
                label: t('talents.options.csv_export_title'),
                available: true,
                Icon: ArrowDownTrayIconSolid,
                onClick: () =>
                  setCsvExportModalState({ open: true, state: 'all' })
              },
              {
                key: 'recover-talents',
                label: t('talents.options.recover_talents_title'),
                available: true,
                Icon: Square3Stack3DIcon,
                onClick: () => navigate('/recover/talents')
              }
            ]}
          />
        }
      />

      <Slideover open={crossmode} setOpen={() => setCrossmode(false)}>
        <PageHeading
          title={t('talents.crossmode.title')}
          withActions={
            <button
              type='button'
              className={classNames(
                'flex justify-center items-center',
                'w-8 h-8 bg-gray-200 hover:bg-gray-300 rounded-full',
                'group focus:outline-none'
              )}
              onClick={() => setCrossmode(false)}
            >
              <span className='sr-only'>Close panel</span>
              <XMarkIcon
                className='h-6 w-6 text-gray-700 group-hover:text-gray-900'
                aria-hidden='true'
              />
            </button>
          }
          sticky
        />
        {crossComparison ? null : (
          <div className='mb-4 py-4'>
            <Paywall type='static' paywall='general' />
          </div>
        )}
        <div className='bg-gray-100'>
          <div className='py-3 px-4 sm:px-6 lg:px-8 md:flex md:items-center'>
            <div className='flex flex-wrap items-center gap-1'>
              {data.careers
                .filter(({ archived }) => !archived)
                .map(({ id, title }) => {
                  const { career_ids } = selectionState
                  const active = career_ids.includes(id)

                  const handleOnClick = () => {
                    if (active) {
                      setSelectionState({
                        career_ids: career_ids.filter(
                          (activeID) => activeID !== id
                        )
                      })
                    } else {
                      if (career_ids.length === 3) return

                      setSelectionState({ career_ids: [...career_ids, id] })
                    }
                  }

                  return (
                    <span
                      key={id}
                      onClick={handleOnClick}
                      className={classNames(
                        'inline-flex border items-center rounded-full py-1.5 px-3 text-sm font-medium cursor-pointer',
                        active
                          ? 'text-white bg-gray-700 border-gray-900'
                          : 'bg-white text-gray-900 border-gray-200'
                      )}
                    >
                      <span>{title}</span>
                    </span>
                  )
                })}
            </div>
          </div>
        </div>
        {crossmodeLoading.current.length ? (
          <div className='mt-32 flex justify-center'>
            <HashLoader
              size={128}
              color={colors.carnationPink}
              speedMultiplier={0.8}
            />
          </div>
        ) : (
          <Table
            data={selectionState.spaces.map((space) => {
              const matching = {}
              const { isScoreVisible } = partner

              const hasCompleteDataset = { current: true }

              Object.keys(matchBatchIfYouCan[space.owner] || {}).forEach(
                (career_id) => {
                  const { matching_score, complete_dataset } =
                    matchBatchIfYouCan[space.owner][career_id][0]

                  // if there is only one with !complete_dataset,
                  // we should display invite again
                  if (!complete_dataset) hasCompleteDataset.current = false

                  if (complete_dataset && isScoreVisible) {
                    matching[career_id] = {
                      value: crossComparison
                        ? Math.round(matching_score)
                        : 'blur',
                      complete_dataset
                    }
                  } else if (complete_dataset && !isScoreVisible) {
                    matching[career_id] = {
                      value: crossComparison ? '-' : 'blur',
                      complete_dataset
                    }
                  } else {
                    matching[career_id] = {
                      value: t('talents.crossmode.complete_dataset_false'),
                      complete_dataset
                    }
                  }
                }
              )

              return {
                ...space,
                ...matching,
                hasCompleteDataset: hasCompleteDataset.current
              }
            })}
            columns={[
              {
                accessorKey: 'identification',
                accessorFn: (row) => row.identification || row.email,
                enableSorting: false,
                header: (
                  <span className='text-sm font-semibold text-gray-900'>
                    {t('talents.column_header_identification')}
                  </span>
                ),
                cell: ({ getValue, cell }) => {
                  const { id, hasCompleteDataset, all_challenges_unlocked } =
                    cell.row.original

                  const alreadyReinvited =
                    all_challenges_unlocked && !hasCompleteDataset

                  const showReinviteOption =
                    !all_challenges_unlocked && !hasCompleteDataset

                  return (
                    <div>
                      <span className='block text-sm font-medium text-gray-900'>
                        {getValue()}
                      </span>
                      {showReinviteOption && (
                        <Button.Text
                          onClick={() => {
                            trackEvent(
                              trackEvents.CLICK_REQUEST_CHALLENGES_CROSSMODE
                            )

                            setCrossmode(false)
                            setShowReinviteSpaceModal(id)
                          }}
                          text={t('talents.open_reinvite_space_modal_action')}
                        />
                      )}
                      {alreadyReinvited && (
                        <span className='text-xs text-gray-700 italic'>
                          {t('talents.already_reinvited_hint')}
                        </span>
                      )}
                    </div>
                  )
                }
              },
              ...selectionState.career_ids.map((id) => ({
                accessorKey: id,
                sortDescFirst: false,
                sortingFn: sortCrossComparison,
                header: (
                  <span className='text-sm font-semibold text-gray-900'>
                    {careers_map.get(id).title}
                  </span>
                ),
                cell: ({ getValue }) => {
                  const { complete_dataset, value } = getValue() || {}
                  const displayTooltip = !complete_dataset && value

                  return (
                    <div className='flex items-center'>
                      <span
                        className={classNames(
                          'text-sm text-gray-500',
                          complete_dataset ? null : 'italic',
                          value === 'blur' ? 'blur' : null
                        )}
                      >
                        {value || t('talents.crossmode.no_owner')}
                      </span>
                      {displayTooltip && (
                        <div className='ml-2'>
                          <Tooltip
                            id='complete_dataset_false_tooltip'
                            place='top'
                            tip={t(
                              'talents.crossmode.complete_dataset_false_tooltip'
                            )}
                          />
                        </div>
                      )}
                    </div>
                  )
                }
              })),
              {
                accessorKey: 'remove',
                enableSorting: false,
                header: '',
                cell: ({ row }) => (
                  <XMarkIcon
                    onClick={row.original.removeSelected}
                    className='ml-auto w-5 h-5 text-gray-900 hover:cursor-pointer'
                  />
                )
              }
            ]}
            disableHover
          />
        )}
        <div className='h-16' />
      </Slideover>
      <CreateSpaceModal
        data={{ ...data, careers_map }}
        refetchSpaces={refetch}
        open={showCreateSpaceModal}
        invitationType={invitationType}
        closeCreateSpaceModal={() => setShowCreateSpaceModal(false)}
        openCreateSpaceModal={() => setShowCreateSpaceModal(true)}
      />
      {showReinviteSpaceModal && (
        <ReinviteSpaceModal
          space={selectionState.spaces.find(
            ({ id }) => id === showReinviteSpaceModal
          )}
          updateHandler={refetch}
          open={!!showReinviteSpaceModal}
          setOpen={() => {
            setCrossmode(true)
            setShowReinviteSpaceModal(null)
          }}
        />
      )}
      <PublicInvitationModal
        open={isPublicInvitationModalVisible}
        setOpen={setIsPublicInvitationModalVisible}
      />
      <SetSpaceState
        spaces={selectionState.spaces}
        updateHandler={() => {
          refetch()
          setResetTableKey(uuidSmall())
        }}
        open={displaySetSpaceState}
        setOpen={setDisplaySetSpaceState}
        setIsCustomerEffortScoreVisible={setIsCustomerEffortScoreVisible}
      />
      <SetSpaceState
        spaces={spaceForStateCTA ? [spaceForStateCTA] : []}
        updateHandler={() => {
          refetch()
          setResetTableKey(uuidSmall())
        }}
        open={!!spaceForStateCTA}
        setOpen={() => setSpaceForStateCTA(null)}
        setIsCustomerEffortScoreVisible={setIsCustomerEffortScoreVisible}
      />
      <CustomerEffortScore
        open={isCustomerEffortScoreVisible}
        setOpen={setIsCustomerEffortScoreVisible}
        context='TALENT-STATE'
      />
      <DeleteSpace
        spaces={[...selectionState.spaces]}
        updateHandler={() => {
          refetch().then(() => {
            setResetTableKey(uuidSmall())
            trackEvent(trackEvents.DELETE_TALENTS)
          })
        }}
        open={displayDeleteSpace}
        setOpen={setDisplayDeleteSpace}
      />
      <SendReminderBulk
        data={{ spaces: selectionState.spaces }}
        updateHandler={() => {
          refetch()
          setResetTableKey(uuidSmall())
        }}
        open={displaySendReminder}
        setOpen={setDisplaySendReminder}
      />
      <ResetExpired
        spaces={selectionState.spaces}
        updateHandler={({ onSuccess }) =>
          refetch().then(() => {
            onSuccess()
            setResetTableKey(uuidSmall())
          })
        }
        open={displayResetExpired}
        setOpen={setDisplayResetExpired}
      />
      <CSVExport
        state={csvExportModalState}
        setState={setCsvExportModalState}
        spaces={
          selectionState.spaces.length ? selectionState.spaces : data.spaces
        }
        careers_map={careers_map}
      />
      <HelpSupportHint />
      <StorePartnerLogoModal
        open={showStorePartnerLogo}
        setOpen={closeStorePartnerLogoModal}
      />
    </>
  )
}

export default Talents
