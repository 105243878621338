/* eslint-disable no-extra-semi */
/* eslint-disable eqeqeq */
/* eslint-disable no-var */
/* eslint-disable one-var */
/* eslint-disable no-undef */
import { useEffect } from 'react'

import { trackEvent } from '../helpers/analytics'
import { localStorage } from '../helpers/local-storage'
import { useLanguageContext } from '../context'

import Tryout from './tryout'

const TryoutDe = () => {
  const { changeLanguage } = useLanguageContext()

  useEffect(() => {
    changeLanguage('de')
    trackEvent('Visited_TryOut')
    localStorage.removeItem('signup_email')
  }, [changeLanguage])

  return (
    <div>
      <Tryout lang={'de'} />
    </div>
  )
}

export default TryoutDe
