import PropTypes from 'prop-types'

import { classNames } from '../../helpers'

const SpaceDetailItem = ({ term, details, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={classNames('sm:col-span-1', onClick && 'group cursor-pointer')}
    >
      <dt className='text-sm text-gray-500'>{term}</dt>
      <dd className='mt-1 text-md font-medium text-gray-900 group-hover:text-blue-600'>
        {details}
      </dd>
    </div>
  )
}

SpaceDetailItem.propTypes = {
  term: PropTypes.string.isRequired,
  details: PropTypes.any.isRequired,
  onClick: PropTypes.func
}

SpaceDetailItem.defaultProps = {
  onClick: undefined
}

export default SpaceDetailItem
