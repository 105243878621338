import { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { DialogTitle } from '@headlessui/react'

import { mutation } from '../../graphql'
import Modal from '../tailwind/Modal'
import Button from '../tailwind/Button'
import { useNotificationContext, useUserContext } from '../../context'
import {
  updateCareerHistory,
  UPDATE_ARCHIVED
} from '../../helpers/career-history'

const RecoverCareersAction = ({ careers, updateHandler, open, setOpen }) => {
  const { t } = useTranslation()
  const { cognitoUser } = useUserContext()
  const { success } = useNotificationContext()

  const [isLoading, setIsLoading] = useState(false)

  const recoverCareers = () => {
    setIsLoading(true)

    const recoverCareer = ({ id: career_id, history, status }) => {
      return mutation({
        mutation: 'updateCareer',
        input: {
          id: career_id,
          archived: false,
          history: updateCareerHistory(
            history,
            UPDATE_ARCHIVED,
            'ARCHIVED',
            status,
            cognitoUser.username
          )
        }
      })
    }

    Promise.all(careers.map((career) => recoverCareer(career)))
      .then(() => {
        updateHandler()

        success(t('recover_careers.recover_career_success'))
        setIsLoading(false)
        setOpen(false)
      })
      .catch(() => setIsLoading(false))
  }

  return (
    <Modal open={open} setOpen={() => setOpen(false)} size='lg'>
      <DialogTitle className='text-2xl font-medium leading-6 text-gray-900 text-center'>
        {t('recover_careers.recover_action')}
      </DialogTitle>
      <span className='text-base text-gray-600 block mt-2 text-center'>
        {t('recover_careers.recover_description')}
      </span>
      <div className='flex flex-row gap-3 mt-8'>
        <Button.SecondaryXL
          fullWidth
          text={t('recover_careers.recover_cancel_action')}
          onClick={() => setOpen(false)}
        />
        <Button.PrimaryXL
          fullWidth
          text={
            careers.length === 1
              ? t('recover_careers.recover_action_single', {
                  count: careers.length
                })
              : t('recover_careers.recover_action_multiple', {
                  count: careers.length
                })
          }
          onClick={recoverCareers}
          isLoading={isLoading}
        />
      </div>
    </Modal>
  )
}

RecoverCareersAction.propTypes = {
  careers: PropTypes.array.isRequired,
  updateHandler: PropTypes.func,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
}

RecoverCareersAction.defaultProps = {
  updateHandler: () => undefined
}

export default RecoverCareersAction
