import { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { trackEvent } from '../../helpers/analytics'
import Button from '../button'
import EditMailTemplate from '../mail-template/edit-mail-template'
import trackEvents from '../../constants/track-events'
import { mutation, query } from '../../graphql'
import { useMutationAndUpdateQuery } from '../../hooks/use-mutation-and-update-query'
import Modal from '../tailwind/Modal'
import {
  useNotificationContext,
  usePartnerContext,
  useUserContext
} from '../../context'
import Datepicker from '../react-datepicker/datepicker'
import { useMailTemplates } from '../../hooks/use-mail-templates'

const TRANSLATION_LOCATION = 'components.career.send_reminder.'

const SendReminder = ({ space, refetchSpace, career, open, setOpen }) => {
  const { t } = useTranslation()
  const { partner } = usePartnerContext()
  const { success } = useNotificationContext()
  const { cognitoUser } = useUserContext()

  const partnerMutation = useMutationAndUpdateQuery({
    queryKey: ['partner', partner.id],
    mutation: 'updatePartner'
  })

  const careerMutation = useMutationAndUpdateQuery({
    queryKey: ['career', career?.id],
    mutation: 'updateCareer'
  })

  const { mailTemplateMacros, mailTemplateReminder } = useMailTemplates({
    career
  })

  const [isLoading, setIsLoading] = useState(false)
  const [expiresAt, setExpiresAt] = useState(
    new Date(space.expiresAt) > new Date()
      ? new Date(space.expiresAt)
      : new Date(Date.now() + 12096e5) // fortnight away
  )
  const [showEditMailTemplateReminder, setShowEditMailTemplateReminder] =
    useState(false)

  const remindTalent = async () => {
    setIsLoading(true)

    const event = {
      eventName: 'SYSTEM',
      afterStatus: 'REMINDED',
      createdAt: new Date().toISOString(),
      eventFiredBy: cognitoUser.username
    }

    expiresAt.setHours(23)
    expiresAt.setMinutes(59)
    expiresAt.setSeconds(59)

    try {
      await mutation({
        mutation: 'updateSpace',
        input: {
          id: space.id,
          history: (space.history || []).concat(event),
          expiresAt: expiresAt.toISOString()
        }
      })

      const variables = {
        space_id: space.id,
        email: space.email,
        language: space.language,
        career_id: space.career_id,
        partner_id: space.partner_id,
        email_template: 'remind'
      }

      await query({ query: 'inviteTalent', variables })
    } catch (err) {
      setIsLoading(false)
      return
    }

    refetchSpace()
    setTimeout(() => {
      trackEvent(trackEvents.REMIND_TALENT)
      success(t(TRANSLATION_LOCATION + 'send_reminder_success'))
      setOpen(false)
      setIsLoading(false)
    }, 1000 * 0.5)
  }

  return (
    <>
      <Modal open={open} setOpen={() => setOpen(false)} size='3xl'>
        <div className='mt-3 text-center sm:mt-5'>
          <h2 className='text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
            {t(TRANSLATION_LOCATION + 'send_reminder_modal_title')}
          </h2>
        </div>
        <div className='mt-12 mb-8 mx-4 flex justify-between items-center'>
          <div>
            <span className='block text-sm font-medium text-gray-700'>
              {t(TRANSLATION_LOCATION + 'mail_template_dropdown_label')}
            </span>
            <span className='mt-1 block text-sm font-semibold text-gray-900'>
              {mailTemplateReminder.subject}
            </span>
          </div>
          <Button
            appearance='xs'
            text={t(TRANSLATION_LOCATION + 'edit_mail_template_action')}
            onClick={() => {
              setOpen(false)
              setTimeout(
                () => setShowEditMailTemplateReminder(true),
                1000 * 0.4
              )
            }}
          />
        </div>
        <div className='mt-8 mx-4'>
          <p className='block text-sm font-medium text-gray-700'>
            {t(TRANSLATION_LOCATION + 'reset_expired_title')}
          </p>
          <div className='mt-2'>
            <Datepicker
              selected={expiresAt}
              onChange={(date) => setExpiresAt(date)}
              minDate={new Date()}
            />
          </div>
          <p className='text-gray-500 text-xs mt-2 italic'>
            {t(TRANSLATION_LOCATION + 'reset_expired_description')}
          </p>
        </div>
        <div className='mt-12 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense md:flex md:justify-center'>
          <Button
            text={t(TRANSLATION_LOCATION + 'send_reminder_modal_cancel_action')}
            onClick={() => setOpen(false)}
            appearance='w-full-gray'
            className='md:w-80'
          />
          <Button
            text={t(TRANSLATION_LOCATION + 'send_reminder_modal_submit_action')}
            onClick={remindTalent}
            appearance='w-full-base'
            className='md:w-80'
            isLoading={isLoading}
          />
        </div>
      </Modal>
      <EditMailTemplate
        open={showEditMailTemplateReminder}
        setOpen={() => {
          setShowEditMailTemplateReminder(false)
          setTimeout(() => setOpen(true), 1000 * 0.4)
        }}
        mailTemplate={mailTemplateReminder}
        mailTemplateMacros={mailTemplateMacros}
        handleSubmit={({ result, onSuccess, onError }) => {
          if (career) {
            const app_settings = career.app_settings
            app_settings.mail_template_reminder = result

            const input = { id: career.id, app_settings }
            careerMutation.mutate({ input }, { onSuccess, onError })
          } else {
            const app_settings = partner.app_settings || {}
            app_settings.mail_template_reminder = result

            const input = { id: partner.id, app_settings }
            partnerMutation.mutate({ input }, { onSuccess, onError })
          }
        }}
      />
    </>
  )
}

SendReminder.propTypes = {
  space: PropTypes.object.isRequired,
  refetchSpace: PropTypes.func.isRequired,
  career: PropTypes.object,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
}

SendReminder.defaultProps = {
  career: null
}

export default SendReminder
