import { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { ActivityIndicator, Seo } from '../components'
import { trackEvent } from '../helpers/analytics'
import { classNames } from '../helpers'
import LayoutPadding from '../components/layout-padding'
import CreateNewCareer from '../components/create-career/create-new-career'
import Steps from '../components/tailwind/steps'
import CareerAnalyse from '../components/create-career/career-analyse'
import Overview from '../components/create-career/overview'
import SelectChallenges from '../components/create-career/configuration'
import Button from '../components/tailwind/Button'
import PageHeading from '../components/tailwind/PageHeading'
import { mutation, query } from '../graphql'
import trackEvents from '../constants/track-events'
// import { useMutationAndUpdateQuery } from '../hooks/use-mutation-and-update-query'
import { useNotificationContext, usePartnerContext } from '../context'
import { useSampleData } from '../hooks/use-sample-data'
import Error404 from './error-404'

const CreateCareer = () => {
  const { id } = useParams()
  const { t } = useTranslation()
  const { partner } = usePartnerContext()
  const { success, alert } = useNotificationContext()

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [isDeleting, setIsDeleting] = useState(false)

  const {
    data: career,
    status,
    refetch
  } = useQuery({
    queryKey: ['create-career', id],
    queryFn: () => {
      const promise = new Promise((resolve) => {
        query({ query: 'getCareer', variables: { id } }).then((c) => {
          if (!c) return resolve(null)

          const { career_preset_id } = JSON.parse(c.career_analyse || '{}')

          if (career_preset_id) {
            const queryKey = ['career-preset', career_preset_id]

            const presetCache = queryClient.getQueryCache().find({ queryKey })
            if (presetCache?.state?.status === 'success') return resolve(c)

            queryClient
              .prefetchQuery({
                queryKey,
                queryFn: () =>
                  query({
                    query: 'publicSqlQuery',
                    variables: {
                      action: 'query-career-preset',
                      input: JSON.stringify({ career_preset_id })
                    }
                  })
              })
              .then(() => resolve(c))
          } else resolve(c)
        })
      })

      return promise
    },
    // /career/new || /career/new/:id
    enabled: !!id
  })

  const { deleteSampleData } = useSampleData()

  // amplify bug! - updateCareer: res - { career_analyse: null }
  // const careerMutation = useMutationAndUpdateQuery({
  //  queryKey: ['create-career', id],
  //  mutation: 'updateCareer'
  // })

  const careerMutation = {
    // implement react-query use mutation functionality with refetch
    mutate: async ({ input }, callbacks = {}) => {
      const { onSuccess, onError } = callbacks

      let response
      // amplify bug! - updateCareer: res - { career_analyse: null }
      try {
        response = await mutation({ mutation: 'updateCareer', input })
      } catch (err) {
        onError && onError()
        return
      }

      await refetch()
      onSuccess && onSuccess(response)
    }
  }

  const deleteCareer = () => {
    alert(
      t('create_career.delete_career_title'),
      [
        t('create_career.delete_career_description_1'),
        t('create_career.delete_career_description_2')
      ],
      [
        {
          onPress: () => {
            setIsDeleting(true)

            const onPromiseResolve = () => {
              setIsDeleting(false)
              success(t('create_career.delete_career_success_message'))
              navigate('/careers')
            }

            if (career.id.includes('sample')) {
              trackEvent(trackEvents.DELETE_DEMO_DATA)

              deleteSampleData({
                onSuccess: () => setTimeout(onPromiseResolve, 0.5 * 1000)
              })

              return
            }

            query({
              query: 'careerControl',
              variables: {
                action: 'deleteCareerAndAnalyses',
                career_id: career.id
              }
            })
              .then(() => setTimeout(onPromiseResolve, 0.5 * 1000))
              .catch(() => setIsDeleting(false))
          },
          text: t('create_career.delete_career_confirm_action'),
          style: 'destructive'
        },
        {
          text: t('create_career.delete_career_cancel_action'),
          style: 'cancel'
        }
      ]
    )
  }

  // /career/new || /career/new/:id
  if (!!id && status === 'pending') {
    return (
      <div className='mx-12'>
        <ActivityIndicator />
      </div>
    )
  }

  if (!!id && !career) {
    return <Error404 />
  }

  // career is duplicated if copy_from_career_id is set
  const isDuplicate = !!career?.copy_from_career_id

  const { status: career_status } = career || {}
  const steps = [
    {
      id: '01',
      name: t('create_career.step_1_title'),
      current: !career_status,
      complete: ['WAIT_EXPERTS', 'WAIT_CONFIG', 'WAIT_ACTIVE'].includes(
        career_status
      )
    },
    {
      id: '02',
      name: t('create_career.step_2_title'),
      current: career_status === 'WAIT_EXPERTS',
      complete: ['WAIT_CONFIG', 'WAIT_ACTIVE'].includes(career_status)
    },
    {
      id: '03',
      name: t('create_career.step_3_title'),
      current: career_status === 'WAIT_CONFIG',
      complete: ['WAIT_ACTIVE'].includes(career_status)
    },
    {
      id: '04',
      name: t('create_career.step_4_title'),
      current: career_status === 'WAIT_ACTIVE' || career_status === 'ACTIVE',
      complete: false
    }
  ]

  return (
    <>
      <Seo title={career?.title || t('create_career.title')} />
      <PageHeading
        title={career?.title || t('create_career.title')}
        withActions={
          <div
            className={classNames('ml-auto', career?.id ? 'block' : 'hidden')}
          >
            <Button.SecondaryXL
              text={t('create_career.delete_career_action')}
              onClick={deleteCareer}
              isLoading={isDeleting}
            />
          </div>
        }
      />
      <LayoutPadding padding='pt-4 pb-12'>
        <div className='max-w-5xl mx-auto'>
          {isDuplicate ? null : (
            <div className='mt-6'>
              <Steps steps={steps} />
            </div>
          )}
          <div className='relative mt-8'>
            <div>
              {!career_status && (
                <CreateNewCareer
                  career={career || { partner_id: partner.id }}
                  careerMutation={careerMutation}
                  isDuplicate={isDuplicate}
                />
              )}
              {career_status === 'WAIT_EXPERTS' && (
                <CareerAnalyse
                  career={career}
                  careerMutation={careerMutation}
                />
              )}
              {career_status === 'WAIT_CONFIG' && (
                <SelectChallenges
                  career={career}
                  careerMutation={careerMutation}
                />
              )}
              {career_status === 'WAIT_ACTIVE' && (
                <Overview career={career} careerMutation={careerMutation} />
              )}
            </div>
          </div>
        </div>
      </LayoutPadding>
    </>
  )
}

export default CreateCareer
