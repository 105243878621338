import { useTranslation } from 'react-i18next'
import { XCircleIcon } from '@heroicons/react/24/solid'

const TRANSLATION_LOCATION = 'components.network_error.'

const NetworkError = () => {
  const { t } = useTranslation()

  return (
    <div className='my-4 rounded-md bg-red-100 p-4'>
      <div className='flex'>
        <div className='flex-shrink-0'>
          <XCircleIcon className='h-5 w-5 text-red-400' aria-hidden='true' />
        </div>
        <div className='ml-3'>
          <h3 className='text-sm font-medium text-red-800'>
            {t(TRANSLATION_LOCATION + 'network_error_title')}
          </h3>
          <div className='mt-2 text-sm text-red-700'>
            <p>{t(TRANSLATION_LOCATION + 'network_error_description')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NetworkError
