import { useEffect } from 'react'
import { Hub } from 'aws-amplify'

const Logout = () => {
  useEffect(() => {
    Hub.dispatch('auth', { event: 'signOut' })
  }, [])

  return null
}

export default Logout
